import {
  CollegePlayer,
  YahooProfileMatchData,
  YahooSyncableData
} from "rivals/shared/interfaces/Athlete";
import { AdminAthleteAction, AdminAthleteActions } from "./actions";

export type State = {
  adminCollegePlayer?: CollegePlayer;
  error?: string;
  submitting: boolean;
  yahooProfileMatchData?: YahooProfileMatchData;
  yahooSyncData?: YahooSyncableData;
};

const initialState: State = {
  adminCollegePlayer: undefined,
  error: undefined,
  submitting: false,
  yahooSyncData: undefined
};

export default function reducer(
  state: State = initialState,
  action: AdminAthleteAction
): State {
  switch (action.type) {
    case AdminAthleteActions.GET_YAHOO_SYNC_DATA_FAILURE:
    case AdminAthleteActions.LINK_YAHOO_COLLEGE_PROFILE_FAILURE:
    case AdminAthleteActions.SYNC_YAHOO_COLLEGE_PROFILE_FAILURE:
    case AdminAthleteActions.UPDATE_COLLEGE_PROFILE_FAILURE:
    case AdminAthleteActions.YAHOO_PROFILE_SEARCH_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        submitting: false
      };
    case AdminAthleteActions.GET_ADMIN_COLLEGE_PLAYER_FAILURE:
      return {
        ...state,
        submitting: false
      };
    case AdminAthleteActions.GET_YAHOO_SYNC_DATA:
    case AdminAthleteActions.LINK_YAHOO_COLLEGE_PROFILE:
    case AdminAthleteActions.SYNC_YAHOO_COLLEGE_PROFILE:
    case AdminAthleteActions.UPDATE_COLLEGE_PROFILE:
    case AdminAthleteActions.YAHOO_PROFILE_SEARCH:
      return {
        ...state,
        error: undefined,
        submitting: true
      };
    case AdminAthleteActions.GET_ADMIN_COLLEGE_PLAYER_SUCCESS:
    case AdminAthleteActions.LINK_YAHOO_COLLEGE_PROFILE_SUCCESS:
    case AdminAthleteActions.SYNC_YAHOO_COLLEGE_PROFILE_SUCCESS:
    case AdminAthleteActions.UPDATE_COLLEGE_PROFILE_SUCCESS:
      return {
        ...state,
        adminCollegePlayer: action.payload.collegePlayer,
        submitting: false
      };
    case AdminAthleteActions.YAHOO_PROFILE_SEARCH_SUCCESS:
      return {
        ...state,
        submitting: false,
        yahooProfileMatchData: action.payload
      };
    case AdminAthleteActions.GET_YAHOO_SYNC_DATA_SUCCESS:
      return {
        ...state,
        submitting: false,
        yahooSyncData: action.payload
      };
    case AdminAthleteActions.CLEAR_YAHOO_SYNC_DATA:
      return {
        ...state,
        yahooSyncData: undefined
      };
    default:
      return state;
  }
}
