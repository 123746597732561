import { createSlice } from "@reduxjs/toolkit";
import { Author } from "rivals/shared/interfaces/Author";

import { getAuthor } from "./actions";

export type State = {
  author?: Author;
  authorArticles?: boolean[];
  authors?: Author[];
  error?: string;
  submitting: boolean;
};

export const initialState: State = {
  author: undefined,
  authorArticles: undefined,
  authors: undefined,
  error: undefined,
  submitting: false
};

const AuthorSlice = createSlice({
  extraReducers: builder => {
    builder
      .addCase(getAuthor.pending, state => {
        state.submitting = true;
      })
      .addCase(getAuthor.fulfilled, (state, action) => {
        state.author = action.payload.author;
        state.submitting = false;
      })
      .addCase(getAuthor.rejected, (state, action) => {
        state.error = (action.payload as string) || action.error.message;
        state.submitting = false;
      });
  },
  initialState,
  name: "authors",
  reducers: {
    cleanAuthors: state => {
      state.author = undefined;
      state.authorArticles = undefined;
    }
  }
});

export const { cleanAuthors } = AuthorSlice.actions;
export default AuthorSlice.reducer;
