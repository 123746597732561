import { all, call, getContext, put, takeLatest } from "redux-saga/effects";

import { showAlert } from "rivals/redux/alert/actions";
import { AlertTypes } from "rivals/redux/alert/reducer";
import API from "rivals/services";
import { Response as FetchListDataResponse } from "rivals/services/fetchListData/types";
import { Response as GetContentListPeopleResponse } from "rivals/services/getContentListPeople/types";
import {
  fetchListData as fetchListDataAction,
  fetchListDataFailure,
  fetchListDataSuccess,
  getContentListPeople as getContentListPeopleAction,
  getContentListPeopleFailure,
  getContentListPeopleSuccess,
  ListActions
} from "./actions";

export function* fetchListData(
  action: ReturnType<typeof fetchListDataAction>
): unknown {
  try {
    const api: API = yield getContext("api");
    const response: FetchListDataResponse = yield call(
      api.fetchListData,
      action.payload
    );

    yield put(fetchListDataSuccess(response));
  } catch (error) {
    yield put(fetchListDataFailure((<Error>error)?.message));
    yield put(
      showAlert({
        hasCloseButton: true,
        message: (<Error>error).message,
        type: AlertTypes.SEVERE
      })
    );
  }
}

export function* getContentListPeople(
  action: ReturnType<typeof getContentListPeopleAction>
): unknown {
  try {
    const api: API = yield getContext("api");
    const response: GetContentListPeopleResponse = yield call(
      api.getContentListPeople,
      action.payload.params
    );
    yield put(getContentListPeopleSuccess(response, action.payload.resetting));
  } catch (error) {
    yield put(getContentListPeopleFailure((<Error>error)?.message));
  }
}

export default function* saga(): Generator {
  yield all([takeLatest(ListActions.FETCH_LIST_DATA, fetchListData)]);
  yield all([
    takeLatest(ListActions.GET_CONTENT_LIST_PEOPLE, getContentListPeople)
  ]);
}
