import {
  SubscriptionAddonsAction,
  SubscriptionAddonsActions
} from "rivals/redux/subscriptionAddons/actions";
import { AddOn, AddOnGroup } from "rivals/shared/interfaces/AddOn";
import { SubscriptionUpcomingBilling } from "rivals/shared/interfaces/Subscription";

export type State = {
  activeAddOn?: AddOn;
  addOnGroupFlags?: AddOnGroup[];
  addOnPricing?: { proratedPrice: number; tax: number };
  addOnPurchaseSuccess?: boolean;
  addOns?: AddOn[];
  bundle?: AddOn[];
  chooseYourOwnSchoolAddOns?: AddOn[];
  error?: string;
  modalStep?: string;
  removeAddOnSuccess?: boolean;
  selectedUpsellStepOption?: string;
  submitting: boolean;
  upcomingBilling?: SubscriptionUpcomingBilling;
  upgradingAddOns?: { subtotal: number; tax: number; upgradingAddOns: AddOn[] };
  userEligibleAddons?: AddOn[];
};

const initialState = {
  activeAddOn: undefined,
  addOnGroupFlags: [],
  addOnPricing: undefined,
  addOns: undefined,
  bundle: [],
  chooseYourOwnSchoolAddOns: undefined,
  error: undefined,
  modalStep: undefined,
  selectedUpsellStepOption: undefined,
  submitting: false,
  upcomingBilling: undefined,
  upgradingAddOns: undefined,
  userEligibleAddons: undefined
};

export default function reducer(
  state: State = initialState,
  action: SubscriptionAddonsAction
): State {
  switch (action.type) {
    case SubscriptionAddonsActions.GET_ADD_ON_PRICING:
    case SubscriptionAddonsActions.GET_USER_ELIGIBLE_ADD_ONS:
    case SubscriptionAddonsActions.GET_ADD_ONS_FOR_SUBSCRIPTION:
      return {
        ...state,
        error: undefined,
        submitting: true
      };
    case SubscriptionAddonsActions.GET_ADD_ONS_FOR_SUBSCRIPTION_FAILURE:
    case SubscriptionAddonsActions.GET_USER_ELIGIBLE_ADD_ONS_FAILURE:
    case SubscriptionAddonsActions.GET_ADD_ON_PRICING_FAILURE:
    case SubscriptionAddonsActions.GET_UPGRADING_ADD_ONS_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        submitting: false
      };
    case SubscriptionAddonsActions.GET_USER_ELIGIBLE_ADD_ONS_SUCCESS:
      return {
        ...state,
        chooseYourOwnSchoolAddOns:
          action.payload.response.chooseYourOwnSchoolAddOns,
        submitting: false,
        userEligibleAddons: action.payload.response.addOns
      };
    case SubscriptionAddonsActions.GET_ADD_ONS_FOR_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        addOnGroupFlags: action.payload.response.addOnGroupFlags,
        addOns: action.payload.response.addOns,
        chooseYourOwnSchoolAddOns:
          action.payload.response.chooseYourOwnSchoolAddOns,
        submitting: false
      };
    case SubscriptionAddonsActions.GET_ADD_ON_PRICING_SUCCESS:
      return {
        ...state,
        addOnPricing: action.payload.response,
        submitting: false
      };
    case SubscriptionAddonsActions.GET_UPGRADING_ADD_ONS_SUCCESS:
      return {
        ...state,
        submitting: false,
        upgradingAddOns: action.payload.response
      };
    case SubscriptionAddonsActions.PURCHASE_ADD_ON:
      return {
        ...state,
        addOnPurchaseSuccess: undefined,
        error: undefined,
        submitting: true,
        upcomingBilling: undefined
      };
    case SubscriptionAddonsActions.PURCHASE_ADD_ON_FAILURE:
      return {
        ...state,
        addOnPurchaseSuccess: false,
        error: action.payload.error,
        submitting: false
      };
    case SubscriptionAddonsActions.PURCHASE_ADD_ON_SUCCESS:
      return {
        ...state,
        addOnPurchaseSuccess: true,
        submitting: false,
        upcomingBilling: action.payload.upcomingBilling
      };
    case SubscriptionAddonsActions.REMOVE_ADD_ON:
      return {
        ...state,
        error: undefined,
        removeAddOnSuccess: undefined,
        submitting: true
      };
    case SubscriptionAddonsActions.REMOVE_ADD_ON_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        removeAddOnSuccess: false,
        submitting: false
      };
    case SubscriptionAddonsActions.REMOVE_ADD_ON_SUCCESS:
      return {
        ...state,
        removeAddOnSuccess: true,
        submitting: false
      };
    case SubscriptionAddonsActions.RESET_ADDON_PRICING:
      return {
        ...state,
        activeAddOn: undefined,
        addOnPricing: undefined,
        addOnPurchaseSuccess: undefined,
        error: undefined,
        removeAddOnSuccess: undefined,
        submitting: false
      };
    case SubscriptionAddonsActions.RESET_ERRORS:
      return {
        ...state,
        error: undefined,
        removeAddOnSuccess: undefined
      };
    case SubscriptionAddonsActions.SET_MODAL_STEP:
      return {
        ...state,
        modalStep: action.payload.modalStep
      };
    case SubscriptionAddonsActions.ADD_ADD_ON_TO_BUNDLE:
      return {
        ...state,
        bundle: state.bundle && [...state.bundle, action.payload.addon]
      };
    case SubscriptionAddonsActions.REMOVE_ADD_ON_FROM_BUNDLE:
      return {
        ...state,
        bundle:
          state.bundle &&
          state.bundle.filter(addon => addon.id !== action.payload.id)
      };
    case SubscriptionAddonsActions.CLEAR_BUNDLE:
      return {
        ...state,
        bundle: []
      };
    case SubscriptionAddonsActions.SET_ACTIVE_ADD_ON:
      return {
        ...state,
        activeAddOn: action.payload.activeAddOn
      };
    case SubscriptionAddonsActions.SET_UPSELL_STEP_OPTION:
      return {
        ...state,
        selectedUpsellStepOption: action.payload.step
      };
    default:
      return state;
  }
}
