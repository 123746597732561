import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiService } from "rivals/redux/store";
import { GetCloudinarySignatureResponse } from "rivals/services/container";

enum CloudinaryActions {
  GET_CLOUDINARY_SIGNATURE = "cloudinary/getCloudinarySignature"
}

export const getCloudinarySignature = createAsyncThunk(
  CloudinaryActions.GET_CLOUDINARY_SIGNATURE,
  async (_request, { rejectWithValue }) => {
    try {
      const response: GetCloudinarySignatureResponse = await apiService.getCloudinarySignature();
      return response;
    } catch (error) {
      return rejectWithValue((<Error>error)?.message);
    }
  }
);
