import { all, call, getContext, put, takeLatest } from "redux-saga/effects";

import API from "rivals/services";
import { Response as GetAddOnPricingResponse } from "rivals/services/getAddOnPricing/types";
import { Response as GetAddOnsForSubscriptionResponse } from "rivals/services/getAddOnsForSubscription/types";
import { Response as GetUpgradingAddOnsResponse } from "rivals/services/getUpgradingAddOns/types";
import { Response as getUserEligibleAddonsResponse } from "rivals/services/getUserEligibleAddons/types";
import { Response as PurchaseAddOnResponse } from "rivals/services/purchaseAddOn/types";
import {
  GENERIC_ERROR_MESSAGE,
  OBI_ADD_ON_ERROR
} from "rivals/shared/constants";
import RivalsApiError from "rivals/shared/error";
import {
  getAddOnPricing as getAddOnPricingAction,
  getAddOnPricingFailure,
  getAddOnPricingSuccess,
  getAddonsForSubscription as getAddOnsForSubscriptionAction,
  getAddonsForSubscriptionFailure,
  getAddonsForSubscriptionSuccess,
  getUpgradingAddOns as getUpgradingAddOnsAction,
  getUpgradingAddOnsFailure,
  getUpgradingAddOnsSuccess,
  getUserEligibleAddonsFailure,
  getUserEligibleAddonsSuccess,
  purchaseAddOn as purchaseAddOnAction,
  purchaseAddOnFailure,
  purchaseAddOnSuccess,
  removeAddOn as removeAddOnAction,
  removeAddOnFailure,
  removeAddOnSuccess,
  SubscriptionAddonsActions
} from "./actions";
import { showAlert } from "../alert/actions";
import { AlertTypes } from "../alert/reducer";

export function* getUserEligibleAddons(): unknown {
  try {
    const api: API = yield getContext("api");
    const response: getUserEligibleAddonsResponse = yield call(
      api.getUserEligibleAddons
    );
    yield put(getUserEligibleAddonsSuccess(response));
  } catch (error) {
    yield all([
      put(getUserEligibleAddonsFailure((<Error>error)?.message)),
      put(
        showAlert({ message: GENERIC_ERROR_MESSAGE, type: AlertTypes.SEVERE })
      )
    ]);
  }
}

export function* getAddOnsForSubscription(
  action: ReturnType<typeof getAddOnsForSubscriptionAction>
): unknown {
  try {
    const api: API = yield getContext("api");
    const response: GetAddOnsForSubscriptionResponse = yield call(
      api.getAddOnsForSubscription,
      action.payload
    );
    yield put(getAddonsForSubscriptionSuccess(response));
  } catch (error) {
    yield put(getAddonsForSubscriptionFailure((<Error>error)?.message));
  }
}

export function* getAddOnPricing(
  action: ReturnType<typeof getAddOnPricingAction>
): unknown {
  try {
    const api: API = yield getContext("api");
    const response: GetAddOnPricingResponse = yield call(
      api.getAddOnPricing,
      action.payload
    );
    yield put(getAddOnPricingSuccess(response));
  } catch (error) {
    yield put(getAddOnPricingFailure((<Error>error)?.message));
  }
}

export function* getUpgradingAddOns(
  action: ReturnType<typeof getUpgradingAddOnsAction>
): unknown {
  try {
    const api: API = yield getContext("api");
    const response: GetUpgradingAddOnsResponse = yield call(
      api.getUpgradingAddOns,
      action.payload
    );
    yield put(getUpgradingAddOnsSuccess(response.response));
  } catch (error) {
    yield put(getUpgradingAddOnsFailure((<Error>error)?.message));
  }
}

export function* purchaseAddOn(
  action: ReturnType<typeof purchaseAddOnAction>
): unknown {
  try {
    const api: API = yield getContext("api");
    const response: PurchaseAddOnResponse = yield call(
      api.purchaseAddOn,
      action.payload
    );
    yield put(purchaseAddOnSuccess(response.upcomingBilling));
  } catch (error) {
    yield put(purchaseAddOnFailure((<Error>error)?.message));
  }
}

export function* removeAddOn(
  action: ReturnType<typeof removeAddOnAction>
): unknown {
  try {
    const api: API = yield getContext("api");
    yield call(api.removeAddOn, action.payload);
    yield put(removeAddOnSuccess());
  } catch (error) {
    yield put(removeAddOnFailure((<Error>error)?.message));
    if (error instanceof RivalsApiError) {
      yield put(
        showAlert({
          message: OBI_ADD_ON_ERROR,
          persisted: false,
          type: AlertTypes.SEVERE
        })
      );
    }
  }
}

export default function* saga(): Generator {
  yield all([
    takeLatest(
      SubscriptionAddonsActions.GET_USER_ELIGIBLE_ADD_ONS,
      getUserEligibleAddons
    )
  ]),
    yield all([
      takeLatest(SubscriptionAddonsActions.PURCHASE_ADD_ON, purchaseAddOn)
    ]),
    yield all([
      takeLatest(SubscriptionAddonsActions.REMOVE_ADD_ON, removeAddOn)
    ]),
    yield all([
      takeLatest(SubscriptionAddonsActions.GET_ADD_ON_PRICING, getAddOnPricing)
    ]),
    yield all([
      takeLatest(
        SubscriptionAddonsActions.GET_ADD_ONS_FOR_SUBSCRIPTION,
        getAddOnsForSubscription
      )
    ]);
  yield all([
    takeLatest(
      SubscriptionAddonsActions.GET_UPGRADING_ADD_ONS,
      getUpgradingAddOns
    )
  ]);
}
