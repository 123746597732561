import { find, remove } from "lodash";
import { UnknownAction } from "redux";
import { v4 as uuidv4 } from "uuid";
import { areAlertsEqual } from "rivals/components/app/Layout/Alerts/utils";
import { AlertAction, AlertActions } from "rivals/redux/alert/actions";

export const ALERT_PREFIX = "RIVALS-ALERT";

// each type has its styles and sometimes logic
// SITE_ADAPTIVE changes the alert background depending on the active current site
// falls back to a default background when the site is one of the national ones
// or when active current site does not have a primary nor secondary colors
export enum AlertTypes {
  INFO = "info",
  SEVERE = "severe",
  SITE_ADAPTIVE = "siteAdaptive",
  SUCCESS = "success"
}

export interface Alert {
  action?: UnknownAction;
  durationInSeconds?: number;
  hasCloseButton?: boolean;
  href?: string;
  linkFillsAlert?: boolean;
  linkText?: string;
  message: string;
  persisted?: boolean;
  status?: "pending" | "resolved";
  type?: AlertTypes;
}

export interface UniqueAlert extends Alert {
  id: string;
}

export interface State {
  alerts: UniqueAlert[];
}

export const initialState = {
  alerts: []
};

/* eslint-disable  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
export const isAlert = (unknown: any): unknown is UniqueAlert => {
  if (
    typeof unknown === "object" &&
    !Array.isArray(unknown) &&
    unknown !== null &&
    !!unknown.id &&
    typeof unknown.id === "string" &&
    unknown.id.startsWith(ALERT_PREFIX)
  ) {
    return true;
  }
  return false;
};
/* eslint-enable  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */

export default function reducer(
  state: State = initialState,
  action: AlertAction
): State {
  switch (action.type) {
    case AlertActions.CLEAR_ALERTS: {
      return initialState;
    }
    case AlertActions.HIDE_ALERT: {
      const alerts = [...state.alerts];
      remove(alerts, ["id", action.payload.id]);
      return {
        ...state,
        alerts
      };
    }
    case AlertActions.SHOW_ALERT: {
      const alerts = [...state.alerts];
      const { payload } = action;
      if (!find(alerts, alert => areAlertsEqual(alert, payload))) {
        alerts.push({
          ...payload,
          id: `${ALERT_PREFIX}-${uuidv4()}`
        });
      }
      return {
        ...state,
        alerts
      };
    }
    default: {
      return state;
    }
  }
}
