import Cookies from "js-cookie";
import {
  all,
  call,
  getContext,
  put,
  takeEvery,
  takeLatest
} from "redux-saga/effects";
import { clearAlerts, showAlert } from "rivals/redux/alert/actions";
import { AlertTypes } from "rivals/redux/alert/reducer";
import { sendGAEvent } from "rivals/redux/ga/actions";
import API from "rivals/services";
import { Response } from "rivals/services/loginUser/types";
import {
  DISMISSED_ENABLE_NOTIFICATION_KEY,
  DISMISSED_INSTALL_PWA_KEY,
  GENERIC_ERROR_MESSAGE,
  RIVALS_AUTH_COOKIE
} from "rivals/shared/constants";
import RivalsApiError from "rivals/shared/error";
import {
  AuthActions,
  loginUser as loginUserAction,
  loginUserFailure,
  loginUserSuccess,
  logoutUserFailure,
  logoutUserSuccess
} from "./actions";

// TODO: https://griosf.atlassian.net/browse/RVLS-2792
export function* loginUser(
  action: ReturnType<typeof loginUserAction>
): unknown {
  try {
    yield put(clearAlerts());
    const api: API = yield getContext("api");
    const response: Response = yield call(api.loginUser, action.payload);
    yield put(
      sendGAEvent({
        eventName: "login",
        eventParams: {
          method: "users/sign_in form"
        }
      })
    );
    yield put(loginUserSuccess(response));
  } catch (error) {
    if (error instanceof RivalsApiError) {
      yield put(
        sendGAEvent({
          eventName: "exception",
          eventParams: {
            description: `User failed to sign_in with the exception: ${error.message}`,
            fatal: false
          }
        })
      );
    }
    yield put(loginUserFailure((<Error>error)?.message));
    yield put(
      showAlert({
        message:
          error instanceof RivalsApiError
            ? error.message
            : GENERIC_ERROR_MESSAGE,
        persisted: false,
        type: AlertTypes.SEVERE
      })
    );
  }
}

// TODO: https://griosf.atlassian.net/browse/RVLS-2792
export function* logoutUser(): unknown {
  try {
    const api: API = yield getContext("api");
    yield call(api.logoutUser);
    yield put(logoutUserSuccess());
    Cookies.remove(RIVALS_AUTH_COOKIE);
    localStorage.removeItem(DISMISSED_INSTALL_PWA_KEY);
    localStorage.removeItem(DISMISSED_ENABLE_NOTIFICATION_KEY);
  } catch (error) {
    yield put(logoutUserFailure((<Error>error)?.message));
  }
}

export default function* saga(): Generator {
  yield all([
    takeEvery(AuthActions.LOGIN_USER, loginUser),
    takeLatest(AuthActions.LOGOUT_USER, logoutUser)
  ]);
}
