import { createSelector } from "reselect";
import { State as AppState, StateKeys } from "rivals/redux/reducer";
import { State } from "./reducer";

export const selectTeamsNavState = (state: AppState): State =>
  state[StateKeys.TEAMS_NAV];

export const selectTeamsNavItems = createSelector(
  selectTeamsNavState,
  state => state.data
);
