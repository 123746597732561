import { all, call, getContext, put, takeLatest } from "redux-saga/effects";
import { showAlert } from "rivals/redux/alert/actions";
import { AlertTypes } from "rivals/redux/alert/reducer";
import { getAddonsForSubscription as getAddonsForSubscriptionAction } from "rivals/redux/subscriptionAddons/actions";
import API from "rivals/services";
import { Response as CreateSubscriptionAction } from "rivals/services/createSubscription/types";
import { Response as ExtendMgmSubscription } from "rivals/services/extendMgmSubscription/types";
import { Response as GetSaveEligibilityResponse } from "rivals/services/getSaveEligibility/types";
import { Response as PayOutstandingBalanceResponse } from "rivals/services/payOutstandingBalance/types";
import { Response as ReactivateSubscriptionResponse } from "rivals/services/reactivateSubscription/types";
import { PATHS } from "rivals/shared/constants";
import RivalsApiError from "rivals/shared/error";
import {
  autoSave as autoSaveAction,
  autoSaveFailure,
  autoSaveSuccess,
  cancelSubscription as cancelSubscriptionAction,
  cancelSubscriptionFailure,
  cancelSubscriptionSuccess,
  createSubscription as createSubscriptionAction,
  createSubscriptionFailure,
  createSubscriptionSuccess,
  emailOffer as emailOfferAction,
  emailOfferFailure,
  emailOfferSuccess,
  extendMgmSubscription as extendMgmSubscriptionAction,
  extendMgmSubscriptionFailure,
  extendMgmSubscriptionSuccess,
  getSaveEligibility as getSaveEligibilityAction,
  getSaveEligibilitySuccess,
  payOutstandingBalanceFailure,
  payOutstandingBalanceSuccess,
  reactivateSubscription,
  reactivateSubscriptionFailure,
  reactivateSubscriptionSuccess,
  SubscriptionActions,
  upgradeToAnnual as upgradeToAnnualAction,
  upgradeToAnnualFailure
} from "./actions";

// TODO: https://griosf.atlassian.net/browse/RVLS-2792
export function* autoSave(action: ReturnType<typeof autoSaveAction>): unknown {
  try {
    const api: API = yield getContext("api");
    yield call(api.extendSubscription, action.payload);
    yield put(autoSaveSuccess());
  } catch (error) {
    yield put(autoSaveFailure((<Error>error)?.message));
    if (error instanceof RivalsApiError) {
      yield put(
        showAlert({
          message: error.message,
          persisted: false,
          type: AlertTypes.SEVERE
        })
      );
    }
  }
}

// TODO: https://griosf.atlassian.net/browse/RVLS-2792
export function* createSubscription(
  action: ReturnType<typeof createSubscriptionAction>
): unknown {
  try {
    const api: API = yield getContext("api");
    const { subscription }: CreateSubscriptionAction = yield call(
      api.createSubscription,
      action.payload
    );
    yield all([
      put(getAddonsForSubscriptionAction(subscription.id)),
      put(createSubscriptionSuccess())
    ]);
    window.location.assign(PATHS.LANDING_PAGE);
  } catch (error) {
    yield put(createSubscriptionFailure((<Error>error)?.message));
    if (error instanceof RivalsApiError) {
      yield put(
        showAlert({
          message: error.message,
          persisted: false,
          type: AlertTypes.SEVERE
        })
      );
    }
  }
}

// TODO: https://griosf.atlassian.net/browse/RVLS-2792
export function* cancelSubscription(
  action: ReturnType<typeof cancelSubscriptionAction>
): unknown {
  try {
    const api: API = yield getContext("api");
    yield call(api.cancelSubscription, action.payload);
    yield put(cancelSubscriptionSuccess());
  } catch (error) {
    yield put(cancelSubscriptionFailure((<Error>error)?.message));
    if (error instanceof RivalsApiError) {
      yield put(
        showAlert({
          message: error.message,
          persisted: false,
          type: AlertTypes.SEVERE
        })
      );
    }
  }
}

// TODO: https://griosf.atlassian.net/browse/RVLS-2792
export function* emailOffer(
  action: ReturnType<typeof emailOfferAction>
): unknown {
  try {
    const api: API = yield getContext("api");
    yield call(api.emailOffer, {
      subscriptionId: action.payload.subscriptionId
    });
    yield put(emailOfferSuccess());
  } catch (error) {
    yield put(emailOfferFailure((<Error>error)?.message));
    if (error instanceof RivalsApiError) {
      yield put(
        showAlert({
          message: error.message,
          persisted: false,
          type: AlertTypes.SEVERE
        })
      );
    }
  }
}

// TODO: https://griosf.atlassian.net/browse/RVLS-2792
export function* extendMgmSubscription(
  action: ReturnType<typeof extendMgmSubscriptionAction>
): unknown {
  try {
    const api: API = yield getContext("api");
    const response: ExtendMgmSubscription = yield call(
      api.extendMgmSubscription,
      {
        subscriptionId: action.payload.subscriptionId
      }
    );
    yield put(extendMgmSubscriptionSuccess());
    yield put(
      showAlert({
        message: response.message,
        persisted: true,
        type: AlertTypes.SUCCESS
      })
    );
    window.location.assign(PATHS.SUBSCRIPTIONS);
  } catch (error) {
    yield put(extendMgmSubscriptionFailure((<Error>error)?.message));
    if (error instanceof RivalsApiError) {
      yield put(
        showAlert({
          message: error.message,
          persisted: false,
          type: AlertTypes.SEVERE
        })
      );
    }
  }
}

// TODO: https://griosf.atlassian.net/browse/RVLS-2792
export function* getSaveEligibility(
  action: ReturnType<typeof getSaveEligibilityAction>
): unknown {
  try {
    const api: API = yield getContext("api");
    const response: GetSaveEligibilityResponse = yield call(
      api.getSaveEligibility,
      action.payload
    );
    yield put(getSaveEligibilitySuccess(response));
  } catch (error) {
    if (error instanceof RivalsApiError) {
      yield put(
        showAlert({
          message: error.message,
          persisted: false,
          type: AlertTypes.SEVERE
        })
      );
    }
  }
}

// TODO: https://griosf.atlassian.net/browse/RVLS-2792
export function* payOutstandingBalance(): unknown {
  try {
    const api: API = yield getContext("api");
    const response: PayOutstandingBalanceResponse = yield call(
      api.payOutstandingBalance
    );
    yield put(payOutstandingBalanceSuccess(response.message));
  } catch (error) {
    yield put(payOutstandingBalanceFailure((<Error>error)?.message));
    if (error instanceof RivalsApiError) {
      yield put(
        showAlert({
          message: error.message,
          type: AlertTypes.SEVERE
        })
      );
    }
  }
}

// TODO: https://griosf.atlassian.net/browse/RVLS-2792
export function* reactivate(
  action: ReturnType<typeof reactivateSubscription>
): unknown {
  try {
    const api: API = yield getContext("api");
    const response: ReactivateSubscriptionResponse = yield call(
      api.reactivateSubscription,
      {
        subscriptionId: action.payload.subscriptionId
      }
    );
    yield put(reactivateSubscriptionSuccess(response.subscription));
  } catch (error) {
    yield put(reactivateSubscriptionFailure((<Error>error)?.message));
    if (error instanceof RivalsApiError) {
      yield put(
        showAlert({
          message: error.message,
          persisted: true,
          type: AlertTypes.SEVERE
        })
      );
    }
  }
}

// TODO: https://griosf.atlassian.net/browse/RVLS-2792
export function* upgradeToAnnual(
  action: ReturnType<typeof upgradeToAnnualAction>
): unknown {
  try {
    const api: API = yield getContext("api");
    yield call(api.upgradeToAnnual, {
      autoRenewalOptIn: action.payload.autoRenewalOptIn,
      promoCode: action.payload.promoCode,
      subscriptionId: action.payload.subscriptionId,
      subscriptionPlan: action.payload.subscriptionPlan,
      userId: action.payload.userId
    });
    window.location.assign(PATHS.SUBSCRIPTIONS);
  } catch (error) {
    yield put(upgradeToAnnualFailure((<Error>error)?.message));
    if (error instanceof RivalsApiError) {
      yield put(
        showAlert({
          message: error.message,
          persisted: false,
          type: AlertTypes.SEVERE
        })
      );
    }
  }
}

export default function* saga(): Generator {
  yield all([
    takeLatest(SubscriptionActions.AUTO_SAVE, autoSave),
    takeLatest(SubscriptionActions.CANCEL_SUBSCRIPTION, cancelSubscription),
    takeLatest(SubscriptionActions.CREATE_SUBSCRIPTION, createSubscription),
    takeLatest(SubscriptionActions.EMAIL_OFFER, emailOffer),
    takeLatest(
      SubscriptionActions.EXTEND_MGM_SUBSCRIPTION,
      extendMgmSubscription
    ),
    takeLatest(SubscriptionActions.GET_SAVE_ELIGIBILITY, getSaveEligibility),
    takeLatest(
      SubscriptionActions.PAY_OUTSTANDING_BALANCE,
      payOutstandingBalance
    ),
    takeLatest(SubscriptionActions.REACTIVATE_SUBSCRIPTION, reactivate),
    takeLatest(SubscriptionActions.UPGRADE_TO_ANNUAL, upgradeToAnnual)
  ]);
}
