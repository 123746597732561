import isEqual from "lodash/isEqual";

/* eslint-disable  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */

/**
 * Predicate function that validates if array has an element
 * element can be object or any type
 * @param arr of any type
 * @param element of any type
 * @param equals custom predicate to validate 2 elements equality, default is `areObjectsEqual`
 * @returns boolean, true if array contains element, false if not
 */
export const arrayHasElement = (
  arr: any[],
  element?: any,
  equals: (item1: any, item2: any) => boolean = isEqual
): boolean => arr.some((item: any): boolean => equals(item, element));

/* eslint-enable  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
