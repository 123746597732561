import {
  FuturecastAction,
  FuturecastActions
} from "rivals/redux/futurecast/actions";
import { Forecast, Forecaster } from "rivals/shared/interfaces/Futurecast";

export type State = {
  error?: string;
  forecasters?: Forecaster[];
  forecasts?: Forecast[];
  forecastSaved?: boolean;
  submitting: boolean;
};

const initialState = {
  error: undefined,
  forecastSaved: undefined,
  forecasters: undefined,
  forecasts: undefined,
  submitting: false
};

export default function reducer(
  state: State = initialState,
  action: FuturecastAction
): State {
  switch (action.type) {
    case FuturecastActions.GET_FORECASTERS_FAILURE:
    case FuturecastActions.GET_FORECASTS_FAILURE:
    case FuturecastActions.VALIDATE_FORECAST_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        submitting: false
      };
    case FuturecastActions.CREATE_FORECAST_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        forecastSaved: false,
        submitting: false
      };
    case FuturecastActions.GET_FORECASTERS_SUCCESS:
      return {
        ...state,
        forecasters: action.payload.forecasters
      };
    case FuturecastActions.GET_FORECASTS_SUCCESS:
      return {
        ...state,
        forecasts: action.payload.forecasts
      };
    case FuturecastActions.CREATE_FORECAST:
      return {
        ...state,
        error: undefined,
        forecastSaved: undefined,
        submitting: true
      };
    case FuturecastActions.RESET_FORECAST:
      return {
        ...state,
        error: undefined,
        forecastSaved: undefined,
        submitting: false
      };
    case FuturecastActions.VALIDATE_FORECAST:
      return {
        ...state,
        submitting: true
      };
    case FuturecastActions.CREATE_FORECAST_SUCCESS:
      return {
        ...state,
        forecastSaved: action.payload.forecastSaved,
        submitting: false
      };
    case FuturecastActions.VALIDATE_FORECAST_SUCCESS:
      return {
        ...state,
        error: undefined,
        submitting: false
      };
    default:
      return state;
  }
}
