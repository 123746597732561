import { createAsyncThunk } from "@reduxjs/toolkit";

import { apiService } from "rivals/redux/store";
import { GetAuthorRequest, GetAuthorResponse } from "rivals/services/container";
import RivalsApiError from "rivals/shared/error";

export enum AuthorActions {
  GET_AUTHOR = "authors/getAuthor"
}

export const getAuthor = createAsyncThunk(
  AuthorActions.GET_AUTHOR,
  async (request: GetAuthorRequest, { rejectWithValue }) => {
    try {
      const response: GetAuthorResponse = await apiService.getAuthor(request);
      return response;
    } catch (error) {
      return rejectWithValue((error as RivalsApiError)?.message);
    }
  }
);
