import { getApp, getApps, initializeApp } from "@firebase/app";
import {
  getMessaging,
  getToken,
  isSupported,
  Messaging
} from "@firebase/messaging";

export const FIREBASE_CONFIG = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
  measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET
};

const app = getApps().length ? getApp() : initializeApp(FIREBASE_CONFIG);

export async function messaging(): Promise<Messaging | null> {
  const supported = await isSupported();
  return supported ? getMessaging(app) : null;
}

export async function fetchToken(): Promise<string | null> {
  try {
    const firebaseCloudMessaging = await messaging();
    if (firebaseCloudMessaging) {
      return getToken(firebaseCloudMessaging, {
        vapidKey: process.env.NEXT_PUBLIC_FIREBASE_VAPID_KEY
      });
    }
    return null;
  } catch (error) {
    return null;
  }
}
