"use client";

import { Component, ReactNode } from "react";
import errorService, { ExtraInfo } from "rivals/services/error";

interface Props {
  children: ReactNode;
  fallback?: ReactNode;
}

interface State {
  readonly error: Error | undefined;
  readonly hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  public static getDerivedStateFromError(error: Error): State {
    return {
      error: error,
      hasError: true
    };
  }
  constructor(props: Props) {
    super(props);
    this.state = {
      error: undefined,
      hasError: false
    };
  }

  public componentDidCatch(error: Error, errorInfo: ExtraInfo): void {
    this.setState({ hasError: true });
    errorService.capture(error, errorInfo);
  }

  public render(): ReactNode {
    const { fallback } = this.props;

    if (fallback !== undefined && this.state.hasError) {
      return fallback;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
