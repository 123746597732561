import {
  GenerateAddOnReportRequest,
  ListAddOnReportsResponse
} from "rivals/services/container";
import {
  FetchGoogleAnalyticsReportsResponse,
  GenerateGoogleAnalyticsReportRequest,
  UpdateAnalyticsMonitoredSitesRequest
} from "rivals/services/googleAnalyticsReports/types";
import { CsAdminReportUser } from "rivals/shared/interfaces/Admin";

export enum AdminReportsActions {
  GENERATE_ADD_ON_REPORT = "GENERATE_ADD_ON_REPORT",
  GENERATE_ADD_ON_REPORT_FAILURE = "GENERATE_ADD_ON_REPORT_FAILURE",
  GENERATE_ADD_ON_REPORT_SUCCESS = "GENERATE_ADD_ON_REPORT_SUCCESS",
  GENERATE_GOOGLE_ANALYITCS_REPORT = "GENERATE_GOOGLE_ANALYITCS_REPORT",
  GENERATE_GOOGLE_ANALYITCS_REPORT_FAILURE = "GENERATE_GOOGLE_ANALYITCS_REPORT_FAILURE",
  GENERATE_GOOGLE_ANALYITCS_REPORT_SUCCESS = "GENERATE_GOOGLE_ANALYITCS_REPORT_SUCCESS",
  GET_CS_ADMIN_REPORTS = "GET_CS_ADMIN_REPORTS",
  GET_CS_ADMIN_REPORTS_SUCCESS = "GET_CS_ADMIN_REPORTS_SUCCESS",
  GET_CS_ADMIN_REPORTS_FAILURE = "GET_CS_ADMIN_REPORTS_FAILURE",
  GET_ADD_ON_REPORTS_LIST = "GET_ADD_ON_REPORTS_LIST",
  GET_ADD_ON_REPORTS_LIST_FAILURE = "GET_ADD_ON_REPORTS_LIST_FAILURE",
  GET_ADD_ON_REPORTS_LIST_SUCCESS = "GET_ADD_ON_REPORTS_LIST_SUCCESS",
  FETCH_GOOGLE_ANALYTICS_REPORTS = "FETCH_GOOGLE_ANALYTICS_REPORTS",
  FETCH_GOOGLE_ANALYTICS_REPORTS_FAILURE = "FETCH_GOOGLE_ANALYTICS_REPORTS_FAILURE",
  FETCH_GOOGLE_ANALYTICS_REPORTS_SUCCESS = "FETCH_GOOGLE_ANALYTICS_REPORTS_SUCCESS",
  UPDATE_CS_ADMIN_PERMISSIONS = "UPDATE_CS_ADMIN_PERMISSIONS",
  UPDATE_ANALYTICS_MONITORED_SITES = "UPDATE_ANALYTICS_MONITORED_SITES",
  UPDATE_ANALYTICS_MONITORED_SITES_FAILURE = "UPDATE_ANALYTICS_MONITORED_SITES_FAILURE",
  UPDATE_ANALYTICS_MONITORED_SITES_SUCCESS = "UPDATE_ANALYTICS_MONITORED_SITES_SUCCESS",
  UPDATE_CS_ADMIN_PERMISSIONS_SUCCESS = "UPDATE_CS_ADMIN_PERMISSIONS_SUCCESS",
  UPDATE_CS_ADMIN_PERMISSIONS_FAILURE = "UPDATE_CS_ADMIN_PERMISSIONS_FAILURE"
}

export const getCsAdminReports = () =>
  ({
    type: AdminReportsActions.GET_CS_ADMIN_REPORTS
  } as const);

export const getCsAdminReportsFailure = (error?: string) =>
  ({
    error: true,
    payload: { error },
    type: AdminReportsActions.GET_CS_ADMIN_REPORTS_FAILURE
  } as const);

export const getCsAdminReportsSuccess = (users?: CsAdminReportUser[]) =>
  ({
    payload: { users },
    type: AdminReportsActions.GET_CS_ADMIN_REPORTS_SUCCESS
  } as const);

export const updateCsAdminPermissions = (userId: number, expiresIn: number) =>
  ({
    payload: {
      expiresIn,
      userId
    },
    type: AdminReportsActions.UPDATE_CS_ADMIN_PERMISSIONS
  } as const);

export const updateCsAdminPermissionsFailure = (error?: string) =>
  ({
    error: true,
    payload: {
      error
    },
    type: AdminReportsActions.UPDATE_CS_ADMIN_PERMISSIONS_FAILURE
  } as const);

export const updateCsAdminPermissionsSuccess = (users?: CsAdminReportUser[]) =>
  ({
    payload: { users },
    type: AdminReportsActions.UPDATE_CS_ADMIN_PERMISSIONS_SUCCESS
  } as const);

export const generateAddOnReport = (request: GenerateAddOnReportRequest) =>
  ({
    payload: request,
    type: AdminReportsActions.GENERATE_ADD_ON_REPORT
  } as const);

export const generateAddOnReportFailure = (error?: string) =>
  ({
    error: true,
    payload: { error },
    type: AdminReportsActions.GENERATE_ADD_ON_REPORT_FAILURE
  } as const);

export const generateAddOnReportSuccess = () =>
  ({
    type: AdminReportsActions.GENERATE_ADD_ON_REPORT_SUCCESS
  } as const);

export const getAddOnReportsList = () =>
  ({
    type: AdminReportsActions.GET_ADD_ON_REPORTS_LIST
  } as const);

export const getAddOnReportsListFailure = (error?: string) =>
  ({
    error: true,
    payload: { error },
    type: AdminReportsActions.GET_ADD_ON_REPORTS_LIST_FAILURE
  } as const);

export const getAddOnReportsListSuccess = (
  response: ListAddOnReportsResponse
) =>
  ({
    payload: response,
    type: AdminReportsActions.GET_ADD_ON_REPORTS_LIST_SUCCESS
  } as const);

export const generateGoogleAnalyticsReport = (
  request: GenerateGoogleAnalyticsReportRequest
) =>
  ({
    payload: request,
    type: AdminReportsActions.GENERATE_GOOGLE_ANALYITCS_REPORT
  } as const);

export const generateGoogleAnalyticsReportFailure = (error?: string) =>
  ({
    payload: { error },
    type: AdminReportsActions.GENERATE_GOOGLE_ANALYITCS_REPORT_FAILURE
  } as const);

export const generateGoogleAnalyticsReportSuccess = () =>
  ({
    type: AdminReportsActions.GENERATE_GOOGLE_ANALYITCS_REPORT_SUCCESS
  } as const);

export const fetchGoogleAnalyticsReports = () =>
  ({
    type: AdminReportsActions.FETCH_GOOGLE_ANALYTICS_REPORTS
  } as const);

export const fetchGoogleAnalyticsReportsFailure = (error?: string) =>
  ({
    payload: { error },
    type: AdminReportsActions.FETCH_GOOGLE_ANALYTICS_REPORTS_FAILURE
  } as const);

export const fetchGoogleAnalyticsReportsSuccess = (
  response: FetchGoogleAnalyticsReportsResponse
) =>
  ({
    payload: response,
    type: AdminReportsActions.FETCH_GOOGLE_ANALYTICS_REPORTS_SUCCESS
  } as const);

export const updateAnalyticsMonitoredSites = (
  request: UpdateAnalyticsMonitoredSitesRequest
) =>
  ({
    payload: request,
    type: AdminReportsActions.UPDATE_ANALYTICS_MONITORED_SITES
  } as const);

export const updateAnalyticsMonitoredSitesFailure = (error?: string) =>
  ({
    error: true,
    payload: { error },
    type: AdminReportsActions.UPDATE_ANALYTICS_MONITORED_SITES_FAILURE
  } as const);

export const updateAnalyticsMonitoredSitesSuccess = () =>
  ({
    type: AdminReportsActions.UPDATE_ANALYTICS_MONITORED_SITES_SUCCESS
  } as const);

export type AdminReportsAction =
  | ReturnType<typeof generateAddOnReport>
  | ReturnType<typeof generateAddOnReportFailure>
  | ReturnType<typeof generateAddOnReportSuccess>
  | ReturnType<typeof generateGoogleAnalyticsReport>
  | ReturnType<typeof generateGoogleAnalyticsReportFailure>
  | ReturnType<typeof generateGoogleAnalyticsReportSuccess>
  | ReturnType<typeof getCsAdminReports>
  | ReturnType<typeof getCsAdminReportsSuccess>
  | ReturnType<typeof getCsAdminReportsFailure>
  | ReturnType<typeof getAddOnReportsList>
  | ReturnType<typeof getAddOnReportsListFailure>
  | ReturnType<typeof getAddOnReportsListSuccess>
  | ReturnType<typeof fetchGoogleAnalyticsReports>
  | ReturnType<typeof fetchGoogleAnalyticsReportsFailure>
  | ReturnType<typeof fetchGoogleAnalyticsReportsSuccess>
  | ReturnType<typeof updateAnalyticsMonitoredSites>
  | ReturnType<typeof updateAnalyticsMonitoredSitesFailure>
  | ReturnType<typeof updateAnalyticsMonitoredSitesSuccess>
  | ReturnType<typeof updateCsAdminPermissions>
  | ReturnType<typeof updateCsAdminPermissionsSuccess>
  | ReturnType<typeof updateCsAdminPermissionsFailure>;
