import { AddOn, AddOnGroup } from "rivals/shared/interfaces/AddOn";
import { SubscriptionUpcomingBilling } from "rivals/shared/interfaces/Subscription";

export enum SubscriptionAddonsActions {
  GET_ACTIVE_ADD_ON = "GET_ACTIVE_ADD_ON",
  GET_ADD_ON_PRICING = "GET_ADD_ON_PRICING",
  GET_ADD_ON_PRICING_FAILURE = "GET_ADD_ON_PRICING_FAILURE",
  GET_ADD_ON_PRICING_SUCCESS = "GET_ADD_ON_PRICING_SUCCESS",
  GET_ADD_ONS_FOR_SUBSCRIPTION = "GET_ADD_ONS_FOR_SUBSCRIPTION",
  GET_ADD_ONS_FOR_SUBSCRIPTION_FAILURE = "GET_ADD_ONS_FOR_SUBSCRIPTION_FAILURE",
  GET_ADD_ONS_FOR_SUBSCRIPTION_SUCCESS = "GET_ADD_ONS_FOR_SUBSCRIPTION_SUCCESS",
  GET_UPGRADING_ADD_ONS = "GET_UPGRADING_ADD_ONS",
  GET_UPGRADING_ADD_ONS_FAILURE = "GET_UPGRADING_ADD_ONS_FAILURE",
  GET_UPGRADING_ADD_ONS_SUCCESS = "GET_UPGRADING_ADD_ONS_SUCCESS",
  GET_USER_ELIGIBLE_ADD_ONS = "GET_USER_ELIGIBLE_ADD_ONS",
  GET_USER_ELIGIBLE_ADD_ONS_FAILURE = "GET_USER_ELIGIBLE_ADD_ONS_FAILURE",
  GET_USER_ELIGIBLE_ADD_ONS_SUCCESS = "GET_USER_ELIGIBLE_ADD_ONS_SUCCESS",
  PURCHASE_ADD_ON = "PURCHASE_ADD_ON",
  PURCHASE_ADD_ON_FAILURE = "PURCHASE_ADD_ON_FAILURE",
  PURCHASE_ADD_ON_SUCCESS = "PURCHASE_ADD_ON_SUCCESS",
  REMOVE_ADD_ON = "REMOVE_ADD_ON",
  REMOVE_ADD_ON_FAILURE = "REMOVE_ADD_ON_FAILURE",
  REMOVE_ADD_ON_SUCCESS = "REMOVE_ADD_ON_SUCCESS",
  RESET_ADDON_PRICING = "RESET_ADDON_PRICING",
  RESET_ERRORS = "RESET_ERRORS",
  SET_MODAL_STEP = "SET_MODAL_STEP",
  GET_MODAL_STEP = "GET_MODAL_STEP",
  ADD_ADD_ON_TO_BUNDLE = "ADD_ADD_ON_TO_BUNDLE",
  REMOVE_ADD_ON_FROM_BUNDLE = "REMOVE_ADD_ON_FROM_BUNDLE",
  CLEAR_BUNDLE = "CLEAR_BUNDLE",
  SET_ACTIVE_ADD_ON = "SET_ACTIVE_ADD_ON",
  SET_UPSELL_STEP_OPTION = "SET_UPSELL_STEP_OPTION"
}

export const getAddOnPricing = (subscriptionId: number, addOnId: number) =>
  ({
    payload: { addOnId, subscriptionId },
    type: SubscriptionAddonsActions.GET_ADD_ON_PRICING
  } as const);

export const getAddOnPricingFailure = (error?: string) =>
  ({
    payload: { error },
    type: SubscriptionAddonsActions.GET_ADD_ON_PRICING_FAILURE
  } as const);

export const getAddOnPricingSuccess = (response: {
  proratedPrice: number;
  tax: number;
}) =>
  ({
    payload: {
      response
    },
    type: SubscriptionAddonsActions.GET_ADD_ON_PRICING_SUCCESS
  } as const);

export const getAddonsForSubscription = (subscriptionId?: number) =>
  ({
    payload: { subscriptionId },
    type: SubscriptionAddonsActions.GET_ADD_ONS_FOR_SUBSCRIPTION
  } as const);

export const getAddonsForSubscriptionSuccess = (response: {
  addOnGroupFlags: AddOnGroup[];
  addOns: AddOn[];
  chooseYourOwnSchoolAddOns: AddOn[];
}) =>
  ({
    payload: { response },
    type: SubscriptionAddonsActions.GET_ADD_ONS_FOR_SUBSCRIPTION_SUCCESS
  } as const);

export const getAddonsForSubscriptionFailure = (error?: string) =>
  ({
    payload: { error },
    type: SubscriptionAddonsActions.GET_ADD_ONS_FOR_SUBSCRIPTION_FAILURE
  } as const);

export const getUpgradingAddOns = (subscriptionId: number) =>
  ({
    payload: { subscriptionId },
    type: SubscriptionAddonsActions.GET_UPGRADING_ADD_ONS
  } as const);

export const getUpgradingAddOnsSuccess = (response: {
  subtotal: number;
  tax: number;
  upgradingAddOns: AddOn[];
}) =>
  ({
    payload: { response },
    type: SubscriptionAddonsActions.GET_UPGRADING_ADD_ONS_SUCCESS
  } as const);

export const getUpgradingAddOnsFailure = (error?: string) =>
  ({
    payload: { error },
    type: SubscriptionAddonsActions.GET_UPGRADING_ADD_ONS_FAILURE
  } as const);

export const getUserEligibleAddons = () =>
  ({
    type: SubscriptionAddonsActions.GET_USER_ELIGIBLE_ADD_ONS
  } as const);

export const getUserEligibleAddonsSuccess = (response: {
  addOns: AddOn[];
  chooseYourOwnSchoolAddOns: AddOn[];
}) =>
  ({
    payload: { response },
    type: SubscriptionAddonsActions.GET_USER_ELIGIBLE_ADD_ONS_SUCCESS
  } as const);

export const getUserEligibleAddonsFailure = (error?: string) =>
  ({
    payload: { error },
    type: SubscriptionAddonsActions.GET_USER_ELIGIBLE_ADD_ONS_FAILURE
  } as const);

// NOTE: purchase/remove endpoint can accept multiple add on ids at one time
export const purchaseAddOn = (addOnId: number, sitesIds?: (number | null)[]) =>
  ({
    payload: {
      addOnId,
      sitesIds
    },
    type: SubscriptionAddonsActions.PURCHASE_ADD_ON
  } as const);

export const purchaseAddOnFailure = (error?: string) =>
  ({
    payload: { error },
    type: SubscriptionAddonsActions.PURCHASE_ADD_ON_FAILURE
  } as const);

export const purchaseAddOnSuccess = (
  upcomingBilling: SubscriptionUpcomingBilling
) =>
  ({
    payload: {
      upcomingBilling
    },
    type: SubscriptionAddonsActions.PURCHASE_ADD_ON_SUCCESS
  } as const);

export const removeAddOn = (subscriptionId: number, addOnId: number) =>
  ({
    payload: {
      addOnId,
      subscriptionId
    },
    type: SubscriptionAddonsActions.REMOVE_ADD_ON
  } as const);

export const removeAddOnFailure = (error?: string) =>
  ({
    payload: { error },
    type: SubscriptionAddonsActions.REMOVE_ADD_ON_FAILURE
  } as const);

export const removeAddOnSuccess = () =>
  ({
    type: SubscriptionAddonsActions.REMOVE_ADD_ON_SUCCESS
  } as const);

export const resetAddOnPricing = () =>
  ({
    type: SubscriptionAddonsActions.RESET_ADDON_PRICING
  } as const);

export const resetErrors = () =>
  ({
    type: SubscriptionAddonsActions.RESET_ERRORS
  } as const);

export const setModalStep = (step?: string) =>
  ({
    payload: {
      modalStep: step
    },
    type: SubscriptionAddonsActions.SET_MODAL_STEP
  } as const);

export const getModalStep = () =>
  ({
    type: SubscriptionAddonsActions.GET_MODAL_STEP
  } as const);

export const addAddOnToBundle = (addon: AddOn) =>
  ({
    payload: {
      addon
    },
    type: SubscriptionAddonsActions.ADD_ADD_ON_TO_BUNDLE
  } as const);

export const removeAddOnFromBundle = (id: number) =>
  ({
    payload: {
      id
    },
    type: SubscriptionAddonsActions.REMOVE_ADD_ON_FROM_BUNDLE
  } as const);

export const clearBundle = () =>
  ({
    type: SubscriptionAddonsActions.CLEAR_BUNDLE
  } as const);

export const setActiveAddOn = (addOn?: AddOn) =>
  ({
    payload: {
      activeAddOn: addOn
    },
    type: SubscriptionAddonsActions.SET_ACTIVE_ADD_ON
  } as const);

export const getActiveAddOn = () =>
  ({
    type: SubscriptionAddonsActions.GET_ACTIVE_ADD_ON
  } as const);

export const setUpsellStepOption = (step?: string) =>
  ({
    payload: { step },
    type: SubscriptionAddonsActions.SET_UPSELL_STEP_OPTION
  } as const);

export type SubscriptionAddonsAction =
  | ReturnType<typeof getAddOnPricing>
  | ReturnType<typeof getAddOnPricingFailure>
  | ReturnType<typeof getAddOnPricingSuccess>
  | ReturnType<typeof getAddonsForSubscription>
  | ReturnType<typeof getAddonsForSubscriptionFailure>
  | ReturnType<typeof getAddonsForSubscriptionSuccess>
  | ReturnType<typeof getUpgradingAddOns>
  | ReturnType<typeof getUpgradingAddOnsFailure>
  | ReturnType<typeof getUpgradingAddOnsSuccess>
  | ReturnType<typeof getUserEligibleAddons>
  | ReturnType<typeof getUserEligibleAddonsFailure>
  | ReturnType<typeof getUserEligibleAddonsSuccess>
  | ReturnType<typeof purchaseAddOn>
  | ReturnType<typeof purchaseAddOnFailure>
  | ReturnType<typeof purchaseAddOnSuccess>
  | ReturnType<typeof removeAddOn>
  | ReturnType<typeof removeAddOnFailure>
  | ReturnType<typeof removeAddOnSuccess>
  | ReturnType<typeof resetAddOnPricing>
  | ReturnType<typeof resetErrors>
  | ReturnType<typeof setModalStep>
  | ReturnType<typeof getModalStep>
  | ReturnType<typeof setActiveAddOn>
  | ReturnType<typeof getActiveAddOn>
  | ReturnType<typeof addAddOnToBundle>
  | ReturnType<typeof removeAddOnFromBundle>
  | ReturnType<typeof clearBundle>
  | ReturnType<typeof setUpsellStepOption>;
