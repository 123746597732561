import { createAsyncThunk } from "@reduxjs/toolkit";

import { apiService } from "rivals/redux/store";
import type { Image, ImageParams } from "rivals/shared/interfaces/Image";

enum ImageActions {
  CREATE_IMAGE = "image/createImage"
}

export const createImage = createAsyncThunk(
  ImageActions.CREATE_IMAGE,
  async (request: ImageParams, { rejectWithValue }) => {
    try {
      const response: Image = await apiService.createImage({
        image: request
      });
      return response;
    } catch (error) {
      return rejectWithValue((<Error>error)?.message);
    }
  }
);
