import { USER_ROLES } from "rivals/shared/constants";
import type { Site } from "rivals/shared/interfaces/Site";

export interface Address {
  city: string;
  firstName: string;
  lastName: string;
  state: string;
  street1: string;
  street2: string;
  zip: string;
}

export interface TaxAddress extends Partial<Address> {
  state: string;
  zip: string;
}

export interface CurrentUserAddress {
  city: string | null;
  country: string | null;
  state: string | null;
  street1: string | null;
  street2: string | null;
  zip: string | null;
}

interface OutstandingBalance {
  cents: number;
  currency: string;
}

export interface BillingInformation {
  cardType: string | null;
  expirationDate: string | null;
  obfuscatedCreditCard: string | null;
  outstandingBalance: OutstandingBalance | null;
  postalCode: string | null;
}

export interface PayPalBillingAgreement {
  billingAgreementId: string | null;
  payerId: string | null;
  redirectUrl: string | null;
  status: string | null;
  transactionId: string | null;
  userInfo: string | null;
}

export interface PayPalAuthorizationData {
  billingToken: string | null;
  intent: string | null;
  orderID: string | null;
  payerID: string | null;
  paymentID: string | null;
  paymentToken: string | null;
  returnUrl: string | null;
}

export interface PayPalBillingAgreementParams {
  billingAgreementAction: string;
  cancelUrl: string;
  returnUrl: string;
  userEmail: string;
}

export interface State {
  code: string;
  name: string;
}

// TODO: https://griosf.atlassian.net/browse/RVLS-10613
type ChartbeatUserType = "paid" | "lgdin";
export type GoogleAnalyticsUserType =
  | "subscriber"
  | "free-registered"
  | "anonymous";

export interface User {
  adLite?: boolean;
  admin?: boolean;
  affiliatedSiteId?: number;
  canForecast?: boolean;
  chartbeatUserType?: ChartbeatUserType;
  classicUserId?: number | null;
  email: string;
  emailConfirmed?: boolean;
  emailInvalid?: boolean;
  expiredPaymentMethod?: boolean;
  googleAnalyticsUserType?: Exclude<GoogleAnalyticsUserType, "anonymous">;
  inappropriateUserPhoto?: boolean;
  inappropriateUsername?: boolean;
  isPublisher?: boolean;
  isStaffWriter?: boolean;
  obiCustomerId?: string;
  paypalEmail?: string | null;
  primarySite: Site | null;
  publishingSites?: Site[];
  rivalsEmails: boolean;
  role?: typeof USER_ROLES[keyof typeof USER_ROLES];
  salt?: string;
  showAdminPortal?: boolean;
  showEnduser?: boolean;
  showPublishing?: boolean;
  since?: string;
  spyAddOnSites?: Site[];
  subscribedAll?: boolean;
  subscriptions?: Site[];
  thirdPartyEmails: boolean;
  tokenPayload?: string;
  userId: number;
  username: string;
}

interface LapsedSubAlert {
  href: string;
  linkText: string;
  message: string;
}

export interface CurrentUser extends User {
  activeDeviceTokens: string[];
  lapsedSubs?: LapsedSubAlert[];
  location: CurrentUserAddress;
  nextExpiringSub: number;
}

export function isCurrentUserType(
  user: User | CurrentUser
): user is CurrentUser {
  return (user as CurrentUser).nextExpiringSub !== undefined;
}
