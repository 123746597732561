import { getCookie } from "cookies-next";
import snakeCaseKeys from "snakecase-keys";
import { useSWRConfig } from "swr";
import useSWRMutation from "swr/mutation";
import type { SWRMutationResponse } from "swr/mutation";
import { HTTP_METHODS } from "rivals/services/base";
import { KEY as CURRENT_USER_KEY } from "rivals/services/getCurrentUser/useGetCurrentUser";
import { getMutationHeaders } from "rivals/services/utils";
import { CSURF_TOKEN_COOKIE, USER_TOKEN_COOKIE } from "rivals/shared/constants";
import type { Request } from "./types";

export const KEY = `/api/v2/users/device`;

export default function useCreateDeviceToken(): SWRMutationResponse<
  void,
  Error,
  string,
  Request
> {
  const { mutate } = useSWRConfig();

  return useSWRMutation(
    KEY,
    async function (url, { arg }) {
      const token = getCookie(USER_TOKEN_COOKIE);
      const csurf = getCookie(CSURF_TOKEN_COOKIE);

      await fetch(url, {
        body: JSON.stringify(snakeCaseKeys(arg)),
        credentials: "include",
        headers: getMutationHeaders(token, csurf),
        method: HTTP_METHODS.POST
      });
    },
    {
      onSuccess: function () {
        // Invalidate the current user cache since this returns the active device tokens
        mutate(CURRENT_USER_KEY);
      }
    }
  );
}
