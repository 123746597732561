import { all, call, takeEvery } from "redux-saga/effects";
import { gaSagaEvent } from "rivals/shared/analytics";
import { GAActions, sendGAEvent as sendGAEventAction } from "./actions";

export function* sendGAEvent(
  action: ReturnType<typeof sendGAEventAction>
): Generator<ReturnType<typeof call>, void, unknown> {
  const { payload } = action;
  yield call(gaSagaEvent, payload);
}

export default function* saga(): Generator {
  yield all([takeEvery(GAActions.SEND_GA_EVENT, sendGAEvent)]);
}
