import {
  ADVANCED_SEARCH,
  FAN_FUTURECAST_LEADERBOARD,
  FUTURECAST,
  HOME,
  PROSPECT_SEARCH,
  SUBSCRIBE,
  TRANSFER_SEARCH
} from "rivals/shared/constants";

export default {
  navSubTitles: {
    colleges: "Colleges",
    fanFuturecastLeaderboard: FAN_FUTURECAST_LEADERBOARD,
    highschools: "High Schools",
    prospectSearch: PROSPECT_SEARCH,
    transferSearch: TRANSFER_SEARCH
  },
  navTitles: {
    advancedSearch: ADVANCED_SEARCH,
    futureCast: FUTURECAST,
    home: HOME,
    subscribe: SUBSCRIBE,
    teams: "Teams"
  }
};
