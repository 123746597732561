import {
  Request as SaveAdminListRequest,
  Response as SaveAdminListResponse
} from "rivals/services/saveAdminList/types";
import {
  Request as SearchPeopleRequest,
  Response as SearchPeopleResponse
} from "rivals/services/searchPeople/types";
import { ListData, ListPeople } from "rivals/shared/interfaces/adminLists";

export enum AdminListsActions {
  CLEAN_LIST_PEOPLE = "CLEAN_LIST_PEOPLE",
  DELETE_LIST = "DELETE_LIST",
  DELETE_LIST_FAILURE = "DELETE_LIST_FAILURE",
  DELETE_LIST_SUCCESS = "DELETE_LIST_SUCCESS",
  GET_LIST_DATA = "GET_LIST_DATA",
  GET_LIST_DATA_FAILURE = "GET_LIST_DATA_FAILURE",
  GET_LIST_DATA_SUCCESS = "GET_LIST_DATA_SUCCESS",
  GET_LIST_PEOPLE = "GET_LIST_PEOPLE",
  GET_LIST_PEOPLE_FAILURE = "GET_LIST_PEOPLE_FAILURE",
  GET_LIST_PEOPLE_SUCCESS = "GET_LIST_PEOPLE_SUCCESS",
  SAVE_ADMIN_LIST = "SAVE_ADMIN_LIST",
  SAVE_ADMIN_LIST_FAILURE = "SAVE_ADMIN_LIST_FAILURE",
  SAVE_ADMIN_LIST_SUCCESS = "SAVE_ADMIN_LIST_SUCCESS",
  SAVE_ADMIN_LIST_SHEETS = "SAVE_ADMIN_LIST_SHEETS",
  SAVE_ADMIN_LIST_SHEETS_FAILURE = "SAVE_ADMIN_LIST_SHEETS_FAILURE",
  SAVE_ADMIN_LIST_SHEETS_SUCCESS = "SAVE_ADMIN_LIST_SHEETS_SUCCESS",

  SEARCH_PEOPLE = "SEARCH_LIST_PEOPLE",
  SEARCH_PEOPLE_FAILURE = "SEARCH_LIST_PEOPLE_FAILURE",
  SEARCH_PEOPLE_SUCCESS = "SEARCH_LIST_PEOPLE_SUCCESS"
}

export const cleanListPeople = () =>
  ({
    type: AdminListsActions.CLEAN_LIST_PEOPLE
  } as const);

export const deleteList = (id: number) =>
  ({
    payload: id,
    type: AdminListsActions.DELETE_LIST
  } as const);

export const deleteListFailure = (error?: string) =>
  ({
    payload: { error },
    type: AdminListsActions.DELETE_LIST_FAILURE
  } as const);

export const deleteListSuccess = () =>
  ({
    type: AdminListsActions.DELETE_LIST_SUCCESS
  } as const);

export const getListData = (id: string) =>
  ({
    payload: { id },
    type: AdminListsActions.GET_LIST_DATA
  } as const);

export const getListDataSuccess = (list: ListData) =>
  ({
    payload: list,
    type: AdminListsActions.GET_LIST_DATA_SUCCESS
  } as const);

export const getListDataFailure = (error?: string) =>
  ({
    payload: { error },
    type: AdminListsActions.GET_LIST_DATA_FAILURE
  } as const);

export const getListPeople = (id: string) =>
  ({
    payload: { id },
    type: AdminListsActions.GET_LIST_PEOPLE
  } as const);

export const getListPeopleSuccess = (listPeople: ListPeople) =>
  ({
    payload: listPeople,
    type: AdminListsActions.GET_LIST_PEOPLE_SUCCESS
  } as const);

export const getListPeopleFailure = (error?: string) =>
  ({
    payload: { error },
    type: AdminListsActions.GET_LIST_PEOPLE_FAILURE
  } as const);

export const searchPeople = (params: SearchPeopleRequest) =>
  ({
    payload: params,
    type: AdminListsActions.SEARCH_PEOPLE
  } as const);

export const searchPeopleSuccess = (response: SearchPeopleResponse) =>
  ({
    payload: response,
    type: AdminListsActions.SEARCH_PEOPLE_SUCCESS
  } as const);

export const searchPeopleFailure = (error?: string) =>
  ({
    payload: { error },
    type: AdminListsActions.SEARCH_PEOPLE_FAILURE
  } as const);

export const saveAdminList = (params: SaveAdminListRequest) =>
  ({
    payload: params,
    type: AdminListsActions.SAVE_ADMIN_LIST
  } as const);

export const saveAdminListSuccess = (response: SaveAdminListResponse) =>
  ({
    payload: response,
    type: AdminListsActions.SAVE_ADMIN_LIST_SUCCESS
  } as const);

export const saveAdminListFailure = (error?: string) =>
  ({
    payload: { error },
    type: AdminListsActions.SAVE_ADMIN_LIST_FAILURE
  } as const);

export const saveAdminListSheets = (
  sport: string,
  year: string,
  personIds: string,
  createNewVersion: boolean
) =>
  ({
    payload: { createNewVersion, personIds, sport, year },
    type: AdminListsActions.SAVE_ADMIN_LIST_SHEETS
  } as const);

export const saveAdminListSheetsSuccess = (workerTriggered: boolean) =>
  ({
    payload: workerTriggered,
    type: AdminListsActions.SAVE_ADMIN_LIST_SHEETS_SUCCESS
  } as const);

export const saveAdminListSheetsFailure = (error?: string) =>
  ({
    payload: { error },
    type: AdminListsActions.SAVE_ADMIN_LIST_SHEETS_FAILURE
  } as const);

export type AdminListsAction =
  | ReturnType<typeof deleteList>
  | ReturnType<typeof deleteListFailure>
  | ReturnType<typeof deleteListSuccess>
  | ReturnType<typeof cleanListPeople>
  | ReturnType<typeof getListData>
  | ReturnType<typeof getListDataFailure>
  | ReturnType<typeof getListDataSuccess>
  | ReturnType<typeof getListPeople>
  | ReturnType<typeof getListPeopleFailure>
  | ReturnType<typeof getListPeopleSuccess>
  | ReturnType<typeof saveAdminList>
  | ReturnType<typeof saveAdminListFailure>
  | ReturnType<typeof saveAdminListSuccess>
  | ReturnType<typeof saveAdminListSheets>
  | ReturnType<typeof saveAdminListSheetsFailure>
  | ReturnType<typeof saveAdminListSheetsSuccess>
  | ReturnType<typeof searchPeople>
  | ReturnType<typeof searchPeopleFailure>
  | ReturnType<typeof searchPeopleSuccess>;
