import { PromotionParams } from "rivals/services/validatePromoCode/types";
import { Promotion } from "rivals/shared/interfaces/Promotion";

export enum PromotionActions {
  GET_ACTIVE_PROMOTION = "GET_ACTIVE_PROMOTION",
  GET_PROMOTION_FAILURE = "GET_PROMOTION_FAILURE",
  GET_PROMOTION_SUCCESS = "GET_PROMOTION_SUCCESS",
  REMOVE_PROMOTION = "REMOVE_PROMOTION",
  VALIDATE_PROMO_CODE = "VALIDATE_PROMO_CODE"
}

export const getActivePromotion = (promoCode: string) =>
  ({
    payload: { promoCode },
    type: PromotionActions.GET_ACTIVE_PROMOTION
  } as const);

export const getPromotionFailure = (error?: string) =>
  ({
    error: true,
    payload: { error },
    type: PromotionActions.GET_PROMOTION_FAILURE
  } as const);

export const getPromotionSuccess = (promotion: Promotion) =>
  ({
    payload: {
      promotion
    },
    type: PromotionActions.GET_PROMOTION_SUCCESS
  } as const);

export const removePromotion = () =>
  ({ type: PromotionActions.REMOVE_PROMOTION } as const);

export const validatePromoCode = (promotionParams: PromotionParams) =>
  ({
    payload: {
      promotion: promotionParams
    },
    type: PromotionActions.VALIDATE_PROMO_CODE
  } as const);

export type PromotionAction =
  | ReturnType<typeof getActivePromotion>
  | ReturnType<typeof getPromotionFailure>
  | ReturnType<typeof getPromotionSuccess>
  | ReturnType<typeof removePromotion>
  | ReturnType<typeof validatePromoCode>;
