import { ReactElement } from "react";
import styles from "rivals/components/shared/Icons/Icons.module.scss";
import { IconProps } from "rivals/components/shared/Icons/iconPropsTypes";

export default function PushNotification({
  fill = styles.bone,
  height = "120",
  width = "120"
}: IconProps): ReactElement {
  return (
    <svg
      fill="none"
      height={height}
      viewBox="0 0 120 120"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Push Notification</title>
      <path
        d="M86.9997 39.983H67.6875V35.9172L70.7368 32.8679V26.7693L73.2779 22.7036H81.4094L83.9504 26.7693V32.8679L86.9997 35.9172V39.983Z"
        stroke={fill}
        strokeWidth="2.03287"
      />
      <path
        d="M74.5687 23.6047C74.5687 25.466 73.0598 26.9749 71.1984 26.9749C69.3371 26.9749 67.8281 25.466 67.8281 23.6047C67.8281 21.7433 69.3371 20.2344 71.1984 20.2344C73.0598 20.2344 74.5687 21.7433 74.5687 23.6047Z"
        fill="#FF0000"
      />
      <path
        clipRule="evenodd"
        d="M25.4289 30.0021H47.2707V25.2021H25.4288C22.7779 25.2021 20.6289 27.3512 20.6289 30.0021V101.4C20.6289 104.051 22.7779 106.2 25.4288 106.2H69.1126C71.7636 106.2 73.9126 104.051 73.9126 101.4V65.7012H69.1126V101.4L25.4288 101.4L25.4289 30.0021Z"
        fill={fill}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M53.3555 13.1958H106.639V50.995H77.5781L59.7103 66.9153L64.6239 50.995H53.3555V13.1958ZM67.8764 48.595L64.5431 59.3947L76.664 48.595H104.239V15.5958H55.7555V48.595H67.8764Z"
        fill="#9C9C9C"
        fillRule="evenodd"
      />
      <path
        d="M48.7911 90.4268C48.7911 91.7412 47.7256 92.8068 46.4112 92.8068C45.0968 92.8068 44.0312 91.7412 44.0312 90.4268C44.0312 89.1124 45.0968 88.0469 46.4112 88.0469C47.7256 88.0469 48.7911 89.1124 48.7911 90.4268Z"
        fill={fill}
      />
    </svg>
  );
}
