"use client";

import { connect } from "react-redux";
import {
  DispatchProps,
  StateProps
} from "rivals/components/app/Layout/Footer/types";
import { getCCPAFooter as getCCPAFooterConstantsAction } from "rivals/redux/rails/actions";
import { selectCCPAFooterConstants } from "rivals/redux/rails/selectors";
import { State } from "rivals/redux/reducer";
import Footer from ".";

const mapStateToProps = (state: State): StateProps => ({
  CCPAFooterConstants: selectCCPAFooterConstants(state)
});

const mapDispatchToProps: DispatchProps = {
  getCCPAFooterConstantsAction
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
