import { createSlice } from "@reduxjs/toolkit";
import type { FetchTeamsNavItemsResponse } from "rivals/services/container";
import { fetchTeamsNavItems } from "./actions";

export type State = {
  data?: FetchTeamsNavItemsResponse;
  error?: string;
  submitting: boolean;
  success?: boolean;
};

export const initialState: State = {
  submitting: false
};

const teamsNavSlice = createSlice({
  extraReducers: builder => {
    builder
      .addCase(fetchTeamsNavItems.pending, state => {
        state.submitting = true;
        state.success = undefined;
      })
      .addCase(fetchTeamsNavItems.rejected, (state, action) => {
        state.error = (action.payload as string) || action.error.message;
        state.submitting = false;
        state.success = false;
      })
      .addCase(fetchTeamsNavItems.fulfilled, (state, action) => {
        state.data = action.payload;
        state.submitting = false;
        state.error = undefined;
        state.success = true;
      });
  },
  initialState,
  name: "teamsNav",
  reducers: {}
});

export default teamsNavSlice.reducer;
