import styles from "./Icons.module.scss";
import type { IconProps } from "./iconPropsTypes";

const Warning = ({
  className = "",
  fill = styles.bone,
  fillBg = styles.fail,
  height = "16px",
  stroke = styles.crimson,
  title = "Warning",
  width = "16px"
}: IconProps): React.JSX.Element => {
  return (
    <svg
      className={className}
      fill="none"
      height={height}
      viewBox="0 0 20 17"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{title}</title>
      <path
        d="M19.1146 16.4996H0.863071L9.75854 0.986195L19.1146 16.4996Z"
        fill={fillBg}
        stroke={stroke}
      />
      <path
        clipRule="evenodd"
        d="M8.88992 12.3036L8.88992 6.23828L9.92969 6.23828L9.92969 12.3036L8.88992 12.3036Z"
        fill={fill}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M8.88992 14.2092L8.88992 12.9961L9.92969 12.9961L9.92969 14.2092L8.88992 14.2092Z"
        fill={fill}
        fillRule="evenodd"
      />
    </svg>
  );
};

export default Warning;
