import { all } from "redux-saga/effects";

import adminAthleteSaga from "rivals/redux/admin/athlete/saga";
import adminListsSaga from "rivals/redux/admin/lists/saga";
import adminReportsSaga from "rivals/redux/admin/reports/saga";
import adminUsersSaga from "rivals/redux/admin/users/saga";
import athleteSaga from "rivals/redux/athlete/saga";
import authorizationSaga from "rivals/redux/authentication/saga";
import contentSaga from "rivals/redux/content/saga";
import errorSaga from "rivals/redux/error/saga";
import futurecastSaga from "rivals/redux/futurecast/saga";
import googleEventSaga from "rivals/redux/ga/saga";
import imageTagSaga from "rivals/redux/imageTag/saga";
import listSaga from "rivals/redux/list/saga";
import promotionSaga from "rivals/redux/promotion/saga";
import prospectSaga from "rivals/redux/prospect/saga";
import railsSaga from "rivals/redux/rails/saga";
import registrationSaga from "rivals/redux/registration/saga";
import searchSaga from "rivals/redux/search/saga";
import subscriptionSaga from "rivals/redux/subscription/saga";
import subscriptionAddonsSaga from "rivals/redux/subscriptionAddons/saga";
import usersSaga from "rivals/redux/user/saga";

export default function* rootSaga(): Generator {
  yield all([
    adminAthleteSaga(),
    adminListsSaga(),
    adminReportsSaga(),
    adminUsersSaga(),
    athleteSaga(),
    authorizationSaga(),
    contentSaga(),
    errorSaga(),
    futurecastSaga(),
    googleEventSaga(),
    imageTagSaga(),
    listSaga(),
    promotionSaga(),
    prospectSaga(),
    railsSaga(),
    registrationSaga(),
    searchSaga(),
    subscriptionAddonsSaga(),
    subscriptionSaga(),
    usersSaga()
  ]);
}
