import localFont from "next/font/local";

const gtAmericaExtended = localFont({
  src: [
    {
      path: "./GT-America-Extended-Bold.woff2",
      style: "normal",
      weight: "700"
    },
    {
      path: "./GT-America-Extended-Black.woff2",
      style: "normal",
      weight: "900"
    }
  ],
  variable: "--gt-america-extended"
});

export default gtAmericaExtended;
