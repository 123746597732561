"use client";

import { onMessage } from "@firebase/messaging";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { fetchToken, FIREBASE_CONFIG, messaging } from "rivals/firebase";
import useCreateDeviceToken from "rivals/services/createDeviceToken/useCreateDeviceToken";
import useGetCurrentUser from "rivals/services/getCurrentUser/useGetCurrentUser";
import useGetSiteFromLocation from "rivals/services/getSiteFromLocation/useGetSiteFromLocation";
import {
  NotificationPermissionStatus,
  ReturnType
} from "rivals/shared/hooks/useNotificationManager/types";

const NOTIFICATION_GENERATION = 2;
const NOTIFICATION_PLATFORM = "web";

export default function useNotificationManager(): ReturnType {
  const [
    notificationPermissionStatus,
    setNotificationPermissionStatus
  ] = useState<NotificationPermission | null>(null);
  const [token, setToken] = useState<string | null>(null);
  const { data: user } = useGetCurrentUser();
  const { data: site } = useGetSiteFromLocation();
  const { trigger: createDeviceToken } = useCreateDeviceToken();

  useEffect(() => {
    async function registerWorkerAndCheckPermission(): Promise<void> {
      registerFirebaseServiceWorker();

      if (!("Notification" in window)) {
        return;
      }

      const permission = Notification.permission;
      setNotificationPermissionStatus(permission);
      if (permission === NotificationPermissionStatus.GRANTED) {
        const fcmToken = await fetchToken();
        setToken(fcmToken);
      }
    }

    registerWorkerAndCheckPermission();
  }, []);

  /**
   * Send the device token to the server when the user is logged in, the fcm token is set and the site is available.
   * Only send the token if it is not already in the list of active device tokens.
   */
  useEffect(() => {
    const userActiveDeviceTokens = user?.user?.activeDeviceTokens ?? [];
    const isNewToken = !!token && !userActiveDeviceTokens.includes(token);
    const userId = user?.user?.userId;
    const siteId = site?.id;

    if (isNewToken && !!userId && !!siteId && !!site?.enablePwa) {
      createDeviceToken({
        userDevice: {
          generation: NOTIFICATION_GENERATION,
          platform: NOTIFICATION_PLATFORM,
          siteId,
          token,
          userId
        }
      });
    }
  }, [site?.id, user?.user, token, createDeviceToken, site?.enablePwa]);

  /**
   * Hook to listen for incoming messages push notifications while app is in the foreground on desktop.
   * The background notifications are handled by the service worker.
   */
  useEffect(() => {
    const setupListener = async function (): Promise<(() => void) | undefined> {
      const firebaseMessaging = await messaging();

      if (!firebaseMessaging) {
        return;
      }

      const unsubscribe = onMessage(firebaseMessaging, async payload => {
        if (payload.notification) {
          const notification = new Notification(
            payload.notification.title || "",
            {
              body: payload.notification.body
            }
          );

          notification.onclick = (): void => {
            const url =
              payload.fcmOptions?.link || payload.data?.content_url || "/";
            // Open the URL in a new tab to be consistent with the service worker.
            window.open(url, "_blank");
            notification.close();
          };
        }
      });

      return () => unsubscribe();
    };

    if (token && !!site?.enablePwa && !isMobile) {
      setupListener();
    }
  }, [token, site?.enablePwa]);

  async function registerFirebaseServiceWorker(): Promise<void> {
    if ("serviceWorker" in navigator && "PushManager" in window) {
      // Since we can't access env variables in the service worker, we need to pass the Firebase config as a query string
      const urlFirebaseConfig = new URLSearchParams(FIREBASE_CONFIG.toString());

      try {
        await navigator.serviceWorker.register(
          `/firebase-messaging-sw.js?${urlFirebaseConfig}`,
          {
            scope: "/",
            updateViaCache: "none"
          }
        );
      } catch (error) {
        console.log("Service Worker registration failed:", error); // eslint-disable-line no-console
      }
    }
  }

  async function requestNotificationPermission(): Promise<boolean> {
    if (!("Notification" in window)) {
      return false;
    }

    // Request permission to send notifications
    const permission = await Notification.requestPermission();
    setNotificationPermissionStatus(permission);
    if (permission === NotificationPermissionStatus.GRANTED) {
      // Retrieve device token. Subsequent calls to this function will return from cache.
      const fcmToken = await fetchToken();
      setToken(fcmToken);
      return !!fcmToken;
    }
    return false;
  }

  return {
    isFeatureEnabled: !!site?.enablePwa,
    notificationPermissionStatus,
    requestNotificationPermission,
    token
  };
}
