import { createMedia } from "@artsy/fresnel";
import { BREAKPOINTS } from "./shared/constants";

const RivalsMedia = createMedia({
  breakpoints: {
    lg: BREAKPOINTS["screen-sm-desktop-min"],
    md: BREAKPOINTS["screen-tablet-min"],
    sm: BREAKPOINTS["screen-mobile-min"],
    xl: BREAKPOINTS["screen-lg-desktop-min"],
    xs: 0
  }
});

// Make styles for injection into the header of the page
export const mediaStyles = RivalsMedia.createMediaStyle();

export const { Media, MediaContextProvider } = RivalsMedia;

export enum MEDIA_BREAKPOINTS {
  XL = "xl",
  LG = "lg",
  MD = "md",
  SM = "sm",
  XS = "xs"
}
