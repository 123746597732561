import localFont from "next/font/local";

const yahooSans = localFont({
  src: [
    {
      path: "./YahooSans-Black.woff2",
      style: "normal",
      weight: "900"
    },
    {
      path: "./YahooSans-BlackItalic.woff2",
      style: "italic",
      weight: "900"
    },
    {
      path: "./YahooSans-Bold.woff2",
      style: "normal",
      weight: "700"
    },
    {
      path: "./YahooSans-BoldItalic.woff2",
      style: "italic",
      weight: "700"
    },
    {
      path: "./YahooSans-ExtraBold.woff2",
      style: "normal",
      weight: "900"
    },
    {
      path: "./YahooSans-ExtraBoldItalic.woff2",
      style: "italic",
      weight: "900"
    },
    {
      path: "./YahooSans-ExtraLight.woff2",
      style: "normal",
      weight: "200"
    },
    {
      path: "./YahooSans-Italic.woff2",
      style: "italic",
      weight: "400"
    },
    {
      path: "./YahooSans-Light.woff2",
      style: "normal",
      weight: "300"
    },
    {
      path: "./YahooSans-LightItalic.woff2",
      style: "italic",
      weight: "300"
    },
    {
      path: "./YahooSans-Medium.woff2",
      style: "normal",
      weight: "500"
    },
    {
      path: "./YahooSans-Regular.woff2",
      style: "normal",
      weight: "400"
    },
    {
      path: "./YahooSans-Semibold.woff2",
      style: "normal",
      weight: "600"
    }
  ],
  variable: "--yahoo-sans"
});

export default yahooSans;
