import { createSlice } from "@reduxjs/toolkit";

import type { Image } from "rivals/shared/interfaces/Image";
import { createImage } from "./actions";

export type State = {
  error?: string;
  image?: Image;
};

const initialState: State = {
  error: undefined,
  image: undefined
};

const imageSlice = createSlice({
  extraReducers: builder => {
    builder
      .addCase(createImage.fulfilled, (state, action) => {
        state.error = undefined;
        state.image = action.payload;
      })
      .addCase(createImage.rejected, (state, action) => {
        state.error = (action.payload as string) || action.error.message;
      });
  },
  initialState,
  name: "image",
  reducers: {
    resetImage: state => {
      state.image = undefined;
    }
  }
});

export const { resetImage } = imageSlice.actions;
export default imageSlice.reducer;
