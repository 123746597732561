import { SWRResponse } from "swr";
import type { Response } from "rivals/services/getCurrentUser/types";
import useQuery, {
  UseQueryOptions
} from "rivals/shared/hooks/useQuery/useQuery";

export const KEY = "/api/v2/users/current";

export default function useGetCurrentUser(
  options?: UseQueryOptions
): SWRResponse<Response> {
  return useQuery<Response>(KEY, options);
}
