import { PRIMARY_SPORTS } from "rivals/shared/constants";

export interface Props {
  group: string;
  groupingType: GROUPING_TYPES;
  sport: PRIMARY_SPORTS;
  type: TEAM_TYPES;
}

export enum TEAM_TYPES {
  COLLEGES = "colleges",
  HIGH_SCHOOLS = "highschools"
}

export enum GROUPING_TYPES {
  ALPHABETICALLY = "alphabetically",
  BY_CONFERENCE = "by_conference",
  BY_REGION = "by_region"
}

export type PageParams = Props;
