import {
  AdminListsAction,
  AdminListsActions
} from "rivals/redux/admin/lists/actions";
import {
  ListData,
  ListPeople,
  PeopleData
} from "rivals/shared/interfaces/adminLists";

export type State = {
  error?: string;
  listData?: ListData;
  listPeople?: ListPeople;
  peopleData: PeopleData;
  submitting: boolean;
  successfulListDataSave?: boolean;
};

const initialState = {
  listData: undefined,
  peopleData: {
    error: undefined,
    people: [],
    searching: false,
    total: 0
  },
  submitting: false,
  successfulListDataSave: false
};

export default function reducer(
  state: State = initialState,
  action: AdminListsAction
): State {
  switch (action.type) {
    case AdminListsActions.CLEAN_LIST_PEOPLE:
      return {
        ...state,
        peopleData: {
          ...state.peopleData,
          people: []
        }
      };

    case AdminListsActions.GET_LIST_DATA_SUCCESS:
      return {
        ...state,
        listData: {
          ...action.payload
        }
      };

    case AdminListsActions.GET_LIST_DATA_FAILURE:
      return {
        ...state,
        error: action.payload.error
      };

    case AdminListsActions.GET_LIST_PEOPLE_SUCCESS:
      return {
        ...state,
        listPeople: {
          ...action.payload,
          people: action.payload.people.sort((a, b) => {
            const ordinalityA = a.ordinality as number;
            const ordinalityB = b.ordinality as number;

            return ordinalityA - ordinalityB;
          })
        }
      };

    case AdminListsActions.GET_LIST_PEOPLE_FAILURE:
      return {
        ...state,
        error: action.payload.error
      };

    case AdminListsActions.SEARCH_PEOPLE:
      return {
        ...state,
        peopleData: {
          ...state.peopleData,
          people: [],
          searching: true
        }
      };

    case AdminListsActions.SEARCH_PEOPLE_SUCCESS:
      return {
        ...state,
        peopleData: {
          ...state.peopleData,
          people: action.payload.people,
          searching: false,
          total: action.payload.total
        }
      };

    case AdminListsActions.SEARCH_PEOPLE_FAILURE:
      return {
        ...state,
        peopleData: {
          ...state.peopleData,
          error: action.payload.error,
          searching: false
        }
      };

    case AdminListsActions.SAVE_ADMIN_LIST:
    case AdminListsActions.SAVE_ADMIN_LIST_SHEETS:
      return {
        ...state,
        submitting: true,
        successfulListDataSave: false
      };

    case AdminListsActions.SAVE_ADMIN_LIST_FAILURE:
    case AdminListsActions.SAVE_ADMIN_LIST_SHEETS_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        submitting: false
      };

    case AdminListsActions.SAVE_ADMIN_LIST_SUCCESS:
      return {
        ...state,
        listData: {
          history: state.listData ? state.listData.history : [],
          list: {
            ...action.payload.list
          }
        },
        submitting: false,
        successfulListDataSave: true
      };
    case AdminListsActions.SAVE_ADMIN_LIST_SHEETS_SUCCESS:
      return {
        ...state,
        submitting: false,
        successfulListDataSave: true
      };

    default:
      return state;
  }
}
