import { ReactElement } from "react";
import { TEAM_RANKING_TYPES } from "rivals/shared/constants";
import styles from "./index.module.scss";
import { OwnProps } from "./types";

const iconBgMap = {
  comprehensive: styles.abyss,
  recruiting: styles.recruit,
  transfer: styles.transfer
};

const iconColorMap = {
  comprehensive: styles.bone,
  recruiting: styles.abyss,
  transfer: styles.bone
};

const RivalsSquareIcon = ({
  backgroundFill,
  iconFill,
  height = "36",
  type = TEAM_RANKING_TYPES.COMPREHENSIVE,
  width = "36"
}: OwnProps): ReactElement => {
  return (
    <svg
      fill="none"
      height={height}
      viewBox="0 0 36 36"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect fill="url(#pattern0_5979_70256)" height="36" width="36" />
      <rect fill={backgroundFill || iconBgMap[type]} height="36" width="36" />
      <path
        d="M6 10.0123H6.56031C12.9091 10.0123 19.2565 10.0164 25.6039 10C25.8362 10 26.1646 10 26.1646 10C26.1646 10 26.4944 10.2632 26.627 10.3708C27.4688 11.0564 28.3163 11.7337 29.1498 12.4275C29.1995 12.4691 29.3156 12.5666 29.3156 12.5666C29.3156 12.5666 29.315 12.735 29.3156 12.7942C29.3268 14.1885 29.3379 15.5843 29.3073 16.9786C29.3059 17.0427 29.3073 17.2162 29.3073 17.2162C29.3073 17.2162 29.0581 17.4658 28.9407 17.5643C28.1448 18.2361 27.3211 18.8752 26.5169 19.5374C26.3901 19.6415 26.0506 19.8686 26.0506 19.8686C26.0506 19.8686 25.8356 19.8789 25.6583 19.8754C24.638 19.8549 23.6177 19.8686 22.4637 19.8686C25.0018 21.9239 27.4549 23.9121 30 25.9742C29.6083 25.9742 29.3324 25.9742 29.0578 25.9742C27.4298 25.9742 25.8005 25.929 24.1753 25.9934C23.8052 26.0083 23.2025 25.9934 23.2025 25.9934C23.2025 25.9934 22.6447 25.5366 22.3759 25.3119C21.1005 24.246 19.7862 23.2252 18.4955 22.177C17.5505 21.4107 16.6236 20.6239 15.6786 19.8563C14.7698 19.1187 13.8471 18.3962 12.9342 17.6642C12.5272 17.3385 12.13 17.0005 11.7258 16.6721C11.6087 16.5763 11.4805 16.4929 11.2909 16.3547V25.9482H6V10.0123ZM23.9718 16.0988V14.0517H11.3285V16.0988H23.9718Z"
        fill={iconFill || iconColorMap[type]}
      />
      <defs>
        <pattern
          height="1"
          id="pattern0_5979_70256"
          patternContentUnits="objectBoundingBox"
          width="1"
        >
          <use
            transform="scale(0.0015528 0.00155521)"
            xlinkHref="#image0_5979_70256"
          />
        </pattern>
      </defs>
    </svg>
  );
};

export default RivalsSquareIcon;
