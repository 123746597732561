import styles from "rivals/components/shared/Icons/Icons.module.scss";
import type { IconProps } from "rivals/components/shared/Icons/iconPropsTypes";

const CheckCircle = ({
  className = "",
  fill = styles.white,
  fillBg = styles.black,
  height = "73px",
  title = "Check",
  width = "73px"
}: IconProps): React.JSX.Element => {
  return (
    <svg
      className={className}
      fill="none"
      height={height}
      viewBox="0 0 73 73"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{title}</title>
      <circle cx="36.5" cy="36.5" fill={fill} r="33.5" />
      <path
        clipRule="evenodd"
        d="M36.4998 3.04199C18.0217 3.04199 3.0415 18.0222 3.0415 36.5003C3.0415 54.9784 18.0217 69.9587 36.4998 69.9587C54.978 69.9587 69.9582 54.9784 69.9582 36.5003C69.9582 18.0222 54.978 3.04199 36.4998 3.04199ZM51.0025 30.8428C51.2695 30.5376 51.4729 30.182 51.6005 29.797C51.7281 29.412 51.7774 29.0054 51.7455 28.6011C51.7136 28.1967 51.6012 27.8029 51.4149 27.4426C51.2285 27.0824 50.972 26.7631 50.6605 26.5034C50.3489 26.2438 49.9885 26.049 49.6006 25.9307C49.2127 25.8124 48.805 25.7729 48.4015 25.8144C47.9981 25.856 47.607 25.9778 47.2514 26.1728C46.8957 26.3677 46.5826 26.6318 46.3305 26.9495L33.2513 42.6414L26.4836 35.8707C25.91 35.3166 25.1416 35.0101 24.3441 35.017C23.5466 35.0239 22.7837 35.3438 22.2198 35.9077C21.6558 36.4717 21.3359 37.2346 21.329 38.0321C21.3221 38.8296 21.6286 39.5979 22.1827 40.1716L31.3077 49.2966C31.6066 49.5953 31.9644 49.8284 32.3583 49.9812C32.7523 50.1339 33.1738 50.2029 33.5959 50.1837C34.018 50.1645 34.4314 50.0576 34.8099 49.8698C35.1884 49.682 35.5236 49.4174 35.7942 49.0928L51.0025 30.8428Z"
        fill={fillBg}
        fillRule="evenodd"
      />
    </svg>
  );
};

export default CheckCircle;
