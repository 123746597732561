import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiService } from "rivals/redux/store";
import type { FetchTeamsNavItemsResponse } from "rivals/services/container";
import RivalsApiError from "rivals/shared/error";

export enum TeamsNavActions {
  FETCH_TEAMS_NAV_ITEMS = "teamsNav/fetchTeamsNavItems"
}

export const fetchTeamsNavItems = createAsyncThunk(
  TeamsNavActions.FETCH_TEAMS_NAV_ITEMS,
  async (_request, { rejectWithValue }) => {
    try {
      const response: FetchTeamsNavItemsResponse = await apiService.fetchTeamsNavItems();
      return response;
    } catch (error) {
      return rejectWithValue((error as RivalsApiError)?.message);
    }
  }
);
