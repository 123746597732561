import { Gallery } from "rivals/shared/interfaces/Content";

export enum ContentActions {
  GET_GALLERY = "GET_GALLERY",
  GET_GALLERY_FAILURE = "GET_GALLERY_FAILURE",
  GET_GALLERY_SUCCESS = "GET_GALLERY_SUCCESS",
  RESET_GALLERY = "RESET_GALLERY"
}

export const getGallery = (galleryId: number) =>
  ({
    payload: { galleryId },
    type: ContentActions.GET_GALLERY
  } as const);

export const getGalleryFailure = (error?: string) =>
  ({
    error: true,
    payload: { error },
    type: ContentActions.GET_GALLERY_FAILURE
  } as const);

export const getGallerySuccess = (gallery: Gallery) =>
  ({
    payload: { gallery },
    type: ContentActions.GET_GALLERY_SUCCESS
  } as const);

export const resetGallery = () =>
  ({
    type: ContentActions.RESET_GALLERY
  } as const);

export type ContentAction =
  | ReturnType<typeof getGallery>
  | ReturnType<typeof getGalleryFailure>
  | ReturnType<typeof getGallerySuccess>
  | ReturnType<typeof resetGallery>;
