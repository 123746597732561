import { gsap } from "gsap";
import { DependencyList, RefObject, useEffect, useRef, useState } from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import type { Store } from "rivals/redux/store";

// Use throughout the app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => Store["dispatch"] = useDispatch;
export const useAppSelector: TypedUseSelectorHook<ReturnType<
  Store["getState"]
>> = useSelector;

export const useToggleTable = (
  show: boolean,
  anchorRef: RefObject<HTMLDivElement>,
  target = ".nonDefaultRow"
): void => {
  const isInitialMount = useRef<boolean>(true);
  const prevShowAllRef = useRef<boolean>(false);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    }
  }, []);

  useEffect(() => {
    if (show) {
      gsap.fromTo(target, { opacity: 0 }, { duration: 1, opacity: 1 });
    }
    if (
      prevShowAllRef.current !== show &&
      anchorRef.current &&
      !isInitialMount.current
    ) {
      gsap.to(window, {
        duration: 1,
        scrollTo: anchorRef.current
      });
    }
    prevShowAllRef.current = show;
  }, [anchorRef, show, target]);
};

export const useMountedState = (): boolean => {
  const [isMounted, setIsMounted] = useState<boolean>(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return isMounted;
};

export function useOnClickAway<
  T extends { contains: (eventTarget: EventTarget | null) => boolean }
>(ref: RefObject<T>, callback: () => void, deps: DependencyList): void {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: MouseEvent): void {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return (): void => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [deps, callback, ref]);
}
