import Cookies from "js-cookie";
import { DEBUG_ANALYTICS_GA_COOKIE } from "rivals/shared/constants";
import type { GoogleAnalyticsUserType } from "rivals/shared/interfaces/User";
import type { RapidConfig } from "./types";

const debugAnalytics = Cookies.get(DEBUG_ANALYTICS_GA_COOKIE);

/* eslint-disable no-console */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function debugLog(message: string, payload: any = undefined): void {
  if (debugAnalytics) {
    const msg = `Analytics Debug ==> ${message}`;
    if (payload) {
      console.log(msg, payload);
    } else {
      console.log(msg);
    }
  }
}
/* eslint-enable no-console */

export function configAnalyticsGa(
  spaceId: number,
  tagId: string,
  userType: GoogleAnalyticsUserType,
  topicTags?: string
): RapidConfig {
  YAHOO.i13n.WEBWORKER_FILE =
    process.env.NEXT_PUBLIC_RAPID_WEBWORKER_FILE ||
    "js/rivals/analytics/rapidworker-1.2.js";

  /* eslint-disable @typescript-eslint/naming-convention */
  const analyticsConfig: RapidConfig = {
    client_only: true,
    keys: { auth_state: userType },
    nol: true,
    spaceid: spaceId,
    tag_id: tagId
  };
  /* eslint-enable @typescript-eslint/naming-convention */

  if (topicTags) {
    analyticsConfig.keys.pl4 = topicTags;
  }

  return analyticsConfig;
}

export function beaconRouteChange(
  spaceId: number,
  tagId: string,
  userType: GoogleAnalyticsUserType,
  topicTags?: string
): void {
  const analyticsConfig = configAnalyticsGa(
    spaceId,
    tagId,
    userType,
    topicTags
  );

  debugLog("calling 'beaconRouteChange' with config:", analyticsConfig);

  window.rapidInstance.beaconRouteChange(analyticsConfig);
}
