import { ReactElement } from "react";
import styles from "rivals/components/shared/Icons/Icons.module.scss";
import { IconProps } from "./iconPropsTypes";

interface RivalsLogoProps extends IconProps {
  className?: string;
}

const RivalsLogo = ({
  fill = styles.abyss,
  height = "85px",
  width = "100%",
  className
}: RivalsLogoProps): ReactElement => {
  return (
    <svg
      className={className}
      fill="none"
      height={height}
      viewBox="0 0 564 85"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Rivals Logo</title>

      <g clipPath="url(#clip0_5235_89766)">
        <path
          d="M27.32 84.05H0V0H104.23L120.73 13.66V37.83L104.23 51.49H84.37L123.88 84.06H89.73L27.32 32.57V84.05ZM27.32 21.01V32.57H93.41V21.01H27.32ZM155.92 84.05H128.6V0H155.92V84.05ZM165.9 0H193.22V58.94L264.67 0H298.82L196.9 84.05H182.4L165.9 70.39V0ZM259.41 73.65L246.8 84.05H212.65L314.57 0H332.22L348.72 13.66V84.06H321.4V22.48L282.31 54.74H306.37L321.39 73.65H259.4H259.41ZM385.5 63.04H434.88L460.41 84.05H374.67L358.17 70.39V0H385.49V63.04H385.5ZM473.75 21.01V32.57H546.77L563.27 46.23V70.4L546.77 84.06H473.01L447.48 63.05H537V51.49H463.98L447.48 37.83V13.66L463.98 0H537.74L563.27 21.01H473.75Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_5235_89766">
          <rect fill="white" height="84.05" width="563.27" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RivalsLogo;
