import classNames from "classnames";
import { JSX } from "react";
import Button from "rivals/components/shared/Button";
import CustomButton from "rivals/components/shared/CustomButton";
import CloseIcon from "rivals/components/shared/Icons/Close";
import styles from "rivals/components/shared/InstallPWA/InstallPWA.module.scss";
import strings from "rivals/components/shared/InstallPWA/strings";
import { Props } from "rivals/components/shared/InstallPWA/types";
import RivalsLogomarkBlock from "rivals/components/shared/Svgs/Logos/RivalsLogomarkBlock";
import { setData } from "rivals/shared/utils/qa";
import commonStyles from "rivals/styles/exports.module.scss";

export default function InstallPWA({
  positionFixed = false,
  onClick,
  onDismiss,
  invertedButton = true,
  showClose = true
}: Props): JSX.Element {
  return (
    <div
      className={classNames(styles.container, {
        [styles.fixed]: positionFixed
      })}
    >
      <div className={styles.splatter}>
        <img
          alt={"splatter background"}
          src={"/static/images/splatter_small.png"}
        />
      </div>
      {showClose && (
        <div className={styles.close}>
          <CustomButton
            ariaLabel="close"
            className={styles.closeSliderButton}
            icon={<CloseIcon fill={commonStyles.white} />}
            onClick={onDismiss}
            philter={`${strings.philter}-close`}
          />
        </div>
      )}
      <div className={styles.content}>
        <div>
          <RivalsLogomarkBlock
            fill={"transparent"}
            height="47"
            philter={strings.philter}
            width="61"
          />
        </div>
        <div>
          <div className={styles.title} {...setData(strings.philter, "title")}>
            {strings.title}
          </div>
          <div {...setData(strings.philter, "message")}>{strings.message}</div>
        </div>
      </div>
      <Button
        buttonText={strings.install}
        data={strings.philter}
        inverted={invertedButton}
        onClick={onClick}
        size={"small"}
      />
    </div>
  );
}
