import { createSelector } from "reselect";
import { ADDON_MODAL_CONSTANTS } from "rivals/components/shared/AddonOptions/constants";
import { State as AppState, StateKeys } from "rivals/redux/reducer";
import { PAYPAL_SUBSCRIPTION, USER_ROLES } from "rivals/shared/constants";
import { AddOn } from "rivals/shared/interfaces/AddOn";
import { State } from "./reducer";

const { SPY } = ADDON_MODAL_CONSTANTS;

export const getUserState = (state: AppState): State => state[StateKeys.USER];

export const selectUserMessage = createSelector(
  getUserState,
  state => state.message
);

export const getAddress = createSelector(getUserState, state => state.address);

export const getBetMGMExternalRedeemed = createSelector(
  getUserState,
  state => state.betMGMExternalRedeemed
);

export const getBetMGMRedeemed = createSelector(
  getUserState,
  state => state.betMGMRedeemed
);
export const getBetMGMUrl = createSelector(
  getUserState,
  state => state.betMGMUrl
);

export const getBetMGMValidationErrors = createSelector(
  getUserState,
  state => state.betMGMFailureMessage
);

export const getBillingInformation = createSelector(
  getUserState,
  state => state.billingInformation
);

export const getBillingHistoryTransactions = createSelector(
  getUserState,
  state => state.billingHistory
);

export const getCurrentUser = createSelector(getUserState, state => state.user);

export const getError = createSelector(getUserState, state => state.error);

export const selectHasAdLite = createSelector(
  getCurrentUser,
  user => !!user?.adLite
);

export const getIsCurrentUserValid = createSelector(
  getUserState,
  state => state.isCurrentUserValid
);

export const getIsUserLoadCompleted = createSelector(
  getUserState,
  state => state.isUserLoadCompleted
);

export const getMgmPromoCode = createSelector(
  getUserState,
  state => state.mgmPromoCode
);

export const getPasswordUpdated = createSelector(
  getUserState,
  state => state.passwordUpdated
);

export const getPasswordUpdateError = createSelector(
  getUserState,
  state => state.passwordUpdateError
);

export const getRegistrationsError = createSelector(
  getUserState,
  state => state.registrationError
);

export const getSubmitting = createSelector(
  getUserState,
  state => state.submitting
);

export const getSubscriptions = createSelector(
  getUserState,
  state => state.subscriptions
);

const getPurchasedAddOns = createSelector(getSubscriptions, subs => {
  const addOns: AddOn[][] = subs
    ? subs.map(({ associatedAddOns }) =>
        associatedAddOns ? associatedAddOns : []
      )
    : [];
  return ([] as AddOn[]).concat(...addOns);
});

export const getPurchasedSpyAddOns = createSelector(
  getPurchasedAddOns,
  addOns => addOns.filter(addOn => addOn.type === SPY)
);

const getSubscriptionsError = createSelector(
  getUserState,
  state => state.subscriptions
);

export const getUserSubscriptions = createSelector(
  getCurrentUser,
  user => user && user.subscriptions
);

export const getValidationErrors = createSelector(
  getUserState,
  state => state.validationError
);

export const isLoading = createSelector(
  getSubscriptions,
  getSubscriptionsError,
  (subscriptions, error) => subscriptions === undefined && !error
);

export const isProspectFollowed = createSelector(
  getUserState,
  state => state.isProspectFollowed
);

export const isSubscribed = createSelector(
  getCurrentUser,
  user => !!user && user.subscriptions && user.subscriptions.length > 0
);

export const getSpyAddOnSites = createSelector(
  getCurrentUser,
  user => !!user && user.spyAddOnSites
);

export const validateBetMGM = createSelector(
  getUserState,
  state => state.validatedBetMGM
);

export const getCurrentUserEmail = createSelector(
  getUserState,
  state => state.user?.email
);

export const getCurrentUserPaypalEmail = createSelector(
  getUserState,
  state => state.user?.paypalEmail
);

export const getPayPalBillingAgreement = createSelector(
  getUserState,
  state => state.payPalBillingAgreement
);

export const isPayPalLoading = createSelector(
  getUserState,
  state => state.isPayPalLoading
);

export const getPayPalAuthorizationData = createSelector(
  getUserState,
  state => state.payPalAuthorizationData
);

export const getPayPalUniqueTransactionId = createSelector(
  getPayPalAuthorizationData,
  payPalAuthorizationData => {
    if (payPalAuthorizationData?.returnUrl) {
      const url = new URL(payPalAuthorizationData.returnUrl);
      const urlParams = new URLSearchParams(url.search);
      return urlParams.get("t") || "";
    }
    return "";
  }
);

export const getPayPalToken = createSelector(
  getUserState,
  state => state.payPalAuthorizationData?.paymentToken
);

export const getPayPalAddress = createSelector(
  getUserState,
  state => state.payPalAddress
);

export const getPayPalEmail = createSelector(
  getUserState,
  state => state.payPalEmail
);

export const getPayPalError = createSelector(
  getUserState,
  state => state.payPalError
);

export const isPayPalSubscription = createSelector(
  getBillingInformation,
  getCurrentUserPaypalEmail,
  (billingInformation, payPalEmail) =>
    billingInformation
      ? billingInformation.cardType === PAYPAL_SUBSCRIPTION
      : !!payPalEmail
);

export const isPublisher = createSelector(
  getUserState,
  state => !!state.user?.isPublisher
);

export const isStaffWriter = createSelector(
  getUserState,
  state => !!state.user?.isStaffWriter
);

export const isSuperAdmin = createSelector(
  getUserState,
  state => state.user?.role === USER_ROLES.SUPER_ADMIN
);

export const publisherOrStaffWriter = createSelector(
  isSuperAdmin,
  isPublisher,
  isStaffWriter,
  (superAdmin, publisher, staffWriter) =>
    (publisher || staffWriter) && !superAdmin
);
