import { createAsyncThunk } from "@reduxjs/toolkit";

import { apiService } from "rivals/redux/store";
import type {
  FetchHighschoolGroupRequest,
  FetchHighschoolGroupResponse,
  FetchHighschoolGroupsRequest,
  FetchHighschoolGroupsResponse
} from "rivals/services/container";

enum HighschoolActions {
  FETCH_HIGHSCHOOL_GROUP = "highschool/fetchHighschoolGroup",
  FETCH_HIGHSCHOOL_GROUPS = "highschool/fetchHighschoolGroups"
}

export const fetchHighschoolGroups = createAsyncThunk(
  HighschoolActions.FETCH_HIGHSCHOOL_GROUPS,
  async (request: FetchHighschoolGroupsRequest, { rejectWithValue }) => {
    try {
      const response: FetchHighschoolGroupsResponse = await apiService.fetchHighschoolGroups(
        request
      );
      return response;
    } catch (error) {
      return rejectWithValue((<Error>error)?.message);
    }
  }
);

export const fetchHighschoolGroup = createAsyncThunk(
  HighschoolActions.FETCH_HIGHSCHOOL_GROUP,
  async (request: FetchHighschoolGroupRequest, { rejectWithValue }) => {
    try {
      const response: FetchHighschoolGroupResponse = await apiService.fetchHighschoolGroup(
        request
      );
      return response;
    } catch (error) {
      return rejectWithValue((<Error>error)?.message);
    }
  }
);
