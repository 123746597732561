import { findIndex } from "lodash";
import {
  FormValuesErrors,
  ValidationErrors
} from "rivals/components/pages/Registration/CreateAccount/types";
import { Transaction } from "rivals/components/pages/User/BillingHistory/types";
import { AuthAction, AuthActions } from "rivals/redux/authentication/actions";
import {
  PromotionAction,
  PromotionActions
} from "rivals/redux/promotion/actions";
import {
  RegistrationAction,
  RegistrationActions
} from "rivals/redux/registration/actions";
import {
  SubscriptionAction,
  SubscriptionActions
} from "rivals/redux/subscription/actions";
import { Subscription } from "rivals/shared/interfaces/Subscription";
import {
  Address,
  BillingInformation,
  CurrentUser,
  PayPalAuthorizationData,
  PayPalBillingAgreement,
  User
} from "rivals/shared/interfaces/User";
import { UserAction, UserActions } from "./actions";

export type State = {
  address?: Address;
  betMGMExternalRedeemed?: boolean;
  betMGMFailureMessage?: string;
  betMGMRedeemed?: boolean;
  betMGMUrl?: string;
  billingHistory?: Transaction[];
  billingInformation?: BillingInformation;
  error?: string;
  isCurrentUserValid?: boolean;
  isPayPalLoading?: boolean;
  isProspectFollowed?: boolean | null;
  isUserLoadCompleted: boolean;
  message?: string;
  mgmPromoCode?: string | null;
  passwordUpdateError?: string;
  passwordUpdated: boolean;
  payPalAddress?: Address;
  payPalAuthorizationData?: PayPalAuthorizationData;
  payPalBillingAgreement?: PayPalBillingAgreement;
  payPalBillingAgreementError?: string;
  payPalEmail?: string;
  payPalError?: string;
  registrationError?: FormValuesErrors;
  submitting: boolean;
  subscriptions?: Subscription[];
  subscriptionsError?: string;
  user?: User | CurrentUser;
  validatedBetMGM?: boolean;
  validatedBetMGMError?: string;
  validationError?: ValidationErrors;
};

const initialState = {
  isPayPalLoading: false,
  isUserLoadCompleted: false,
  passwordUpdated: false,
  submitting: false,
  subscriptions: [],
  user: undefined
};

export default function reducer(
  state: State = initialState,
  action:
    | AuthAction
    | PromotionAction
    | RegistrationAction
    | SubscriptionAction
    | UserAction
): State {
  switch (action.type) {
    case UserActions.FORGOT_CREDENTIALS_SUCCESS: {
      return {
        ...state,
        message: action.payload.message
      };
    }
    case UserActions.FORGOT_PASSWORD:
    case UserActions.FORGOT_USERNAME: {
      return {
        ...state,
        message: undefined
      };
    }
    case UserActions.GET_ADDRESS_SUCCESS: {
      return {
        ...state,
        address: action.payload.address
      };
    }
    case UserActions.GET_BILLING_HISTORY_SUCCESS: {
      return {
        ...state,
        billingHistory: action.payload.billingHistory
      };
    }
    case UserActions.GET_BILLING_INFORMATION_SUCCESS: {
      return {
        ...state,
        billingInformation: action.payload.billingInformation,
        message: undefined
      };
    }
    case UserActions.UPDATE_CURRENT_USER: {
      return {
        ...state,
        error: undefined,
        submitting: true
      };
    }
    case RegistrationActions.CREATE_ACCOUNT:
    case RegistrationActions.GIFT_SUBSCRIPTION:
    case SubscriptionActions.CREATE_SUBSCRIPTION:
    case AuthActions.LOGIN_USER:
    case SubscriptionActions.PAY_OUTSTANDING_BALANCE:
    case SubscriptionActions.REACTIVATE_SUBSCRIPTION:
    case UserActions.GET_SUBSCRIPTIONS:
    case UserActions.STRICT_SIGN_IN:
    case UserActions.UPDATE_BILLING_INFORMATION:
    case UserActions.UPDATE_PASSWORD:
    case SubscriptionActions.UPGRADE_TO_ANNUAL:
    case SubscriptionActions.EXTEND_MGM_SUBSCRIPTION:
    case PromotionActions.VALIDATE_PROMO_CODE:
    case UserActions.GET_BETMGM_URL:
    case UserActions.VALIDATE_BETMGM: {
      return {
        ...state,
        submitting: true
      };
    }
    case UserActions.STRICT_SIGN_IN_SUCCESS: {
      return {
        ...state,
        error: undefined,
        submitting: false
      };
    }
    case UserActions.UPDATE_BILLING_INFORMATION_SUCCESS: {
      return {
        ...state,
        address: action.payload.address,
        billingInformation: action.payload.billingInformation,
        submitting: false
      };
    }
    case RegistrationActions.GIFT_SUBSCRIPTION_FAILURE:
    case RegistrationActions.GIFT_SUBSCRIPTION_SUCCESS:
    case SubscriptionActions.CREATE_SUBSCRIPTION_FAILURE:
    case SubscriptionActions.CREATE_SUBSCRIPTION_SUCCESS:
    case SubscriptionActions.EXTEND_MGM_SUBSCRIPTION_FAILURE:
    case SubscriptionActions.EXTEND_MGM_SUBSCRIPTION_SUCCESS:
    case SubscriptionActions.PAY_OUTSTANDING_BALANCE_FAILURE:
    case SubscriptionActions.REACTIVATE_SUBSCRIPTION_FAILURE:
    case SubscriptionActions.UPGRADE_TO_ANNUAL_FAILURE:
    case PromotionActions.GET_PROMOTION_FAILURE:
    case PromotionActions.GET_PROMOTION_SUCCESS:
    case UserActions.UPDATE_BILLING_INFORMATION_FAILURE: {
      return {
        ...state,
        submitting: false
      };
    }
    case UserActions.GET_CURRENT_USER_SUCCESS:
    case UserActions.UPDATE_CURRENT_USER_SUCCESS: {
      return {
        ...state,
        error: undefined,
        isUserLoadCompleted: true,
        submitting: false,
        user: action.payload.user
      };
    }
    case UserActions.GET_IS_CURRENT_USER_VALID_SUCCESS: {
      return {
        ...state,
        error: undefined,
        submitting: false,
        user: {
          ...state.user,
          ...action.payload.user
        }
      };
    }
    case UserActions.GET_CURRENT_USER_FAILURE:
    case UserActions.STRICT_SIGN_IN_FAILURE:
    case UserActions.UPDATE_CURRENT_USER_FAILURE: {
      return {
        ...state,
        error: action.payload.error,
        submitting: false
      };
    }
    case UserActions.UPDATE_PASSWORD_SUCCESS: {
      return {
        ...state,
        passwordUpdated: action.payload,
        submitting: false
      };
    }
    case UserActions.UPDATE_PASSWORD_FAILURE: {
      return {
        ...state,
        passwordUpdateError: action.payload.error,
        passwordUpdated: false,
        submitting: false
      };
    }
    case UserActions.RESET_UPDATE_PASSWORD: {
      return {
        ...state,
        passwordUpdateError: undefined,
        passwordUpdated: false
      };
    }
    case UserActions.GET_SUBSCRIPTIONS_SUCCESS: {
      return {
        ...state,
        submitting: false,
        subscriptions: action.payload,
        subscriptionsError: undefined
      };
    }
    case UserActions.GET_PAYPAL_BILLING_AGREEMENT: {
      return {
        ...state,
        isPayPalLoading: true
      };
    }
    case UserActions.GET_PAYPAL_BILLING_AGREEMENT_SUCCESS: {
      return {
        ...state,
        isPayPalLoading: false,
        payPalBillingAgreement: action.payload,
        payPalBillingAgreementError: undefined
      };
    }
    case UserActions.GET_PAYPAL_BILLING_AGREEMENT_FAILURE: {
      return {
        ...state,
        isPayPalLoading: false,
        payPalBillingAgreementError: action.payload.error
      };
    }
    case UserActions.UPDATE_PAYPAL_AUTHORIZATION_DATA: {
      return {
        ...state,
        payPalAuthorizationData: action.payload
      };
    }
    case UserActions.UPDATE_PAYPAL_ADDRESS: {
      return {
        ...state,
        payPalAddress: action.payload
      };
    }
    case UserActions.UPDATE_PAYPAL_EMAIL: {
      return {
        ...state,
        payPalEmail: action.payload
      };
    }
    case UserActions.UPDATE_PAYPAL_ERROR: {
      return {
        ...state,
        payPalError: action.payload
      };
    }
    case UserActions.UPDATE_PAYPAL_BILLING_AGREEMENT: {
      return {
        ...state,
        payPalBillingAgreement: action.payload
      };
    }
    case UserActions.RESET_PAYPAL: {
      return {
        ...state,
        payPalAddress: undefined,
        payPalAuthorizationData: undefined,
        payPalBillingAgreement: undefined,
        payPalEmail: undefined
      };
    }
    case UserActions.GET_SUBSCRIPTIONS_FAILURE: {
      return {
        ...state,
        submitting: false,
        subscriptionsError: action.payload.error
      };
    }

    case AuthActions.LOGIN_USER_SUCCESS: {
      return {
        ...state,
        message: undefined,
        passwordUpdated: false,
        submitting: false,
        user: action?.payload?.user
      };
    }
    case AuthActions.LOGIN_USER_FAILURE: {
      return {
        isUserLoadCompleted: false,
        passwordUpdated: false,
        submitting: false
      };
    }
    case AuthActions.LOGOUT_USER_SUCCESS: {
      return {
        isProspectFollowed: null,
        isUserLoadCompleted: true,
        passwordUpdated: false,
        submitting: false,
        user: undefined
      };
    }
    case RegistrationActions.CREATE_ACCOUNT_SUCCESS: {
      return {
        ...state,
        submitting: false,
        user: action.payload.user
      };
    }
    case RegistrationActions.CREATE_ACCOUNT_FAILURE: {
      return {
        ...state,
        error:
          typeof action.payload === "string" ? action.payload : state.error,
        registrationError:
          typeof action.payload !== "string"
            ? action.payload?.errors
            : undefined,
        submitting: false,
        user:
          typeof action.payload !== "string" ? action.payload?.user : undefined
      };
    }
    case RegistrationActions.CREATE_ACCOUNT_PAYMENT_FAILURE: {
      return {
        ...state,
        registrationError: action.payload.errors,
        submitting: false,
        user: action.payload.user
      };
    }
    case SubscriptionActions.PAY_OUTSTANDING_BALANCE_SUCCESS: {
      if (!state.billingInformation) {
        return state;
      }
      const billingInformation = { ...state.billingInformation };
      billingInformation.outstandingBalance = null;
      return {
        ...state,
        billingInformation,
        message: action.payload.message,
        submitting: false
      };
    }
    case SubscriptionActions.REACTIVATE_SUBSCRIPTION_SUCCESS: {
      const {
        payload: { subscription }
      } = action;
      if (!state.subscriptions) {
        return { ...state, subscriptions: [subscription] };
      }
      const newSubscriptions = [...state.subscriptions];
      const idx = findIndex(newSubscriptions, ["id", subscription.id]);
      newSubscriptions[idx] = subscription;
      return {
        ...state,
        submitting: false,
        subscriptions: newSubscriptions
      };
    }
    case UserActions.VALIDATE_BETMGM_SUCCESS: {
      return {
        ...state,
        betMGMExternalRedeemed: action.payload.externalRedeemed,
        betMGMFailureMessage: action.payload.failure,
        betMGMRedeemed: action.payload.redeemed,
        mgmPromoCode: action.payload.mgmPromoCode,
        submitting: false,
        validatedBetMGM: action.payload.validated
      };
    }
    case UserActions.GET_BETMGM_URL_SUCCESS: {
      return {
        ...state,
        betMGMUrl: action.payload,
        error: undefined,
        submitting: false
      };
    }
    case UserActions.GET_BETMGM_URL_FAILURE:
    case UserActions.VALIDATE_BETMGM_FAILURE: {
      return {
        ...state,
        submitting: false,
        validatedBetMGMError: action.payload.error
      };
    }
    case UserActions.VALIDATE_EMAIL_OR_USERNAME_FAILURE: {
      return {
        ...state,
        validationError: {
          email:
            action.payload.property == "email"
              ? action.payload.error
              : state.validationError && state.validationError.email,
          username:
            action.payload.property == "username"
              ? action.payload.error
              : state.validationError && state.validationError.username
        }
      };
    }
    case UserActions.VALIDATE_EMAIL_OR_USERNAME_SUCCESS: {
      return {
        ...state,
        validationError: {
          email:
            action.payload.property == "email"
              ? undefined
              : state.validationError && state.validationError.email,
          username:
            action.payload.property == "username"
              ? undefined
              : state.validationError && state.validationError.username
        }
      };
    }
    case UserActions.GET_IS_PROSPECT_FOLLOWED_SUCCESS: {
      return {
        ...state,
        isProspectFollowed: action.payload.isFollowing
      };
    }
    case UserActions.GET_IS_PROSPECT_FOLLOWED_FAILURE: {
      return {
        ...state,
        submitting: false
      };
    }
    case UserActions.UPDATE_IS_PROSPECT_FOLLOWED: {
      return {
        ...state,
        submitting: true
      };
    }
    case UserActions.UPDATE_IS_PROSPECT_FOLLOWED_SUCCESS: {
      return {
        ...state,
        isProspectFollowed: action.payload.follow,
        submitting: false
      };
    }
    case UserActions.UPDATE_IS_PROSPECT_FOLLOWED_FAILURE: {
      return {
        ...state,
        submitting: false
      };
    }
    default: {
      return state;
    }
  }
}
