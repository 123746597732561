import { reduce, snakeCase } from "lodash";
import { PHASE_PRODUCTION_BUILD } from "next/constants";
import { SECONDS_PER_HOUR, SECONDS_PER_MINUTE } from "rivals/shared/constants";

export const PROTOCOL = "https://";
export const HOST_BASE_PATH = process.env.NEXT_PUBLIC_DOMAIN || "rivals.com";
export const HOST_URL = `${PROTOCOL}${HOST_BASE_PATH}`;
export const BASE_APP_HOST_URL = `${PROTOCOL}n.${HOST_BASE_PATH}`;

// Consumed at buildtime when generating static pages
export const STATIC_DOMAIN = process.env.STATIC_DOMAIN || HOST_BASE_PATH;

export const apiUrl = (): string => {
  // Hack required to support static pages
  // https://github.com/vercel/next.js/discussions/22036
  // During the build phase, we reach out the the backend via the web
  if (process.env.NEXT_PHASE === PHASE_PRODUCTION_BUILD) {
    return process.env.RIVALS_BUILD_API_URL || `${PROTOCOL}${HOST_BASE_PATH}`;
  }
  // At runtime, we reach out to the backend directly within AWS
  return process.env.RIVALS_API_URL || "";
};

export const CDN_URL = process.env.NEXT_PUBLIC_AWS_S3_CDN_BUCKET_NAME
  ? `${PROTOCOL}${process.env.NEXT_PUBLIC_AWS_S3_CDN_BUCKET_NAME}`
  : `${PROTOCOL}cdn.rivals.com`;

// We do not want to be too granular with the cache times
// Select from this list of cache times only
export const CACHE_SECONDS_SMALL = 30;
export const CACHE_SECONDS_MEDIUM = 5 * SECONDS_PER_MINUTE; // eslint-disable-line no-magic-numbers
export const CACHE_SECONDS_LARGE = SECONDS_PER_HOUR;

export const RESPONSE_CODE_REDIRECT = 300;
export const RESPONSE_CODE_SUCCESS = 200;
export const RESPONSE_CODE_NO_CONTENT = 204;
export const RESPONSE_CODE_TEMPORARY_REDIRECT = 307;
export const RESPONSE_CODE_PERMANENT_REDIRECT = 308;
export const RESPONSE_INTERNAL_SERVICE_ERROR = 500;
export const RESPONSE_CODE_UNAUTHORIZED = 401;
export const RESPONSE_PAGE_NOT_FOUND_ERROR = 404;
export const RESPONSE_TOO_MANY_REQUESTS = 429;
export const RESPONSE_UNPROCESSABLE_ENTITY = 422;

export enum HTTP_METHODS {
  GET = "GET",
  DELETE = "DELETE",
  PATCH = "PATCH",
  POST = "POST",
  PUT = "PUT"
}

export interface ResponseErrors {
  error?: string;
  errors?: string[] | string;
}

export const DEFAULT_HEADERS = {
  Accept: "application/json"
} as const;

/* eslint-disable @typescript-eslint/naming-convention */
export const UPDATE_HEADERS = {
  "Content-Type": "application/json"
} as const;

export const SERVER_HEADERS = {
  "X-Forwarded-Proto": "https"
} as const;
/* eslint-enable @typescript-eslint/naming-convention */

export function createResponseCodeMessage(status: number): string {
  return `Response returned with status ${status}`;
}

export function stringifyParams(params: {
  [k: string]: string | number | undefined;
}): string {
  let result = "";
  const stringifiedParams = reduce(
    params,
    (acc, v, k) => {
      if (v !== undefined) {
        acc.push(`${snakeCase(k)}=${encodeURIComponent(v)}`);
      }
      return acc;
    },
    [] as string[]
  );

  if (stringifiedParams.length) {
    result = `?${stringifiedParams.join("&")}`;
  }

  return result;
}
