import classNames from "classnames";
import { JSX } from "react";
import Button from "rivals/components/shared/Button";
import CustomButton from "rivals/components/shared/CustomButton";
import CloseIcon from "rivals/components/shared/Icons/Close";
import PushNotification from "rivals/components/shared/Icons/PushNotification";
import styles from "rivals/components/shared/PushNotificationsBanner/PushNotificationsBanner.module.scss";
import strings from "rivals/components/shared/PushNotificationsBanner/strings";
import RightArrow from "rivals/components/shared/Svgs/Backgrounds/RightArrow";
import commonStyles from "rivals/styles/exports.module.scss";
import type { Props } from "./types";

export default function PushNotificationsBanner({
  philter,
  invertedButton = true,
  onDismiss,
  onClick,
  inPWA
}: Props): JSX.Element {
  const iconSize = inPWA ? "120" : "90";

  return (
    <div
      className={classNames(styles.container, {
        [styles.inPWA]: inPWA,
        [styles.inBrowser]: !inPWA
      })}
    >
      <div
        className={classNames(styles.accent, {
          [styles.inPWA]: inPWA,
          [styles.inBrowser]: !inPWA
        })}
      >
        <RightArrow />
      </div>
      <div
        className={classNames(styles.contentContainer, {
          [styles.inPWA]: inPWA,
          [styles.inBrowser]: !inPWA
        })}
      >
        <div className={classNames(styles.close, { [styles.inPWA]: inPWA })}>
          <CustomButton
            ariaLabel="hide"
            className={styles.closeSliderButton}
            icon={<CloseIcon fill={commonStyles.white} />}
            onClick={onDismiss}
            philter={`${philter}-${strings.philter}-close`}
          />
        </div>
        <div className={classNames(styles.content, { [styles.inPWA]: inPWA })}>
          <div>
            <PushNotification height={iconSize} width={iconSize} />
          </div>
          <div className={styles.text}>
            <div
              className={classNames(styles.title, { [styles.inPWA]: inPWA })}
            >
              {strings.title}
            </div>
            <div
              className={classNames(styles.message, {
                [styles.inBrowser]: !inPWA
              })}
            >
              {strings.message}
            </div>
          </div>
        </div>
        <div
          className={classNames(styles.buttonContainer, {
            [styles.inBrowser]: !inPWA
          })}
        >
          <Button
            buttonText={strings.buttonText}
            data={`${philter}-${strings.philter}`}
            fullWidth={true}
            inverted={invertedButton}
            onClick={onClick}
            size="small"
          />
        </div>
      </div>
    </div>
  );
}
