import { ContentAction, ContentActions } from "rivals/redux/content/actions";
import { Gallery } from "rivals/shared/interfaces/Content";

export type State = {
  error?: string;
  gallery?: Gallery;
};

const initialState = {
  error: undefined,
  gallery: undefined
};

export default function reducer(
  state: State = initialState,
  action: ContentAction
): State {
  switch (action.type) {
    case ContentActions.GET_GALLERY_SUCCESS:
      return {
        ...state,
        gallery: action.payload.gallery
      };
    case ContentActions.GET_GALLERY_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        gallery: undefined
      };
    case ContentActions.RESET_GALLERY:
      return {
        ...state,
        error: undefined,
        gallery: undefined
      };
    default:
      return state;
  }
}
