import { createSlice } from "@reduxjs/toolkit";
import { College } from "rivals/shared/interfaces/College";
import { MultiSelectType } from "rivals/shared/interfaces/search";
import {
  searchColleges,
  searchExitColleges,
  searchOriginColleges
} from "./actions";

export type State = {
  collegeSelectOptions?: MultiSelectType[];
  colleges: College[];
  error?: string;
  exitColleges: College[];
  exitCollegesSearching: boolean;
  originColleges: College[];
  originCollegesSearching: boolean;
  query?: string;
  searching: boolean;
};

const initialState: State = {
  colleges: [],
  error: undefined,
  exitColleges: [],
  exitCollegesSearching: false,
  originColleges: [],
  originCollegesSearching: false,
  query: undefined,
  searching: false
};

const collegesSlice = createSlice({
  extraReducers: builder => {
    builder
      .addCase(searchColleges.pending, (state, action) => {
        state.colleges = [];
        state.query = action.payload;
        state.searching = true;
      })
      .addCase(searchColleges.fulfilled, (state, action) => {
        state.colleges = action.payload.colleges;
        state.query = undefined;
        state.searching = false;
      })
      .addCase(searchColleges.rejected, (state, action) => {
        state.error = (action.payload as string) || action.error.message;
        state.searching = false;
      })
      .addCase(searchExitColleges.pending, (state, action) => {
        state.exitColleges = [];
        state.query = action.payload;
        state.exitCollegesSearching = true;
      })
      .addCase(searchExitColleges.fulfilled, (state, action) => {
        state.exitColleges = action.payload.colleges;
        state.query = undefined;
        state.exitCollegesSearching = false;
      })
      .addCase(searchExitColleges.rejected, (state, action) => {
        state.error = (action.payload as string) || action.error.message;
        state.exitCollegesSearching = false;
      })
      .addCase(searchOriginColleges.pending, (state, action) => {
        state.originColleges = [];
        state.query = action.payload;
        state.originCollegesSearching = true;
      })
      .addCase(searchOriginColleges.fulfilled, (state, action) => {
        state.originColleges = action.payload.colleges;
        state.query = undefined;
        state.originCollegesSearching = false;
      })
      .addCase(searchOriginColleges.rejected, (state, action) => {
        state.error = (action.payload as string) || action.error.message;
        state.originCollegesSearching = false;
      });
  },
  initialState,
  name: "colleges",
  reducers: {
    cleanColleges: state => {
      state.colleges = [];
    },
    cleanExitColleges: state => {
      state.exitColleges = [];
    },
    cleanOriginColleges: state => {
      state.originColleges = [];
    }
  }
});

export const {
  cleanColleges,
  cleanExitColleges,
  cleanOriginColleges
} = collegesSlice.actions;
export default collegesSlice.reducer;
