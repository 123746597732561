import { useEffect } from "react";

export type UseScriptOptions = {
  async?: boolean;
  shouldAppend?: boolean;
  src?: string;
};

/*
 * useScript React hook:
 * Tries to append script to end of body. Will not append if a script is found with matching src.
 * Uses reference counting to conditionally remove script when unmounting. If after unmounting, no
 * other useScript hooks are mounted with matching src, the script will be removed from the DOM.
 */
export const useScript = ({
  async = true,
  shouldAppend = true,
  src = ""
}: UseScriptOptions): void => {
  const findScript = (): HTMLScriptElement | null => {
    return window.document.querySelector(
      `script[src='${src}']`
    ) as HTMLScriptElement | null;
  };

  // Will not append script if already present.
  // Will increment refCount if already present.
  const appendScript = (): void => {
    let script = findScript();

    if (!script) {
      script = window.document.createElement("script");
      script.async = async;
      script.src = src;
      script = window.document.body.appendChild(script);
    }

    updateScriptRefCount(script, 1);
  };

  const removeScript = (): void => {
    const script = findScript();
    if (script) {
      // eslint-disable-next-line no-magic-numbers
      const refCount = updateScriptRefCount(script, -1);
      if (refCount === 0) {
        script.remove();
      }
    }
  };

  const updateScriptRefCount = (
    script: HTMLScriptElement,
    increment: number
  ): number => {
    const currentRefCount = script.getAttribute("refCount");
    const newRefCount = parseInt(currentRefCount || "0") + increment;
    script.setAttribute("refCount", newRefCount.toString());
    return newRefCount;
  };

  useEffect(() => {
    if (shouldAppend) {
      appendScript();
    } else {
      removeScript();
    }

    return removeScript;
  }, [shouldAppend]);
};
