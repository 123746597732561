import { ImageTagParams } from "rivals/shared/interfaces/ImageTag";
import { ImageTagAction, ImageTagActions } from "./actions";

export type State = {
  error?: string;
  imageTagParams?: ImageTagParams;
  profileRemoved?: boolean;
};

const initialState = {
  error: undefined,
  imageTagParams: undefined,
  profileRemoved: undefined
};

export default function reducer(
  state: State = initialState,
  action: ImageTagAction
): State {
  switch (action.type) {
    case ImageTagActions.RESET_IMAGE_TAG:
      return {
        ...state,
        profileRemoved: undefined
      };
    case ImageTagActions.UPDATE_IMAGE_TAG:
      return {
        ...state,
        imageTagParams: action.payload,
        profileRemoved: undefined
      };
    case ImageTagActions.UPDATE_IMAGE_TAG_FAILURE:
      return {
        ...state,
        error: action.payload.error
      };
    case ImageTagActions.UPDATE_IMAGE_TAG_SUCCESS:
      return {
        ...state,
        imageTagParams: undefined,
        profileRemoved: action.payload
      };
    default:
      return state;
  }
}
