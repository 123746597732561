import camelcaseKeys from "camelcase-keys";
import { CookieValueTypes } from "cookies-next";
import { DEFAULT_HEADERS, UPDATE_HEADERS } from "rivals/services/base";
import { CSURF_TOKEN_HEADER } from "rivals/shared/constants";

export async function swrFetcher<T>(url: string): Promise<T> {
  const response = await fetch(url);
  return camelcaseKeys(await response.json(), { deep: true });
}

export function getMutationHeaders(
  token: CookieValueTypes | null,
  csurf: CookieValueTypes | null
): Headers {
  const headers = new Headers({
    ...DEFAULT_HEADERS,
    ...UPDATE_HEADERS
  });

  if (csurf) {
    headers.append(CSURF_TOKEN_HEADER, csurf);
  }

  if (token) {
    headers.append("Authorization", `token ${token}`);
  }
  return headers;
}
