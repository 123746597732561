import { Alert } from "./reducer";

export enum AlertActions {
  CLEAR_ALERTS = "CLEAR_ALERTS",
  HIDE_ALERT = "HIDE_ALERT",
  SHOW_ALERT = "SHOW_ALERT"
}

export const clearAlerts = () => ({ type: AlertActions.CLEAR_ALERTS } as const);

export const hideAlert = (id: string) =>
  ({ payload: { id }, type: AlertActions.HIDE_ALERT } as const);

export const showAlert = (alert: Alert) =>
  ({
    payload: alert,
    type: AlertActions.SHOW_ALERT
  } as const);

export type AlertAction =
  | ReturnType<typeof clearAlerts>
  | ReturnType<typeof hideAlert>
  | ReturnType<typeof showAlert>;
