import classNames from "classnames";
import MenuLabel from "rivals/components/app/Layout/Header/Nav/MenuLabel";
import styles from "rivals/components/app/Layout/Header/Nav/Nav.module.scss";
import type {
  AntDMenuItem,
  MenuItem
} from "rivals/components/app/Layout/Header/Nav/types";
import headerStrings from "rivals/components/app/Layout/Header/strings";
import { PATHS } from "rivals/shared/constants";

const { subscribeText } = headerStrings;

export function buildMenu(
  useLegacyStyles: boolean,
  menuItems?: MenuItem[],
  parentIndex: number = 0
): AntDMenuItem[] | undefined {
  return menuItems?.reduce((acc: AntDMenuItem[], item, index) => {
    const { className, path, subMenuItems, title, type } = item;
    const label = (
      <div
        className={classNames({
          [styles.menuItemWrapper]: !subMenuItems,
          [styles.legacy]: useLegacyStyles
        })}
      >
        <MenuLabel
          className={classNames(className, {
            [styles.parentMenuLabel]: !!path && !!subMenuItems
          })}
          path={path}
          title={title}
        />
      </div>
    );

    const antdMenuItem = {
      children: buildMenu(useLegacyStyles, subMenuItems, index),
      key: `${title}_${parentIndex}_${index}`,
      label,
      title,
      type
    };
    return [...acc, antdMenuItem];
  }, []);
}

export function buildMobileMenu(
  authenticated: boolean,
  desktopItems?: ReturnType<typeof buildMenu>
): ReturnType<typeof buildMenu> {
  if (!authenticated && desktopItems) {
    return [
      ...desktopItems,
      {
        key: "subscribe",
        label: <MenuLabel path={PATHS.SIGN_UP} title={subscribeText} />
      }
    ];
  }

  return desktopItems;
}
