import { AppAction, AppActions } from "./actions";

export type State = {
  hostUrl?: string;
  isRedirecting: boolean;
  isSubmittingToServer: boolean;
};

const initialState = {
  hostUrl: undefined,
  isRedirecting: false,
  isSubmittingToServer: false
};

export default function reducer(
  state: State = initialState,
  action: AppAction
): State {
  switch (action.type) {
    case AppActions.SET_HOST_URL: {
      return {
        ...state,
        hostUrl: action.payload.hostUrl
      };
    }
    case AppActions.SET_IS_REDIRECTING: {
      return {
        ...state,
        isRedirecting: action.payload.isRedirecting
      };
    }
    case AppActions.SET_IS_SUBMITTING_TO_SERVER: {
      return {
        ...state,
        isSubmittingToServer: action.payload.isSubmittingToServer
      };
    }
    default: {
      return state;
    }
  }
}
