import { Request as UpdateCollegePlayerRequest } from "rivals/services/updateCollegePlayer/types";
import {
  CollegePlayer,
  PersonIdParams,
  YahooProfileMatchData,
  YahooSyncableData
} from "rivals/shared/interfaces/Athlete";

export enum AdminAthleteActions {
  CLEAR_YAHOO_SYNC_DATA = "CLEAR_YAHOO_SYNC_DATA",
  DELETE_COLLEGE_PLAYER = "DELETE_COLLEGE_PLAYER",
  DELETE_COLLEGE_PLAYER_FAILURE = "DELETE_COLLEGE_PLAYER_FAILURE",
  DELETE_COLLEGE_PLAYER_SUCCESS = "DELETE_COLLEGE_PLAYER_SUCCESS",
  GET_ADMIN_COLLEGE_PLAYER = "GET_ADMIN_COLLEGE_PLAYER",
  GET_ADMIN_COLLEGE_PLAYER_FAILURE = "GET_ADMIN_COLLEGE_PLAYER_FAILURE",
  GET_ADMIN_COLLEGE_PLAYER_SUCCESS = "GET_ADMIN_COLLEGE_PLAYER_SUCCESS",
  GET_YAHOO_SYNC_DATA = "GET_YAHOO_SYNC_DATA",
  GET_YAHOO_SYNC_DATA_FAILURE = "GET_YAHOO_SYNC_DATA_FAILURE",
  GET_YAHOO_SYNC_DATA_SUCCESS = "GET_YAHOO_SYNC_DATA_SUCCESS",
  LINK_YAHOO_COLLEGE_PROFILE = "LINK_YAHOO_COLLEGE_PROFILE",
  LINK_YAHOO_COLLEGE_PROFILE_FAILURE = "LINK_YAHOO_COLLEGE_PROFILE_FAILURE",
  LINK_YAHOO_COLLEGE_PROFILE_SUCCESS = "LINK_YAHOO_COLLEGE_PROFILE_SUCCESS",
  SYNC_YAHOO_COLLEGE_PROFILE = "SYNC_YAHOO_COLLEGE_PROFILE",
  SYNC_YAHOO_COLLEGE_PROFILE_FAILURE = "SYNC_YAHOO_COLLEGE_PROFILE_FAILURE",
  SYNC_YAHOO_COLLEGE_PROFILE_SUCCESS = "SYNC_YAHOO_COLLEGE_PROFILE_SUCCESS",
  UPDATE_COLLEGE_PROFILE = "UPDATE_COLLEGE_PROFILE",
  UPDATE_COLLEGE_PROFILE_FAILURE = "UPDATE_COLLEGE_PROFILE_FAILURE",
  UPDATE_COLLEGE_PROFILE_SUCCESS = "UPDATE_COLLEGE_PROFILE_SUCCESS",
  YAHOO_PROFILE_SEARCH = "YAHOO_PROFILE_SEARCH",
  YAHOO_PROFILE_SEARCH_FAILURE = "YAHOO_PROFILE_SEARCH_FAILURE",
  YAHOO_PROFILE_SEARCH_SUCCESS = "YAHOO_PROFILE_SEARCH_SUCCESS"
}

export const clearYahooSyncData = () =>
  ({
    type: AdminAthleteActions.CLEAR_YAHOO_SYNC_DATA
  } as const);

export const deleteCollegePlayer = (collegePlayerId: number) =>
  ({
    payload: collegePlayerId,
    type: AdminAthleteActions.DELETE_COLLEGE_PLAYER
  } as const);

export const deleteCollegePlayerFailure = (error?: string) =>
  ({
    error: true,
    payload: { error },
    type: AdminAthleteActions.DELETE_COLLEGE_PLAYER_FAILURE
  } as const);

export const deleteCollegePlayerSuccess = () =>
  ({
    type: AdminAthleteActions.DELETE_COLLEGE_PLAYER_SUCCESS
  } as const);

export const getAdminCollegePlayer = (personIdParams: PersonIdParams) =>
  ({
    payload: personIdParams,
    type: AdminAthleteActions.GET_ADMIN_COLLEGE_PLAYER
  } as const);

export const getAdminCollegePlayerFailure = (error?: string) =>
  ({
    error: true,
    payload: { error },
    type: AdminAthleteActions.GET_ADMIN_COLLEGE_PLAYER_FAILURE
  } as const);

export const getAdminCollegePlayerSuccess = (collegePlayer: CollegePlayer) =>
  ({
    payload: { collegePlayer },
    type: AdminAthleteActions.GET_ADMIN_COLLEGE_PLAYER_SUCCESS
  } as const);

export const getYahooSyncData = (collegePlayerId: string) =>
  ({
    payload: {
      collegePlayerId
    },
    type: AdminAthleteActions.GET_YAHOO_SYNC_DATA
  } as const);

export const getYahooSyncDataFailure = (error?: string) =>
  ({
    error: true,
    payload: { error },
    type: AdminAthleteActions.GET_YAHOO_SYNC_DATA_FAILURE
  } as const);

export const getYahooSyncDataSuccess = (yahooSyncData: YahooSyncableData) =>
  ({
    payload: yahooSyncData,
    type: AdminAthleteActions.GET_YAHOO_SYNC_DATA_SUCCESS
  } as const);

export const linkYahooCollegeProfile = (
  personId: string,
  yahooPlayerId: string
) =>
  ({
    payload: {
      personId,
      yahooPlayerId
    },
    type: AdminAthleteActions.LINK_YAHOO_COLLEGE_PROFILE
  } as const);

export const linkYahooCollegeProfileFailure = (error?: string) =>
  ({
    error: true,
    payload: { error },
    type: AdminAthleteActions.LINK_YAHOO_COLLEGE_PROFILE_FAILURE
  } as const);

export const linkYahooCollegeProfileSuccess = (collegePlayer: CollegePlayer) =>
  ({
    payload: { collegePlayer: collegePlayer },
    type: AdminAthleteActions.LINK_YAHOO_COLLEGE_PROFILE_SUCCESS
  } as const);

export const syncYahooCollegeProfile = (personId: string) =>
  ({
    payload: {
      personId
    },
    type: AdminAthleteActions.SYNC_YAHOO_COLLEGE_PROFILE
  } as const);

export const syncYahooCollegeProfileFailure = (error?: string) =>
  ({
    error: true,
    payload: { error },
    type: AdminAthleteActions.SYNC_YAHOO_COLLEGE_PROFILE_FAILURE
  } as const);

export const syncYahooCollegeProfileSuccess = (collegePlayer: CollegePlayer) =>
  ({
    payload: { collegePlayer: collegePlayer },
    type: AdminAthleteActions.SYNC_YAHOO_COLLEGE_PROFILE_SUCCESS
  } as const);

export const updateCollegeProfile = (player: UpdateCollegePlayerRequest) =>
  ({
    payload: {
      player
    },
    type: AdminAthleteActions.UPDATE_COLLEGE_PROFILE
  } as const);

export const updateCollegeProfileFailure = (error?: string) =>
  ({
    error: true,
    payload: { error },
    type: AdminAthleteActions.UPDATE_COLLEGE_PROFILE_FAILURE
  } as const);

export const updateCollegeProfileSuccess = (collegePlayer: CollegePlayer) =>
  ({
    payload: { collegePlayer: collegePlayer },
    type: AdminAthleteActions.UPDATE_COLLEGE_PROFILE_SUCCESS
  } as const);

export const yahooProfileSearch = (personId: number) =>
  ({
    payload: personId,
    type: AdminAthleteActions.YAHOO_PROFILE_SEARCH
  } as const);

export const yahooProfileSearchFailure = (error?: string) =>
  ({
    payload: { error },
    type: AdminAthleteActions.YAHOO_PROFILE_SEARCH_FAILURE
  } as const);

export const yahooProfileSearchSuccess = (matchData: YahooProfileMatchData) =>
  ({
    payload: matchData,
    type: AdminAthleteActions.YAHOO_PROFILE_SEARCH_SUCCESS
  } as const);

export type AdminAthleteAction =
  | ReturnType<typeof clearYahooSyncData>
  | ReturnType<typeof deleteCollegePlayer>
  | ReturnType<typeof deleteCollegePlayerFailure>
  | ReturnType<typeof deleteCollegePlayerSuccess>
  | ReturnType<typeof getAdminCollegePlayer>
  | ReturnType<typeof getAdminCollegePlayerFailure>
  | ReturnType<typeof getAdminCollegePlayerSuccess>
  | ReturnType<typeof getYahooSyncData>
  | ReturnType<typeof getYahooSyncDataFailure>
  | ReturnType<typeof getYahooSyncDataSuccess>
  | ReturnType<typeof linkYahooCollegeProfile>
  | ReturnType<typeof linkYahooCollegeProfileFailure>
  | ReturnType<typeof linkYahooCollegeProfileSuccess>
  | ReturnType<typeof syncYahooCollegeProfile>
  | ReturnType<typeof syncYahooCollegeProfileFailure>
  | ReturnType<typeof syncYahooCollegeProfileSuccess>
  | ReturnType<typeof updateCollegeProfile>
  | ReturnType<typeof updateCollegeProfileFailure>
  | ReturnType<typeof updateCollegeProfileSuccess>
  | ReturnType<typeof yahooProfileSearch>
  | ReturnType<typeof yahooProfileSearchFailure>
  | ReturnType<typeof yahooProfileSearchSuccess>;
