import { ReactElement } from "react";
import styles from "rivals/components/shared/Icons/Icons.module.scss";

const Close = ({ fill = styles.black }): ReactElement => (
  <svg
    height="16px"
    version="1.1"
    viewBox="0 0 16 16"
    width="16px"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Close</title>
    <desc>Created with Sketch.</desc>
    <g
      fill="none"
      fillRule="evenodd"
      id="Style-Guide---Icons"
      stroke="none"
      strokeWidth="1"
    >
      <g
        fill={fill}
        id="icn-/-close"
        transform="translate(-181.000000, -1869.000000)"
      >
        <g transform="translate(173.000000, 1861.000000)">
          <path
            d="M16,14.5857864 L22.2928932,8.29289322 L23.7071068,9.70710678 L17.4142136,16 L23.7071068,22.2928932 L22.2928932,23.7071068 L16,17.4142136 L9.70710678,23.7071068 L8.29289322,22.2928932 L14.5857864,16 L8.29289322,9.70710678 L9.70710678,8.29289322 L16,14.5857864 Z"
            id="svg"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);

export default Close;
