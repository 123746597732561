import classNames from "classnames";
import { usePathname } from "next/navigation";
import { JSX, SyntheticEvent, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import styles from "rivals/components/app/Layout/Alerts/Alert/Alert.module.scss";
import AlertPrefix from "rivals/components/app/Layout/Alerts/Alert/AlertPrefix";
import type { Props } from "rivals/components/app/Layout/Alerts/Alert/types";
import Button from "rivals/components/shared/Button";
import CloseIcon from "rivals/components/shared/Icons/Close";
import Link from "rivals/components/shared/Link";
import { hideAlert } from "rivals/redux/alert/actions";
import { AlertTypes } from "rivals/redux/alert/reducer";
import {
  MILLISECONDS_PER_SECOND,
  NATIONAL_SITE_IDENTIFIERS
} from "rivals/shared/constants";
import { siteHexColor } from "rivals/shared/utils/site";

/**
 * Global Alert banner rendered at the top of the page
 * Redux version for use in the Page Router only
 */
const Alert = ({ alert, site }: Props): JSX.Element => {
  const isMountingRef = useRef(false);
  const pathname = usePathname();
  const dispatch = useDispatch();

  const {
    action,
    hasCloseButton = true,
    durationInSeconds,
    href,
    id,
    linkText,
    message,
    persisted = true,
    type = AlertTypes.INFO
  } = alert;

  const handleRouteChange = (): void => {
    if (!persisted) {
      dispatch(hideAlert(id));
    }
  };

  // Ignores the initial setting of the pathname so the alert
  // isn't unintentionally cleared on the first render
  useEffect(() => {
    if (isMountingRef.current) {
      handleRouteChange();
    } else {
      isMountingRef.current = true;
    }
  }, [pathname]);

  useEffect(() => {
    if (durationInSeconds) {
      const timer = setTimeout(() => {
        dispatch(hideAlert(id));
      }, durationInSeconds * MILLISECONDS_PER_SECOND);
      return (): void => clearTimeout(timer);
    }
  }, []);

  const onCloseAlert = (): void => {
    dispatch(hideAlert(id));
  };

  const hasLink = !!(linkText && (href || action));

  const alertClasses = classNames(styles.alert, styles[type], {
    [styles.withCloseButton]: hasCloseButton,
    [styles.fullSizeLink]: !message && hasLink
  });

  const renderLink = (): React.JSX.Element | null => {
    if (!hasLink) {
      return null;
    }

    if (href) {
      return (
        <Link className={styles.link} href={href}>
          {linkText}
        </Link>
      );
    }

    if (action) {
      return (
        <Link
          className={styles.link}
          href=""
          onClick={(e: SyntheticEvent): void => {
            e.preventDefault();
            e.stopPropagation();
            dispatch(action);
            dispatch(hideAlert(id));
          }}
        >
          {linkText}
        </Link>
      );
    }

    return null;
  };

  const getAlertBackgroundColor = (): string | undefined => {
    const isNational = NATIONAL_SITE_IDENTIFIERS.includes(
      site?.shortName?.toLowerCase() || ""
    );

    if (
      type === AlertTypes.SITE_ADAPTIVE &&
      !isNational &&
      site?.primaryColor &&
      site.secondaryColor
    ) {
      return siteHexColor(site.primaryColor || site.secondaryColor);
    }

    // let it fallback to the background color defined on the stylesheet
    return undefined;
  };

  return (
    <div
      className={alertClasses}
      role="alert"
      style={{ backgroundColor: getAlertBackgroundColor() }}
    >
      <div className={styles.content}>
        <AlertPrefix type={type} />
        <div className={styles.textWrapper}>
          <span dangerouslySetInnerHTML={{ __html: message }} />
          {renderLink()}
        </div>
      </div>
      {hasCloseButton && (
        <Button
          backgroundColor="transparent"
          borderColor="transparent"
          buttonText=""
          className={styles.closeButton}
          icon={<CloseIcon />}
          inverted
          onClick={onCloseAlert}
          size="small"
        />
      )}
    </div>
  );
};

export default Alert;
