import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiService } from "rivals/redux/store";
import {
  FetchTransfersRequest,
  FetchTransfersResponse
} from "rivals/services/container";

enum TransferActions {
  GET_TRANSFERS = "transfer/getTransfers"
}

export const getTransfers = createAsyncThunk(
  TransferActions.GET_TRANSFERS,
  async (request: FetchTransfersRequest, { rejectWithValue }) => {
    try {
      const response: FetchTransfersResponse = await apiService.fetchTransfers(
        request
      );
      return response;
    } catch (error) {
      return rejectWithValue((<Error>error)?.message);
    }
  }
);
