"use client";

/* eslint-disable-next-line no-restricted-imports */
import { Media, MediaContextProvider } from "rivals/media";
import type { Props } from "./types";

const MediaWrapper = ({ children, ...props }: Props): React.JSX.Element => {
  return (
    <MediaContextProvider>
      <Media {...props}>{children}</Media>
    </MediaContextProvider>
  );
};

export default MediaWrapper;
