import classNames from "classnames";
import NextLink from "next/link";
import { FunctionComponent, ReactElement } from "react";
import { setData } from "rivals/shared/utils/qa";
import { isReactPath } from "rivals/shared/utils/routes";
import styles from "./Link.module.scss";
import { Props } from "./types";

const LinkWrapper: FunctionComponent<Props> = ({
  ariaLabel,
  children,
  className,
  data = "",
  disabled = false,
  forceNextLink = false,
  href,
  hrefAs,
  id,
  onClick,
  style,
  target
}): ReactElement => {
  const linkClasses = classNames(styles.link, className, {
    [styles.disabled]: disabled
  });

  const htmlLink = (withHref = false): JSX.Element => (
    <a
      aria-label={ariaLabel}
      className={linkClasses}
      {...setData(data, "link")}
      href={withHref ? href : undefined}
      id={id}
      onClick={onClick}
      rel="noreferrer noopener"
      style={style}
      target={target}
    >
      {children}
    </a>
  );

  if (isReactPath(href) || forceNextLink) {
    return (
      <NextLink as={hrefAs} href={href} legacyBehavior prefetch={false}>
        {htmlLink()}
      </NextLink>
    );
  }

  return htmlLink(true);
};

export default LinkWrapper;
