import { createSelector } from "reselect";
import { State } from "rivals/redux/application/reducer";
import { State as AppState } from "rivals/redux/reducer";

const getApplication = (state: AppState): State => state.app;

export const getHostUrl = createSelector(
  getApplication,
  state => state.hostUrl
);

export const getIsRedirecting = createSelector(
  getApplication,
  state => state.isRedirecting
);

export const getIsSubmittingToServer = createSelector(
  getApplication,
  state => state.isSubmittingToServer
);
