import {
  DATE_M_D_YY,
  DATE_M_D_YYYY,
  DATETIME_YYYY_MM_DD_HH_MM_SS,
  RIVALS_TIMEZONE
} from "rivals/shared/constants";

export async function formatTimestamp(
  timeString: string,
  short?: boolean
): Promise<string> {
  const MAX_NUMBER_DAYS_TO_ABBREVIATE = 20;
  const { default: moment } = await import("moment-timezone");
  const time = moment(
    timeString,
    DATETIME_YYYY_MM_DD_HH_MM_SS,
    RIVALS_TIMEZONE
  );
  if (time > moment().subtract(MAX_NUMBER_DAYS_TO_ABBREVIATE, "day")) {
    return time.fromNow();
  } else {
    return short ? time.format(DATE_M_D_YY) : time.format(DATE_M_D_YYYY);
  }
}

export async function updateLocale(): Promise<void> {
  const { default: moment } = await import("moment-timezone");
  moment.updateLocale("en", {
    relativeTime: {
      d: "%dD",
      dd: "%dD",
      future: "IN %s",
      h: "%dH",
      hh: "%dH",
      m: "%dM",
      mm: "%dM",
      past: "%s AGO",
      s: "%dS"
    }
  });
}

export function validateExpiration(value: string): boolean {
  const [mm, yy] = value.split("/");
  if (new Date(`20${yy}-${mm}-01`) > new Date()) {
    return true;
  }
  return false;
}
