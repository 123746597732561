import classNames from "classnames";
import { usePathname } from "next/navigation";
import { JSX, SyntheticEvent, useContext, useEffect, useRef } from "react";
import Button from "rivals/components/shared/Button";
import CloseIcon from "rivals/components/shared/Icons/Close";
import Link from "rivals/components/shared/Link";
import { AlertTypes } from "rivals/redux/alert/reducer";
import { AlertDispatchContext } from "rivals/shared/app/alertContext";
import {
  MILLISECONDS_PER_SECOND,
  NATIONAL_SITE_IDENTIFIERS
} from "rivals/shared/constants";
import { siteHexColor } from "rivals/shared/utils/site";
import styles from "./Alert.module.scss";
import AlertPrefix from "./AlertPrefix";
import type { Props } from "./types";

/**
 * Global Alert banner rendered at the top of the page
 * Redux-free version for use in App Router
 */
export default function Alert({ alert, site }: Props): JSX.Element {
  const isMountingRef = useRef(false);
  const pathname = usePathname();
  const dispatch = useContext(AlertDispatchContext);

  const {
    hasCloseButton = true,
    durationInSeconds,
    href,
    id,
    linkText,
    message,
    persisted = true,
    type = AlertTypes.INFO
  } = alert;

  const handleRouteChange = (): void => {
    if (!persisted) {
      dispatch({ payload: { id }, type: "HIDE_ALERT" });
    }
  };

  // Ignores the initial setting of the pathname so the alert
  // isn't unintentionally cleared on the first render
  useEffect(() => {
    if (isMountingRef.current) {
      handleRouteChange();
    } else {
      isMountingRef.current = true;
    }
  }, [pathname]);

  // Hide alert after durationInSeconds when specified
  useEffect(() => {
    if (durationInSeconds) {
      const timer = setTimeout(() => {
        dispatch({ payload: { id }, type: "HIDE_ALERT" });
      }, durationInSeconds * MILLISECONDS_PER_SECOND);
      return (): void => clearTimeout(timer);
    }
  }, []);

  // Click handler for closing the alert on click of close button
  const onCloseAlert = (): void => {
    dispatch({ payload: { id }, type: "HIDE_ALERT" });
  };

  // Click handler for changing Alert status
  const onUpdateAlertStatus = (): void => {
    dispatch({ payload: { id }, type: "UPDATE_ALERT_STATUS" });
  };

  const hasLink = !!linkText;

  const alertClasses = classNames(styles.alert, styles[type], {
    [styles.withCloseButton]: hasCloseButton,
    [styles.fullSizeLink]: !message && hasLink
  });

  // If an href is specified, render a Link component
  // If an action is specified, render a Link component with an onClick handler
  const renderLink = (): JSX.Element | null => {
    if (!hasLink) {
      return null;
    }

    if (href) {
      return (
        <Link className={styles.link} href={href}>
          {linkText}
        </Link>
      );
    }

    return (
      <Link
        className={styles.link}
        href=""
        onClick={async (e: SyntheticEvent): Promise<void> => {
          e.preventDefault();
          e.stopPropagation();
          onUpdateAlertStatus();
        }}
      >
        {linkText}
      </Link>
    );
  };

  // Support for adaptive site colors due to selected site/subdomain
  const getAlertBackgroundColor = (): string | undefined => {
    const isNational = NATIONAL_SITE_IDENTIFIERS.includes(
      site?.shortName?.toLowerCase() || ""
    );
    const hasColor = site?.primaryColor || site?.secondaryColor;

    if (type === AlertTypes.SITE_ADAPTIVE && !isNational && hasColor) {
      return siteHexColor(site.primaryColor || site.secondaryColor);
    }

    // let it fallback to the background color defined on the stylesheet
    return undefined;
  };

  return (
    <div
      className={alertClasses}
      role="alert"
      style={{ backgroundColor: getAlertBackgroundColor() }}
    >
      <div className={styles.content}>
        <AlertPrefix type={type} />
        <div className={styles.textWrapper}>
          <span dangerouslySetInnerHTML={{ __html: message }} />
          {renderLink()}
        </div>
      </div>
      {hasCloseButton && (
        <Button
          backgroundColor="transparent"
          borderColor="transparent"
          buttonText=""
          className={styles.closeButton}
          icon={<CloseIcon />}
          inverted
          onClick={onCloseAlert}
          size="small"
        />
      )}
    </div>
  );
}
