import localFont from "next/font/local";

const rivalsNumbersLogo = localFont({
  src: [
    {
      path: "./RivalsNumbers-Logo.woff2",
      style: "normal",
      weight: "normal"
    }
  ],
  variable: "--rivals-numbers-logo"
});

export default rivalsNumbersLogo;
