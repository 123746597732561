export const LEARN_MORE_ADDON_MODAL_STEP = "LearnMoreStep";
export const UPSELL_FUNNEL_ADDON_MODAL_STEP = "UpsellPurchaseStep";
export const PURCHASE_ADDON_MODAL_STEP = "PurchaseStep";
export const PURCHASE_SUCCESS_ADDON_MODAL_STEP = "PurchaseSuccessStep";
export const BUNDLE_ADDON_MODAL_STEP = "BundleAddOnStep";
export const MAX_BUNDLE_SIZE = 3; // changing this int is enough to manipulate the addons quantity that a bundle would handle
export const BUNDLE_ADDONS_FILTER_TYPE = "siteName";
export const ADDON_MODAL_CONSTANTS = {
  AD_LITE: "AD_LITE",
  ALL_ACCESS: "ALL_ACCESS",
  BUNDLE: "BUNDLE",
  SPY: "SPY",
  STANDARD: "STANDARD"
};
