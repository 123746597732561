import useSWR, { Key, SWRConfiguration, SWRResponse } from "swr";
import { swrFetcher } from "rivals/services/utils";

export interface UseQueryOptions extends SWRConfiguration {
  shouldFetch?: boolean;
}

export const defaultUseQueryOptions: UseQueryOptions = {
  shouldFetch: true
};

const useQuery = <T>(
  key: Key,
  options: UseQueryOptions = defaultUseQueryOptions
): SWRResponse<T> => {
  const { shouldFetch, ...swrConfig } = {
    ...defaultUseQueryOptions,
    ...options
  };

  return useSWR<T>(shouldFetch ? key : null, swrFetcher, swrConfig);
};

export default useQuery;
