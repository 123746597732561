import {
  SubscriptionAction,
  SubscriptionActions
} from "rivals/redux/subscription/actions";

import { SaveEligibility } from "rivals/shared/interfaces/Subscription/";

export type State = {
  saveEligibility?: SaveEligibility;
  error?: string;
  submitting: boolean;
};

const initialState = {
  error: undefined,
  saveEligibility: undefined,
  submitting: false
};

export default function reducer(
  state: State = initialState,
  action: SubscriptionAction
): State {
  switch (action.type) {
    case SubscriptionActions.GET_SAVE_ELIGIBILITY_SUCCESS: {
      return {
        saveEligibility: action.payload.saveEligibility,
        submitting: false
      };
    }
    case SubscriptionActions.AUTO_SAVE:
    case SubscriptionActions.CANCEL_SUBSCRIPTION:
    case SubscriptionActions.EMAIL_OFFER: {
      return {
        ...state,
        submitting: true
      };
    }
    case SubscriptionActions.AUTO_SAVE_SUCCESS:
    case SubscriptionActions.CANCEL_SUBSCRIPTION_SUCCESS:
    case SubscriptionActions.EMAIL_OFFER_SUCCESS: {
      return {
        ...state,
        error: undefined,
        submitting: false
      };
    }
    case SubscriptionActions.AUTO_SAVE_FAILURE:
    case SubscriptionActions.CANCEL_SUBSCRIPTION_FAILURE:
    case SubscriptionActions.EMAIL_OFFER_FAILURE: {
      return {
        ...state,
        error: action.payload.error,
        submitting: false
      };
    }
    default: {
      return state;
    }
  }
}
