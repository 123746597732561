import { createAsyncThunk } from "@reduxjs/toolkit";

import { showAlert } from "rivals/redux/alert/actions";
import { AlertTypes } from "rivals/redux/alert/reducer";
import { apiService } from "rivals/redux/store";
import {
  GetTeamRankingsRequest,
  GetTeamRankingsResponse,
  GetTeamRankingsYearsResponse
} from "rivals/services/container";

enum TeamRankingsActions {
  GET_TEAM_RANKINGS = "GET_TEAM_RANKINGS",
  GET_TEAM_RANKINGS_YEARS = "GET_TEAM_RANKINGS_YEARS"
}

export const getTeamRankings = createAsyncThunk(
  TeamRankingsActions.GET_TEAM_RANKINGS,
  async (request: GetTeamRankingsRequest, { dispatch, rejectWithValue }) => {
    try {
      const response = await apiService.getTeamRankings(request);
      return response as GetTeamRankingsResponse;
    } catch (error) {
      const message = (error as Error)?.message;
      dispatch(
        showAlert({
          message,
          persisted: false,
          type: AlertTypes.SEVERE
        })
      );
      return rejectWithValue(message);
    }
  }
);

export const getTeamRankingsYears = createAsyncThunk(
  TeamRankingsActions.GET_TEAM_RANKINGS_YEARS,
  async (_request, { dispatch, rejectWithValue }) => {
    try {
      const response = await apiService.getTeamRankingsYears();
      return response as GetTeamRankingsYearsResponse;
    } catch (error) {
      const message = (error as Error)?.message;
      dispatch(
        showAlert({
          message,
          persisted: false,
          type: AlertTypes.SEVERE
        })
      );
      return rejectWithValue(message);
    }
  }
);
