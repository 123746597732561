import { all, call, fork, takeEvery } from "redux-saga/effects";
import { Action } from "rivals/redux/Action";
import { AthleteActions } from "rivals/redux/athlete/actions";
import { AuthActions } from "rivals/redux/authentication/actions";
import errorService from "rivals/services/error";

// TODO: Update to ignore based on status code https://griosf.atlassian.net/browse/RVLS-6679
const ACTIONS_TO_IGNORE = [
  String(AthleteActions.GET_COLLEGE_PLAYER_FAILURE),
  String(AthleteActions.GET_COLLEGE_PLAYER_GAME_DATA_FAILURE),
  String(AthleteActions.GET_COLLEGE_PLAYER_RANKING_DATA_FAILURE),
  String(AuthActions.LOGIN_USER_FAILURE)
];

function* sendToAirbrake(): Generator {
  yield takeEvery("*", function* (action: Action<string, { error?: Error }>) {
    if (action.error && action?.payload?.error) {
      if (ACTIONS_TO_IGNORE.includes(action.type)) {
        return;
      }
      yield call(errorService.capture, action.payload.error);
    }
  });
}

export default function* saga(): Generator {
  yield all([fork(sendToAirbrake)]);
}
