"use client";

import { usePathname } from "next/navigation";
import Script from "next/script";
import useGetCurrentUser from "rivals/services/getCurrentUser/useGetCurrentUser";
import { PATHS } from "rivals/shared/constants";
import logger from "rivals/shared/utils/logger";
import type { Props } from "./types";
import { configAnalyticsGa, debugLog } from "./utils";

export default function Analytics({
  site,
  topicTags
}: Props): React.JSX.Element | null {
  const pathname = usePathname();
  const { data, isLoading, error } = useGetCurrentUser();

  // Wait until we have a user response
  if (isLoading) {
    return null;
  }

  if (error) {
    logger({ message: error, page: "AnalyticsGA", severity: "error" });
    return null;
  }

  const userType = data?.user?.googleAnalyticsUserType ?? "anonymous";

  // If we don't have all the necessary data to configure, return null
  if (!site) {
    debugLog("site not provided");
    return null;
  }

  let spaceId = site.ancillarySpaceId;

  if (pathname === PATHS.HOME) {
    spaceId = site.homepagesSpaceId;
  } else if (pathname?.match(/^\/news/)) {
    spaceId = site.contentSpaceId;
  }

  if (!spaceId) {
    debugLog("missing spaceid");
    return null;
  }

  const tagId = process.env.NEXT_PUBLIC_RAPID_GA_TAG_ID || "";

  if (!tagId) {
    debugLog("missing tag_id");
    return null;
  }

  function onLoad(): void {
    debugLog("analytics script loaded");

    const analyticsConfig = configAnalyticsGa(
      spaceId,
      tagId,
      userType,
      topicTags
    );

    debugLog("calling 'new Analytics' with config:", analyticsConfig);

    window.rapidInstance = new YAHOO.i13n.Rapid(analyticsConfig);
  }

  return (
    <Script
      async
      onLoad={onLoad}
      src={
        process.env.NEXT_PUBLIC_RAPID_SRC_URL ||
        "https://s.yimg.com/ss/analytics-ga.js"
      }
      strategy="afterInteractive"
    />
  );
}
