import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiService } from "rivals/redux/store";
import {
  GetCareerHighlightsRequest,
  getProspectResponse
} from "rivals/services/container";
import {
  Request as GetProspectNewsRequest,
  Response as GetProspectNewsResponse
} from "rivals/services/getProspectNews/types";
import {
  AthletePageParams,
  PersonIdParams
} from "rivals/shared/interfaces/Athlete";
import {
  CareerEvent,
  GraphForecastList,
  Prospect,
  ProspectForecastGraphData,
  ProspectForecastTable,
  ProspectInterests,
  ProspectTopForecasts,
  RankingGraphData
} from "rivals/shared/interfaces/Prospect";

export enum ProspectActions {
  GET_CAREER_HIGHLIGHTS = "GET_CAREER_HIGHLIGHTS",
  GET_CAREER_HIGHLIGHTS_FAILURE = "GET_CAREER_HIGHLIGHTS_FAILURE",
  GET_CAREER_HIGHLIGHTS_SUCCESS = "GET_CAREER_HIGHLIGHTS_SUCCESS",
  GET_PROSPECT = "GET_PROSPECT",
  GET_PROSPECT_FAILURE = "GET_PROSPECT/rejected",
  GET_PROSPECT_SUCCESS = "GET_PROSPECT/fulfilled",
  GET_PROSPECT_RANKING_GRAPH = "GET_PROSPECT_RANKING_GRAPH",
  GET_PROSPECT_RANKING_GRAPH_FAILURE = "GET_PROSPECT_RANKING_GRAPH_FAILURE",
  GET_PROSPECT_RANKING_GRAPH_SUCCESS = "GET_PROSPECT_RANKING_GRAPH_SUCCESS",
  GET_PROSPECT_COLLEGE_INTERESTS = "GET_PROSPECT_COLLEGE_INTERESTS",
  GET_PROSPECT_COLLEGE_INTERESTS_FAILURE = "GET_PROSPECT_COLLEGE_INTERESTS_FAILURE",
  GET_PROSPECT_COLLEGE_INTERESTS_SUCCESS = "GET_PROSPECT_COLLEGE_INTERESTS_SUCCESS",
  GET_PROSPECT_FORECAST_TABLE = "GET_PROSPECT_FORECAST_TABLE",
  GET_PROSPECT_FORECAST_TABLE_FAILURE = "GET_PROSPECT_FORECAST_TABLE_FAILURE",
  GET_PROSPECT_FORECAST_TABLE_SUCCESS = "GET_PROSPECT_FORECAST_TABLE_SUCCESS",
  GET_PROSPECT_FORECAST_GRAPH = "GET_PROSPECT_FORECAST_GRAPH",
  GET_PROSPECT_FORECAST_GRAPH_FAILURE = "GET_PROSPECT_FORECAST_GRAPH_FAILURE",
  GET_PROSPECT_FORECAST_GRAPH_SUCCESS = "GET_PROSPECT_FORECAST_GRAPH_SUCCESS",
  GET_PROSPECT_FORECAST_GRAPH_LIST = "GET_PROSPECT_FORECAST_LIST_GRAPH",
  GET_PROSPECT_FORECAST_GRAPH_LIST_FAILURE = "GET_PROSPECT_FORECAST_GRAPH_LIST_FAILURE",
  GET_PROSPECT_FORECAST_GRAPH_LIST_SUCCESS = "GET_PROSPECT_FORECAST_GRAPH_LIST_SUCCESS",
  GET_PROSPECT_NEWS = "GET_PROSPECT_NEWS",
  GET_PROSPECT_NEWS_FAILURE = "GET_PROSPECT_NEWS_FAILURE",
  GET_PROSPECT_NEWS_SUCCESS = "GET_PROSPECT_NEWS_SUCCESS",
  GET_PROSPECT_TOP_FORECASTS = "GET_PROSPECT_TOP_FORECASTS",
  GET_PROSPECT_TOP_FORECASTS_FAILURE = "GET_PROSPECT_TOP_FORECASTS_FAILURE",
  GET_PROSPECT_TOP_FORECASTS_SUCCESS = "GET_PROSPECT_TOP_FORECASTS_SUCCESS"
}

export const getCareerHighlights = (personParams: GetCareerHighlightsRequest) =>
  ({
    payload: personParams,
    type: ProspectActions.GET_CAREER_HIGHLIGHTS
  } as const);

export const getCareerHighlightsSuccess = (events?: CareerEvent[]) =>
  ({
    payload: { events },
    type: ProspectActions.GET_CAREER_HIGHLIGHTS_SUCCESS
  } as const);

export const getCareerHighlightsFailure = (error?: string) =>
  ({
    error: true,
    payload: { error },
    type: ProspectActions.GET_CAREER_HIGHLIGHTS_FAILURE
  } as const);

export const getProspect = createAsyncThunk(
  ProspectActions.GET_PROSPECT,
  async (request: PersonIdParams, { rejectWithValue }) => {
    try {
      const response: getProspectResponse = await apiService.getProspect(
        request
      );
      return response;
    } catch (error) {
      return rejectWithValue((<Error>error)?.message);
    }
  }
);

export const getProspectFailure = (error?: string) =>
  ({
    error: true,
    payload: { error },
    type: ProspectActions.GET_PROSPECT_FAILURE
  } as const);

export const getProspectSuccess = (prospect: Prospect) =>
  ({
    payload: { prospect },
    type: ProspectActions.GET_PROSPECT_SUCCESS
  } as const);

export const getProspectRankingGraph = (prospectParams: AthletePageParams) =>
  ({
    payload: prospectParams,
    type: ProspectActions.GET_PROSPECT_RANKING_GRAPH
  } as const);

export const getProspectRankingGraphFailure = (error?: string) =>
  ({
    error: true,
    payload: { error },
    type: ProspectActions.GET_PROSPECT_RANKING_GRAPH_FAILURE
  } as const);

export const getProspectRankingGraphSuccess = (
  rankingGraphData?: RankingGraphData
) =>
  ({
    payload: { rankingGraphData },
    type: ProspectActions.GET_PROSPECT_RANKING_GRAPH_SUCCESS
  } as const);

export const getProspectCollegeInterests = (
  prospectInterestParams: AthletePageParams
) =>
  ({
    payload: prospectInterestParams,
    type: ProspectActions.GET_PROSPECT_COLLEGE_INTERESTS
  } as const);

export const getProspectCollegeInterestsFailure = (error?: string) =>
  ({
    error: true,
    payload: { error },
    type: ProspectActions.GET_PROSPECT_COLLEGE_INTERESTS_FAILURE
  } as const);

export const getProspectCollegeInterestsSuccess = (
  prospectCollegeInterests: ProspectInterests
) =>
  ({
    payload: { prospectCollegeInterests },
    type: ProspectActions.GET_PROSPECT_COLLEGE_INTERESTS_SUCCESS
  } as const);

export const getProspectForecastTable = (prospectParams: AthletePageParams) =>
  ({
    payload: prospectParams,
    type: ProspectActions.GET_PROSPECT_FORECAST_TABLE
  } as const);

export const getProspectForecastTableFailure = (error?: string) =>
  ({
    error: true,
    payload: { error },
    type: ProspectActions.GET_PROSPECT_FORECAST_TABLE_FAILURE
  } as const);

export const getProspectForecastTableSuccess = (
  data?: ProspectForecastTable[]
) =>
  ({
    payload: { data },
    type: ProspectActions.GET_PROSPECT_FORECAST_TABLE_SUCCESS
  } as const);

export const getProspectForecastGraph = (
  prospectParams: AthletePageParams & { userTypes?: string }
) =>
  ({
    payload: prospectParams,
    type: ProspectActions.GET_PROSPECT_FORECAST_GRAPH
  } as const);

export const getProspectForecastGraphFailure = (error?: string) =>
  ({
    error: true,
    payload: { error },
    type: ProspectActions.GET_PROSPECT_FORECAST_GRAPH_FAILURE
  } as const);

export const getProspectForecastGraphSuccess = (
  forecastGraphData?: ProspectForecastGraphData
) =>
  ({
    payload: { forecastGraphData },
    type: ProspectActions.GET_PROSPECT_FORECAST_GRAPH_SUCCESS
  } as const);

export const getProspectForecastGraphList = (
  prospectParams: AthletePageParams
) =>
  ({
    payload: prospectParams,
    type: ProspectActions.GET_PROSPECT_FORECAST_GRAPH_LIST
  } as const);

export const getProspectForecastGraphListFailure = (error?: string) =>
  ({
    error: true,
    payload: { error },
    type: ProspectActions.GET_PROSPECT_FORECAST_GRAPH_LIST_FAILURE
  } as const);

export const getProspectForecastGraphListSuccess = (
  forecastGraphList?: GraphForecastList
) =>
  ({
    payload: { forecastGraphList },
    type: ProspectActions.GET_PROSPECT_FORECAST_GRAPH_LIST_SUCCESS
  } as const);

export const getProspectNews = (params: GetProspectNewsRequest) =>
  ({
    payload: params,
    type: ProspectActions.GET_PROSPECT_NEWS
  } as const);

export const getProspectNewsFailure = (error?: string) =>
  ({
    error: true,
    payload: { error },
    type: ProspectActions.GET_PROSPECT_NEWS_FAILURE
  } as const);

export const getProspectNewsSuccess = (prospectNews: GetProspectNewsResponse) =>
  ({
    payload: { prospectNews },
    type: ProspectActions.GET_PROSPECT_NEWS_SUCCESS
  } as const);

export const getProspectTopForecasts = (prospectParams: AthletePageParams) =>
  ({
    payload: prospectParams,
    type: ProspectActions.GET_PROSPECT_TOP_FORECASTS
  } as const);

export const getProspectTopForecastsFailure = (error?: string) =>
  ({
    error: true,
    payload: { error },
    type: ProspectActions.GET_PROSPECT_TOP_FORECASTS_FAILURE
  } as const);

export const getProspectTopForecastsSuccess = (
  topForecasts?: ProspectTopForecasts
) =>
  ({
    payload: { topForecasts },
    type: ProspectActions.GET_PROSPECT_TOP_FORECASTS_SUCCESS
  } as const);

export type ProspectAction =
  | ReturnType<typeof getCareerHighlights>
  | ReturnType<typeof getCareerHighlightsSuccess>
  | ReturnType<typeof getCareerHighlightsFailure>
  | ReturnType<typeof getProspectFailure>
  | ReturnType<typeof getProspectSuccess>
  | ReturnType<typeof getProspectCollegeInterests>
  | ReturnType<typeof getProspectCollegeInterestsFailure>
  | ReturnType<typeof getProspectCollegeInterestsSuccess>
  | ReturnType<typeof getProspectRankingGraph>
  | ReturnType<typeof getProspectRankingGraphFailure>
  | ReturnType<typeof getProspectRankingGraphSuccess>
  | ReturnType<typeof getProspectForecastTable>
  | ReturnType<typeof getProspectForecastTableFailure>
  | ReturnType<typeof getProspectForecastTableSuccess>
  | ReturnType<typeof getProspectForecastGraph>
  | ReturnType<typeof getProspectForecastGraphFailure>
  | ReturnType<typeof getProspectForecastGraphSuccess>
  | ReturnType<typeof getProspectForecastGraphList>
  | ReturnType<typeof getProspectForecastGraphListFailure>
  | ReturnType<typeof getProspectForecastGraphListSuccess>
  | ReturnType<typeof getProspectNews>
  | ReturnType<typeof getProspectNewsFailure>
  | ReturnType<typeof getProspectNewsSuccess>
  | ReturnType<typeof getProspectTopForecasts>
  | ReturnType<typeof getProspectTopForecastsFailure>
  | ReturnType<typeof getProspectTopForecastsSuccess>;
