import classNames from "classnames";
import { FunctionComponent } from "react";
import { useSelector } from "react-redux";

import Spinner from "rivals/components/shared/Spinner";
import { getIsSubmittingToServer } from "rivals/redux/application/selectors";

import { setData } from "rivals/shared/utils/qa";
import styles from "./LoadingOverlay.module.scss";
import type { Props } from "./types";

const LoadingOverlay: FunctionComponent<Props> = ({ force = false }) => {
  const show = useSelector(getIsSubmittingToServer) || force;

  return (
    <div
      className={classNames(styles.overlayContainer, {
        [styles.visible]: show
      })}
      {...setData("loading-overlay-container")}
    >
      <Spinner />
    </div>
  );
};

export default LoadingOverlay;
