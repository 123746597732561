import capitalize from "lodash/capitalize";
import {
  BASKETBALL_RECRUITING_SITE_IDENTIFIER,
  RIVALS_LIST_SPORTS,
  ROOT_SITE_NAME,
  TRANSFER_PORTAL_SITE_IDENTIFIER
} from "rivals/shared/constants";
import { Site } from "rivals/shared/interfaces/Site";

export function metaDescription(siteName: string): string {
  return `The definitive source for all ${siteName} news.`;
}

export function homepageTitle(site: Site, sport?: string): string {
  const sportsString = sport === "other" ? "Sports" : capitalize(sport);
  // Default title for subdomains
  let title = `${site.name}: ${site.friendlyName} ${site.nickname} Football & Basketball Recruiting`;
  // Nationals
  if (site.friendlyName === ROOT_SITE_NAME) {
    title = "Rivals: Football & Basketball Recruiting";
    if (sport) {
      title = title.replace("Football & Basketball", sportsString);
    }
  }
  // Transfer portal
  else if (site.shortName === TRANSFER_PORTAL_SITE_IDENTIFIER) {
    title = "College Football & Basketball Transfer Portal News";
  }
  // Basketballrecruiting
  else if (site.shortName === BASKETBALL_RECRUITING_SITE_IDENTIFIER) {
    title = "BasketballRecruiting: College Basketball Recruiting News";
  }
  // Subdomain with a sport filter selected
  else if (sport) {
    title = `${site.friendlyName} ${site.nickname} College ${sportsString} News - ${site.name}`;
  }
  return title;
}

const dynamicStrings = {
  saveFileError: (fileType: string): string =>
    `Error: Something went wrong. Please try uploading the ${fileType} again.`,
  uploadFileError: (fileType: string): string =>
    `Error: Something went wrong. Please try changing the ${fileType} again.`
};

const sportsToggleTabs = [
  {
    key: RIVALS_LIST_SPORTS.FOOTBALL,
    title: RIVALS_LIST_SPORTS.FOOTBALL.toUpperCase()
  },
  {
    key: RIVALS_LIST_SPORTS.BASKETBALL,
    title: RIVALS_LIST_SPORTS.BASKETBALL.toUpperCase()
  }
];

export default {
  add: "Add",
  added: "Added",
  advertisement: "Advertisement",
  birthday: "Date of Birth",
  cancel: "Cancel",
  defaultProfilePicture: "/static/icons/icons_personprofile_avatar.svg",
  email: "Email",
  logIn: "Log In",
  password: "Password",
  payPalAccountError:
    "An error has occurred. Please re-enter your PayPal account information.",
  payPalEmailError: "Please enter a valid email address above",
  payPalGenericError:
    "Payment declined, please make sure your PayPal info is up to date or choose another payment method.",
  remove: "Remove",
  sportsToggleTabs,
  subscribe: "Subscribe",
  username: "Username",
  ...dynamicStrings
};
