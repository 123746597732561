import { ConfigProvider } from "antd";
import App, { AppInitialProps, AppProps } from "next/app";
import Head from "next/head";
import absoluteUrl from "next-absolute-url";
import { createContext, ReactElement } from "react";
import { Provider } from "react-redux";
import { END } from "redux-saga";
import {
  RivalsAppContext,
  RivalsAppProps,
  RivalsPageProps
} from "rivals/Context";
import AuthenticationWrapper from "rivals/components/app/AuthWrapper/WithRedux";
import ErrorBoundary from "rivals/components/app/ErrorBoundary";
import PageLayout from "rivals/components/app/Layout";
import RootStyles from "rivals/components/app/Layout/RootStyles";
import Analytics from "rivals/components/shared/AnalyticsGa";
import { MediaContextProvider } from "rivals/media";
import { setHostUrl, setIsRedirecting } from "rivals/redux/application/actions";
import {
  getCCPAFooter,
  getSite as getSiteAction
} from "rivals/redux/rails/actions";
import {
  getSelectedSite,
  selectCCPAFooterConstants
} from "rivals/redux/rails/selectors";
import { apiService, reduxWrapper, Store } from "rivals/redux/store";
import { getIsCurrentUserValid } from "rivals/redux/user/actions";
import {
  HOST_BASE_PATH,
  RESPONSE_PAGE_NOT_FOUND_ERROR
} from "rivals/services/base";
import GoogleAnalytics from "rivals/shared/_app/GoogleAnalytics";
import { NO_CACHE_HEADER } from "rivals/shared/constants";
import legacyTheme from "rivals/shared/design/themes/legacy";
import { Site } from "rivals/shared/interfaces/Site";
import { metaDescription } from "rivals/shared/strings/common";
import "./index.scss";
import { useCompliance } from "rivals/shared/useCompliance";
import { getSubdomain } from "rivals/shared/utils/routes";

export const LinkContext = createContext(true);

function RivalsApp({
  Component,
  ...rest
}: AppProps<RivalsPageProps> & RivalsAppProps): ReactElement {
  const {
    store,
    props: { pageProps }
  } = reduxWrapper.useWrappedStore(rest);
  const state = store.getState();
  const currentSite = getSelectedSite(state);
  const footerConstants = selectCCPAFooterConstants(state);

  useCompliance(footerConstants);

  function meta(site: Site): ReactElement {
    return (
      <>
        <title>{site.name}</title>
        <meta
          content={
            process.env.NEXT_PUBLIC_GUCE_CONSENT_HOST || "stage.guce.rivals.com"
          }
          name="oath:guce:consent-host"
        />
        <meta content="false" name="oath:guce:product-eu" />
        <meta content={site.name} key="og:title" property="og:title" />
        <meta
          content="initial-scale=1.0, maximum-scale=5.0, width=device-width"
          name="viewport"
        />
        <meta
          content={metaDescription(site.friendlyName)}
          key="description"
          name="description"
        />
      </>
    );
  }

  let content = <Component {...pageProps} />;

  if (!pageProps.rawPage) {
    content = (
      <AuthenticationWrapper>
        <MediaContextProvider>
          <PageLayout
            className={pageProps.cssSelector}
            flipperSettings={pageProps.flipperSettings}
          >
            <Component {...pageProps} />
          </PageLayout>
        </MediaContextProvider>
      </AuthenticationWrapper>
    );
  }

  return (
    <ConfigProvider theme={legacyTheme}>
      <RootStyles>
        <Head>{currentSite && meta(currentSite)}</Head>
        <Analytics site={currentSite} />
        <GoogleAnalytics
          googleAnalyticsMeasurementId={
            !pageProps.rawPage
              ? currentSite?.googleAnalyticsMeasurementId
              : null
          }
        />
        <ErrorBoundary>
          <Provider store={store}>{content}</Provider>
        </ErrorBoundary>
      </RootStyles>
    </ConfigProvider>
  );
}

/**
 * Applied to all pages in the application
 * 1. Gets the host URL
 * 2. Determines the subdomain from the host URL and sets in Redux
 * 3. Gets the list of sites from the Rails server
 * 4. Checks authentication state of current user
 */
RivalsApp.getInitialProps = reduxWrapper.getInitialAppProps(
  store => async (appContext: RivalsAppContext): Promise<AppInitialProps> => {
    const {
      ctx: { query, req, res }
    } = appContext;
    const { dispatch } = store;
    const { host, origin } = absoluteUrl(req, HOST_BASE_PATH);

    // checks for a specific query value to bypass the cloudflare cache
    if (req && query[NO_CACHE_HEADER] && query[NO_CACHE_HEADER] === "true") {
      req.headers = {
        ...req.headers,
        [NO_CACHE_HEADER]: "no-cache, no-store, must-revalidate"
      };
    }

    dispatch(setHostUrl(origin));
    dispatch(setIsRedirecting(false));
    dispatch(getSiteAction({ subdomain: getSubdomain(host) }));
    dispatch(getIsCurrentUserValid());
    dispatch(getCCPAFooter(false));

    const appProps = await App.getInitialProps(appContext);

    // this is part of `redux-saga` with `next-redux-wrapper` setup process
    // https://github.com/kirill-konshin/next-redux-wrapper#using-pages_app
    if (req) {
      dispatch(END);
      await (store as Store).sagaTask?.toPromise();
    }

    // If the page has custom logic to check for 404 call it here
    if (
      appContext.Component.handleRedirect &&
      appContext.Component.handleRedirect(store.getState()) &&
      res
    ) {
      // Forces redirect to ErrorPage with 404
      res.statusCode = RESPONSE_PAGE_NOT_FOUND_ERROR;
      appProps.pageProps.statusCode = RESPONSE_PAGE_NOT_FOUND_ERROR;
    }

    try {
      const flipperSettings = await apiService.getFlipper();
      appProps.pageProps.flipperSettings = flipperSettings;
    } catch (e) {
      appProps.pageProps.flipperSettings = undefined;
    }

    return appProps;
  }
);

export default RivalsApp;
