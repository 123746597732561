import snakeCase from "lodash/snakeCase";
import snakecaseKeys from "snakecase-keys";

// the following types are from the types declaration file on: @types/gtag.js
// reference: https://github.com/DefinitelyTyped/DefinitelyTyped/tree/4d4da2106dcd54af4a0ddeb5e0360f322fd8a5bf/types/gtag.js
export interface GAEventPayload {
  eventName: Gtag.EventNames | (string & {});
  eventParams?: Gtag.ControlParams | Gtag.EventParams | Gtag.CustomParams;
}

const DEFAULT_MAX_DELAY_MILLIS = 1000;

const gaEventCallback = (callback: () => void) => {
  return (tagId: string): void => {
    if (/^G-/.test(tagId)) {
      // tagId starts with G- for GA4 properties or with UA-
      // for universal analytics properties
      callback();
    }
  };
};

// reference gtag.js API docs: https://developers.google.com/tag-platform/gtagjs/reference
// https://developers.google.com/analytics/devguides/collection/ga4/events?client_type=gtag
export const gaEvent = ({
  eventName,
  eventParams = {}
}: GAEventPayload): void => {
  window.gtag &&
    window.gtag(
      "event",
      snakeCase(eventName),
      snakecaseKeys(eventParams, { deep: true })
    );
};

// Helper for invoking callbacks after a timeout in case gaEvent() fail to call hitCallback.
// https://developers.google.com/analytics/devguides/collection/analyticsjs/sending-hits#handling_timeouts
export const gaTimeout = (
  callback: () => void,
  maxDelay = DEFAULT_MAX_DELAY_MILLIS
): (() => void) => {
  let called = false;
  function wrapper(): void {
    if (!called) {
      called = true;
      callback();
    }
  }
  window.setTimeout(wrapper, maxDelay);
  return wrapper;
};

// The promise returned by gaSagaEvent always resolve so that sagas don't stop executing
// for failed ga requests, and so that sagas don't have to wrap every ga request in a useless try/catch.

export const gaSagaEvent = (
  eventPayload: GAEventPayload,
  maxDelay = DEFAULT_MAX_DELAY_MILLIS
): Promise<void> => {
  return new Promise(resolve => {
    gaEvent({
      ...eventPayload,
      eventParams: {
        ...eventPayload.eventParams,
        eventCallback: gaEventCallback(resolve)
      }
    });
    window.setTimeout(resolve, maxDelay);
  });
};
