import { SearchAction, SearchActions } from "rivals/redux/search/actions";
import { CollegeTransferSearchRequest } from "rivals/services/search/types";
import { Prospect } from "rivals/shared/interfaces/Prospect";
import { PersonList } from "rivals/shared/interfaces/adminLists";
import {
  SearchArticle,
  SearchCollegeTransfer
} from "rivals/shared/interfaces/search";

export type State = {
  collegeTransfersSearchError?: string;
  collegeTransfersSearchLoading?: boolean;
  collegeTransfersSearchResult?: {
    collegeTransfers: SearchCollegeTransfer[];
    pageNumber: number;
    total: number;
  };
  personSearchResult?: { people: PersonList[]; total: number };
  personSearchLoading?: boolean;
  personSearchError?: string;
  articleError?: string;
  articles?: SearchArticle[];
  articlesSearchSubmitting: boolean;
  predictText?: string[];
  predictTextError?: string;
  prospectError?: string;
  prospects?: Prospect[];
  prospectsSearchSubmitting: boolean;
  transferSearchFilters?: CollegeTransferSearchRequest;
};

const initialState = {
  articleError: undefined,
  articles: undefined,
  articlesSearchSubmitting: false,
  collegeTransfersSearchError: undefined,
  collegeTransfersSearchLoading: false,
  collegeTransfersSearchResult: undefined,
  personSearchError: undefined,
  personSearchLoading: false,
  personSearchResult: undefined,
  predictText: undefined,
  prospectError: undefined,
  prospects: undefined,
  prospectsSearchSubmitting: false
};

export default function reducer(
  state: State = initialState,
  action: SearchAction
): State {
  switch (action.type) {
    case SearchActions.SEARCH_COLLEGE_PLAYERS:
      return {
        ...state,
        personSearchLoading: true
      };
    case SearchActions.SEARCH_COLLEGE_PLAYERS_SUCCESS:
      return {
        ...state,
        personSearchLoading: false,
        personSearchResult: action.payload
      };
    case SearchActions.SEARCH_COLLEGE_PLAYERS_FAILURE: {
      return {
        ...state,
        articlesSearchSubmitting: false,
        personSearchError: action.payload.error
      };
    }
    case SearchActions.HEADER_SEARCH_ARTICLE:
      return {
        ...state,
        articlesSearchSubmitting: true
      };
    case SearchActions.HEADER_SEARCH_ARTICLE_SUCCESS: {
      return {
        ...state,
        articles: action.payload,
        articlesSearchSubmitting: false
      };
    }
    case SearchActions.HEADER_SEARCH_ARTICLE_FAILURE: {
      return {
        ...state,
        articleError: action.payload.error,
        articlesSearchSubmitting: false
      };
    }
    case SearchActions.HEADER_SEARCH_PREDICT_TEXT_SUCCESS: {
      return {
        ...state,
        predictText: action.payload
      };
    }
    case SearchActions.HEADER_SEARCH_PREDICT_TEXT_FAILURE: {
      return {
        ...state,
        predictTextError: action.payload.error
      };
    }
    case SearchActions.HEADER_SEARCH_PROSPECT:
      return {
        ...state,
        prospectsSearchSubmitting: true
      };
    case SearchActions.HEADER_SEARCH_PROSPECT_SUCCESS: {
      return {
        ...state,
        prospects: action.payload,
        prospectsSearchSubmitting: false
      };
    }
    case SearchActions.HEADER_SEARCH_PROSPECT_FAILURE: {
      return {
        ...state,
        prospectError: action.payload.error,
        prospectsSearchSubmitting: false
      };
    }
    case SearchActions.SEARCH_COLLEGE_TRANSFERS: {
      const { rivalsRating, stars, ...rest } = action.payload;
      const payload = {
        ...rest,
        rivalsRating: Object.assign({}, rivalsRating),
        stars: Object.assign({}, stars)
      };
      return {
        ...state,
        collegeTransfersSearchLoading: true,
        transferSearchFilters: payload
      };
    }
    case SearchActions.SEARCH_COLLEGE_TRANSFERS_SUCCESS: {
      return {
        ...state,
        collegeTransfersSearchLoading: false,
        collegeTransfersSearchResult: action.payload
      };
    }
    case SearchActions.SEARCH_COLLEGE_TRANSFERS_FAILURE: {
      return {
        ...state,
        collegeTransfersSearchError: action.payload.error,
        collegeTransfersSearchLoading: false
      };
    }
    case SearchActions.CLEAR_COLLEGE_TRANSFERS: {
      return {
        ...state,
        collegeTransfersSearchResult: undefined
      };
    }
    default: {
      return state;
    }
  }
}
