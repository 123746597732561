import { all, call, getContext, put, takeLatest } from "redux-saga/effects";

import API from "rivals/services";
import {
  Response as CreateForecastResponse,
  ValidationResponse as ValidateForecastResponse
} from "rivals/services/createForecast/types";
import { Response as ForecasterListResponse } from "rivals/services/getForecasterList/types";
import { Response as RecentForecastsResponse } from "rivals/services/getRecentForecasts/types";

import {
  createForecast as createForecastAction,
  createForecastFailure,
  createForecastSuccess,
  FuturecastActions,
  getForecastersFailure,
  getForecastersSuccess,
  getForecastsFailure,
  getForecastsSuccess,
  validateForecast as validateForecastAction,
  validateForecastFailure,
  validateForecastSuccess
} from "./actions";

export function* createForecast(
  action: ReturnType<typeof createForecastAction>
): unknown {
  try {
    const api: API = yield getContext("api");
    const response: CreateForecastResponse = yield call(
      api.createForecast,
      action.payload
    );
    yield put(createForecastSuccess(response.forecastSaved));
  } catch (error) {
    yield put(createForecastFailure((<Error>error)?.message));
  }
}

export function* validateForecast(
  action: ReturnType<typeof validateForecastAction>
): unknown {
  try {
    const api: API = yield getContext("api");
    const response: ValidateForecastResponse = yield call(
      api.validateForecast,
      action.payload
    );
    yield put(validateForecastSuccess(response.forecastValidated));
  } catch (error) {
    yield put(validateForecastFailure((<Error>error)?.message));
  }
}

export function* getForecasters(): unknown {
  try {
    const api: API = yield getContext("api");
    const response: ForecasterListResponse = yield call(api.getForecasterList);
    yield put(getForecastersSuccess(response.forecasters));
  } catch (error) {
    yield put(getForecastersFailure((<Error>error)?.message));
  }
}

export function* getForecasts(): unknown {
  try {
    const api: API = yield getContext("api");
    const response: RecentForecastsResponse = yield call(
      api.getRecentForecasts
    );
    yield put(getForecastsSuccess(response.forecasts));
  } catch (error) {
    yield put(getForecastsFailure((<Error>error)?.message));
  }
}

export default function* saga(): Generator {
  yield all([
    takeLatest(FuturecastActions.CREATE_FORECAST, createForecast),
    takeLatest(FuturecastActions.VALIDATE_FORECAST, validateForecast),
    takeLatest(FuturecastActions.GET_FORECASTERS, getForecasters),
    takeLatest(FuturecastActions.GET_FORECASTS, getForecasts)
  ]);
}
