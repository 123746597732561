import { ParsedUrlQuery } from "querystring";
import { HOST_BASE_PATH, HOST_URL, PROTOCOL } from "rivals/services/base";
import { UrlQueryParams } from "rivals/shared/app/types";
import {
  NATIONAL_SUBDOMAINS,
  PATHS,
  REACT_PATHS,
  ROOT_SITE_IDENTIFIER
} from "rivals/shared/constants";

export function buildForumUrl(subdomain: string): string {
  if (isRootDomain(subdomain)) {
    return `${PROTOCOL}forums.${HOST_BASE_PATH}`;
  } else {
    return `${PROTOCOL}${subdomain}.forums.${HOST_BASE_PATH}`;
  }
}

export function buildGuceUrl(redirectUrl: string): string {
  // redirect uri for guce MUST be a fully qualified rivals domain, not just a path, otherwise it gets dropped
  // additionally it MUST be urlencoded otherwise the guce redirect will drop our params
  const rivalsRegex = new RegExp(`${PROTOCOL}([A-z0-9]+\\.)*${HOST_BASE_PATH}`);
  let finalRedirectUrl;
  if (rivalsRegex.test(redirectUrl)) {
    finalRedirectUrl = encodeURI(redirectUrl);
  } else {
    finalRedirectUrl = encodeURI(`${HOST_URL}${redirectUrl}`);
  }
  return `${PROTOCOL}${
    process.env.NEXT_PUBLIC_GUCE_CONSENT_HOST || "stage.guce.rivals.com"
  }/consent?brandType=nonEu&lang=en-US&done=${finalRedirectUrl}`;
}

export function buildSiteUrl(subdomain: string): string {
  if (isRootDomain(subdomain)) {
    return HOST_URL;
  } else {
    return `${PROTOCOL}${subdomain}.${HOST_BASE_PATH}`;
  }
}

export const getAdminListVersionPath = (type: string, id: number): string =>
  `/admin/lists/${type}/${id}/edit`;

export const getPathParameter = (
  query: ParsedUrlQuery,
  key: string
): string => {
  const parameterValue =
    typeof query[key as keyof ParsedUrlQuery] === "string"
      ? query[key]
      : query[key][0];
  return parameterValue as string;
};

/**
 * Given a host string that follows the "subdomain.domain.tld" pattern, return the
 * `subdomain` portion of the string. For example, "utah" is returned if the host
 * is "utah.rivals.com".
 *
 * If the host has no subdomain, or is a national subdomain (i.e. "n" or "www"),
 * then "rivals" is returned by default.
 *
 * NOTE: the param host can be a full URL: https://www.texas.rivals-local.com/transfer_search/football
 * it will still return texas
 *
 * @param {string} host - The host from which to extract a subdomain
 * @returns {string} The extracted subdomain, or "rivals" by default
 */
export const getSubdomain = (host: string): string => {
  // Remove protocol and www from the beginning of the URL
  host = host.replace(/^(https?:\/\/)?(www\.)?/, "");

  let subdomain = ROOT_SITE_IDENTIFIER;
  const parts = host.split(".");
  const PARTS_WITH_SUBDOMAIN = 3;

  if (parts.length === PARTS_WITH_SUBDOMAIN) {
    const subdomainLower = parts[0].toLowerCase();
    if (!NATIONAL_SUBDOMAINS.includes(subdomainLower)) {
      subdomain = subdomainLower;
    }
  }

  return subdomain;
};

export const isReactPath = (path: string): boolean =>
  REACT_PATHS.some(reactPath => reactPath === path);

export function isRootDomain(subdomain: string): boolean {
  return subdomain.toLowerCase() === ROOT_SITE_IDENTIFIER;
}

export function redirectToGuce(finalUri: string): void {
  if (process.env.NEXT_PUBLIC_GUCE_LOGIN_REDIRECT_ENABLED === "true") {
    window.location.assign(buildGuceUrl(finalUri));
  }
  // Legacy code flow, no GUCE redirect
  else {
    window.location.assign(finalUri);
  }
}

export const isUserProtectedPage = (pathname: string): boolean => {
  return [
    PATHS.BILLING,
    PATHS.NOTIFICATIONS,
    PATHS.STRICT_SIGN_IN,
    PATHS.SUBSCRIPTIONS,
    PATHS.SUBSCRIPTION_CANCELLATION,
    PATHS.SUBSCRIPTION_UPGRADE,
    PATHS.USER_PROFILE
  ].some(path => pathname === path);
};

export const isAdminProtectedPage = (pathname: string): boolean => {
  return pathname.startsWith("/admin");
};

export const buildPlayerUrlQueryParams = (
  url: string,
  params: UrlQueryParams
): string => {
  const { type, id, personId, year, playerSlug } = params;
  if (type === "id") {
    return `${url}/${id}`;
  } else if (type === "personId") {
    return `${url}?person_id=${personId}`;
  } else {
    return `${url}?year=${year}&player_slug=${playerSlug}`;
  }
};
