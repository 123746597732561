import { createAsyncThunk } from "@reduxjs/toolkit";

import { apiService } from "rivals/redux/store";
import {
  FetchConferenceRequest,
  FetchConferenceResponse,
  FetchConferencesRequest,
  FetchConferencesResponse
} from "rivals/services/container";
import RivalsApiError from "rivals/shared/error";

export enum ConferenceActions {
  FETCH_CONFERENCE = "conference/searchConference",
  FETCH_CONFERENCES = "conference/searchConferences"
}

export const fetchConferences = createAsyncThunk(
  ConferenceActions.FETCH_CONFERENCES,
  async (request: FetchConferencesRequest, { rejectWithValue }) => {
    try {
      const response: FetchConferencesResponse = await apiService.fetchConferences(
        request
      );
      return response;
    } catch (error) {
      return rejectWithValue((error as RivalsApiError)?.message);
    }
  }
);

export const fetchConference = createAsyncThunk(
  ConferenceActions.FETCH_CONFERENCE,
  async (request: FetchConferenceRequest, { rejectWithValue }) => {
    try {
      const response: FetchConferenceResponse = await apiService.fetchConference(
        request
      );
      return response;
    } catch (error) {
      return rejectWithValue((error as RivalsApiError)?.message);
    }
  }
);
