import {
  PEOPLE_FILTERS_TYPES,
  PeopleFiltersType
} from "rivals/components/pages/TransferTracker/types";
import { ListAction, ListActions } from "rivals/redux/list/actions";
import { College } from "rivals/shared/interfaces/College";
import { PeopleList, Version } from "rivals/shared/interfaces/List";
import { List } from "rivals/shared/interfaces/adminLists";

export type State = {
  availableYears: { basketball?: number[]; football?: number[] };
  collegeFocus?: College;
  error?: string;
  list?: List;
  people: PeopleList;
  peopleSuccess?: boolean;
  submitting: boolean;
  success?: boolean;
  totalPeople: number;
  validFilters: PeopleFiltersType;
  versions?: Version[];
};

const initialState = {
  availableYears: { basketball: [], football: [] },
  list: undefined,
  people: [],
  peopleSuccess: false,
  submitting: false,
  success: false,
  totalPeople: 0,
  validFilters: {
    [PEOPLE_FILTERS_TYPES.ATHLETIC_YEAR]: "",
    [PEOPLE_FILTERS_TYPES.ORIGIN_COLLEGE]: "",
    [PEOPLE_FILTERS_TYPES.EXIT_COLLEGE]: "",
    [PEOPLE_FILTERS_TYPES.TRANSFER_POSITION]: ""
  },
  versions: undefined
};

export default function reducer(
  state: State = initialState,
  action: ListAction
): State {
  switch (action.type) {
    case ListActions.FETCH_LIST_DATA:
      return {
        ...state,
        submitting: true,
        success: false
      };
    case ListActions.GET_CONTENT_LIST_PEOPLE:
      return {
        ...state,
        peopleSuccess: false,
        submitting: true
      };

    case ListActions.FETCH_LIST_DATA_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        people: [],
        submitting: false,
        success: false,
        totalPeople: 0
      };
    case ListActions.GET_CONTENT_LIST_PEOPLE_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        people: [],
        peopleSuccess: false,
        submitting: false,
        totalPeople: 0
      };

    case ListActions.FETCH_LIST_DATA_SUCCESS:
      return {
        ...state,
        availableYears: action.payload.availableYears,
        list: action.payload.list,
        submitting: false,
        success: true,
        versions: action.payload.versions
      };
    case ListActions.GET_CONTENT_LIST_PEOPLE_SUCCESS:
      return {
        ...state,
        collegeFocus: action.payload.response.college,
        people: action.payload.resetting
          ? action.payload.response.people
          : state.people.concat(action.payload.response.people),
        peopleSuccess: true,
        submitting: false,
        totalPeople: action.payload.response.total,
        validFilters: action.payload.response.validFilters
      };

    default:
      return state;
  }
}
