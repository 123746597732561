import { all, call, getContext, put, takeLatest } from "redux-saga/effects";
import API from "rivals/services";
import { Response as getCareerHighlightsResponse } from "rivals/services/getCareerHighlights/types";
import { Response as getProspectCollegeInterestsResponse } from "rivals/services/getProspectCollegeInterests/types";
import { Response as getProspectForecastGraphResponse } from "rivals/services/getProspectForecastGraph/types";
import { Response as getProspectForecastGraphListResponse } from "rivals/services/getProspectForecastGraphList/types";
import { Response as getProspectForecastTableResponse } from "rivals/services/getProspectForecastTable/types";
import { Response as getProspectNewsResponse } from "rivals/services/getProspectNews/types";
import { Response as getProspectRankingGraphResponse } from "rivals/services/getProspectRankingGraph/types";
import { Response as getProspectTopForecastsResponse } from "rivals/services/getProspectTopForecasts/types";
import {
  getCareerHighlights as getCareerHighlightsAction,
  getCareerHighlightsFailure,
  getCareerHighlightsSuccess,
  getProspectCollegeInterests as getProspectCollegeInterestsAction,
  getProspectCollegeInterestsFailure,
  getProspectCollegeInterestsSuccess,
  getProspectForecastGraph as getProspectForecastGraphAction,
  getProspectForecastGraphFailure,
  getProspectForecastGraphList as getProspectForecastGraphListAction,
  getProspectForecastGraphListFailure,
  getProspectForecastGraphListSuccess,
  getProspectForecastGraphSuccess,
  getProspectForecastTable as getProspectForecastTableAction,
  getProspectForecastTableFailure,
  getProspectForecastTableSuccess,
  getProspectNews as getProspectNewsAction,
  getProspectNewsFailure,
  getProspectNewsSuccess,
  getProspectRankingGraph as getProspectRankingGraphAction,
  getProspectRankingGraphFailure,
  getProspectRankingGraphSuccess,
  getProspectTopForecasts as getProspectTopForecastsAction,
  getProspectTopForecastsFailure,
  getProspectTopForecastsSuccess,
  ProspectActions
} from "./actions";

export function* getCareerHighlights(
  action: ReturnType<typeof getCareerHighlightsAction>
): unknown {
  try {
    const api: API = yield getContext("api");
    const response: getCareerHighlightsResponse = yield call(
      api.getCareerHighlights,
      action.payload
    );
    yield put(getCareerHighlightsSuccess(response.events));
  } catch (error) {
    yield put(getCareerHighlightsFailure((<Error>error)?.message));
  }
}

export function* getProspectCollegeInterests(
  action: ReturnType<typeof getProspectCollegeInterestsAction>
): unknown {
  try {
    const api: API = yield getContext("api");
    const response: getProspectCollegeInterestsResponse = yield call(
      api.getProspectCollegeInterests,
      action.payload
    );
    yield put(getProspectCollegeInterestsSuccess(response));
  } catch (error) {
    yield put(getProspectCollegeInterestsFailure((<Error>error)?.message));
  }
}

export function* getProspectForecastTable(
  action: ReturnType<typeof getProspectForecastTableAction>
): unknown {
  try {
    const api: API = yield getContext("api");
    const response: getProspectForecastTableResponse = yield call(
      api.getProspectForecastTable,
      action.payload
    );
    yield put(getProspectForecastTableSuccess(response.data));
  } catch (error) {
    yield put(getProspectForecastTableFailure((<Error>error)?.message));
  }
}

export function* getProspectForecastGraph(
  action: ReturnType<typeof getProspectForecastGraphAction>
): unknown {
  try {
    const api: API = yield getContext("api");
    const response: getProspectForecastGraphResponse = yield call(
      api.getProspectForecastGraph,
      action.payload
    );
    yield put(getProspectForecastGraphSuccess(response.forecastGraphData));
  } catch (error) {
    yield put(getProspectForecastGraphFailure((<Error>error)?.message));
  }
}

export function* getProspectForecastGraphList(
  action: ReturnType<typeof getProspectForecastGraphListAction>
): unknown {
  try {
    const api: API = yield getContext("api");
    const response: getProspectForecastGraphListResponse = yield call(
      api.getProspectForecastGraphList,
      action.payload
    );
    yield put(getProspectForecastGraphListSuccess(response.forecastGraphList));
  } catch (error) {
    yield put(getProspectForecastGraphListFailure((<Error>error)?.message));
  }
}

export function* getProspectNews(
  action: ReturnType<typeof getProspectNewsAction>
): unknown {
  try {
    const api: API = yield getContext("api");
    const response: getProspectNewsResponse = yield call(
      api.getProspectNews,
      action.payload
    );
    yield put(getProspectNewsSuccess(response));
  } catch (error) {
    yield put(getProspectNewsFailure((<Error>error)?.message));
  }
}

export function* getProspectTopForecasts(
  action: ReturnType<typeof getProspectTopForecastsAction>
): unknown {
  try {
    const api: API = yield getContext("api");
    const response: getProspectTopForecastsResponse = yield call(
      api.getProspectTopForecasts,
      action.payload
    );
    yield put(getProspectTopForecastsSuccess(response.topForecasts));
  } catch (error) {
    yield put(getProspectTopForecastsFailure((<Error>error)?.message));
  }
}
export function* getProspectRankingGraph(
  action: ReturnType<typeof getProspectRankingGraphAction>
): unknown {
  try {
    const api: API = yield getContext("api");
    const response: getProspectRankingGraphResponse = yield call(
      api.getProspectRankingGraph,
      action.payload
    );
    yield put(getProspectRankingGraphSuccess(response.rankingGraphData));
  } catch (error) {
    yield put(getProspectRankingGraphFailure((<Error>error)?.message));
  }
}

export default function* saga(): Generator {
  yield all([
    takeLatest(ProspectActions.GET_CAREER_HIGHLIGHTS, getCareerHighlights)
  ]);
  yield all([
    takeLatest(
      ProspectActions.GET_PROSPECT_COLLEGE_INTERESTS,
      getProspectCollegeInterests
    )
  ]);
  yield all([takeLatest(ProspectActions.GET_PROSPECT_NEWS, getProspectNews)]);
  yield all([
    takeLatest(
      ProspectActions.GET_PROSPECT_FORECAST_TABLE,
      getProspectForecastTable
    )
  ]);
  yield all([
    takeLatest(
      ProspectActions.GET_PROSPECT_FORECAST_GRAPH,
      getProspectForecastGraph
    )
  ]);
  yield all([
    takeLatest(
      ProspectActions.GET_PROSPECT_FORECAST_GRAPH_LIST,
      getProspectForecastGraphList
    )
  ]);
  yield all([
    takeLatest(
      ProspectActions.GET_PROSPECT_TOP_FORECASTS,
      getProspectTopForecasts
    )
  ]);
  yield all([
    takeLatest(
      ProspectActions.GET_PROSPECT_RANKING_GRAPH,
      getProspectRankingGraph
    )
  ]);
}
