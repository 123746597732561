import { createSlice } from "@reduxjs/toolkit";

import { Response as GetTeamRankingsYearsResponse } from "rivals/services/getTeamRankingsYears/types";
import { TeamRanking } from "rivals/shared/interfaces/TeamRanking";
import { getTeamRankings, getTeamRankingsYears } from "./actions";

export type State = {
  teamRankings: {
    data?: TeamRanking[];
    error?: string;
    submitting: boolean;
  };
  years: {
    data?: GetTeamRankingsYearsResponse;
    error?: string;
    submitting: boolean;
  };
};

const initialState: State = {
  teamRankings: {
    data: undefined,
    error: undefined,
    submitting: false
  },
  years: {
    data: undefined,
    error: undefined,
    submitting: false
  }
};

const teamRankingsSlice = createSlice({
  extraReducers: builder => {
    builder.addCase(getTeamRankings.pending, state => {
      state.teamRankings.submitting = true;
    });

    builder.addCase(getTeamRankings.fulfilled, ({ teamRankings }, action) => {
      teamRankings.data = action.payload.teamRankings;
      teamRankings.submitting = false;
    });

    builder.addCase(getTeamRankings.rejected, ({ teamRankings }, action) => {
      teamRankings.error = (action.payload as string) || action.error.message;
      teamRankings.submitting = false;
    });

    builder.addCase(getTeamRankingsYears.pending, ({ years }) => {
      years.submitting = true;
    });

    builder.addCase(getTeamRankingsYears.fulfilled, ({ years }, action) => {
      years.data = action.payload;
      years.submitting = false;
    });

    builder.addCase(getTeamRankingsYears.rejected, ({ years }, action) => {
      years.error = (action.payload as string) || action.error.message;
      years.submitting = false;
    });
  },
  initialState,
  name: "teamRankings",
  reducers: {}
});

export default teamRankingsSlice.reducer;
