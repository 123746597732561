import { all, call, getContext, put, takeLatest } from "redux-saga/effects";
import { showAlert } from "rivals/redux/alert/actions";
import { AlertTypes } from "rivals/redux/alert/reducer";
import API from "rivals/services";
import {
  FetchGoogleAnalyticsReportsResponse,
  ListAddOnReportsResponse
} from "rivals/services/container";
import { Response as getCsAdminReportsResponse } from "rivals/services/getCsAdminReports/types";
import {
  AdminReportsActions,
  fetchGoogleAnalyticsReportsFailure,
  fetchGoogleAnalyticsReportsSuccess,
  generateAddOnReport as generateAddOnReportAction,
  generateAddOnReportFailure,
  generateAddOnReportSuccess,
  generateGoogleAnalyticsReport as generateGoogleAnalyticsReportAction,
  generateGoogleAnalyticsReportFailure,
  generateGoogleAnalyticsReportSuccess,
  getAddOnReportsListFailure,
  getAddOnReportsListSuccess,
  getCsAdminReportsFailure,
  getCsAdminReportsSuccess,
  updateAnalyticsMonitoredSites as updateAnalyticsMonitoredSitesAction,
  updateAnalyticsMonitoredSitesFailure,
  updateAnalyticsMonitoredSitesSuccess,
  updateCsAdminPermissions as updateCsAdminPermissionsAction,
  updateCsAdminPermissionsFailure,
  updateCsAdminPermissionsSuccess
} from "./actions";

export function* getCsAdminReports(): unknown {
  try {
    const api: API = yield getContext("api");
    const response: getCsAdminReportsResponse = yield call(
      api.getCsAdminReports
    );
    yield put(getCsAdminReportsSuccess(response.users));
  } catch (error) {
    yield put(getCsAdminReportsFailure((<Error>error)?.message));
  }
}

export function* updateCsAdminPermissions(
  action: ReturnType<typeof updateCsAdminPermissionsAction>
): unknown {
  try {
    const api: API = yield getContext("api");
    const response: getCsAdminReportsResponse = yield call(
      api.updateCsAdminPermissions,
      action.payload
    );
    yield put(updateCsAdminPermissionsSuccess(response.users));
    yield put(
      showAlert({
        durationInSeconds: 5,
        message: "Expiration date has been updated successfully.",
        persisted: false,
        type: AlertTypes.SUCCESS
      })
    );
  } catch (error) {
    yield put(updateCsAdminPermissionsFailure((<Error>error)?.message));
    yield put(
      showAlert({
        durationInSeconds: 5,
        message: (<Error>error)?.message,
        persisted: false,
        type: AlertTypes.SEVERE
      })
    );
  }
}

export function* generateAddOnReport(
  action: ReturnType<typeof generateAddOnReportAction>
): unknown {
  try {
    const api: API = yield getContext("api");
    yield call(api.generateAddOnReport, action.payload);
    yield put(generateAddOnReportSuccess());
    yield put(
      showAlert({
        message:
          "Request to generate report has been received, you will receive an email notification once it's complete",
        persisted: false,
        type: AlertTypes.SUCCESS
      })
    );
  } catch (error) {
    yield put(generateAddOnReportFailure((<Error>error)?.message));
  }
}

export function* generateGoogleAnalyticsReport(
  action: ReturnType<typeof generateGoogleAnalyticsReportAction>
): unknown {
  try {
    const api: API = yield getContext("api");
    yield call(api.generateGoogleAnalyticsReport, action.payload);
    yield put(generateGoogleAnalyticsReportSuccess());
    yield put(
      showAlert({
        durationInSeconds: 10,
        message:
          "Request to generate report has been received, you will receive an email notification once it's complete.",
        persisted: false,
        type: AlertTypes.SUCCESS
      })
    );
  } catch (error) {
    yield put(generateGoogleAnalyticsReportFailure((<Error>error)?.message));
    yield put(
      showAlert({
        durationInSeconds: 10,
        message: "Request to generate report failed, try again later.",
        persisted: false,
        type: AlertTypes.SEVERE
      })
    );
  }
}
export function* getAddOnReportsList(): unknown {
  try {
    const api: API = yield getContext("api");
    const response: ListAddOnReportsResponse = yield call(api.listAddOnReports);
    yield put(getAddOnReportsListSuccess(response));
  } catch (error) {
    yield put(getAddOnReportsListFailure((<Error>error)?.message));
  }
}

export function* fetchGoogleAnalyticsReports(): unknown {
  try {
    const api: API = yield getContext("api");
    const response: FetchGoogleAnalyticsReportsResponse = yield call(
      api.fetchGoogleAnalyticsReports
    );
    yield put(fetchGoogleAnalyticsReportsSuccess(response));
  } catch (error) {
    yield put(fetchGoogleAnalyticsReportsFailure((<Error>error)?.message));
  }
}

export function* updateAnalyticsMonitoredSites(
  action: ReturnType<typeof updateAnalyticsMonitoredSitesAction>
): unknown {
  try {
    const api: API = yield getContext("api");
    yield call(api.updateAnalyticsMonitoredSites, action.payload);
    yield put(updateAnalyticsMonitoredSitesSuccess());
    yield put(
      showAlert({
        durationInSeconds: 10,
        message:
          "Successful update, next activity monitoring report will reflect the changes you made.",
        persisted: false,
        type: AlertTypes.SUCCESS
      })
    );
  } catch (error) {
    yield put(updateAnalyticsMonitoredSitesFailure((<Error>error)?.message));
    yield put(
      showAlert({
        durationInSeconds: 10,
        message: "Failed to update, try again later.",
        persisted: false,
        type: AlertTypes.SEVERE
      })
    );
  }
}

export default function* saga(): Generator {
  yield all([
    takeLatest(AdminReportsActions.GET_CS_ADMIN_REPORTS, getCsAdminReports),
    takeLatest(
      AdminReportsActions.GET_ADD_ON_REPORTS_LIST,
      getAddOnReportsList
    ),
    takeLatest(
      AdminReportsActions.UPDATE_CS_ADMIN_PERMISSIONS,
      updateCsAdminPermissions
    ),
    takeLatest(AdminReportsActions.GENERATE_ADD_ON_REPORT, generateAddOnReport),
    takeLatest(
      AdminReportsActions.GENERATE_GOOGLE_ANALYITCS_REPORT,
      generateGoogleAnalyticsReport
    ),
    takeLatest(
      AdminReportsActions.FETCH_GOOGLE_ANALYTICS_REPORTS,
      fetchGoogleAnalyticsReports
    ),
    takeLatest(
      AdminReportsActions.UPDATE_ANALYTICS_MONITORED_SITES,
      updateAnalyticsMonitoredSites
    )
  ]);
}
