import { all, call, getContext, put, takeLatest } from "redux-saga/effects";
import API from "rivals/services";
import { Response as getGalleryResponse } from "rivals/services/getGallery/types";
import {
  ContentActions,
  getGallery as getGalleryAction,
  getGalleryFailure,
  getGallerySuccess
} from "./actions";

export function* getGallery(
  action: ReturnType<typeof getGalleryAction>
): unknown {
  try {
    const api: API = yield getContext("api");
    const response: getGalleryResponse = yield call(
      api.getGallery,
      action.payload
    );
    yield put(getGallerySuccess(response.gallery));
  } catch (error) {
    yield put(getGalleryFailure((<Error>error)?.message));
  }
}

export default function* saga(): Generator {
  yield all([takeLatest(ContentActions.GET_GALLERY, getGallery)]);
}
