import {
  all,
  call,
  getContext,
  put,
  takeLatest,
  throttle
} from "redux-saga/effects";
import { clearAlerts, showAlert } from "rivals/redux/alert/actions";
import { AlertTypes } from "rivals/redux/alert/reducer";
import { resetPayPal } from "rivals/redux/user/actions";
import API from "rivals/services";
import { Response as GetGiftTaxResponse } from "rivals/services/getGiftTax/types";
import {
  CheckGiftTokenResponse,
  CreateAccountResponse,
  GetPublisherLetterResponse,
  GiftSubscriptionResponse
} from "rivals/services/registration/types";
import { PATHS } from "rivals/shared/constants";
import RivalsApiError from "rivals/shared/error";
import {
  checkGiftToken as checkGiftTokenAction,
  checkGiftTokenFailure,
  checkGiftTokenSuccess,
  createAccount as createAccountAction,
  createAccountFailure,
  createAccountPaymentFailure,
  createAccountSuccess,
  getGiftTax as getGiftTaxAction,
  getGiftTaxFailure,
  getGiftTaxSuccess,
  getPublisherLetter as getPublisherLetterAction,
  getPublisherLetterFailure,
  getPublisherLetterSuccess,
  giftSubscription as giftSubscriptionAction,
  giftSubscriptionFailure,
  giftSubscriptionSuccess,
  redeemGift as redeemGiftAction,
  redeemGiftFailure,
  redeemGiftSuccess,
  RegistrationActions
} from "./actions";

const GET_GIFT_TAX_THROTTLE_MILLISECONDS = 2000;

export function* checkGiftToken(
  action: ReturnType<typeof checkGiftTokenAction>
): unknown {
  try {
    const api: API = yield getContext("api");
    const response: CheckGiftTokenResponse = yield call(
      api.checkGiftToken,
      action.payload
    );
    yield put(checkGiftTokenSuccess(response));
  } catch (error) {
    yield put(checkGiftTokenFailure((<Error>error)?.message));
  }
}

export function* createAccount(
  action: ReturnType<typeof createAccountAction>
): unknown {
  try {
    yield put(clearAlerts());
    const api: API = yield getContext("api");
    const response: CreateAccountResponse = yield call(
      api.createAccount,
      action.payload
    );
    // registration form errors can be nested arrays, so handle as a response until comprehensive errors are implemented
    if (response.errors) {
      if (response.user && response.user.userId) {
        yield put(createAccountPaymentFailure(response));
      } else {
        yield put(createAccountFailure(response));
      }
      yield put(
        showAlert({
          message: Object.values(response.errors).join(),
          persisted: false,
          type: AlertTypes.SEVERE
        })
      );
    } else {
      yield put(createAccountSuccess(response));
      yield put(resetPayPal());
    }
  } catch (error) {
    // this should not get triggered. Errors are handled as a response for this endpoint
    if (
      typeof error === "object" &&
      error !== null &&
      ("errors" in error || "user" in error)
    ) {
      yield put(createAccountFailure(error as CreateAccountResponse));
    } else {
      yield put(createAccountFailure((<Error>error)?.message));
    }
  }
}

// TODO: https://griosf.atlassian.net/browse/RVLS-2792
export function* getGiftTax(
  action: ReturnType<typeof getGiftTaxAction>
): unknown {
  try {
    const api: API = yield getContext("api");
    const response: GetGiftTaxResponse = yield call(
      api.getGiftTax,
      action.payload
    );
    yield put(
      getGiftTaxSuccess(
        response.totalTax,
        response.totalPrice,
        response.priceBeforeTax
      )
    );
  } catch (error) {
    yield put(getGiftTaxFailure((<Error>error)?.message));
  }
}

export function* getPublisherLetter(
  action: ReturnType<typeof getPublisherLetterAction>
): unknown {
  try {
    const api: API = yield getContext("api");
    const response: GetPublisherLetterResponse = yield call(
      api.getPublisherLetter,
      action.payload
    );
    yield put(getPublisherLetterSuccess(response));
  } catch (error) {
    yield put(getPublisherLetterFailure());
  }
}

// TODO: https://griosf.atlassian.net/browse/RVLS-2792
export function* giftSubscription(
  action: ReturnType<typeof giftSubscriptionAction>
): unknown {
  try {
    const api: API = yield getContext("api");
    yield call(api.giftSubscription, action.payload);
    yield put(giftSubscriptionSuccess());
    yield put(resetPayPal());
    window.location.assign(PATHS.GIFTING_SUCCESS);
  } catch (error) {
    yield put(giftSubscriptionFailure());
    if (error instanceof RivalsApiError) {
      yield put(
        showAlert({
          message: error.message,
          persisted: false,
          type: AlertTypes.SEVERE
        })
      );
    }
  }
}

// TODO: https://griosf.atlassian.net/browse/RVLS-2792
export function* redeemGift(
  action: ReturnType<typeof redeemGiftAction>
): unknown {
  try {
    const api: API = yield getContext("api");
    const response: GiftSubscriptionResponse = yield call(
      api.redeemGift,
      action.payload
    );
    yield put(redeemGiftSuccess(response));
    window.location.assign(PATHS.LANDING_PAGE);
  } catch (error) {
    yield put(redeemGiftFailure((<Error>error)?.message));
    if (error instanceof RivalsApiError) {
      yield put(
        showAlert({
          message: error.message,
          persisted: false,
          type: AlertTypes.SEVERE
        })
      );
    }
  }
}

export default function* saga(): Generator {
  yield all([
    takeLatest(RegistrationActions.CHECK_GIFT_TOKEN, checkGiftToken),
    takeLatest(RegistrationActions.CREATE_ACCOUNT, createAccount),
    takeLatest(RegistrationActions.GIFT_SUBSCRIPTION, giftSubscription),
    takeLatest(RegistrationActions.REDEEM_GIFT, redeemGift),
    takeLatest(RegistrationActions.GET_PUBLISHER_LETTER, getPublisherLetter),
    throttle(
      GET_GIFT_TAX_THROTTLE_MILLISECONDS,
      RegistrationActions.GET_GIFT_TAX,
      getGiftTax
    )
  ]);
}
