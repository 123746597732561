import { all, call, getContext, put, takeLatest } from "redux-saga/effects";
import { showAlert } from "rivals/redux/alert/actions";
import { AlertTypes } from "rivals/redux/alert/reducer";
import API from "rivals/services";
import {
  AdminAthleteActions,
  deleteCollegePlayer as deleteCollegePlayerAction,
  deleteCollegePlayerFailure,
  deleteCollegePlayerSuccess,
  getAdminCollegePlayer as getAdminCollegePlayerAction,
  getAdminCollegePlayerFailure,
  getAdminCollegePlayerSuccess,
  getYahooSyncData as getYahooSyncDataAction,
  getYahooSyncDataFailure,
  getYahooSyncDataSuccess,
  linkYahooCollegeProfile as linkYahooCollegeProfileAction,
  linkYahooCollegeProfileFailure,
  linkYahooCollegeProfileSuccess,
  syncYahooCollegeProfile as syncYahooCollegeProfileAction,
  syncYahooCollegeProfileFailure,
  syncYahooCollegeProfileSuccess,
  updateCollegeProfile as updateCollegeProfileAction,
  updateCollegeProfileFailure,
  updateCollegeProfileSuccess,
  yahooProfileSearch as yahooProfileSearchAction,
  yahooProfileSearchFailure,
  yahooProfileSearchSuccess
} from "./actions";

export function* deleteCollegePlayer(
  action: ReturnType<typeof deleteCollegePlayerAction>
): unknown {
  try {
    const api: API = yield getContext("api");
    yield call(api.deleteCollegePlayer, action.payload);
    yield put(deleteCollegePlayerSuccess());
  } catch (error) {
    yield put(deleteCollegePlayerFailure((<Error>error)?.message));
  }
}

export function* getAdminCollegePlayer(
  action: ReturnType<typeof getAdminCollegePlayerAction>
): unknown {
  try {
    const api: API = yield getContext("api");
    const response = yield call(api.getAdminCollegePlayer, {
      personId: action.payload.personId,
      type: action.payload.type
    });
    yield put(getAdminCollegePlayerSuccess(response.collegePlayer));
  } catch (error) {
    yield put(getAdminCollegePlayerFailure((<Error>error)?.message));
  }
}

export function* getYahooSyncData(
  action: ReturnType<typeof getYahooSyncDataAction>
): unknown {
  try {
    const api: API = yield getContext("api");
    const response = yield call(api.getYahooSyncData, {
      collegePlayerId: action.payload.collegePlayerId
    });
    yield put(getYahooSyncDataSuccess(response));
  } catch (error) {
    yield put(getYahooSyncDataFailure((<Error>error)?.message));
  }
}

export function* linkYahooCollegeProfile(
  action: ReturnType<typeof linkYahooCollegeProfileAction>
): unknown {
  try {
    const api: API = yield getContext("api");
    const response = yield call(api.linkYahooCollegePlayer, {
      personId: action.payload.personId,
      yahooPlayerId: action.payload.yahooPlayerId
    });
    yield put(linkYahooCollegeProfileSuccess(response.collegePlayer));
    yield put(
      showAlert({
        durationInSeconds: 5,
        message:
          "College profile has been created and linked to Yahoo! databases.",
        persisted: false,
        type: AlertTypes.SUCCESS
      })
    );
  } catch (error) {
    yield put(linkYahooCollegeProfileFailure((<Error>error)?.message));
  }
}

export function* syncYahooCollegeProfile(
  action: ReturnType<typeof syncYahooCollegeProfileAction>
): unknown {
  try {
    const api: API = yield getContext("api");
    const response = yield call(api.syncYahooCollegePlayer, {
      personId: action.payload.personId
    });
    yield put(syncYahooCollegeProfileSuccess(response.collegePlayer));
    yield put(
      showAlert({
        durationInSeconds: 5,
        message: "College profile has been synced with Yahoo! databases.",
        persisted: false,
        type: AlertTypes.SUCCESS
      })
    );
  } catch (error) {
    yield put(syncYahooCollegeProfileFailure((<Error>error)?.message));
  }
}

export function* updateCollegeProfile(
  action: ReturnType<typeof updateCollegeProfileAction>
): unknown {
  try {
    const api: API = yield getContext("api");
    const response = yield call(api.updateCollegePlayer, action.payload.player);
    yield put(updateCollegeProfileSuccess(response.collegePlayer));
    yield put(
      showAlert({
        durationInSeconds: 5,
        message: "College profile has been updated successfully.",
        persisted: false,
        type: AlertTypes.SUCCESS
      })
    );
  } catch (error) {
    yield put(updateCollegeProfileFailure((<Error>error)?.message));
  }
}

export function* yahooProfileSearch(
  action: ReturnType<typeof yahooProfileSearchAction>
): unknown {
  try {
    const api: API = yield getContext("api");
    const response = yield call(api.yahooProfileSearch, action.payload);
    yield put(yahooProfileSearchSuccess(response.matchData));
  } catch (error) {
    yield put(yahooProfileSearchFailure((<Error>error)?.message));
  }
}

export default function* saga(): Generator {
  yield all([
    takeLatest(AdminAthleteActions.DELETE_COLLEGE_PLAYER, deleteCollegePlayer),
    takeLatest(
      AdminAthleteActions.GET_ADMIN_COLLEGE_PLAYER,
      getAdminCollegePlayer
    ),
    takeLatest(AdminAthleteActions.GET_YAHOO_SYNC_DATA, getYahooSyncData),
    takeLatest(
      AdminAthleteActions.LINK_YAHOO_COLLEGE_PROFILE,
      linkYahooCollegeProfile
    ),
    takeLatest(
      AdminAthleteActions.SYNC_YAHOO_COLLEGE_PROFILE,
      syncYahooCollegeProfile
    ),
    takeLatest(
      AdminAthleteActions.UPDATE_COLLEGE_PROFILE,
      updateCollegeProfile
    ),
    takeLatest(AdminAthleteActions.YAHOO_PROFILE_SEARCH, yahooProfileSearch)
  ]);
}
