import each from "lodash/each";

import { GiftPricing } from "rivals/components/pages/User/Subscriptions/types";
import {
  GiftInfo,
  RegistrationParams
} from "rivals/services/registration/types";
import { AddOns } from "rivals/shared/interfaces/AddOn";
import { PublisherLetter } from "rivals/shared/interfaces/Site";
import { RegistrationAction, RegistrationActions } from "./actions";

export interface State {
  error?: string;
  giftInfo?: GiftInfo;
  giftPricing?: GiftPricing;
  giftPricingError?: string;
  giftSubdomain?: string;
  giftToken?: string;
  isAdLiteAddedToSub?: boolean;
  publisherLetter?: PublisherLetter | null;
  registrationParams?: RegistrationParams;
  selectedAddOns: {
    [k in AddOns]: boolean;
  };
  submitting: boolean;
}

const initialState = {
  error: undefined,
  giftPricing: undefined,
  giftSubdomain: undefined,
  giftToken: undefined,
  isAdLiteAddedToSub: undefined,
  publisherLetter: undefined,
  registerParams: undefined,
  selectedAddOns: {
    [AddOns.AD_LITE]: false,
    [AddOns.ALL_ACCESS]: false
  },
  submitting: false
};

export default function reducer(
  state: State = initialState,
  action: RegistrationAction
): State {
  switch (action.type) {
    case RegistrationActions.CHECK_GIFT_TOKEN_FAILURE: {
      return {
        ...state,
        error: action.payload.error,
        giftToken: undefined
      };
    }
    case RegistrationActions.CHECK_GIFT_TOKEN_SUCCESS: {
      return {
        ...state,
        giftInfo: action.payload.giftInfo,
        giftSubdomain: action.payload.subdomain,
        giftToken: action.payload.token
      };
    }
    case RegistrationActions.GET_GIFT_TAX_FAILURE: {
      return {
        ...state,
        giftPricing: undefined,
        giftPricingError: action.payload.error
      };
    }
    case RegistrationActions.GET_GIFT_TAX_SUCCESS: {
      return {
        ...state,
        giftPricing: action.payload,
        giftPricingError: undefined
      };
    }
    case RegistrationActions.GET_PUBLISHER_LETTER: {
      return {
        ...state,
        error: undefined,
        publisherLetter: undefined,
        submitting: true
      };
    }
    case RegistrationActions.GET_PUBLISHER_LETTER_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        publisherLetter: payload.publisherLetter,
        submitting: false
      };
    }
    case RegistrationActions.GET_PUBLISHER_LETTER_FAILURE: {
      return {
        ...state,
        publisherLetter: null,
        submitting: false
      };
    }
    case RegistrationActions.REDEEM_GIFT: {
      return {
        ...state,
        submitting: true
      };
    }
    case RegistrationActions.REDEEM_GIFT_FAILURE:
    case RegistrationActions.REDEEM_GIFT_SUCCESS: {
      return {
        ...state,
        submitting: false
      };
    }
    case RegistrationActions.CREATE_ACCOUNT:
      return {
        ...state,
        registrationParams: action.payload.registration
      };
    case RegistrationActions.SET_SELECTED_ADD_ON: {
      const selectedAddOns = {
        ...state.selectedAddOns
      };

      const { payload } = action;

      // If already selected, deselect
      if (selectedAddOns[payload]) {
        selectedAddOns[payload] = false;
      } else {
        // If we are setting something to true, set all others to false
        each(selectedAddOns, (_value, addOn) => {
          selectedAddOns[addOn as AddOns] = false;
        });
        selectedAddOns[payload] = true;
      }

      return {
        ...state,
        selectedAddOns
      };
    }
    case RegistrationActions.TOGGLE_SUBSCRIPTION_AD_LITE:
      return {
        ...state,
        isAdLiteAddedToSub: !state.isAdLiteAddedToSub
      };
    default: {
      return state;
    }
  }
}
