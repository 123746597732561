import { createSlice } from "@reduxjs/toolkit";
import type { PendingAction } from "rivals/redux/Action";
import type { HighschoolGroup } from "rivals/shared/interfaces/HighschoolGroup";
import { fetchHighschoolGroup, fetchHighschoolGroups } from "./actions";

export type State = {
  error?: string;
  highschoolGroup?: HighschoolGroup;
  highschoolGroups?: HighschoolGroup[];
  highschoolLetterGroups?: HighschoolGroup[];
  submitting: boolean;
  success?: boolean;
};

const initialState: State = {
  submitting: false
};

const highschoolSlice = createSlice({
  extraReducers: builder => {
    builder
      .addCase(fetchHighschoolGroup.fulfilled, (state, action) => {
        state.submitting = false;
        state.success = true;
        state.highschoolGroup = action.payload.highschoolGroup;
      })
      .addCase(fetchHighschoolGroups.fulfilled, (state, action) => {
        state.submitting = false;
        state.success = true;
        state.highschoolGroups = action.payload.highschoolGroups;
        state.highschoolLetterGroups = action.payload.highschoolLetterGroups;
      })
      .addCase(fetchHighschoolGroup.rejected, (state, action) => {
        state.error = (action.payload as string) || action?.error?.message;
        state.submitting = false;
        state.success = false;
        state.highschoolGroup = undefined;
      })
      .addCase(fetchHighschoolGroups.rejected, (state, action) => {
        state.error = (action.payload as string) || action?.error?.message;
        state.submitting = false;
        state.success = false;
        state.highschoolGroups = undefined;
        state.highschoolLetterGroups = undefined;
      })
      .addMatcher<PendingAction>(
        action =>
          [
            fetchHighschoolGroup.pending.toString(),
            fetchHighschoolGroups.pending.toString()
          ].includes(action.type),
        state => {
          state.submitting = true;
          state.success = false;
        }
      );
  },
  initialState,
  name: "highschool",
  reducers: {}
});

export default highschoolSlice.reducer;
