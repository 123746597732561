import { ExtraInfo } from "rivals/services/error";
import { Response } from "rivals/services/loginUser/types";

export enum AuthActions {
  LOGIN_USER = "LOGIN_USER",
  LOGIN_USER_FAILURE = "LOGIN_USER_FAILURE",
  LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS",
  LOGOUT_USER = "LOGOUT_USER",
  LOGOUT_USER_FAILURE = "LOGOUT_USER_FAILURE",
  LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS",
  SET_AUTHENTICATION = "SET_AUTHENTICATION"
}

//Types should be corrected when we handle ticket https://griosf.atlassian.net/browse/RVLS-2152
export const loginUser = (
  login?: string,
  password?: string,
  captchaToken?: string | null
) =>
  ({
    payload: {
      captchaToken,
      login,
      password
    },
    type: AuthActions.LOGIN_USER
  } as const);

export const loginUserFailure = (error?: string, extraInfo?: ExtraInfo) =>
  ({
    error: true,
    payload: {
      error,
      extraInfo
    },
    type: AuthActions.LOGIN_USER_FAILURE
  } as const);

export const loginUserSuccess = (response: Response) =>
  ({ payload: response, type: AuthActions.LOGIN_USER_SUCCESS } as const);

export const logoutUser = () => ({ type: AuthActions.LOGOUT_USER } as const);

export const logoutUserFailure = (error?: string, extraInfo?: ExtraInfo) =>
  ({
    error: true,
    payload: { error, extraInfo },
    type: AuthActions.LOGOUT_USER_FAILURE
  } as const);

export const logoutUserSuccess = () =>
  ({ type: AuthActions.LOGOUT_USER_SUCCESS } as const);

export const setAuthentication = (isAuthenticated = true) =>
  ({
    payload: { authenticated: isAuthenticated },
    type: AuthActions.SET_AUTHENTICATION
  } as const);

export type AuthAction =
  | ReturnType<typeof loginUser>
  | ReturnType<typeof loginUserFailure>
  | ReturnType<typeof loginUserSuccess>
  | ReturnType<typeof logoutUser>
  | ReturnType<typeof logoutUserFailure>
  | ReturnType<typeof logoutUserSuccess>
  | ReturnType<typeof setAuthentication>;
