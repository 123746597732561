import { createAsyncThunk } from "@reduxjs/toolkit";

import { showAlert } from "rivals/redux/alert/actions";
import { AlertTypes } from "rivals/redux/alert/reducer";
import { apiService } from "rivals/redux/store";
import {
  GetCoachesRequest,
  GetCoachesResponse
} from "rivals/services/container";

enum AdminCoachesActions {
  GET_COACHES = "adminCoaches/getCoaches"
}

export const getCoaches = createAsyncThunk(
  AdminCoachesActions.GET_COACHES,
  async (request: GetCoachesRequest, { dispatch, rejectWithValue }) => {
    try {
      const response: GetCoachesResponse = await apiService.getCoaches(request);
      return response;
    } catch (error) {
      dispatch(
        showAlert({
          message: (error as Error)?.message,
          persisted: false,
          type: AlertTypes.SEVERE
        })
      );
      return rejectWithValue((<Error>error)?.message);
    }
  }
);
