import moment from "moment";
import { Transaction } from "rivals/components/pages/User/BillingHistory/types";
import { Response as ProspectTrackingHistory } from "rivals/services/prospectTrackingHistory/types";
import {
  Forum,
  Response as UserForumInfo
} from "rivals/services/userForumInfo/types";
import { Response as UserHistoryTrackingHistory } from "rivals/services/userHistoryTrackingHistory/types";
import { Response as UserTrackingHistory } from "rivals/services/userTrackingHistory/types";
import { Subscription } from "rivals/shared/interfaces/Subscription";
import { Address, User } from "rivals/shared/interfaces/User";
import { AdminUsersAction, AdminUsersActions } from "./actions";

export type State = {
  address?: Address;
  error?: string;
  prospectTrackingHistory?: ProspectTrackingHistory;
  submitting: boolean;
  transactions?: Transaction[];
  upgradingSubscription?: Subscription;
  user?: User;
  userForumInfo?: UserForumInfo;
  userHistoryTrackingHistory?: UserHistoryTrackingHistory;
  userTrackingHistory?: UserTrackingHistory;
};

const initialState = {
  address: undefined,
  error: undefined,
  prospectTrackingHistory: undefined,
  submitting: false,
  transactions: undefined,
  user: undefined,
  userForumInfo: undefined,
  userHistoryTrackingHistory: undefined,
  userTrackingHistory: undefined
};

export default function reducer(
  state: State = initialState,
  action: AdminUsersAction
): State {
  switch (action.type) {
    case AdminUsersActions.GET_ADMIN_UPGRADING_USER_FAILURE:
    case AdminUsersActions.UPGRADE_USER_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        submitting: false
      };

    case AdminUsersActions.GET_ADMIN_UPGRADING_USER_SUCCESS:
      return {
        ...state,
        address: action.payload.response.address,
        upgradingSubscription: action.payload.response.subscription,
        user: action.payload.response.user
      };
    case AdminUsersActions.UPGRADE_USER_SUBSCRIPTION:
      return {
        ...state,
        error: undefined,
        submitting: true
      };
    case AdminUsersActions.UPGRADE_USER_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        submitting: false
      };
    case AdminUsersActions.GET_USER_BILLING_HISTORY_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        submitting: false
      };
    case AdminUsersActions.GET_USER_BILLING_HISTORY_SUCCESS: {
      return {
        ...state,
        transactions: action.payload.userBillingHistory
      };
    }
    case AdminUsersActions.GET_USER_FORUM_INFO: {
      return {
        ...state,
        submitting: true
      };
    }
    case AdminUsersActions.GET_USER_FORUM_INFO_FAILURE: {
      return {
        ...state,
        error: action.payload.error,
        submitting: false
      };
    }
    case AdminUsersActions.GET_USER_FORUM_INFO_SUCCESS: {
      let updatedForums: { [key: string]: Forum } = {};

      const forums = action.payload?.forumsUserData;

      if (forums) {
        for (const key in action.payload.forumsUserData) {
          const obj: Forum = forums[key];

          updatedForums = {
            ...updatedForums,
            [key]: {
              ...obj,
              user: {
                ...obj.user,
                lastActivity: obj?.user?.lastActivity
                  ? moment(obj.user.lastActivity).format("MM/DD/YYYY HH:mm:ss")
                  : "",
                registerDate: obj?.user?.registerDate
                  ? moment(obj.user.registerDate).format("MM/DD/YYYY HH:mm:ss")
                  : ""
              }
            }
          };
        }
      }

      return {
        ...state,
        submitting: false,
        userForumInfo: {
          ...action.payload,
          forumsUserData: updatedForums
        }
      };
    }
    case AdminUsersActions.GET_USER_TRACKING_HISTORY: {
      return {
        ...state,
        submitting: true
      };
    }
    case AdminUsersActions.GET_USER_TRACKING_HISTORY_FAILURE: {
      return {
        ...state,
        error: action.payload.error,
        submitting: false
      };
    }
    case AdminUsersActions.GET_USER_TRACKING_HISTORY_SUCCESS:
      return {
        ...state,
        submitting: false,
        userTrackingHistory: {
          ...action.payload,
          items: action.payload.items
            ? action.payload.items.map(({ createdAt, ...rest }, index) => ({
                createdAt: moment(createdAt).format("MM/DD/YYYY HH:mm:ss"),
                key: index,
                ...rest
              }))
            : []
        }
      };
    case AdminUsersActions.GET_PROSPECT_TRACKING_HISTORY: {
      return {
        ...state,
        submitting: true
      };
    }
    case AdminUsersActions.GET_PROSPECT_TRACKING_HISTORY_FAILURE: {
      return {
        ...state,
        error: action.payload.error,
        submitting: false
      };
    }
    case AdminUsersActions.GET_PROSPECT_TRACKING_HISTORY_SUCCESS:
      return {
        ...state,
        prospectTrackingHistory: {
          ...action.payload,
          items: action.payload.items
            ? action.payload.items.map(({ version, ...rest }, index) => ({
                key: index,
                version: {
                  ...version,
                  createdAt: moment(version?.createdAt).format(
                    "MM/DD/YYYY HH:mm:ss"
                  )
                },
                ...rest
              }))
            : []
        },
        submitting: false
      };
    case AdminUsersActions.GET_USER_HISTORY_TRACKING_HISTORY: {
      return {
        ...state,
        submitting: true
      };
    }
    case AdminUsersActions.GET_USER_HISTORY_TRACKING_HISTORY_FAILURE: {
      return {
        ...state,
        error: action.payload.error,
        submitting: false
      };
    }
    case AdminUsersActions.GET_USER_HISTORY_TRACKING_HISTORY_SUCCESS:
      return {
        ...state,
        submitting: false,
        userHistoryTrackingHistory: {
          ...action.payload,
          items: action.payload.items
            ? action.payload.items.map(({ createdAt, ...rest }, index) => ({
                createdAt: moment(createdAt).format("MM/DD/YYYY HH:mm:ss"),
                key: index,
                ...rest
              }))
            : []
        }
      };
    default:
      return state;
  }
}
