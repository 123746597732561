import { all, call, getContext, put, takeLatest } from "redux-saga/effects";
import { sendGAEvent } from "rivals/redux/ga/actions";
import API from "rivals/services";
import { CUSTOM_EVENTS } from "rivals/shared/analytics/customEvents";
import { Promotion } from "rivals/shared/interfaces/Promotion";
import {
  getActivePromotion as getActivePromotionAction,
  getPromotionFailure,
  getPromotionSuccess,
  PromotionActions,
  validatePromoCode as validatePromoCodeAction
} from "./actions";

// TODO: https://griosf.atlassian.net/browse/RVLS-2792
export function* getActivePromotion(
  action: ReturnType<typeof getActivePromotionAction>
): unknown {
  try {
    const api: API = yield getContext("api");
    const response: Promotion = yield call(
      api.getActivePromotion,
      action.payload
    );
    yield put(getPromotionSuccess(response));
  } catch (error) {
    yield put(getPromotionFailure((<Error>error)?.message));
  }
}

// TODO: https://griosf.atlassian.net/browse/RVLS-2792
export function* validatePromoCode(
  action: ReturnType<typeof validatePromoCodeAction>
): unknown {
  try {
    const api: API = yield getContext("api");
    const response: Promotion = yield call(
      api.validatePromoCode,
      action.payload
    );
    yield put(
      sendGAEvent(CUSTOM_EVENTS.applyPromotion(action.payload.promotion.code))
    );
    yield put(getPromotionSuccess(response));
  } catch (error) {
    yield put(getPromotionFailure((<Error>error)?.message));
  }
}

export default function* saga(): Generator {
  yield all([
    takeLatest(PromotionActions.GET_ACTIVE_PROMOTION, getActivePromotion),
    takeLatest(PromotionActions.VALIDATE_PROMO_CODE, validatePromoCode)
  ]);
}
