import camelCaseKeys from "camelcase-keys";
import Cookies from "js-cookie";
import snakeCase from "lodash/snakeCase";
import snakeCaseKeys from "snakecase-keys";
import { setIsSubmittingToServer } from "rivals/redux/application/actions";
import { globalStore } from "rivals/redux/store";
import {
  BASKETBALL_RECRUITING_SITE_IDENTIFIER,
  CSURF_TOKEN_COOKIE,
  CSURF_TOKEN_HEADER,
  PLANS,
  RIVALS_API_ERROR,
  ROOT_SITE_IDENTIFIER,
  SEARCH_OVERLAY_LIMITS
} from "rivals/shared/constants";
import RivalsApiError from "rivals/shared/error";
import {
  AthletePageParams,
  PersonIdParams
} from "rivals/shared/interfaces/Athlete";
import { ImageTagParams } from "rivals/shared/interfaces/ImageTag";
import { Promotion } from "rivals/shared/interfaces/Promotion";
import { Site } from "rivals/shared/interfaces/Site";
import { ListPeople } from "rivals/shared/interfaces/adminLists";
import { buildPlayerUrlQueryParams } from "rivals/shared/utils/routes";
import {
  apiUrl,
  CACHE_SECONDS_MEDIUM,
  CACHE_SECONDS_SMALL,
  CDN_URL,
  createResponseCodeMessage,
  DEFAULT_HEADERS,
  HTTP_METHODS,
  RESPONSE_CODE_NO_CONTENT,
  RESPONSE_CODE_REDIRECT,
  RESPONSE_CODE_SUCCESS,
  ResponseErrors,
  SERVER_HEADERS,
  stringifyParams,
  UPDATE_HEADERS
} from "./base";
import {
  AddressResponse,
  AdminUpgradeUserSubscriptionRequest,
  APIConfig,
  ArticleSearchResponse,
  BillingHistoryResponse,
  BillingInfoResponse,
  CancelSubscriptionRequest,
  CancelSubscriptionResponse,
  CheckGiftTokenRequest,
  CheckGiftTokenResponse,
  CollegeTransferSearchRequest,
  CollegeTransferSearchResponse,
  CommitmentsRequest,
  CommitmentsResponse,
  CreateAccountRequest,
  CreateAccountResponse,
  CreateCollegeTransferRequest,
  CreateCollegeTransferResponse,
  CreateForecastRequest,
  CreateForecastResponse,
  CreateImageRequest,
  CreateImageResponse,
  CreateSubscriptionRequest,
  CreateSubscriptionResponse,
  DeleteCollegeTransferRequest,
  EmailOfferRequest,
  EmailOfferResponse,
  ExtendMgmSubscriptionRequest,
  ExtendMgmSubscriptionResponse,
  ExtendSubscriptionRequest,
  ExtendSubscriptionResponse,
  FetchConferenceRequest,
  FetchConferenceResponse,
  FetchConferencesRequest,
  FetchConferencesResponse,
  FetchContentRequest,
  FetchContentResponse,
  FetchGoogleAnalyticsReportsResponse,
  FetchHighschoolGroupRequest,
  FetchHighschoolGroupResponse,
  FetchHighschoolGroupsRequest,
  FetchHighschoolGroupsResponse,
  FetchListDataRequest,
  FetchListDataResponse,
  FetchOptions,
  FetchTeamsNavItemsResponse,
  FetchTransfersRequest,
  FetchTransfersResponse,
  ForecastValidationResponse,
  ForgotPasswordRequest,
  ForgotPasswordResponse,
  ForgotUsernameRequest,
  ForgotUsernameResponse,
  GenerateAddOnReportRequest,
  GenerateGoogleAnalyticsReportRequest,
  GetActivePromotionRequest,
  GetAddOnPricingRequest,
  GetAddOnPricingResponse,
  GetAddOnsForSubscriptionRequest,
  GetAddOnsForSubscriptionResponse,
  GetAdminUpgradingUserRequest,
  GetAdminUpgradingUserResponse,
  GetAthleteFeaturedMediaResponse,
  GetAuthorRequest,
  GetAuthorResponse,
  getBetMGMUrlResponse,
  GetCareerHighlightsRequest,
  GetCareerHighlightsResponse,
  GetCCPAFooterResponse,
  GetCloudinarySignatureResponse,
  GetCoachesRequest,
  GetCoachesResponse,
  GetCollegePlayerGameDataResponse,
  GetCollegePlayerRankingDataResponse,
  GetCollegePlayerRequest,
  GetCollegePlayerResponse,
  GetCollegeTransferRequest,
  GetCollegeTransferResponse,
  GetContentListPeopleRequest,
  GetContentListPeopleResponse,
  GetCsAdminReportsResponse,
  GetCurrentUserResponse,
  GetCustomModuleContentRequest,
  GetCustomModuleContentResponse,
  GetDefaultCustomModuleContentRequest,
  GetFlipperResponse,
  GetForecasterListResponse,
  GetForumsFeedsRequest,
  GetForumsFeedsResponse,
  GetGalleryRequest,
  GetGalleryResponse,
  GetGiftTaxRequest,
  GetGiftTaxResponse,
  GetHeadlineNewsContentRequest,
  GetHeadlineNewsContentResponse,
  GetInfiniteScrollContentMetaResponse,
  GetIsCurrentUserValidResponse,
  GetIsProspectFollowedResponse,
  GetListDataRequest,
  GetListDataResponse,
  GetListPeopleRequest,
  GetNotificationSettingsResponse,
  GetPageConfigRequest,
  GetPageConfigResponse,
  GetPositionsRequest,
  GetPositionsResponse,
  GetPromotionBannerRequest,
  GetPromotionBannerResponse,
  getProspectCollegeInterestsResponse,
  GetProspectForecastGraphListResponse,
  GetProspectForecastGraphResponse,
  GetProspectForecastTableResponse,
  GetProspectNewsRequest,
  GetProspectNewsResponse,
  GetProspectRankingGraphResponse,
  GetProspectRankingsListRequest,
  GetProspectRankingsListResponse,
  getProspectResponse,
  GetProspectTopForecastsResponse,
  GetPublisherLetterRequest,
  GetPublisherLetterResponse,
  GetRecentForecastsResponse,
  GetSaveEligibilityRequest,
  GetSaveEligibilityResponse,
  GetScoresAndSchedulesRequest,
  GetScoresAndSchedulesResponse,
  GetSiteRequest,
  GetSiteResponse,
  GetSitesRequest,
  GetSitesResponse,
  GetSponsorRequest,
  GetSponsorResponse,
  GetSubscriptionsResponse,
  GetTaxRequest,
  GetTaxResponse,
  GetTeamRankingsRequest,
  GetTeamRankingsResponse,
  GetTeamRankingsYearsResponse,
  GetTickerEventsRequest,
  GetTickerEventsResponse,
  GetTopForumsSitesResponse,
  GetTopTargetsRequest,
  GetTopTargetsResponse,
  GetUpgradingAddOnsRequest,
  GetUpgradingAddOnsResponse,
  GetUserEligibleAddonsResponse,
  GetYahooSyncDataRequest,
  GetYahooSyncDataResponse,
  GiftSubscriptionRequest,
  GiftSubscriptionResponse,
  LinkYahooCollegePlayerRequest,
  LinkYahooCollegePlayerResponse,
  ListAddOnReportsResponse,
  LoginUserAPIResponse,
  LoginUserRequest,
  LoginUserResponse,
  LogoutUserAPIResponse,
  NavOptions,
  PayOutstandingBalanceResponse,
  PayPalBillingAgreementRequest,
  PayPalBillingAgreementResponse,
  PersonSearchRequest,
  PredictTextSearchResponse,
  ProspectSearchResponse,
  ProspectTrackingHistoryRequest,
  PurchaseAddOnRequest,
  PurchaseAddOnResponse,
  RailsConstantsResponse,
  ReactivateSubscriptionRequest,
  ReactivateSubscriptionResponse,
  RedeemGiftParams,
  RedeemGiftResponse,
  RemoveAddOnRequest,
  Request,
  RequestConfirmationEmailResponse,
  SaveAdminListRequest,
  SaveAdminListResponse,
  SaveAdminListSheetsRequest,
  SaveAdminListSheetsResponse,
  SearchCollegesResponse,
  SearchPeopleRequest,
  SearchPeopleResponse,
  SearchRequest,
  StrictSignInRequest,
  StrictSignInResponse,
  SyncYahooCollegePlayerRequest,
  SyncYahooCollegePlayerResponse,
  UpdateAnalyticsMonitoredSitesRequest,
  UpdateBillingInformationRequest,
  UpdateBillingInformationResponse,
  UpdateCollegePlayerRequest,
  UpdateCollegePlayerResponse,
  UpdateCollegeTransferRequest,
  UpdateCollegeTransferResponse,
  UpdateCsAdminPermissionsRequest,
  UpdateCsAdminPermissionsResponse,
  UpdateImageTagResponse,
  UpdateIsProspectFollowedRequest,
  UpdatePasswordResponse,
  UpdateUserRequest,
  UpdateUserResponse,
  UpgradeToAnnualRequest,
  UserBillingHistoryRequest,
  UserForumInfoRequest,
  UserForumInfoResponse,
  UserHistoryTrackingHistoryRequest,
  UserTrackingHistoryRequest,
  ValidateBetMGMResponse,
  ValidatePromoCodeRequest,
  ValidationParamsRequest,
  YahooProfileSearchResponse
} from "./container";

/**
 * Sets the headers and urlBase depending on origin of api call.
 * Bypasses the rails force_ssl feature via use of the
 * X-Forwarded-Proto header (calls from server only) in the
 * same way nginx proxy_pass is configured.
 */
export function configureAPI(
  isServer: boolean,
  token?: string,
  csurf?: string
): APIConfig {
  const finalHeaders = DEFAULT_HEADERS;
  if (isServer) {
    Object.assign(finalHeaders, SERVER_HEADERS);
  }

  return {
    csurf,
    headers: finalHeaders,
    token,
    urlBase: isServer ? apiUrl() : ""
  };
}

/**
 * Instantiates and throws an Error with a recognizable `name` and http `status` code
 * for use in sagas
 * @param errorMessage
 */
function throwRivalsAPIError(errorMessage: string, status: number): void {
  const error = new RivalsApiError(errorMessage, status);
  error.name = RIVALS_API_ERROR;
  throw error;
}

/**
 * Throws the error from the api when returned.
 * Defaults to and throws generic status errors when no error/errors are returned.
 * @param responseJSON - The parsed JSON response from the API
 * @param status - The status of the response from the API
 * @returns responseJSON - returned if no errors are thrown
 */
export function handleError<T>(
  responseJSON: T & ResponseErrors,
  status: number
): T {
  if (responseJSON.error) {
    throwRivalsAPIError(responseJSON.error, status);
    // TODO: https://griosf.atlassian.net/browse/RVLS-3486
  } else if (typeof responseJSON.errors === "string") {
    throwRivalsAPIError(responseJSON.errors, status);
  } else if (responseJSON.errors && responseJSON.errors.length) {
    throwRivalsAPIError(responseJSON.errors[0], status);
  } else if (
    status < RESPONSE_CODE_SUCCESS ||
    status >= RESPONSE_CODE_REDIRECT
  ) {
    throw new Error(createResponseCodeMessage(status));
  }

  return responseJSON;
}

/**
 * Returns the responseJSON or throws an error
 * Passes the json error from the API when available
 * Defaults to a generic message with the status when not available
 * @param responseJSON
 * @param status
 * @returns responseJSON
 */
export function handleResponse<T>(
  promise: Promise<Response>,
  shouldHandleError: boolean
): Promise<T> {
  return promise.then(response => {
    if (response.status === RESPONSE_CODE_NO_CONTENT) {
      return "";
    }

    if (
      (response.status < RESPONSE_CODE_SUCCESS ||
        response.status >= RESPONSE_CODE_REDIRECT) &&
      !response.headers?.get("Content-Type")?.match(/application\/json/)
    ) {
      throw new Error(createResponseCodeMessage(response.status));
    }

    return response.json().then(responseJSON => {
      const formattedResponse = camelCaseKeys(responseJSON, { deep: true });
      if (shouldHandleError) {
        return handleError<T>(formattedResponse, response.status);
      } else {
        return formattedResponse;
      }
    });
  });
}

/**
 * Returns formatted url params with undefined values removed
 * @param params Request Object
 * @returns URLSearchParams
 */
export function cleanParams(params: Object): URLSearchParams {
  const searchParams = new URLSearchParams({});

  Object.entries(snakeCaseKeys(params)).map(([key, value]) => {
    if (value !== undefined) {
      searchParams.append(key, value);
    }
  });

  return searchParams;
}

export default class API {
  private apiConfig: ReturnType<typeof configureAPI>;
  private isFetchingCsurfToken = false;
  private isServer: boolean;
  private subscribers: Array<(csurfToken: string) => void> = [];

  constructor(isServer: boolean, token?: string, csurf?: string) {
    this.apiConfig = configureAPI(isServer, token, csurf);

    this.isServer = isServer;

    this.articleSearch = this.articleSearch.bind(this);
    this.cancelSubscription = this.cancelSubscription.bind(this);
    this.checkGiftToken = this.checkGiftToken.bind(this);
    this.collegePlayerSearch = this.collegePlayerSearch.bind(this);
    this.collegeTransfersSearch = this.collegeTransfersSearch.bind(this);
    this.createAccount = this.createAccount.bind(this);
    this.createCollegeTransfer = this.createCollegeTransfer.bind(this);
    this.createImage = this.createImage.bind(this);
    this.createSubscription = this.createSubscription.bind(this);
    this.createForecast = this.createForecast.bind(this);
    this.deleteList = this.deleteList.bind(this);
    this.deleteCollegePlayer = this.deleteCollegePlayer.bind(this);
    this.emailOffer = this.emailOffer.bind(this);
    this.extendMgmSubscription = this.extendMgmSubscription.bind(this);
    this.extendSubscription = this.extendSubscription.bind(this);
    this.fetchWrapper = this.fetchWrapper.bind(this);
    this.forgotPassword = this.forgotPassword.bind(this);
    this.forgotUsername = this.forgotUsername.bind(this);
    this.getActivePromotion = this.getActivePromotion.bind(this);
    this.getAddOnsForSubscription = this.getAddOnsForSubscription.bind(this);
    this.getAddress = this.getAddress.bind(this);
    this.getAdminCollegePlayer = this.getAdminCollegePlayer.bind(this);
    this.getAuthor = this.getAuthor.bind(this);
    this.getBillingInformation = this.getBillingInformation.bind(this);
    this.getBillingHistory = this.getBillingHistory.bind(this);
    this.getAdminUserBillingHistory = this.getAdminUserBillingHistory.bind(
      this
    );
    this.getCareerHighlights = this.getCareerHighlights.bind(this);
    this.getCCPAFooter = this.getCCPAFooter.bind(this);
    this.getCurrentUser = this.getCurrentUser.bind(this);
    this.getCollegeTransfers = this.getCollegeTransfers.bind(this);
    this.getAllCollegeTransfersByCollegePlayerId = this.getAllCollegeTransfersByCollegePlayerId.bind(
      this
    );
    this.getIsCurrentUserValid = this.getIsCurrentUserValid.bind(this);
    this.getForecasterList = this.getForecasterList.bind(this);
    this.getGallery = this.getGallery.bind(this);
    this.getIsProspectFollowed = this.getIsProspectFollowed.bind(this);
    this.getPositions = this.getPositions.bind(this);
    this.getProspectTopForecasts = this.getProspectTopForecasts.bind(this);
    this.getProspectRankingGraph = this.getProspectRankingGraph.bind(this);
    this.getProspectRankingsList = this.getProspectRankingsList.bind(this);
    this.getAthleteFeaturedMedia = this.getAthleteFeaturedMedia.bind(this);
    this.getProspectForecastTable = this.getProspectForecastTable.bind(this);
    this.getProspectForecastGraph = this.getProspectForecastGraph.bind(this);
    this.getProspectForecastGraphList = this.getProspectForecastGraphList.bind(
      this
    );
    this.getPublisherLetter = this.getPublisherLetter.bind(this);
    this.getRecentForecasts = this.getRecentForecasts.bind(this);
    this.getListData = this.getListData.bind(this);
    this.getListPeople = this.getListPeople.bind(this);
    this.searchPeople = this.searchPeople.bind(this);
    this.saveAdminList = this.saveAdminList.bind(this);
    this.saveAdminListSheets = this.saveAdminListSheets.bind(this);
    this.getNavItems = this.getNavItems.bind(this);
    this.getRailsConstants = this.getRailsConstants.bind(this);
    this.getSites = this.getSites.bind(this);
    this.getSite = this.getSite.bind(this);
    this.getSubscriptions = this.getSubscriptions.bind(this);
    this.getGiftTax = this.getGiftTax.bind(this);
    this.getAddOnPricing = this.getAddOnPricing.bind(this);
    this.getUpgradingAddOns = this.getUpgradingAddOns.bind(this);
    this.getAdminUpgradingUser = this.getAdminUpgradingUser.bind(this);
    this.getTax = this.getTax.bind(this);
    this.giftSubscription = this.giftSubscription.bind(this);
    this.loginUser = this.loginUser.bind(this);
    this.logoutUser = this.logoutUser.bind(this);
    this.payOutstandingBalance = this.payOutstandingBalance.bind(this);
    this.predictTextSearch = this.predictTextSearch.bind(this);
    this.prospectSearch = this.prospectSearch.bind(this);
    this.purchaseAddOn = this.purchaseAddOn.bind(this);
    this.reactivateSubscription = this.reactivateSubscription.bind(this);
    this.redeemGift = this.redeemGift.bind(this);
    this.requestConfirmationEmail = this.requestConfirmationEmail.bind(this);
    this.removeAddOn = this.removeAddOn.bind(this);
    this.updateIsProspectFollowed = this.updateIsProspectFollowed.bind(this);
    this.searchColleges = this.searchColleges.bind(this);
    this.strictSignIn = this.strictSignIn.bind(this);
    this.updateBillingInformation = this.updateBillingInformation.bind(this);
    this.updateCurrentUser = this.updateCurrentUser.bind(this);
    this.updateCsAdminPermissions = this.updateCsAdminPermissions.bind(this);
    this.updateImageTag = this.updateImageTag.bind(this);
    this.updatePassword = this.updatePassword.bind(this);
    this.upgradeToAnnual = this.upgradeToAnnual.bind(this);
    this.adminUpgradeUserSubscription = this.adminUpgradeUserSubscription.bind(
      this
    );
    this.getSaveEligibility = this.getSaveEligibility.bind(this);
    this.validateEmailOrUsername = this.validateEmailOrUsername.bind(this);
    this.validateBetMGM = this.validateBetMGM.bind(this);
    this.validateForecast = this.validateForecast.bind(this);
    this.getBetMGMUrl = this.getBetMGMUrl.bind(this);
    this.getCollegePlayer = this.getCollegePlayer.bind(this);
    this.linkYahooCollegePlayer = this.linkYahooCollegePlayer.bind(this);
    this.syncYahooCollegePlayer = this.syncYahooCollegePlayer.bind(this);
    this.updateCollegePlayer = this.updateCollegePlayer.bind(this);
    this.deleteCollegeTransfer = this.deleteCollegeTransfer.bind(this);
    this.getCollegePlayerGameData = this.getCollegePlayerGameData.bind(this);
    this.getCollegePlayerRankingData = this.getCollegePlayerRankingData.bind(
      this
    );
    this.getProspect = this.getProspect.bind(this);
    this.getProspectCollegeInterests = this.getProspectCollegeInterests.bind(
      this
    );
    this.getProspectNews = this.getProspectNews.bind(this);
    this.getCoaches = this.getCoaches.bind(this);
    this.getCsAdminReports = this.getCsAdminReports.bind(this);
    this.getUserEligibleAddons = this.getUserEligibleAddons.bind(this);
    this.validatePromoCode = this.validatePromoCode.bind(this);
    this.getUserForumInfo = this.getUserForumInfo.bind(this);
    this.getUserTrackingHistory = this.getUserTrackingHistory.bind(this);
    this.getUserHistoryTrackingHistory = this.getUserHistoryTrackingHistory.bind(
      this
    );
    this.getProspectTrackingHistory = this.getProspectTrackingHistory.bind(
      this
    );
    this.getPayPalBillingAgreement = this.getPayPalBillingAgreement.bind(this);
    this.getYahooSyncData = this.getYahooSyncData.bind(this);
    this.updateCollegeTransfer = this.updateCollegeTransfer.bind(this);
    this.fetchListData = this.fetchListData.bind(this);
    this.getContentListPeople = this.getContentListPeople.bind(this);
    this.fetchTransfers = this.fetchTransfers.bind(this);
    this.yahooProfileSearch = this.yahooProfileSearch.bind(this);
    this.fetchConferences = this.fetchConferences.bind(this);
    this.generateAddOnReport = this.generateAddOnReport.bind(this);
    this.listAddOnReports = this.listAddOnReports.bind(this);
    this.generateGoogleAnalyticsReport = this.generateGoogleAnalyticsReport.bind(
      this
    );
    this.fetchGoogleAnalyticsReports = this.fetchGoogleAnalyticsReports.bind(
      this
    );
    this.updateAnalyticsMonitoredSites = this.updateAnalyticsMonitoredSites.bind(
      this
    );
    this.getTeamRankings = this.getTeamRankings.bind(this);
    this.getTeamRankingsYears = this.getTeamRankingsYears.bind(this);
    this.fetchHighschoolGroups = this.fetchHighschoolGroups.bind(this);
    this.fetchHighschoolGroup = this.fetchHighschoolGroup.bind(this);
    this.fetchTeamsNavItems = this.fetchTeamsNavItems.bind(this);
    this.fetchContent = this.fetchContent.bind(this);
    this.getPageConfig = this.getPageConfig.bind(this);
    this.getHeadlineNewsContent = this.getHeadlineNewsContent.bind(this);
    this.getCustomModuleContent = this.getCustomModuleContent.bind(this);
    this.getDefaultCustomModuleContent = this.getDefaultCustomModuleContent.bind(
      this
    );
    this.getTopForumsSites = this.getTopForumsSites.bind(this);
    this.fetchNotificationSettings = this.fetchNotificationSettings.bind(this);
  }

  private isStateChangingRequest = (method: HTTP_METHODS): boolean => {
    return [
      HTTP_METHODS.DELETE,
      HTTP_METHODS.POST,
      HTTP_METHODS.PUT,
      HTTP_METHODS.PATCH
    ].includes(method);
  };

  private afterResponse(method: HTTP_METHODS): void {
    const dispatch = !this.isServer && globalStore?.dispatch;

    if (dispatch && this.isStateChangingRequest(method)) {
      dispatch(setIsSubmittingToServer(false));
    }
  }

  private onFetchedCsurfToken = (csurfToken: string): void => {
    this.subscribers.map(subscriber => subscriber(csurfToken));
  };

  private subscribeCsurfTokenFetch(
    subscriber: (csurfToken: string) => void
  ): void {
    this.subscribers.push(subscriber);
  }

  private async fetchWrapper<T>({
    body,
    cacheDurationSeconds,
    displaySpinner = false,
    headers,
    isAuthenticated = false,
    method = HTTP_METHODS.GET,
    shouldHandleError = true,
    url
  }: FetchOptions): Promise<T> {
    const dispatch = !this.isServer && globalStore?.dispatch;
    const options: {
      body?: string;
      cache?: RequestCache;
      credentials?: "include";
      headers: HeadersInit;
      method: string;
      next?: object;
    } = {
      headers,
      method
    };

    /**
     * If you want to cache in the browser, use SWR via useQuery hook, not this class
     * Caching is respected only on Next server
     * https://nextjs.org/docs/app/building-your-application/caching#data-cache
     */
    if (
      cacheDurationSeconds !== undefined &&
      this.isServer &&
      process.env.NEXT_DATA_CACHE_ENABLED === "true"
    ) {
      options.next = { revalidate: cacheDurationSeconds };
    }

    if (this.isStateChangingRequest(method)) {
      options.headers = { ...headers, ...UPDATE_HEADERS };

      if (dispatch && displaySpinner) {
        dispatch(setIsSubmittingToServer(true));
      }
    }

    // If a GET request considers authentication, add the token to the headers and allow cookies
    // If the request is anything other than GET, assume it requires Auth
    if (
      this.apiConfig?.token &&
      (isAuthenticated || this.isStateChangingRequest(method))
    ) {
      options.headers = {
        ...options.headers,
        ...{ AUTHORIZATION: `token ${this.apiConfig?.token}` }
      };
    }
    if (body) {
      /**
        /^_/gm execludes strings starting with underscore
        eg: _destroy
      */
      options.body = JSON.stringify(snakeCaseKeys(body, { exclude: [/^_/gm] }));
    }
    /**
     * Required by iOS Safari 10 and 11.  Will send regardless of origin
     * Only set this to true if it's an internal api and the api requires authentication
     * https://github.com/developit/unfetch#caveats
     */
    if (isAuthenticated || this.isStateChangingRequest(method)) {
      options.credentials = "include";
    }

    const csurfExistingToken = Cookies.get()
      ? Cookies.get(CSURF_TOKEN_COOKIE)
      : this.apiConfig.csurf;

    // if csurf token is not stored as client side cookie OR not set on
    // api module config object AND the request was not a state change one
    // then fetch csurf token first and hold on sending the rest of requests
    // until response is returned.
    // note: remove `csurfExistingToken` check if you want the token to be
    // regenerated before each state change request AND update this comment.
    if (!csurfExistingToken && this.isStateChangingRequest(method)) {
      if (!this.isFetchingCsurfToken) {
        this.isFetchingCsurfToken = true;
        this.getCsurfToken()
          .then(token => {
            this.isFetchingCsurfToken = false;
            this.onFetchedCsurfToken(token);
          })
          .finally(() => {
            // clean ups
            this.isFetchingCsurfToken = false;
            this.subscribers = [];
          });
      }
      return new Promise<T>(resolve => {
        this.subscribeCsurfTokenFetch(token => {
          resolve(
            handleResponse<T>(
              fetch(url, {
                ...options,
                headers: { ...options.headers, [CSURF_TOKEN_HEADER]: token }
              }),
              shouldHandleError
            ).finally(() => this.afterResponse(method))
          );
        });
      });
    }

    if (this.isStateChangingRequest(method)) {
      Object.assign(options.headers, {
        [CSURF_TOKEN_HEADER]: csurfExistingToken
      });
    }

    if (process.env.ENABLE_PROD_FETCH_LOGGING === "true") {
      // eslint-disable-next-line no-console
      console.info("NEXT_FETCHING:", url);
    }

    return handleResponse<T>(
      fetch(url, options),
      shouldHandleError
    ).finally(() => this.afterResponse(method));
  }

  private async getCsurfToken(): Promise<string> {
    const { headers, urlBase } = this.apiConfig;
    const csrufURL = `${urlBase}/api/v2/csurf_token`;
    // NOTE: Important to keep the vanilla fetch here as this.fetchWrapper would loop
    const csurfPayload = await fetch(csrufURL, { headers });
    const csurfJSON = await csurfPayload.json();
    const csurfToken = csurfJSON.csurf_token;

    this.apiConfig.csurf = csurfToken;
    Cookies.set(CSURF_TOKEN_COOKIE, csurfToken);

    return csurfToken;
  }

  public articleSearch({
    limit = SEARCH_OVERLAY_LIMITS.ARTICLES,
    term
  }: SearchRequest): Promise<ArticleSearchResponse> {
    const { headers, urlBase } = this.apiConfig;
    const url = `${urlBase}/api/v2/content/contents?search[query]=${term}&search[page_size]=${limit}`;

    return this.fetchWrapper<ArticleSearchResponse>({
      headers,
      isAuthenticated: true,
      url
    });
  }

  public getCloudinarySignature(): Promise<GetCloudinarySignatureResponse> {
    const { headers } = this.apiConfig;

    return this.fetchWrapper<GetCloudinarySignatureResponse>({
      headers,
      url: "/api/getCloudinarySignature"
    });
  }

  public collegePlayerSearch(
    params: PersonSearchRequest
  ): Promise<SearchPeopleResponse> {
    const { headers, urlBase } = this.apiConfig;
    const queryParams = new URLSearchParams(snakeCaseKeys(params));
    const url = `${urlBase}/api/v2/people/college_player_search?${queryParams}`;

    return this.fetchWrapper<SearchPeopleResponse>({
      headers,
      isAuthenticated: true,
      url
    });
  }

  public collegeTransfersSearch(
    params: CollegeTransferSearchRequest
  ): Promise<CollegeTransferSearchResponse> {
    const { headers, urlBase } = this.apiConfig;
    let keyParam: keyof CollegeTransferSearchRequest;
    // encoded request params
    const requestParams: { [key: string]: string } = {};

    for (keyParam in params) {
      if (params[keyParam]) {
        if (
          typeof params[keyParam] === "object" ||
          Array.isArray(params[keyParam])
        ) {
          // objects and arrays
          requestParams[keyParam] = encodeURIComponent(
            JSON.stringify(params[keyParam])
          );
        } else {
          // numbers, strings
          requestParams[keyParam] = params[keyParam]?.toString() as string;
        }
      }
    }

    // decode immediately before sending the request to restore
    // URLSearchParams `%` symbol encoding
    const url = `${urlBase}/api/v2/college_transfers?${decodeURIComponent(
      new URLSearchParams(snakeCaseKeys(requestParams)).toString()
    )}`;

    return this.fetchWrapper<CollegeTransferSearchResponse>({
      headers,
      isAuthenticated: true,
      url
    });
  }

  // CollegeTransfer
  public createCollegeTransfer({
    collegePlayerId,
    collegeTransfer
  }: CreateCollegeTransferRequest): Promise<CreateCollegeTransferResponse> {
    const { headers, urlBase } = this.apiConfig;
    const url = `${urlBase}/api/v2/college_players/${collegePlayerId}/college_transfers`;

    return this.fetchWrapper<CreateCollegeTransferResponse>({
      body: collegeTransfer,
      headers,
      isAuthenticated: true,
      method: HTTP_METHODS.POST,
      url
    });
  }

  public fetchContent(
    request: FetchContentRequest
  ): Promise<FetchContentResponse> {
    const { headers, urlBase } = this.apiConfig;
    const url = `${urlBase}/api/v2/content/contents/${request.slug}?site_id=${request.siteId}`;

    return this.fetchWrapper<FetchContentResponse>({
      headers,
      isAuthenticated: true,
      url
    });
  }

  public fetchConferences(
    request: FetchConferencesRequest
  ): Promise<FetchConferencesResponse> {
    const { headers, urlBase } = this.apiConfig;
    const formattedRequest = snakeCaseKeys(request);
    const params = Object.keys(formattedRequest).reduce((acc, key) => {
      if (formattedRequest[key]) {
        acc.append(key, `${formattedRequest[key]}`);
      }
      return acc;
    }, new URLSearchParams());

    const url = `${urlBase}/api/v2/conferences?${params.toString()}`;

    return this.fetchWrapper<FetchConferencesResponse>({
      headers,
      isAuthenticated: true,
      url
    });
  }

  public fetchConference({
    abbreviation,
    ...request
  }: FetchConferenceRequest): Promise<FetchConferenceResponse> {
    const { headers, urlBase } = this.apiConfig;
    const formattedRequest = snakeCaseKeys(request);
    const params = Object.keys(formattedRequest).reduce((acc, key) => {
      if (formattedRequest[key]) {
        acc.append(key, `${formattedRequest[key]}`);
      }
      return acc;
    }, new URLSearchParams());

    const url = `${urlBase}/api/v2/conferences/${abbreviation}?${params.toString()}`;

    return this.fetchWrapper<FetchConferenceResponse>({
      headers,
      isAuthenticated: true,
      url
    });
  }

  public deleteCollegePlayer(collegePlayerId: number): Promise<{}> {
    const { headers, urlBase } = this.apiConfig;
    const url = `${urlBase}/api/v2/admin/college_players/${collegePlayerId}`;

    return this.fetchWrapper<{}>({
      body: { player: { id: collegePlayerId } },
      headers,
      isAuthenticated: true,
      method: HTTP_METHODS.DELETE,
      url
    });
  }

  public deleteList(listId: number): Promise<{}> {
    const { headers, urlBase } = this.apiConfig;
    const url = `${urlBase}/api/v2/admin/lists/${listId}`;

    return this.fetchWrapper<{}>({
      headers,
      isAuthenticated: true,
      method: HTTP_METHODS.DELETE,
      url
    });
  }

  public getCollegeTransfers({
    collegePlayerId,
    collegeTransferId
  }: GetCollegeTransferRequest): Promise<GetCollegeTransferResponse> {
    const { headers, urlBase } = this.apiConfig;
    const url = `${urlBase}/api/v2/college_players/${collegePlayerId}/college_transfers/${collegeTransferId}`;

    return this.fetchWrapper<GetCollegeTransferResponse>({
      headers,
      isAuthenticated: true,
      url
    });
  }

  public getAllCollegeTransfersByCollegePlayerId({
    collegePlayerId
  }: Omit<GetCollegeTransferRequest, "collegeTransferId">): Promise<
    GetCollegeTransferResponse
  > {
    const { headers, urlBase } = this.apiConfig;
    const url = `${urlBase}/api/v2/college_players/${collegePlayerId}/college_transfers`;

    return this.fetchWrapper<GetCollegeTransferResponse>({
      headers,
      isAuthenticated: true,
      url
    });
  }

  public deleteCollegeTransfer({
    collegePlayerId,
    collegeTransferId
  }: DeleteCollegeTransferRequest): Promise<{}> {
    const { headers, urlBase } = this.apiConfig;
    const url = `${urlBase}/api/v2/college_players/${collegePlayerId}/college_transfers/${collegeTransferId}`;

    return this.fetchWrapper<{}>({
      headers,
      method: HTTP_METHODS.DELETE,
      url
    });
  }

  public extendSubscription(
    request: ExtendSubscriptionRequest
  ): Promise<ExtendSubscriptionResponse> {
    const { headers, urlBase } = this.apiConfig;
    const url = `${urlBase}/api/v2/users/subscriptions/${request.subscriptionId}/extend_renewal_date`;

    return this.fetchWrapper<ExtendSubscriptionResponse>({
      headers,
      isAuthenticated: true,
      method: HTTP_METHODS.PUT,
      url
    });
  }

  public forgotPassword(
    request: ForgotPasswordRequest
  ): Promise<ForgotPasswordResponse> {
    const { headers, urlBase } = this.apiConfig;
    const url = `${urlBase}/api/user/password`;

    return this.fetchWrapper<ForgotPasswordResponse>({
      body: {
        user: { email: request.email }
      },
      headers,
      isAuthenticated: true,
      method: HTTP_METHODS.POST,
      url
    });
  }

  public forgotUsername(
    request: ForgotUsernameRequest
  ): Promise<ForgotUsernameResponse> {
    const { headers, urlBase } = this.apiConfig;
    const url = `${urlBase}/api/v2/users/forgot_username`;

    return this.fetchWrapper<ForgotUsernameResponse>({
      body: {
        email: request.email
      },
      headers,
      isAuthenticated: true,
      method: HTTP_METHODS.POST,
      url
    });
  }

  public getAddress(): Promise<AddressResponse> {
    const { headers, urlBase } = this.apiConfig;
    const url = `${urlBase}/api/v2/users/address`;

    return this.fetchWrapper<AddressResponse>({
      headers,
      isAuthenticated: true,
      url
    });
  }

  public getPayPalBillingAgreement(
    request: PayPalBillingAgreementRequest
  ): Promise<PayPalBillingAgreementResponse> {
    const { headers, urlBase } = this.apiConfig;
    const {
      returnUrl,
      cancelUrl,
      billingAgreementAction,
      userEmail = ""
    } = request;
    /* eslint-disable @typescript-eslint/naming-convention */
    const params = new URLSearchParams({
      billing_agreement_action: billingAgreementAction,
      cancel_url: cancelUrl,
      return_url: returnUrl,
      user_email: userEmail
    });
    /* eslint-enable @typescript-eslint/naming-convention */
    const url = `${urlBase}/api/v2/users/paypal/manage_billing_agreement?${params}`;

    return this.fetchWrapper<PayPalBillingAgreementResponse>({
      headers,
      isAuthenticated: true,
      url
    });
  }

  public validateBetMGM(): Promise<ValidateBetMGMResponse> {
    const { headers, urlBase } = this.apiConfig;
    const url = `${urlBase}/api/v2/users/betcheck`;

    return this.fetchWrapper<ValidateBetMGMResponse>({
      headers,
      isAuthenticated: true,
      url
    });
  }

  public getAddOnsForSubscription(
    request: GetAddOnsForSubscriptionRequest
  ): Promise<GetAddOnsForSubscriptionResponse> {
    const { headers, urlBase } = this.apiConfig;
    const url = `${urlBase}/api/v2/add_on?subscription_id=${request.subscriptionId}`;

    return this.fetchWrapper<GetAddOnsForSubscriptionResponse>({
      headers,
      isAuthenticated: true,
      url
    });
  }

  public getAuthor(request: GetAuthorRequest): Promise<GetAuthorResponse> {
    const { headers, urlBase } = this.apiConfig;
    const url = `${urlBase}/api/v2/content/authors/${request.id}`;
    return this.fetchWrapper<GetAuthorResponse>({
      headers,
      isAuthenticated: true,
      url
    });
  }

  public getBetMGMUrl(): Promise<getBetMGMUrlResponse> {
    const { headers, urlBase } = this.apiConfig;
    const url = `${urlBase}/api/v2/users/beturl`;

    return this.fetchWrapper<getBetMGMUrlResponse>({
      headers,
      isAuthenticated: true,
      url
    });
  }

  public getAdminCollegePlayer(
    request: GetCollegePlayerRequest
  ): Promise<GetCollegePlayerResponse> {
    const { headers, urlBase } = this.apiConfig;
    const url = `${urlBase}/api/v2/admin/college_player?player[person_id]=${request.personId}`;
    return this.fetchWrapper<GetCollegePlayerResponse>({
      headers,
      isAuthenticated: true,
      url
    });
  }

  public getYahooSyncData(
    request: GetYahooSyncDataRequest
  ): Promise<GetYahooSyncDataResponse> {
    const { headers, urlBase } = this.apiConfig;
    const url = `${urlBase}/api/v2/admin/yahoo_sync_data?player[id]=${request.collegePlayerId}`;
    return this.fetchWrapper<GetYahooSyncDataResponse>({
      headers,
      isAuthenticated: true,
      url
    });
  }

  public getCollegePlayer(
    request: GetCollegePlayerRequest
  ): Promise<GetCollegePlayerResponse> {
    const { headers, urlBase } = this.apiConfig;
    const url = `${urlBase}/api/v2/athletes/college_player?player[person_id]=${request.personId}`;
    return this.fetchWrapper<GetCollegePlayerResponse>({
      headers,
      isAuthenticated: true,
      url
    });
  }

  public linkYahooCollegePlayer(
    request: LinkYahooCollegePlayerRequest
  ): Promise<LinkYahooCollegePlayerResponse> {
    const { headers, urlBase } = this.apiConfig;
    const url = `${urlBase}/api/v2/admin/college_players`;
    const body = {
      player: request
    };
    return this.fetchWrapper<LinkYahooCollegePlayerResponse>({
      body,
      headers,
      method: HTTP_METHODS.POST,
      url
    });
  }

  public syncYahooCollegePlayer(
    request: SyncYahooCollegePlayerRequest
  ): Promise<SyncYahooCollegePlayerResponse> {
    const { headers, urlBase } = this.apiConfig;
    const url = `${urlBase}/api/v2/admin/sync_college_player?player[person_id]=${request.personId}`;

    return this.fetchWrapper<SyncYahooCollegePlayerResponse>({
      headers,
      method: HTTP_METHODS.PUT,
      url
    });
  }

  public updateCollegePlayer(
    request: UpdateCollegePlayerRequest
  ): Promise<UpdateCollegePlayerResponse> {
    const { headers, urlBase } = this.apiConfig;
    const url = `${urlBase}/api/v2/admin/college_players/${request.id}`;
    const body = {
      player: request
    };
    return this.fetchWrapper<UpdateCollegePlayerResponse>({
      body,
      headers,
      method: HTTP_METHODS.PUT,
      url
    });
  }

  public getCollegePlayerGameData(
    params: AthletePageParams
  ): Promise<GetCollegePlayerGameDataResponse> {
    const { headers, urlBase } = this.apiConfig;
    let url = `${urlBase}/api/v2/athletes/college_player_game_data`;
    url = buildPlayerUrlQueryParams(url, params);

    return this.fetchWrapper<GetCollegePlayerGameDataResponse>({
      headers,
      isAuthenticated: true,
      url
    });
  }

  public getCollegePlayerRankingData(
    params: AthletePageParams
  ): Promise<GetCollegePlayerRankingDataResponse> {
    const { headers, urlBase } = this.apiConfig;
    let url = `${urlBase}/api/v2/athletes/college_player_ranking_data`;
    url = buildPlayerUrlQueryParams(url, params);

    return this.fetchWrapper<GetCollegePlayerRankingDataResponse>({
      headers,
      isAuthenticated: true,
      url
    });
  }

  public getIsProspectFollowed(
    params: AthletePageParams
  ): Promise<GetIsProspectFollowedResponse> {
    const { headers, urlBase } = this.apiConfig;
    let url = `${urlBase}/api/v2/prospects/is_following`;
    url = buildPlayerUrlQueryParams(url, params);

    return this.fetchWrapper<GetIsProspectFollowedResponse>({
      headers,
      isAuthenticated: true,
      url
    });
  }

  public getPositions(
    request: GetPositionsRequest
  ): Promise<GetPositionsResponse> {
    const { headers, urlBase } = this.apiConfig;
    const { year, type, sport } = request;
    let url = `${urlBase}/api/v2/positions`;
    url += `${stringifyParams({
      sport,
      type,
      year
    })}`;

    return this.fetchWrapper<GetPositionsResponse>({
      headers,
      isAuthenticated: true,
      url
    });
  }

  public getProspect(params: AthletePageParams): Promise<getProspectResponse> {
    const { headers, urlBase } = this.apiConfig;

    let url = `${urlBase}/api/v2/prospects/prospect`;
    url = buildPlayerUrlQueryParams(url, params);

    return this.fetchWrapper<getProspectResponse>({
      cacheDurationSeconds: CACHE_SECONDS_SMALL,
      headers,
      url
    });
  }

  public getProspectCollegeInterests(
    params: AthletePageParams
  ): Promise<getProspectCollegeInterestsResponse> {
    const { headers, urlBase } = this.apiConfig;
    let url = `${urlBase}/api/v2/prospects/prospect_interests/`;
    const spread = `show_interest_spread=true`;

    if (params.type === "id") {
      url = `${url}/${params.id}?${spread}`;
    } else if (params.type === "personId") {
      url = `${url}?person_id=${params.personId}`;
    } else {
      url = `${url}?year=${params.year}&player_slug=${params.playerSlug}&${spread}`;
    }
    return this.fetchWrapper<getProspectCollegeInterestsResponse>({
      headers,
      isAuthenticated: true,
      url
    });
  }

  public getAthleteFeaturedMedia(
    params: PersonIdParams
  ): Promise<GetAthleteFeaturedMediaResponse> {
    const { headers, urlBase } = this.apiConfig;
    let url = `${urlBase}/api/v2/athletes/${params.personId}/featured_media`;
    url = buildPlayerUrlQueryParams(url, params);
    url += params.includeGalleries
      ? `&include_galleries=${params.includeGalleries}`
      : "";

    return this.fetchWrapper<GetAthleteFeaturedMediaResponse>({
      headers,
      isAuthenticated: true,
      url
    });
  }

  public getGallery(request: GetGalleryRequest): Promise<GetGalleryResponse> {
    const { headers, urlBase } = this.apiConfig;
    const { galleryId } = request;
    const url = `${urlBase}/api/v2/content/galleries/${galleryId}`;

    return this.fetchWrapper<GetGalleryResponse>({
      headers,
      isAuthenticated: true,
      url
    });
  }

  public getProspectForecastTable(
    params: AthletePageParams
  ): Promise<GetProspectForecastTableResponse> {
    const { headers, urlBase } = this.apiConfig;
    let url = `${urlBase}/api/v2/prospects/forecast_table`;
    url = buildPlayerUrlQueryParams(url, params);
    return this.fetchWrapper<GetProspectForecastTableResponse>({
      headers,
      isAuthenticated: true,
      url
    });
  }

  public getProspectForecastGraph(
    params: AthletePageParams & { userTypes?: string }
  ): Promise<GetProspectForecastGraphResponse> {
    const { headers, urlBase } = this.apiConfig;
    let url = `${urlBase}/api/v2/prospects/forecast_graph`;
    const userTypes = params.userTypes ? `userTypes=${params.userTypes}` : "";
    if (params.type === "id") {
      url = `${url}/${params.id}${userTypes ? `?${userTypes}` : ""}`;
    } else if (params.type === "personId") {
      url = `${url}?person_id=${params.personId}`;
    } else {
      url = `${url}?year=${params.year}&player_slug=${params.playerSlug}${
        userTypes ? `&${userTypes}` : ""
      }`;
    }
    return this.fetchWrapper<GetProspectForecastGraphResponse>({
      headers,
      isAuthenticated: true,
      url
    });
  }

  public getProspectForecastGraphList(
    params: AthletePageParams
  ): Promise<GetProspectForecastGraphListResponse> {
    const { headers, urlBase } = this.apiConfig;
    let url = `${urlBase}/api/v2/prospects/forecast_list`;
    url = buildPlayerUrlQueryParams(url, params);
    return this.fetchWrapper<GetProspectForecastGraphListResponse>({
      headers,
      isAuthenticated: true,
      url
    });
  }

  public getProspectNews(
    params: GetProspectNewsRequest
  ): Promise<GetProspectNewsResponse> {
    const { headers, urlBase } = this.apiConfig;
    let url = `${urlBase}/api/v2/prospects/news`;
    /* eslint-disable @typescript-eslint/naming-convention */
    if (params.type === "id") {
      url = `${url}/${params.id}?${new URLSearchParams({
        page: params.page,
        per_page: params.perPage
      })}`;
    } else if (params.type === "personId") {
      url = `${url}?${new URLSearchParams({
        page: params.page,
        per_page: params.perPage,
        person_id: params.personId
      })}`;
    } else {
      url = `${url}?${new URLSearchParams({
        page: params.page,
        per_page: params.perPage,
        player_slug: params.playerSlug,
        year: params.year
      })}`;
    }
    /* eslint-enable @typescript-eslint/naming-convention */
    return this.fetchWrapper<GetProspectNewsResponse>({
      headers,
      isAuthenticated: true,
      url
    });
  }

  public getProspectRankingsList(
    params: GetProspectRankingsListRequest
  ): Promise<GetProspectRankingsListResponse> {
    const { headers, urlBase } = this.apiConfig;
    const url = `${urlBase}/api/v2/prospect_rankings?${cleanParams(params)}`;

    return this.fetchWrapper<GetProspectRankingsListResponse>({
      cacheDurationSeconds: CACHE_SECONDS_SMALL,
      headers,
      url
    });
  }

  public getProspectTopForecasts(
    params: AthletePageParams
  ): Promise<GetProspectTopForecastsResponse> {
    const { headers, urlBase } = this.apiConfig;
    let url = `${urlBase}/api/v2/prospects/prospect_top_forecasts`;
    url = buildPlayerUrlQueryParams(url, params);
    return this.fetchWrapper<GetProspectTopForecastsResponse>({
      headers,
      isAuthenticated: true,
      url
    });
  }

  public getProspectRankingGraph(
    params: AthletePageParams
  ): Promise<GetProspectRankingGraphResponse> {
    const { headers, urlBase } = this.apiConfig;
    let url = `${urlBase}/api/v2/prospects/ranking_graph`;
    url = buildPlayerUrlQueryParams(url, params);
    return this.fetchWrapper<GetProspectRankingGraphResponse>({
      headers,
      isAuthenticated: true,
      url
    });
  }

  public getPublisherLetter(
    params: GetPublisherLetterRequest
  ): Promise<GetPublisherLetterResponse> {
    const { headers, urlBase } = this.apiConfig;
    const url = `${urlBase}/api/v2/sites/${params.siteId}/publisher_letter`;

    return this.fetchWrapper<GetPublisherLetterResponse>({
      headers,
      isAuthenticated: true,
      url
    });
  }

  public getPromotionBanner(
    request: GetPromotionBannerRequest
  ): Promise<GetPromotionBannerResponse> {
    const { headers, urlBase } = this.apiConfig;
    const url = `${urlBase}/api/v2/content/promotion_banner/${request.siteId}`;

    return this.fetchWrapper<GetPromotionBannerResponse>({
      headers,
      isAuthenticated: true,
      url
    });
  }

  public getTickerEvents(
    request: GetTickerEventsRequest
  ): Promise<GetTickerEventsResponse> {
    const { headers, urlBase } = this.apiConfig;
    const queryParams = new URLSearchParams(snakeCaseKeys(request));
    const url = `${urlBase}/api/v2/content/event_ticker?${queryParams}`;

    return this.fetchWrapper<GetTickerEventsResponse>({
      cacheDurationSeconds: CACHE_SECONDS_SMALL,
      headers,
      url
    });
  }

  public getCsAdminReports(): Promise<GetCsAdminReportsResponse> {
    const { headers, urlBase } = this.apiConfig;
    const url = `${urlBase}/api/v2/admin/reports/cs_admin_reports`;

    return this.fetchWrapper<GetCsAdminReportsResponse>({
      headers,
      isAuthenticated: true,
      url
    });
  }

  public getAdminUserBillingHistory(
    params: UserBillingHistoryRequest
  ): Promise<BillingHistoryResponse> {
    const { headers, urlBase } = this.apiConfig;
    const url = `${urlBase}/api/v2/admin/users/${params.id}/billing_history`;

    return this.fetchWrapper<BillingHistoryResponse>({
      headers,
      isAuthenticated: true,
      url
    });
  }

  public getUserEligibleAddons(): Promise<GetUserEligibleAddonsResponse> {
    const { headers, urlBase } = this.apiConfig;
    const url = `${urlBase}/api/v2/add_ons`;

    return this.fetchWrapper<GetUserEligibleAddonsResponse>({
      headers,
      isAuthenticated: true,
      url
    });
  }

  public getBillingInformation(): Promise<BillingInfoResponse> {
    const { headers, urlBase } = this.apiConfig;
    const url = `${urlBase}/api/v2/users/billing_information`;

    return this.fetchWrapper<BillingInfoResponse>({
      headers,
      isAuthenticated: true,
      url
    });
  }

  public getBillingHistory(): Promise<BillingHistoryResponse> {
    const { headers, urlBase } = this.apiConfig;
    const url = `${urlBase}/api/v2/users/billing_history`;

    return this.fetchWrapper<BillingHistoryResponse>({
      headers,
      isAuthenticated: true,
      url
    });
  }

  // This request should never be made from the Next.js server
  public getCCPAFooter(): Promise<GetCCPAFooterResponse> {
    const { headers, urlBase } = this.apiConfig;
    const url = `${urlBase}/api/v2/check_jurisdiction`;

    return this.fetchWrapper<GetCCPAFooterResponse>({
      headers,
      isAuthenticated: true,
      url
    });
  }

  public getIsCurrentUserValid(): Promise<GetIsCurrentUserValidResponse> {
    const { headers, urlBase } = this.apiConfig;
    const url = `${urlBase}/api/v2/users/is_current_user_valid`;

    return this.fetchWrapper<GetIsCurrentUserValidResponse>({
      headers,
      isAuthenticated: true,
      url
    });
  }

  public getCurrentUser(): Promise<GetCurrentUserResponse> {
    const { headers, urlBase } = this.apiConfig;
    const url = `${urlBase}/api/v2/users/current`;

    return this.fetchWrapper<GetCurrentUserResponse>({
      headers,
      isAuthenticated: true,
      url
    });
  }

  public getForecasterList(): Promise<GetForecasterListResponse> {
    const { headers, urlBase } = this.apiConfig;
    const url = `${urlBase}/api/v2/futurecast/forecasters`;

    return this.fetchWrapper<GetForecasterListResponse>({
      headers,
      isAuthenticated: true,
      url
    });
  }

  public getRecentForecasts(): Promise<GetRecentForecastsResponse> {
    const { headers, urlBase } = this.apiConfig;
    const url = `${urlBase}/api/v2/futurecast/recent_forecasts`;

    return this.fetchWrapper<GetRecentForecastsResponse>({
      headers,
      isAuthenticated: true,
      url
    });
  }

  public getListData({ id }: GetListDataRequest): Promise<GetListDataResponse> {
    const { headers, urlBase } = this.apiConfig;
    const url = `${urlBase}/api/v2/admin/lists/${id}`;

    return this.fetchWrapper<GetListDataResponse>({
      headers,
      isAuthenticated: true,
      url
    });
  }

  public getListPeople({ id }: GetListPeopleRequest): Promise<ListPeople> {
    const { headers, urlBase } = this.apiConfig;
    const url = `${urlBase}/api/v2/admin/lists/${id}/people`;

    return this.fetchWrapper<ListPeople>({
      headers,
      isAuthenticated: true,
      url
    });
  }

  public searchPeople(
    request: SearchPeopleRequest
  ): Promise<SearchPeopleResponse> {
    const { headers, urlBase } = this.apiConfig;

    const queryParams = new URLSearchParams(snakeCaseKeys(request));
    const url = `${urlBase}/api/v2/people/admin_list_search?${queryParams}`;

    return this.fetchWrapper({
      headers,
      isAuthenticated: true,
      url
    });
  }

  public saveAdminList(
    request: SaveAdminListRequest
  ): Promise<SaveAdminListResponse> {
    const { headers, urlBase } = this.apiConfig;
    const url = `${urlBase}/api/v2/admin/lists/${request.list.id}`;

    return this.fetchWrapper<SaveAdminListResponse>({
      body: { ...request },
      headers,
      isAuthenticated: true,
      method: HTTP_METHODS.PUT,
      url
    });
  }

  public saveAdminListSheets({
    createNewVersion,
    personIds,
    sport,
    year
  }: SaveAdminListSheetsRequest): Promise<SaveAdminListSheetsResponse> {
    const { headers, urlBase } = this.apiConfig;
    const url = `${urlBase}/api/v2/admin/list_sheets`;

    return this.fetchWrapper<SaveAdminListSheetsResponse>({
      body: {
        ...{
          createNewVersion: createNewVersion,
          personIds: personIds,
          sport: sport,
          year: year
        }
      },
      headers,
      isAuthenticated: true,
      method: HTTP_METHODS.PUT,
      url
    });
  }

  /**
   * Gets the NavOptions json from the CDN
   * "basketballrecruiting" uses the standard rivals nationals links
   * see app/assets/javascripts/content/forumNavigationService.js in Angular
   *
   * @param siteName - Site `identifier` aka `subdomain` from sites endpoint
   */
  public getNavItems(siteName = ROOT_SITE_IDENTIFIER): Promise<NavOptions> {
    const { headers } = this.apiConfig;
    let siteIdentifier = siteName;
    if (siteName === BASKETBALL_RECRUITING_SITE_IDENTIFIER) {
      siteIdentifier = ROOT_SITE_IDENTIFIER;
    }
    const url = `${CDN_URL}/navigation/${siteIdentifier}-nav.json`;

    return this.fetchWrapper<NavOptions>({
      headers,
      url
    }).then(response => {
      // The forums provided home link is never needed. A separate home link will be used instead.
      delete response["home"];
      return response;
    });
  }

  public getRailsConstants({
    components
  }: Request): Promise<RailsConstantsResponse> {
    const { headers, urlBase } = this.apiConfig;

    const params = components
      .map(component => `components[]=${component}`)
      .join("&");
    const url = `${urlBase}/api/v2/react_props/?${params}`;

    return this.fetchWrapper<RailsConstantsResponse>({
      headers,
      isAuthenticated: true,
      url
    });
  }

  public getScoresAndSchedules(
    request: GetScoresAndSchedulesRequest
  ): Promise<GetScoresAndSchedulesResponse> {
    const { headers, urlBase } = this.apiConfig;
    const queryParams = new URLSearchParams(snakeCaseKeys(request));

    const url = `${urlBase}/api/v2/scores_and_schedule?${queryParams}`;

    return this.fetchWrapper<GetScoresAndSchedulesResponse>({
      headers,
      isAuthenticated: true,
      url
    });
  }

  public getSites(request?: GetSitesRequest): Promise<GetSitesResponse> {
    const { headers, urlBase } = this.apiConfig;
    const params = new URLSearchParams(snakeCaseKeys(request || {}));
    const url = `${urlBase}/api/v2/sites?${params.toString()}`;

    return this.fetchWrapper<Site[]>({
      cacheDurationSeconds: CACHE_SECONDS_MEDIUM,
      headers,
      url
    });
  }

  public getSite(request: GetSiteRequest): Promise<GetSiteResponse> {
    const { headers, urlBase } = this.apiConfig;
    const url = `${urlBase}/api/v2/sites/${request.subdomain}`;

    return this.fetchWrapper<Site>({
      cacheDurationSeconds: CACHE_SECONDS_MEDIUM,
      headers,
      url
    });
  }

  public getSubscriptions(): Promise<GetSubscriptionsResponse> {
    const { headers, urlBase } = this.apiConfig;
    const url = `${urlBase}/api/v2/users/subscriptions`;

    return this.fetchWrapper<GetSubscriptionsResponse>({
      headers,
      isAuthenticated: true,
      url
    });
  }

  // TODO: https://griosf.atlassian.net/browse/RVLS-3696
  public giftSubscription(
    request: GiftSubscriptionRequest
  ): Promise<GiftSubscriptionResponse> {
    const { headers, urlBase } = this.apiConfig;
    const url = `${urlBase}/api/v2/gifts.json`;
    const { cardExp, street1, street2 } = request;
    const marshalledExp = cardExp && cardExp.replace("/", "");
    const marshalledRequest = {
      ...request,
      cardExp: marshalledExp,
      country: "US",
      street_1: street1, //eslint-disable-line @typescript-eslint/naming-convention
      street_2: street2 //eslint-disable-line @typescript-eslint/naming-convention
    };
    delete marshalledRequest.street1;
    delete marshalledRequest.street2;
    delete marshalledRequest.autoRenewalOptIn;

    return this.fetchWrapper<GiftSubscriptionResponse>({
      body: marshalledRequest,
      headers,
      method: HTTP_METHODS.POST,
      shouldHandleError: false,
      url
    });
  }

  // TODO: https://griosf.atlassian.net/browse/RVLS-3696
  public getGiftTax(request: GetGiftTaxRequest): Promise<GetGiftTaxResponse> {
    const { headers, urlBase } = this.apiConfig;
    const {
      address: { city, state, zip, street1, street2 },
      siteId,
      plan,
      salePrice
    } = request;

    const rawParams = {
      city,
      plan,
      salePrice,
      siteId,
      state,
      street1,
      street2,
      zip
    };
    const url = `${urlBase}/api/v2/gifts/estimate_price${stringifyParams(
      rawParams
    )}`;

    return this.fetchWrapper<GetGiftTaxResponse>({
      headers,
      isAuthenticated: true,
      url
    });
  }

  // TODO: https://griosf.atlassian.net/browse/RVLS-3696
  public getTax(request: GetTaxRequest): Promise<GetTaxResponse> {
    const { headers, urlBase } = this.apiConfig;
    const {
      address: { city, state, zip, street1, street2 },
      plan,
      promoCode,
      salePrice,
      siteId
    } = request;

    const rawParams = {
      city,
      plan,
      promoCode,
      salePrice,
      siteId,
      state,
      street1,
      street2,
      zip
    };
    if (promoCode) {
      rawParams.promoCode = promoCode.toUpperCase();
    }
    const url = `${urlBase}/api/v2/users/billing_information/estimate_tax${stringifyParams(
      rawParams
    )}`;

    return this.fetchWrapper<GetTaxResponse>({
      headers,
      isAuthenticated: true,
      url
    });
  }

  public getAddOnPricing(
    request: GetAddOnPricingRequest
  ): Promise<GetAddOnPricingResponse> {
    const { headers, urlBase } = this.apiConfig;
    const url = `${urlBase}/api/v2/users/billing_information/estimate_add_on_price?add_on_id=${request.addOnId}&subscription_id=${request.subscriptionId}`;

    return this.fetchWrapper<GetAddOnPricingResponse>({
      headers,
      isAuthenticated: true,
      url
    });
  }

  public getUpgradingAddOns(
    request: GetUpgradingAddOnsRequest
  ): Promise<GetUpgradingAddOnsResponse> {
    const { headers, urlBase } = this.apiConfig;
    const url = `${urlBase}/api/v2/users/subscriptions/${request.subscriptionId}/get_upgrading_add_ons`;

    return this.fetchWrapper<GetUpgradingAddOnsResponse>({
      headers,
      isAuthenticated: true,
      url
    });
  }

  public getAdminUpgradingUser(
    request: GetAdminUpgradingUserRequest
  ): Promise<GetAdminUpgradingUserResponse> {
    const { headers, urlBase } = this.apiConfig;
    const url = `${urlBase}/api/v2/admin/users/subscriptions/${request.subscriptionId}`;

    return this.fetchWrapper<GetAdminUpgradingUserResponse>({
      headers,
      isAuthenticated: true,
      url
    });
  }

  public loginUser(request: LoginUserRequest): Promise<LoginUserResponse> {
    const { headers, urlBase } = this.apiConfig;
    const url = `${urlBase}/api/user/sign_in`;

    return this.fetchWrapper<LoginUserAPIResponse>({
      body: {
        user: request
      },
      headers,
      method: HTTP_METHODS.POST,
      url
    }).then(responseJSON => {
      return { user: responseJSON };
    });
  }

  public logoutUser(): Promise<LogoutUserAPIResponse> {
    const { headers, urlBase } = this.apiConfig;
    const url = `${urlBase}/api/user/sign_out`;

    return this.fetchWrapper<LogoutUserAPIResponse>({
      headers,
      method: HTTP_METHODS.DELETE,
      url
    });
  }

  public payOutstandingBalance(): Promise<PayOutstandingBalanceResponse> {
    const { headers, urlBase } = this.apiConfig;
    const url = `${urlBase}/api/v2/users/billing_information/collect_osb`;

    return this.fetchWrapper<PayOutstandingBalanceResponse>({
      headers,
      method: HTTP_METHODS.POST,
      url
    });
  }

  public predictTextSearch({
    term
  }: SearchRequest): Promise<PredictTextSearchResponse> {
    const { headers, urlBase } = this.apiConfig;
    const url = `${urlBase}/api/v2/search_bar_search/predict_text?search[query]=${term}`;

    return this.fetchWrapper<PredictTextSearchResponse>({
      headers,
      url
    });
  }

  public prospectSearch({
    limit = SEARCH_OVERLAY_LIMITS.PROSPECTS,
    term
  }: SearchRequest): Promise<ProspectSearchResponse> {
    const { headers, urlBase } = this.apiConfig;
    const pageSize = limit.toString();
    const url = `${urlBase}/api/v2/people`;

    return this.fetchWrapper<ProspectSearchResponse>({
      body: {
        search: {
          fullProspectProfile: "true",
          member: "Prospect",
          pageSize,
          query: term
        }
      },
      headers,
      method: HTTP_METHODS.POST,
      url
    });
  }

  public purchaseAddOn(
    request: PurchaseAddOnRequest
  ): Promise<PurchaseAddOnResponse> {
    const { headers, urlBase } = this.apiConfig;
    const url = `${urlBase}/api/v2/users/subscription_add_ons`;

    return this.fetchWrapper<PurchaseAddOnResponse>({
      body: {
        subscriptionAddOn: {
          addOnIds: [request.addOnId],
          siteIds: request.sitesIds
        }
      },
      headers,
      method: HTTP_METHODS.POST,
      url
    });
  }

  public reactivateSubscription(
    request: ReactivateSubscriptionRequest
  ): Promise<ReactivateSubscriptionResponse> {
    const { headers, urlBase } = this.apiConfig;
    const url = `${urlBase}/api/v2/users/subscriptions/${request.subscriptionId}/reactivate`;

    return this.fetchWrapper<ReactivateSubscriptionResponse>({
      headers,
      method: HTTP_METHODS.PUT,
      url
    });
  }

  public redeemGift(request: RedeemGiftParams): Promise<RedeemGiftResponse> {
    const { headers, urlBase } = this.apiConfig;
    const url = `${urlBase}/api/v2/registrations/gift_redeem`;

    return this.fetchWrapper<RedeemGiftResponse>({
      body: {
        giftRedeem: { ...request }
      },
      headers,
      method: HTTP_METHODS.POST,
      url
    });
  }

  public removeAddOn(request: RemoveAddOnRequest): Promise<{}> {
    const { headers, urlBase } = this.apiConfig;
    const url = `${urlBase}/api/v2/users/subscription_add_ons`;

    return this.fetchWrapper<{}>({
      body: {
        subscriptionAddOn: {
          addOnIds: [request.addOnId],
          subscriptionId: request.subscriptionId
        }
      },
      headers,
      method: HTTP_METHODS.DELETE,
      url
    });
  }

  public requestConfirmationEmail(
    email: string
  ): Promise<RequestConfirmationEmailResponse> {
    const { headers, urlBase } = this.apiConfig;
    const url = `${urlBase}/api/user/confirm_email`;

    return this.fetchWrapper<RequestConfirmationEmailResponse>({
      body: {
        user: { email }
      },
      headers,
      method: HTTP_METHODS.POST,
      url
    });
  }

  public updateIsProspectFollowed(
    params: UpdateIsProspectFollowedRequest
  ): Promise<{}> {
    const { headers, urlBase } = this.apiConfig;
    const url = `${urlBase}/api/v2/users/follow`;

    return this.fetchWrapper({
      body: {
        follow: {
          id: params.id,
          type: "Prospect"
        }
      },
      headers,
      method: params.follow ? HTTP_METHODS.PUT : HTTP_METHODS.DELETE,
      url
    });
  }

  public searchColleges(request: string): Promise<SearchCollegesResponse> {
    const { headers, urlBase } = this.apiConfig;
    const url = `${urlBase}/api/v2/colleges?search[query]=${request}`;

    return this.fetchWrapper({
      headers,
      isAuthenticated: true,
      url
    });
  }

  public strictSignIn(
    request: StrictSignInRequest
  ): Promise<StrictSignInResponse> {
    const { headers, urlBase } = this.apiConfig;
    const url = `${urlBase}/api/v2/users/strict_sign_in`;

    return this.fetchWrapper<StrictSignInResponse>({
      body: {
        password: request.password
      },
      headers,
      method: HTTP_METHODS.POST,
      url
    });
  }

  public updateBillingInformation(
    request: UpdateBillingInformationRequest
  ): Promise<UpdateBillingInformationResponse> {
    const { headers, urlBase } = this.apiConfig;
    const url = `${urlBase}/api/v2/users/billing_information/update`;

    return this.fetchWrapper<UpdateBillingInformationResponse>({
      body: { billingInformation: request },
      headers,
      method: HTTP_METHODS.PUT,
      url
    });
  }

  public updateCollegeTransfer({
    collegeTransfer
  }: UpdateCollegeTransferRequest): Promise<UpdateCollegeTransferResponse> {
    const { headers, urlBase } = this.apiConfig;
    const {
      classYear,
      enterDate,
      entryType,
      exitCollegeId,
      exitDate,
      exitType,
      intentDate,
      originCollegeId,
      rivalsRating,
      stars,
      collegePlayerId,
      id
    } = collegeTransfer;
    const url = `${urlBase}/api/v2/college_players/${collegePlayerId}/college_transfers/${id}`;

    const bodyParams = {
      classYear: classYear,
      enterDate: enterDate || null,
      entryType: entryType,
      exitCollegeId: exitCollegeId,
      exitDate: exitDate,
      exitType: exitType,
      intentDate: intentDate || null,
      originCollegeId: originCollegeId,
      rivalsRating: rivalsRating,
      stars: stars
    };

    return this.fetchWrapper<UpdateCollegeTransferResponse>({
      body: bodyParams,
      headers,
      method: HTTP_METHODS.PATCH,
      url
    });
  }

  public updateCurrentUser(
    request: UpdateUserRequest
  ): Promise<UpdateUserResponse> {
    const { headers, urlBase } = this.apiConfig;
    const url = `${urlBase}/api/v2/users/update`;

    return this.fetchWrapper<UpdateUserResponse>({
      body: {
        user: request
      },
      headers,
      method: HTTP_METHODS.PATCH,
      url
    });
  }

  public updateCsAdminPermissions(
    request: UpdateCsAdminPermissionsRequest
  ): Promise<UpdateCsAdminPermissionsResponse> {
    const { headers, urlBase } = this.apiConfig;
    const url = `${urlBase}/api/v2/admin/reports/cs_admin/update`;

    return this.fetchWrapper<UpdateCsAdminPermissionsResponse>({
      body: {
        users: request
      },
      headers,
      method: HTTP_METHODS.PATCH,
      url
    });
  }

  public updateImageTag(
    request: ImageTagParams
  ): Promise<UpdateImageTagResponse> {
    const { headers, urlBase } = this.apiConfig;
    const url = `${urlBase}/api/v2/image_tags`;
    const body = { imageTag: request };
    return this.fetchWrapper<UpdateImageTagResponse>({
      body,
      headers,
      method: HTTP_METHODS.PATCH,
      url
    });
  }

  public updatePassword({
    currentPassword,
    newPassword
  }: {
    currentPassword: string;
    newPassword: string;
  }): Promise<UpdatePasswordResponse> {
    const { headers, urlBase } = this.apiConfig;
    const url = `${urlBase}/api/v2/users/update_password`;

    return this.fetchWrapper<UpdatePasswordResponse>({
      body: {
        user: {
          currentPassword,
          password: newPassword
        }
      },
      headers,
      method: HTTP_METHODS.PUT,
      url
    });
  }

  public cancelSubscription(
    request: CancelSubscriptionRequest
  ): Promise<CancelSubscriptionResponse> {
    const { headers, urlBase } = this.apiConfig;
    const url = `${urlBase}/api/v2/users/subscriptions/${request.subscriptionId}`;

    return this.fetchWrapper<CancelSubscriptionResponse>({
      body: {
        // TODO: https://griosf.atlassian.net/browse/RVLS-3826
        // TODO: REF: https://www.herocoders.com/blog-posts/magic-behind-rails-query-params
        // TODO: research Rails wrap_parameters as to why this nesting is required and not happening automatically on the backend.
        // Currently if you do not nest params under the model rails will append an empty hash for that model within the params causing issues.
        subscription: {
          note: request.cancelText,
          reason: request.cancelReason
        }
      },
      headers,
      method: HTTP_METHODS.DELETE,
      url
    });
  }

  public checkGiftToken(
    request: CheckGiftTokenRequest
  ): Promise<CheckGiftTokenResponse> {
    const { headers, urlBase } = this.apiConfig;
    const url = `${urlBase}/api/v2/registrations/gift_check?token=${request.token}&siteName=${request.siteName}`;

    return this.fetchWrapper<CheckGiftTokenResponse>({
      headers,
      isAuthenticated: true,
      url
    });
  }

  public createAccount(
    request: CreateAccountRequest
  ): Promise<CreateAccountResponse> {
    const { headers, urlBase } = this.apiConfig;
    const url = `${urlBase}/api/v2/registrations`;

    return this.fetchWrapper<CreateAccountResponse>({
      body: request,
      displaySpinner: true,
      headers,
      method: HTTP_METHODS.POST,
      shouldHandleError: false,
      url
    });
  }

  public createForecast(
    request: CreateForecastRequest
  ): Promise<CreateForecastResponse> {
    const { headers, urlBase } = this.apiConfig;
    const url = `${urlBase}/api/v2/futurecast/new`;

    return this.fetchWrapper<CreateForecastResponse>({
      body: {
        forecast: {
          ...request
        }
      },
      headers,
      method: HTTP_METHODS.POST,
      url
    });
  }

  public createImage(
    request: CreateImageRequest
  ): Promise<CreateImageResponse> {
    const { headers, urlBase } = this.apiConfig;
    const url = `${urlBase}/api/v2/media/images`;
    return this.fetchWrapper<CreateImageResponse>({
      body: request,
      headers,
      method: HTTP_METHODS.POST,
      url
    });
  }

  public validateForecast(
    request: CreateForecastRequest
  ): Promise<ForecastValidationResponse> {
    const { headers, urlBase } = this.apiConfig;
    const url = `${urlBase}/api/v2/futurecast/validate`;

    return this.fetchWrapper<ForecastValidationResponse>({
      body: {
        forecast: {
          ...request
        }
      },
      headers,
      method: HTTP_METHODS.PUT,
      url
    });
  }

  public createSubscription(
    request: CreateSubscriptionRequest
  ): Promise<CreateSubscriptionResponse> {
    const { headers, urlBase } = this.apiConfig;
    const url = `${urlBase}/api/v2/users/subscriptions`;

    return this.fetchWrapper<CreateSubscriptionResponse>({
      body: {
        subscription: {
          ...request
        }
      },
      displaySpinner: true,
      headers,
      method: HTTP_METHODS.POST,
      url
    });
  }

  public emailOffer(request: EmailOfferRequest): Promise<EmailOfferResponse> {
    const { headers, urlBase } = this.apiConfig;
    const url = `${urlBase}/api/v2/users/subscriptions/${request.subscriptionId}/cancellation_save_email`;

    return this.fetchWrapper<EmailOfferResponse>({
      headers,
      method: HTTP_METHODS.POST,
      url
    });
  }

  public extendMgmSubscription(
    request: ExtendMgmSubscriptionRequest
  ): Promise<ExtendMgmSubscriptionResponse> {
    const { headers, urlBase } = this.apiConfig;
    const url = `${urlBase}/api/v2/users/mgm/extension`;

    return this.fetchWrapper<ExtendMgmSubscriptionResponse>({
      body: { subscriptionId: request.subscriptionId },
      headers,
      method: HTTP_METHODS.POST,
      url
    });
  }

  public getActivePromotion(
    request: GetActivePromotionRequest
  ): Promise<Promotion> {
    const { headers, urlBase } = this.apiConfig;
    const { promoCode } = request;
    const url = `${urlBase}/api/v2/promotions/active?code=${promoCode}`;

    return this.fetchWrapper<Promotion>({
      headers,
      isAuthenticated: true,
      url
    });
  }

  public getSaveEligibility(
    request: GetSaveEligibilityRequest
  ): Promise<GetSaveEligibilityResponse> {
    const { headers, urlBase } = this.apiConfig;
    const { subscriptionId } = request;
    const url = `${urlBase}/api/v2/users/subscriptions/${subscriptionId}/cancellation_save_eligibility`;

    return this.fetchWrapper<GetSaveEligibilityResponse>({
      headers,
      isAuthenticated: true,
      url
    });
  }

  // TODO: https://griosf.atlassian.net/browse/RVLS-3704
  public upgradeToAnnual(request: UpgradeToAnnualRequest): Promise<Response> {
    const { headers, urlBase } = this.apiConfig;
    const { subscriptionId, userId } = request;
    const url = `${urlBase}/api/v2/users/${userId}/subscriptions/${subscriptionId}`;

    return this.fetchWrapper<Response>({
      body: {
        subscription: {
          autoRenewalOptIn: request.autoRenewalOptIn,
          promoCode: request.promoCode,
          subscriptionPlan: request.subscriptionPlan
        }
      },
      displaySpinner: true,
      headers,
      method: HTTP_METHODS.PATCH,
      url
    });
  }

  public adminUpgradeUserSubscription(
    request: AdminUpgradeUserSubscriptionRequest
  ): Promise<Response> {
    const { headers, urlBase } = this.apiConfig;
    const { subscriptionId } = request;
    const url = `${urlBase}/api/v2/admin/users/subscriptions/${subscriptionId}/upgrade_to_annual`;

    return this.fetchWrapper<Response>({
      body: {
        autoRenewalOptIn: request.autoRenewalOptIn,
        promoCode: request.promoCode,
        subscriptionPlan: PLANS.ANNUAL
      },
      headers,
      method: HTTP_METHODS.PUT,
      url
    });
  }

  public validatePromoCode(
    request: ValidatePromoCodeRequest
  ): Promise<Promotion> {
    const { headers, urlBase } = this.apiConfig;
    let url = `${urlBase}/api/v2/promotions/validation?code=${request.promotion.code}&plan=${request.promotion.planType}&site_id=${request.promotion.siteId}`;
    if (request.promotion.subscriptionId) {
      url = `${url}&subscription_id=${request.promotion.subscriptionId}`;
    }
    if (request.promotion.referAFriendCode) {
      url = `${url}&refer_friend_id=${request.promotion.referAFriendCode}`;
    }
    if (request.promotion.userId) {
      url = `${url}&user_id=${request.promotion.userId}`;
    }

    return this.fetchWrapper<Promotion>({
      headers,
      isAuthenticated: true,
      url
    });
  }

  public validateEmailOrUsername(
    request: ValidationParamsRequest
  ): Promise<Response> {
    const { headers, urlBase } = this.apiConfig;
    const { property, value } = request;
    const url = `${urlBase}/api/v2/users/validate?${new URLSearchParams({
      property,
      value
    })}`;

    return this.fetchWrapper<Response>({
      headers,
      isAuthenticated: true,
      url
    });
  }

  public getUserForumInfo(
    request: UserForumInfoRequest
  ): Promise<UserForumInfoResponse> {
    const { headers, urlBase } = this.apiConfig;
    const { id, query } = request;
    const params = new URLSearchParams({});
    if (query) {
      params.append("query", query);
    }
    const url = `${urlBase}/api/v2/admin/users/${id}/forum_info?${params}`;

    return this.fetchWrapper<UserForumInfoResponse>({
      headers,
      isAuthenticated: true,
      url
    });
  }

  public getUserTrackingHistory(
    request: UserTrackingHistoryRequest
  ): Promise<Response> {
    const { headers, urlBase } = this.apiConfig;
    const {
      id,
      page,
      perPage,
      query,
      pathFilters,
      creationDateSort,
      occurrenceCountSort
    } = request;
    const params = new URLSearchParams({
      page: `${page}`,
      per_page: `${perPage}` // eslint-disable-line @typescript-eslint/naming-convention
    });
    if (pathFilters && pathFilters.length) {
      params.append("filters", pathFilters.join(","));
    }
    if (query) {
      params.append("query", query);
    }
    if (creationDateSort) {
      params.append("creation_date_sort", creationDateSort);
    }
    if (occurrenceCountSort) {
      params.append("occurrence_count_sort", occurrenceCountSort);
    }
    const url = `${urlBase}/api/v2/admin/users/${id}/tracking?${params}`;

    return this.fetchWrapper<Response>({
      headers,
      isAuthenticated: true,
      url
    });
  }

  public getProspectTrackingHistory(
    request: ProspectTrackingHistoryRequest
  ): Promise<Response> {
    const { headers, urlBase } = this.apiConfig;
    const { id, page, perPage, creationDateSort } = request;
    const params = new URLSearchParams({
      page: `${page}`,
      per_page: `${perPage}` // eslint-disable-line @typescript-eslint/naming-convention
    });
    if (creationDateSort) {
      params.append("creation_date_sort", creationDateSort);
    }
    const url = `${urlBase}/api/v2/admin/users/${id}/prospect_tracking?${params}`;

    return this.fetchWrapper<Response>({
      headers,
      isAuthenticated: true,
      url
    });
  }

  public getUserHistoryTrackingHistory(
    request: UserHistoryTrackingHistoryRequest
  ): Promise<Response> {
    const { headers, urlBase } = this.apiConfig;
    const { id, page, perPage, creationDateSort } = request;
    const params = new URLSearchParams({
      page: `${page}`,
      per_page: `${perPage}` // eslint-disable-line @typescript-eslint/naming-convention
    });
    if (creationDateSort) {
      params.append("creation_date_sort", creationDateSort);
    }
    const url = `${urlBase}/api/v2/admin/users/${id}/user_history_tracking?${params}`;

    return this.fetchWrapper<Response>({
      headers,
      isAuthenticated: true,
      url
    });
  }

  public getCoaches(params: GetCoachesRequest): Promise<GetCoachesResponse> {
    const { headers, urlBase } = this.apiConfig;
    const { page, perPage, query, collegeId = "" } = params;
    const searchParams = new URLSearchParams({
      page: `${page}`,
      per_page: `${perPage}`, // eslint-disable-line @typescript-eslint/naming-convention
      q: query
    });

    if (collegeId) {
      searchParams.append("college_id", `${collegeId}`);
    }
    const url = `${urlBase}/api/v2/admin/coaches?${searchParams}`;

    return this.fetchWrapper<GetCoachesResponse>({
      headers,
      isAuthenticated: true,
      url
    });
  }

  public getCareerHighlights(
    request: GetCareerHighlightsRequest
  ): Promise<GetCareerHighlightsResponse> {
    const { headers, urlBase } = this.apiConfig;
    const { personId } = request;
    const url = `${urlBase}/api/v2/people/${personId}/events`;

    return this.fetchWrapper<GetCareerHighlightsResponse>({
      headers,
      isAuthenticated: true,
      url
    });
  }

  public fetchListData(
    request: FetchListDataRequest
  ): Promise<FetchListDataResponse> {
    const { headers, urlBase } = this.apiConfig;
    let url = "";

    if (request.id) {
      url = `${urlBase}/api/v2/lists/${request.id}`;
    } else {
      const searchParams = new URLSearchParams({
        kind: `${request.kind}`,
        sport: `${request.sport}`,
        year: `${request.year}`
      });
      if (request.version) {
        searchParams.append("version", request.version);
      }
      url = `${urlBase}/api/v2/lists?${searchParams}`;
    }

    return this.fetchWrapper<FetchListDataResponse>({
      headers,
      isAuthenticated: true,
      url
    });
  }

  public getContentListPeople(
    request: GetContentListPeopleRequest
  ): Promise<GetContentListPeopleResponse> {
    const { headers, urlBase } = this.apiConfig;
    const snakeCasedRequest = snakeCaseKeys(request);
    const searchParams = new URLSearchParams(snakeCasedRequest);

    if (!request.version) {
      // If version is undefined, remove it from the params
      searchParams.delete("version");
    }
    const url = `${urlBase}/api/v2/lists_people?${searchParams}`;

    return this.fetchWrapper<GetContentListPeopleResponse>({
      headers,
      url
    });
  }

  public fetchTransfers(
    request: FetchTransfersRequest
  ): Promise<FetchTransfersResponse> {
    const { headers, urlBase } = this.apiConfig;
    const snakeCasedRequest = snakeCaseKeys(request);
    const searchParams = new URLSearchParams({});

    Object.entries(snakeCasedRequest).map(([key, value]) => {
      if (value !== undefined) {
        searchParams.append(key, value);
      }
    });

    const url = `${urlBase}/api/v2/transfers/recent_transfers?${searchParams}`;

    return this.fetchWrapper<FetchTransfersResponse>({
      headers,
      isAuthenticated: true,
      url
    });
  }

  public yahooProfileSearch(
    personId: number
  ): Promise<YahooProfileSearchResponse> {
    const { headers, urlBase } = this.apiConfig;
    const url = `${urlBase}/api/v2/athletes/yahoo_profile_search?person_id=${personId}`;
    return this.fetchWrapper<YahooProfileSearchResponse>({
      headers,
      isAuthenticated: true,
      url
    });
  }

  public generateAddOnReport({
    dateRange,
    plan
  }: GenerateAddOnReportRequest): Promise<{}> {
    const { headers, urlBase } = this.apiConfig;
    const url = `${urlBase}/api/v2/admin/reports/add_on_reports/generate_report`;

    return this.fetchWrapper<{}>({
      body: snakeCaseKeys({
        addOnReport: {
          endDate: dateRange.pop(),
          plan: plan,
          startDate: dateRange.shift()
        }
      }),
      headers,
      method: HTTP_METHODS.POST,
      url
    });
  }

  public listAddOnReports(): Promise<ListAddOnReportsResponse> {
    const { headers, urlBase } = this.apiConfig;
    const url = `${urlBase}/api/v2/admin/reports/add_on_reports`;

    return this.fetchWrapper<ListAddOnReportsResponse>({
      headers,
      isAuthenticated: true,
      url
    });
  }

  public generateGoogleAnalyticsReport(
    request: GenerateGoogleAnalyticsReportRequest
  ): Promise<{}> {
    const { headers, urlBase } = this.apiConfig;
    const url = `${urlBase}/api/v2/admin/reports/google_analytics/generate_report`;

    return this.fetchWrapper<{}>({
      body: request,
      headers,
      method: HTTP_METHODS.POST,
      url
    });
  }

  public fetchGoogleAnalyticsReports(): Promise<
    FetchGoogleAnalyticsReportsResponse
  > {
    const { headers, urlBase } = this.apiConfig;
    const url = `${urlBase}/api/v2/admin/reports/google_analytics/reports`;

    return this.fetchWrapper<FetchGoogleAnalyticsReportsResponse>({
      headers,
      isAuthenticated: true,
      url
    });
  }

  public updateAnalyticsMonitoredSites(
    request: UpdateAnalyticsMonitoredSitesRequest
  ): Promise<{}> {
    const { headers, urlBase } = this.apiConfig;
    const url = `${urlBase}/api/v2/admin/reports/google_analytics/update_monitored_sites`;

    return this.fetchWrapper<{}>({
      body: request,
      headers,
      method: HTTP_METHODS.PATCH,
      url
    });
  }

  public getTeamRankings(
    params: GetTeamRankingsRequest
  ): Promise<GetTeamRankingsResponse> {
    const { headers, urlBase } = this.apiConfig;
    const url = `${urlBase}/api/v2/team_rankings?${cleanParams(params)}`;

    return this.fetchWrapper<GetTeamRankingsResponse>({
      headers,
      isAuthenticated: true,
      url
    });
  }

  public getTeamRankingsYears(): Promise<GetTeamRankingsYearsResponse> {
    const { headers, urlBase } = this.apiConfig;
    const url = `${urlBase}/api/v2/team_rankings/available_years`;

    return this.fetchWrapper<GetTeamRankingsYearsResponse>({
      headers,
      isAuthenticated: true,
      url
    });
  }

  public fetchHighschoolGroups(
    request: FetchHighschoolGroupsRequest
  ): Promise<FetchHighschoolGroupsResponse> {
    const { headers, urlBase } = this.apiConfig;
    const params = new URLSearchParams(snakeCaseKeys(request || {}));
    const url = `${urlBase}/api/v2/highschool_groups?${params.toString()}`;

    return this.fetchWrapper({
      headers,
      isAuthenticated: true,
      url
    });
  }

  public fetchHighschoolGroup({
    region,
    ...request
  }: FetchHighschoolGroupRequest): Promise<FetchHighschoolGroupResponse> {
    const { headers, urlBase } = this.apiConfig;
    const params = new URLSearchParams(snakeCaseKeys(request || {}));
    const url = `${urlBase}/api/v2/highschool_groups/${region}?${params.toString()}`;

    return this.fetchWrapper({
      headers,
      isAuthenticated: true,
      url
    });
  }

  public fetchTeamsNavItems(): Promise<FetchTeamsNavItemsResponse> {
    const { headers, urlBase } = this.apiConfig;
    const url = `${urlBase}/api/v2/nav_teams`;

    return this.fetchWrapper({
      headers,
      isAuthenticated: true,
      url
    });
  }

  public getCommitments(
    params: CommitmentsRequest
  ): Promise<CommitmentsResponse> {
    const { headers, urlBase } = this.apiConfig;
    const url = `${urlBase}/api/v2/commitments?${cleanParams(params)}`;

    return this.fetchWrapper<CommitmentsResponse>({
      cacheDurationSeconds: CACHE_SECONDS_SMALL,
      headers,
      url
    });
  }

  /**
   * This api is intened to be used by the server only
   *
   * Leverages the backend authentication pattern to prevent access
   * without the proper API key
   */
  public getFlipper(): Promise<GetFlipperResponse> {
    if (!this.isServer || !process.env.FLIPPER_API_KEY) {
      throw "getFlipper is only available on the server";
    }

    const { headers, urlBase } = this.apiConfig;

    // Additional keys could be added here when/if needed
    const flipperKeys = [
      "enable_infinite_scroll_articles",
      "gifting",
      "enable_notifications"
    ];

    const params = flipperKeys.map(key => `keys[]=${snakeCase(key)}`).join("&");

    const url = `${urlBase}/api/v2/flipper?${params}`;

    return this.fetchWrapper<GetFlipperResponse>({
      cacheDurationSeconds: CACHE_SECONDS_SMALL,
      headers: {
        ...headers,
        Authorization: `token token=${process.env.FLIPPER_API_KEY}`
      },
      url
    });
  }

  public getForumsFeeds(
    params: GetForumsFeedsRequest
  ): Promise<GetForumsFeedsResponse> {
    const { headers, urlBase } = this.apiConfig;
    const url = `${urlBase}/api/v2/forums/feeds?site_id=${params.siteId}`;

    return this.fetchWrapper<GetForumsFeedsResponse>({
      headers,
      isAuthenticated: true,
      url
    });
  }

  public getPageConfig(
    params: GetPageConfigRequest
  ): Promise<GetPageConfigResponse> {
    const { headers, urlBase } = this.apiConfig;
    const { id, ...searchParams } = params;

    const url = `${urlBase}/api/v2/page_configs/${id}?${cleanParams(
      searchParams
    )}`;

    return this.fetchWrapper<GetPageConfigResponse>({
      headers,
      isAuthenticated: true,
      url
    });
  }

  public getTopTargets(
    params: GetTopTargetsRequest
  ): Promise<GetTopTargetsResponse> {
    const { headers, urlBase } = this.apiConfig;
    const queryParams = new URLSearchParams(snakeCaseKeys(params));
    if (!params.year) {
      queryParams.delete("year");
    }
    const url = `${urlBase}/api/v2/top_targets?${queryParams}`;

    return this.fetchWrapper<GetTopTargetsResponse>({
      headers,
      isAuthenticated: true,
      url
    });
  }

  public getHeadlineNewsContent(
    params: GetHeadlineNewsContentRequest
  ): Promise<GetHeadlineNewsContentResponse> {
    const { headers, urlBase } = this.apiConfig;
    const url = `${urlBase}/api/v2/content/contents/headline_news_feed?${cleanParams(
      params
    )}`;

    return this.fetchWrapper<GetHeadlineNewsContentResponse>({
      cacheDurationSeconds: CACHE_SECONDS_SMALL,
      headers,
      url
    });
  }

  public getInfiniteScrollContentMeta(
    request: FetchContentRequest
  ): Promise<GetInfiniteScrollContentMetaResponse> {
    const { headers, urlBase } = this.apiConfig;
    const url = `${urlBase}/api/v2/content/contents/infinite_feed?site_id=${request.siteId}&current_content[]=${request.slug}`;

    return this.fetchWrapper<GetInfiniteScrollContentMetaResponse>({
      headers,
      isAuthenticated: true,
      url
    });
  }

  public getCustomModuleContent(
    params: GetCustomModuleContentRequest
  ): Promise<GetCustomModuleContentResponse> {
    const { headers, urlBase } = this.apiConfig;
    const url = `${urlBase}/api/v2/custom_modules/${params.id}?${cleanParams(
      params
    )}`;

    return this.fetchWrapper<GetCustomModuleContentResponse>({
      headers,
      isAuthenticated: true,
      url
    });
  }

  public getDefaultCustomModuleContent(
    params: GetDefaultCustomModuleContentRequest
  ): Promise<GetCustomModuleContentResponse> {
    const { headers, urlBase } = this.apiConfig;
    const url = `${urlBase}/api/v2/custom_modules/default?${cleanParams(
      params
    )}`;

    return this.fetchWrapper<GetCustomModuleContentResponse>({
      headers,
      isAuthenticated: true,
      url
    });
  }

  public getSponsor(params: GetSponsorRequest): Promise<GetSponsorResponse> {
    const { headers, urlBase } = this.apiConfig;
    const { id } = params;
    const url = `${urlBase}/api/v2/sponsors/${id}`;

    return this.fetchWrapper<GetSponsorResponse>({
      headers,
      isAuthenticated: true,
      url
    });
  }

  public getTopForumsSites(): Promise<GetTopForumsSitesResponse> {
    const { headers, urlBase } = this.apiConfig;
    const url = `${urlBase}/api/v2/forums/feeds/top_sites`;

    return this.fetchWrapper<GetTopForumsSitesResponse>({
      headers,
      isAuthenticated: true,
      url
    });
  }

  public fetchNotificationSettings(): Promise<GetNotificationSettingsResponse> {
    const { headers, urlBase } = this.apiConfig;
    const url = `${urlBase}/api/v2/users/notification_settings`;

    return this.fetchWrapper<GetNotificationSettingsResponse>({
      headers,
      isAuthenticated: true,
      url
    });
  }
}
