import { createSelector } from "reselect";
import { State as AppState, StateKeys } from "rivals/redux/reducer";
import { State } from "./reducer";

export const getAuthentication = (state: AppState): State =>
  state[StateKeys.AUTH];

export const getError = createSelector(getAuthentication, state => state.error);

export const isAuthenticated = createSelector(
  getAuthentication,
  state => state.authenticated
);
