import { ThemeConfig } from "antd";
import { anchorComponentToken } from "rivals/shared/design/component_tokens/anchor";
import legacyDesignToken from "rivals/shared/design/design_tokens/legacy";

const legacyTheme: ThemeConfig = {
  components: {
    Anchor: anchorComponentToken
  },
  token: legacyDesignToken
};

export default legacyTheme;
