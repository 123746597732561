import { AliasToken } from "antd/es/theme/interface";
import styles from "rivals/styles/exports.module.scss";

// This theme is meant to be used in conjunction with the deprecated global styles located in pages/index.scss
export const legacyDesignToken: Partial<AliasToken> = {
  borderRadius: parseFloat(styles["spacing-xxs"]),
  colorBgSpotlight: styles["white"],
  colorLink: styles["call-to-action-blue"],
  colorPrimary: styles["call-to-action-blue"],
  colorTextBase: "rgba(0,0,0,.85)",
  colorTextLightSolid: styles["black"],
  fontFamily:
    "-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji",
  fontSize: parseFloat(styles["font-size-default"]),
  lineHeight: parseFloat(styles["line-height-default"]),
  linkDecoration: "none"
};

export default legacyDesignToken;
