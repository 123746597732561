import { capitalizeWords } from "rivals/shared/utils/strings";
import type { AdminLinks, NavOptions } from "./types";

export function parseNavItems(navItems: AdminLinks): NavOptions {
  const result: NavOptions = {};

  if (navItems) {
    Object.keys(navItems).forEach(key => {
      result[key] = {
        description: "",
        items: {},
        link: navItems[key as keyof typeof navItems],
        nofollow: 0,
        noreferrer: 0,
        target: "",
        title: capitalizeWords(key)
      };
    });
  }

  return result;
}
