import {
  RegistrationAction,
  RegistrationActions
} from "rivals/redux/registration/actions";
import { AuthAction, AuthActions } from "./actions";

export type State = {
  authenticated: boolean;
  error?: string;
};

const initialState = {
  authenticated: false,
  error: undefined
};

export default function reducer(
  state: State = initialState,
  action: AuthAction | RegistrationAction
): State {
  switch (action.type) {
    case RegistrationActions.CREATE_ACCOUNT_SUCCESS:
    case AuthActions.LOGIN_USER_SUCCESS: {
      return {
        authenticated: true
      };
    }
    case RegistrationActions.CREATE_ACCOUNT_PAYMENT_FAILURE: {
      return {
        authenticated: true,
        error: action.payload.errors ? action.payload.errors.payment_issue : ""
      };
    }
    case AuthActions.LOGIN_USER_FAILURE: {
      return {
        authenticated: false,
        error: action.payload.error
      };
    }
    case AuthActions.LOGOUT_USER_SUCCESS: {
      return {
        authenticated: false
      };
    }
    case AuthActions.LOGOUT_USER_FAILURE: {
      return {
        ...state,
        error: action.payload.error
      };
    }
    case AuthActions.SET_AUTHENTICATION: {
      return {
        ...state,
        authenticated: action.payload.authenticated
      };
    }
    default: {
      return state;
    }
  }
}
