import classNames from "classnames";
import React from "react";
import styles from "rivals/components/app/Layout/Footer/Footer.module.scss";
import strings from "rivals/components/app/Layout/Footer/strings";
import Link from "rivals/components/shared/Link";
import { PATHS } from "rivals/shared/constants";
import type { Props } from "./types";

const {
  giftLinkText,
  aboutLinkText,
  contactLinkText,
  footerText,
  footerCopyrightText,
  helpLinkText,
  tosLinkText
} = strings;

const { FOOTER_ABOUT_LINK, CONTACT, MEMBER_SERVICES, FOOTER_TOS_LINK } = PATHS;

export default function FooterLinks({
  areArticleLinks = false,
  children,
  giftingEnabled = false
}: Props): React.JSX.Element | null {
  const footerStyles = classNames(styles.footer, {
    [styles.articleFooter]: areArticleLinks
  });

  if (areArticleLinks) {
    return (
      <footer className={footerStyles} data-philter={"article-footer"}>
        <div className={styles.articleLinksContainer}>
          <div className={styles.footerLinks}>
            <Link
              className={styles.articleLinks}
              href={FOOTER_TOS_LINK}
              target="_blank"
            >
              {tosLinkText}
            </Link>
            {children}
            {giftingEnabled && (
              <Link href={PATHS.GIFTING} target="_blank">
                {giftLinkText}
              </Link>
            )}
          </div>
        </div>
      </footer>
    );
  }
  return (
    <footer className={styles.footer}>
      <div className={styles.footerLogo} />
      <div className={styles.footerLinks}>
        <Link href={FOOTER_ABOUT_LINK} target="_blank">
          {aboutLinkText}
        </Link>
        <Link href={MEMBER_SERVICES} target="_blank">
          {helpLinkText}
        </Link>
        <Link href={CONTACT} target="_blank">
          {contactLinkText}
        </Link>
      </div>
      <div className={styles.footerLinks}>
        <Link href={FOOTER_TOS_LINK} target="_blank">
          {tosLinkText}
        </Link>
      </div>
      <div className={styles.footerLinks}>
        {giftingEnabled && (
          <Link href={PATHS.GIFTING} target="_blank">
            {giftLinkText}
          </Link>
        )}
      </div>
      <div className={styles.footerLinks}>{children}</div>
      <div className={styles.copyrightSection}>
        <div
          className={styles.copyrightNotice}
          data-philter={"react-data-footer"}
        >
          {footerText}
        </div>
        <div
          className={styles.copyrightNotice}
          data-philter={"react-copyright-footer"}
        >
          {footerCopyrightText}
        </div>
      </div>
    </footer>
  );
}
