/* eslint-disable no-magic-numbers */
import { RIVALS_API_ERROR } from "rivals/shared/constants";

class RivalsApiError extends Error {
  public status: number;

  constructor(message: string, status: number) {
    if (status < 300) {
      status = 500;
    }
    super(message);
    this.name = RIVALS_API_ERROR;
    this.status = status;
  }
}

export default RivalsApiError;
