// TODO: https://griosf.atlassian.net/browse/RVLS-10668
function logger({
  message,
  severity = "info",
  type,
  page = ""
}: {
  message: string | Error;
  page: string;
  severity: "error" | "info" | "warn";
  type?: "error";
}): void {
  let prefix = "ReactLogger";
  // Formatting is preserved from original implementation
  switch (type) {
    case "error":
      prefix = `${prefix}Error(${page})`;
      break;
    default:
      prefix = `${prefix}(${page})`;
  }
  // eslint-disable-next-line no-console
  console[severity](`${prefix}: ${message}`);
}

export default logger;
