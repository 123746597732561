export enum AppActions {
  SET_HOST_URL = "SET_HOST_URL",
  SET_IS_REDIRECTING = "SET_IS_REDIRECTING",
  SET_IS_SUBMITTING_TO_SERVER = "SET_IS_SUBMITTING_TO_SERVER"
}

export const setHostUrl = (url?: string) =>
  ({
    payload: {
      hostUrl: url
    },
    type: AppActions.SET_HOST_URL
  } as const);

export const setIsRedirecting = (isRedirecting: boolean) =>
  ({
    payload: {
      isRedirecting
    },
    type: AppActions.SET_IS_REDIRECTING
  } as const);

export const setIsSubmittingToServer = (isSubmittingToServer: boolean) =>
  ({
    payload: {
      isSubmittingToServer
    },
    type: AppActions.SET_IS_SUBMITTING_TO_SERVER
  } as const);

export type AppAction =
  | ReturnType<typeof setHostUrl>
  | ReturnType<typeof setIsRedirecting>
  | ReturnType<typeof setIsSubmittingToServer>;
