import { Response as UpdateImageTagResponse } from "rivals/services/updateImageTag/types";
import { ImageTagParams } from "rivals/shared/interfaces/ImageTag";

export enum ImageTagActions {
  RESET_IMAGE_TAG = "RESET_IMAGE_TAG",
  UPDATE_IMAGE_TAG = "UPDATE_IMAGE_TAG",
  UPDATE_IMAGE_TAG_FAILURE = "UPDATE_IMAGE_TAG_FAILURE",
  UPDATE_IMAGE_TAG_SUCCESS = "UPDATE_IMAGE_TAG_SUCCESS"
}

export const resetImageTag = () =>
  ({ type: ImageTagActions.RESET_IMAGE_TAG } as const);

export const updateImageTag = (imageTagParams: ImageTagParams) =>
  ({
    payload: imageTagParams,
    type: ImageTagActions.UPDATE_IMAGE_TAG
  } as const);

export const updateImageTagFailure = (error?: string) =>
  ({
    error: true,
    payload: { error },
    type: ImageTagActions.UPDATE_IMAGE_TAG_FAILURE
  } as const);

export const updateImageTagSuccess = (response: UpdateImageTagResponse) =>
  ({
    payload: response.profileRemoved,
    type: ImageTagActions.UPDATE_IMAGE_TAG_SUCCESS
  } as const);

export type ImageTagAction =
  | ReturnType<typeof resetImageTag>
  | ReturnType<typeof updateImageTag>
  | ReturnType<typeof updateImageTagFailure>
  | ReturnType<typeof updateImageTagSuccess>;
