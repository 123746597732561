import { MenuProps } from "antd";

export const enum MenuVariant {
  Light = "light",
  Dark = "dark"
}

export interface Props extends MenuProps {
  variant?: MenuVariant;
}
