import { SWRResponse } from "swr";
import useQuery, {
  defaultUseQueryOptions,
  UseQueryOptions
} from "rivals/shared/hooks/useQuery/useQuery";
import { Site } from "rivals/shared/interfaces/Site";
import { useMountedState } from "rivals/shared/utils/hooks";
import { getSubdomain } from "rivals/shared/utils/routes";

export default function useGetSiteFromLocation(
  options: UseQueryOptions = defaultUseQueryOptions
): SWRResponse<Site> {
  // The purpose of tracking isMounted is to avoid consideration of window until after initial client side render in
  // order to enforce that the result of this hook is the same between the initial server side and client side renders.
  const isMounted = useMountedState();
  const subdomain = isMounted ? getSubdomain(window.location.host) : "";
  return useQuery<Site>(`/api/v2/sites/${subdomain}`, {
    ...options,
    shouldFetch: options.shouldFetch && !!subdomain
  });
}
