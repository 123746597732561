import { PurchasablePlan } from "rivals/shared/constants";

export enum AddOns {
  AD_LITE = "Ad-Lite",
  ALL_ACCESS = "All-Access"
}

interface BundleAddOn {
  brandingColorOverlay: string;
  fontColor: string;
  friendlyName: string;
  googleAnalyticsId?: number;
  headerLogoCloudinary?: string;
  headerName?: string;
  id: number;
  legacyId: number;
  name: string;
  primaryColor: string;
  publisherLetter?: string;
  secondaryColor: string;
  shortName: string;
  subdomain: string;
  subscribable: boolean;
  teamLogo: string;
}

export interface AddOn {
  accessableSiteNames: string | null;
  active: boolean | null;
  addOnGroup?: AddOnGroup;
  associatedSubscription?: { id: number; name: string; subscribed: boolean };
  cancelledAt?: string;
  comped: boolean | null;
  compedUntil: string | null;
  contentSiteName: string | null;
  duration: string | null;
  expiresAt: string | null;
  formattedPriceString: string;
  id: number;
  includedAddOn: AddOn | null;
  lineItem: string;
  name: string;
  plan: PurchasablePlan;
  price: string;
  purchasePrice: string | null;
  recurring: boolean;
  siteColor: string;
  siteId: number | null;
  siteLogoUrl: string | null;
  siteName: string;
  spyBundleData: { bundledSites?: BundleAddOn[] };
  startsAt: string | null;
  summary: string;
  title: string;
  type: string;
  upgradeable: boolean;
}

export interface AddOnGroup {
  active: boolean;
  displayName: string;
  id: number;
  logoUrl: string | null;
  staticGroupType: string;
}
