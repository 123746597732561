import classNames from "classnames";
import styles from "rivals/components/app/Layout/Header/Nav/Nav.module.scss";
import Link from "rivals/components/shared/Link";
import type { Props } from "./types";

export default function MenuLabel({
  className = "",
  path,
  title
}: Props): React.JSX.Element {
  const classes = classNames(styles.navItem, className);
  if (path) {
    return (
      <Link className={classes} href={path}>
        {title}
      </Link>
    );
  }
  return <span className={classes}>{title}</span>;
}
