import { OverrideToken } from "antd/es/theme/interface";
import { MenuVariant } from "rivals/components/shared/Menu/types";
import styles from "rivals/styles/exports.module.scss";

const base: Partial<OverrideToken["Menu"]> = {
  colorBgContainer: "transparent",
  horizontalLineHeight: "26px",
  lineWidth: 0
};

const desktop: Partial<OverrideToken["Menu"]> = {
  ...base,
  fontSize: 13
};

const mobile: Partial<OverrideToken["Menu"]> = {
  ...base,
  fontSize: 11,
  itemPaddingInline: styles["spacing-10"]
};

const lightMenu = {
  colorText: styles.abyss,
  horizontalItemSelectedColor: styles.bone
};

const lightMenuDesktop = {
  ...desktop,
  ...lightMenu
};

const lightMenuMobile = {
  ...mobile,
  ...lightMenu
};

const darkMenu = {
  colorBgElevated: styles.abyss,
  colorText: styles.bone,
  horizontalItemSelectedColor: styles.bone
};

const darkMenuDesktop = {
  ...desktop,
  ...darkMenu
};

const darkMenuMobile = {
  ...mobile,
  ...darkMenu
};

const MenuTokens = {
  [MenuVariant.Dark]: {
    Desktop: darkMenuDesktop,
    Mobile: darkMenuMobile
  },
  [MenuVariant.Light]: {
    Desktop: lightMenuDesktop,
    Mobile: lightMenuMobile
  }
};

export default MenuTokens;
