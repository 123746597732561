import { FunctionComponent, useEffect } from "react";
import JurisdictionLinks from "rivals/components/app/Layout/Footer/JurisdictionLinks";
import Links from "rivals/components/app/Layout/Footer/Links";
import { Props } from "rivals/components/app/Layout/Footer/types";

// TODO: https://griosf.atlassian.net/browse/RVLS-9222
/**
 * Legacy footer for use with Redux
 * Do not use in the App Router
 */
const Footer: FunctionComponent<Props> = ({
  CCPAFooterConstants,
  getCCPAFooterConstantsAction,
  giftingEnabled
}) => {
  useEffect(() => {
    getCCPAFooterConstantsAction(false);
    // get back to this
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [CCPAFooterConstants]);

  const jurisdictionLinks = (
    <JurisdictionLinks ccpaFooterConstants={CCPAFooterConstants} />
  );

  return <Links giftingEnabled={giftingEnabled}>{jurisdictionLinks}</Links>;
};

export default Footer;
