import {
  AthleteFeaturedMedia,
  CollegePlayer,
  CollegePlayerGameData,
  CollegePlayerRankingData,
  CollegeTransfer
} from "rivals/shared/interfaces/Athlete";
import { AthleteAction, AthleteActions } from "./actions";

export type State = {
  collegePlayer?: CollegePlayer;
  collegePlayerGameData?: CollegePlayerGameData;
  collegePlayerRankingData?: CollegePlayerRankingData;
  collegeTransfers?: CollegeTransfer[];
  error?: string;
  featuredMedia?: AthleteFeaturedMedia;
  submitting: boolean;
  successfulCollegeTransferDelete?: boolean;
  successfulCollegeTransfersFetch?: boolean;
};

const initialState = {
  collegePlayer: undefined,
  collegePlayerGameData: undefined,
  collegePlayerRankingData: undefined,
  collegeTransfers: undefined,
  error: undefined,
  featuredMedia: {
    galleries: [],
    images: [],
    videos: []
  },
  submitting: false,
  successfulCollegeTransferDelete: false,
  successfulCollegeTransfersFetch: false
};

export default function reducer(
  state: State = initialState,
  action: AthleteAction
): State {
  switch (action.type) {
    case AthleteActions.CREATE_COLLEGE_TRANSFER_SUCCESS:
      return {
        ...state,
        collegeTransfers: state.collegeTransfers?.concat(action.payload)
      };
    case AthleteActions.CREATE_COLLEGE_TRANSFER_FAILURE:
      return {
        ...state,
        error: action.payload.error
      };
    case AthleteActions.GET_ATHLETE_FEATURED_MEDIA_SUCCESS:
      return {
        ...state,
        featuredMedia: action.payload.featuredMedia
      };
    case AthleteActions.GET_ATHLETE_FEATURED_MEDIA_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        featuredMedia: undefined
      };
    case AthleteActions.GET_COLLEGE_PLAYER_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        submitting: false
      };
    case AthleteActions.GET_COLLEGE_PLAYER_SUCCESS:
      return {
        ...state,
        collegePlayer: action.payload.collegePlayer
      };
    case AthleteActions.GET_COLLEGE_PLAYER_GAME_DATA_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        submitting: false
      };
    case AthleteActions.GET_COLLEGE_PLAYER_GAME_DATA_SUCCESS:
      return {
        ...state,
        collegePlayerGameData: action.payload.collegePlayerGameData
      };
    case AthleteActions.GET_COLLEGE_PLAYER_RANKING_DATA_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        submitting: false
      };
    case AthleteActions.GET_COLLEGE_PLAYER_RANKING_DATA_SUCCESS:
      return {
        ...state,
        collegePlayerRankingData: action.payload.collegePlayerRankingData
      };
    case AthleteActions.GET_ALL_COLLEGE_TRANSFERS_BY_COLLEGE_PLAYER_ID:
    case AthleteActions.GET_COLLEGE_TRANSFER:
      return {
        ...state,
        successfulCollegeTransfersFetch: false
      };
    case AthleteActions.GET_COLLEGE_TRANSFER_SUCCESS:
      return {
        ...state,
        collegeTransfers: action.payload,
        successfulCollegeTransfersFetch: true
      };
    case AthleteActions.GET_COLLEGE_TRANSFER_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        submitting: false
      };
    case AthleteActions.UPDATE_COLLEGE_TRANSFER_SUCCESS:
      return {
        ...state,
        collegeTransfers: state.collegeTransfers
          ? state.collegeTransfers.map(transfer =>
              transfer.id === action.payload.collegeTransfer.id
                ? action.payload.collegeTransfer
                : transfer
            )
          : state.collegeTransfers
      };
    case AthleteActions.UPDATE_COLLEGE_TRANSFER_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        submitting: false
      };
    case AthleteActions.DELETE_COLLEGE_TRANSFER:
      return {
        ...state,
        submitting: true,
        successfulCollegeTransferDelete: false
      };
    case AthleteActions.DELETE_COLLEGE_TRANSFER_SUCCESS:
      return {
        ...state,
        collegeTransfers: state.collegeTransfers?.length
          ? state.collegeTransfers.filter(
              transfer => transfer.id !== action.payload.id
            )
          : state.collegeTransfers,
        submitting: false,
        successfulCollegeTransferDelete: true
      };
    case AthleteActions.DELETE_COLLEGE_TRANSFER_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        submitting: false
      };
    default:
      return state;
  }
}
