import { Request as CreateForecastRequest } from "rivals/services/createForecast/types";
import { Forecast, Forecaster } from "rivals/shared/interfaces/Futurecast";

export enum FuturecastActions {
  CREATE_FORECAST = "CREATE_FORECAST",
  CREATE_FORECAST_SUCCESS = "CREATE_FORECAST_SUCCESS",
  CREATE_FORECAST_FAILURE = "CREATE_FORECAST_FAILURE",
  GET_FORECASTERS = "GET_FORECASTERS",
  GET_FORECASTERS_SUCCESS = "GET_FORECASTERS_SUCCESS",
  GET_FORECASTERS_FAILURE = "GET_FORECASTERS_FAILURE",
  GET_FORECASTS = "GET_FORECASTS",
  GET_FORECASTS_SUCCESS = "GET_FORECASTS_SUCCESS",
  GET_FORECASTS_FAILURE = "GET_FORECASTS_FAILURE",
  RESET_FORECAST = "RESET_FORECAST",
  VALIDATE_FORECAST = "VALIDATE_FORECAST",
  VALIDATE_FORECAST_SUCCESS = "VALIDATE_FORECAST_SUCCESS",
  VALIDATE_FORECAST_FAILURE = "VALIDATE_FORECAST_FAILURE"
}

export const createForecast = (request: CreateForecastRequest) =>
  ({
    payload: request,
    type: FuturecastActions.CREATE_FORECAST
  } as const);

export const createForecastSuccess = (forecastSaved: boolean) =>
  ({
    payload: { forecastSaved },
    type: FuturecastActions.CREATE_FORECAST_SUCCESS
  } as const);

export const createForecastFailure = (error?: string) =>
  ({
    error: true,
    payload: { error },
    type: FuturecastActions.CREATE_FORECAST_FAILURE
  } as const);

export const validateForecast = (request: CreateForecastRequest) =>
  ({
    payload: request,
    type: FuturecastActions.VALIDATE_FORECAST
  } as const);

export const validateForecastSuccess = (forecastValidated: boolean) =>
  ({
    payload: { forecastValidated },
    type: FuturecastActions.VALIDATE_FORECAST_SUCCESS
  } as const);

export const validateForecastFailure = (error?: string) =>
  ({
    error: true,
    payload: { error },
    type: FuturecastActions.VALIDATE_FORECAST_FAILURE
  } as const);

export const getForecasters = () =>
  ({
    type: FuturecastActions.GET_FORECASTERS
  } as const);

export const getForecastersFailure = (error?: string) =>
  ({
    error: true,
    payload: { error },
    type: FuturecastActions.GET_FORECASTERS_FAILURE
  } as const);

export const getForecastersSuccess = (forecasters: Forecaster[]) =>
  ({
    payload: { forecasters },
    type: FuturecastActions.GET_FORECASTERS_SUCCESS
  } as const);

export const getForecasts = () =>
  ({
    type: FuturecastActions.GET_FORECASTS
  } as const);

export const getForecastsFailure = (error?: string) =>
  ({
    error: true,
    payload: { error },
    type: FuturecastActions.GET_FORECASTS_FAILURE
  } as const);

export const getForecastsSuccess = (forecasts: Forecast[]) =>
  ({
    payload: { forecasts },
    type: FuturecastActions.GET_FORECASTS_SUCCESS
  } as const);

export const resetForecast = () =>
  ({
    type: FuturecastActions.RESET_FORECAST
  } as const);

export type FuturecastAction =
  | ReturnType<typeof createForecast>
  | ReturnType<typeof createForecastSuccess>
  | ReturnType<typeof createForecastFailure>
  | ReturnType<typeof getForecasters>
  | ReturnType<typeof getForecastersSuccess>
  | ReturnType<typeof getForecastersFailure>
  | ReturnType<typeof getForecasts>
  | ReturnType<typeof getForecastsSuccess>
  | ReturnType<typeof getForecastsFailure>
  | ReturnType<typeof resetForecast>
  | ReturnType<typeof validateForecast>
  | ReturnType<typeof validateForecastSuccess>
  | ReturnType<typeof validateForecastFailure>;
