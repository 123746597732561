import { all, call, getContext, put, takeLatest } from "redux-saga/effects";
import { showAlert } from "rivals/redux/alert/actions";
import { AlertTypes } from "rivals/redux/alert/reducer";
import API from "rivals/services";
import { Response as GetAdminUpgradingUserResponse } from "rivals/services/getAdminUpgradingUser/types";
import { Response as BillingHistoryResponse } from "rivals/services/getBillingHistory/types";
import { Response as ProspectTrackingHistoryResponse } from "rivals/services/prospectTrackingHistory/types";
import { Response as UserForumInfoResponse } from "rivals/services/userForumInfo/types";
import { Response as UserTrackingHistoryResponse } from "rivals/services/userTrackingHistory/types";
import {
  AdminUsersActions,
  getAdminUpgradingUser as getAdminUpgradingUserAction,
  getAdminUpgradingUserFailure,
  getAdminUpgradingUserSuccess,
  getProspectTrackingHistory as getProspectTrackingHistoryAction,
  getProspectTrackingHistoryFailure,
  getProspectTrackingHistorySuccess,
  getUserBillingHistory,
  getUserBillingHistoryFailure,
  getUserBillingHistorySuccess,
  getUserForumInfo as getUserForumInfoAction,
  getUserForumInfoFailure,
  getUserForumInfoSuccess,
  getUserHistoryTrackingHistory as getUserHistoryTrackingHistoryAction,
  getUserHistoryTrackingHistoryFailure,
  getUserHistoryTrackingHistorySuccess,
  getUserTrackingHistory as getUserTrackingHistoryAction,
  getUserTrackingHistoryFailure,
  getUserTrackingHistorySuccess,
  upgradeUserSubscription as upgradeUserSubscriptionAction,
  upgradeUserSubscriptionFailure,
  upgradeUserSubscriptionSuccess
} from "./actions";

export function* getAdminUpgradingUser(
  action: ReturnType<typeof getAdminUpgradingUserAction>
): unknown {
  try {
    const api: API = yield getContext("api");
    const response: GetAdminUpgradingUserResponse = yield call(
      api.getAdminUpgradingUser,
      action.payload
    );
    yield put(getAdminUpgradingUserSuccess(response));
  } catch (error) {
    yield put(getAdminUpgradingUserFailure((<Error>error)?.message));
  }
}

export function* upgradeUserSubscription(
  action: ReturnType<typeof upgradeUserSubscriptionAction>
): unknown {
  try {
    const api: API = yield getContext("api");
    yield call(api.adminUpgradeUserSubscription, {
      autoRenewalOptIn: action.payload.isAutoRenewAgreed,
      promoCode: action.payload.promoCode,
      subscriptionId: action.payload.subscriptionId
    });
    yield put(upgradeUserSubscriptionSuccess());
    window.location.assign(`/admin/users/${action.payload.userId}`);
  } catch (error) {
    yield put(upgradeUserSubscriptionFailure((<Error>error)?.message));
    yield put(
      showAlert({
        message: (<Error>error)?.message,
        persisted: false,
        type: AlertTypes.SEVERE
      })
    );
  }
}

export function* getUsersBillingHistory(
  action: ReturnType<typeof getUserBillingHistory>
): unknown {
  try {
    const api: API = yield getContext("api");
    const response: BillingHistoryResponse = yield call(
      api.getAdminUserBillingHistory,
      action.payload
    );
    yield put(getUserBillingHistorySuccess(response.transactions));
  } catch (error) {
    yield put(getUserBillingHistoryFailure((<Error>error)?.message));
  }
}

export function* getUserForumInfo(
  action: ReturnType<typeof getUserForumInfoAction>
): unknown {
  try {
    const api: API = yield getContext("api");
    const response: UserForumInfoResponse = yield call(
      api.getUserForumInfo,
      action.payload
    );
    yield put(getUserForumInfoSuccess(response));
  } catch (error) {
    yield put(getUserForumInfoFailure((<Error>error)?.message));
    yield put(
      showAlert({
        hasCloseButton: false,
        href: "./forums",
        linkText: "Refresh Page",
        message: "Unable to load page. Please refresh or try again later.",
        persisted: true,
        type: AlertTypes.SEVERE
      })
    );
  }
}

export function* getUserTrackingHistory(
  action: ReturnType<typeof getUserTrackingHistoryAction>
): unknown {
  try {
    const api: API = yield getContext("api");
    const response: UserTrackingHistoryResponse = yield call(
      api.getUserTrackingHistory,
      action.payload
    );
    yield put(getUserTrackingHistorySuccess(response));
  } catch (error) {
    yield put(getUserTrackingHistoryFailure((<Error>error)?.message));
  }
}

export function* getProspectTrackingHistory(
  action: ReturnType<typeof getProspectTrackingHistoryAction>
): unknown {
  try {
    const api: API = yield getContext("api");
    const response: ProspectTrackingHistoryResponse = yield call(
      api.getProspectTrackingHistory,
      action.payload
    );

    yield put(getProspectTrackingHistorySuccess(response));
  } catch (error) {
    yield put(getProspectTrackingHistoryFailure((<Error>error)?.message));
  }
}

export function* getUserHistoryTrackingHistory(
  action: ReturnType<typeof getUserHistoryTrackingHistoryAction>
): unknown {
  try {
    const api: API = yield getContext("api");
    const response: UserTrackingHistoryResponse = yield call(
      api.getUserHistoryTrackingHistory,
      action.payload
    );
    yield put(getUserHistoryTrackingHistorySuccess(response));
  } catch (error) {
    yield put(getUserHistoryTrackingHistoryFailure((<Error>error)?.message));
  }
}

export default function* saga(): Generator {
  yield all([
    takeLatest(
      AdminUsersActions.GET_ADMIN_UPGRADING_USER,
      getAdminUpgradingUser
    ),
    takeLatest(
      AdminUsersActions.UPGRADE_USER_SUBSCRIPTION,
      upgradeUserSubscription
    ),
    takeLatest(
      AdminUsersActions.GET_USER_BILLING_HISTORY,
      getUsersBillingHistory
    ),
    takeLatest(AdminUsersActions.GET_USER_FORUM_INFO, getUserForumInfo),
    takeLatest(
      AdminUsersActions.GET_USER_TRACKING_HISTORY,
      getUserTrackingHistory
    ),
    takeLatest(
      AdminUsersActions.GET_PROSPECT_TRACKING_HISTORY,
      getProspectTrackingHistory
    ),
    takeLatest(
      AdminUsersActions.GET_USER_HISTORY_TRACKING_HISTORY,
      getUserHistoryTrackingHistory
    )
  ]);
}
