"use client";

import { JSX, useContext } from "react";
import { AlertContext } from "rivals/shared/app/alertContext";
import Alert from "./Alert";
import type { Props } from "./types";

export const MAX_NUMBER_ALERTS = 3;

const Alerts = ({ site }: Props): JSX.Element => {
  const { alerts } = useContext(AlertContext);

  return (
    <div>
      {alerts
        .slice(0, MAX_NUMBER_ALERTS)
        .reverse()
        .map(alert => (
          <Alert alert={alert} key={alert.id} site={site} />
        ))}
    </div>
  );
};

export default Alerts;
