import { upperFirst } from "lodash";
import map from "lodash/map";
import type {
  NavBarRailsConstants,
  NavOptions
} from "rivals/components/app/Layout/Header/types";
import { TEAM_TYPES } from "rivals/components/pages/Teams/types";
import type {
  Response as NavTeamsResponse,
  TeamsGroups
} from "rivals/services/fetchTeamsNavItems/types";
import {
  PATHS,
  PRIMARY_SPORTS,
  ROOT_SITE_IDENTIFIER
} from "rivals/shared/constants";
import {
  isAdminProtectedPage,
  isUserProtectedPage
} from "rivals/shared/utils/routes";
import { replaceMultiple } from "rivals/shared/utils/strings";
import styles from "./Nav.module.scss";
import {
  ADVANCED_SEARCH_ELEMENTS,
  FUTURECAST_ELEMENTS,
  HOME_ELEMENT,
  USER_NAV_ELEMENTS
} from "./constants";
import strings from "./strings";
import type { MenuItem } from "./types";

const { navSubTitles, navTitles } = strings;

const preProcessNavOptions = (options: NavOptions): MenuItem[] => {
  return map(options, option => ({
    path: option.link,
    subMenuItems:
      !!option.items && Object.keys(option.items).length > 0
        ? map(option.items, item => ({
            path: item.link,
            title: item.title
          }))
        : undefined,
    title: option.title
  }));
};

const preProcessNavTeams = (teams?: TeamsGroups): MenuItem[] => {
  return map(teams, team => ({
    path: team.path,
    subMenuItems: team.items,
    title: team.title
  }));
};

const teamsMenuHeader = (
  title: string,
  teamType: TEAM_TYPES = TEAM_TYPES.COLLEGES
): MenuItem => ({
  path: replaceMultiple(PATHS.TEAMS, {
    /* eslint-disable @typescript-eslint/naming-convention */
    "[group]": "",
    "[sport]": PRIMARY_SPORTS.FOOTBALL,
    "[type]": teamType
    /* eslint-enable @typescript-eslint/naming-convention */
  }),
  title
});

const teamsMenu = (teams?: NavTeamsResponse): MenuItem => {
  return {
    ...teamsMenuHeader(navTitles.teams),
    subMenuItems: [
      {
        ...teamsMenuHeader(navSubTitles.colleges),
        className: styles.teamHeader,
        subMenuItems: preProcessNavTeams(teams?.colleges),
        type: "group"
      },
      {
        ...teamsMenuHeader(navSubTitles.highschools, TEAM_TYPES.HIGH_SCHOOLS),
        className: styles.teamHeader,
        subMenuItems: preProcessNavTeams(teams?.highschools),
        type: "group"
      }
    ]
  };
};

const publicNavOptions = (
  teamOptions?: NavTeamsResponse,
  navItems?: NavOptions
): MenuItem[] => {
  const processedNavOptions = navItems ? preProcessNavOptions(navItems) : [];
  return [
    HOME_ELEMENT,
    ...processedNavOptions,
    ADVANCED_SEARCH_ELEMENTS,
    FUTURECAST_ELEMENTS,
    teamsMenu(teamOptions)
  ];
};

export const navElements = ({
  path,
  teamOptions,
  navItems,
  navBarRailsConstants
}: {
  navBarRailsConstants?: NavBarRailsConstants;
  navItems?: NavOptions;
  path: string;
  teamOptions?: NavTeamsResponse;
}): MenuItem[] => {
  if (isUserProtectedPage(path)) {
    if (navBarRailsConstants?.userLinks) {
      const { userLinks } = navBarRailsConstants;
      return Object.keys(userLinks).map((name: string) => ({
        path: userLinks[name as keyof typeof userLinks],
        title: upperFirst(name)
      }));
    }
    return USER_NAV_ELEMENTS;
  }

  if (isAdminProtectedPage(path) && navItems) {
    const options = preProcessNavOptions(navItems);
    return options;
  }

  return publicNavOptions(teamOptions, navItems);
};

export const getSelectedMenuItem = (
  menuItems: MenuItem[],
  selectedPath: string
): string => {
  const selectedMenuItem = menuItems.find(menuItem => {
    return menuItem.path && menuItem.path === selectedPath;
  });
  if (selectedMenuItem && selectedMenuItem.path) {
    return `${menuItems.indexOf(selectedMenuItem)}`;
  }
  return "";
};

export const fixNavLinksforEnv = (navItems: NavOptions): NavOptions => {
  if (process.env.NEXT_PUBLIC_DOMAIN === `${ROOT_SITE_IDENTIFIER}.com`) {
    return navItems;
  }

  return Object.keys(navItems).reduce((acc, key) => {
    const option = {
      ...navItems[key],
      link: navItems[key].link.replace(
        `${ROOT_SITE_IDENTIFIER}.com`,
        `${process.env.NEXT_PUBLIC_DOMAIN}`
      )
    };
    if (option.items) {
      option.items = Object.keys(option.items).reduce((nestedAcc, itemKey) => {
        nestedAcc[itemKey] = {
          ...option.items[itemKey],
          link: option.items[itemKey].link.replace(
            `${ROOT_SITE_IDENTIFIER}.com`,
            `${process.env.NEXT_PUBLIC_DOMAIN}`
          )
        };
        return nestedAcc;
      }, {} as typeof option.items);
    }
    acc[key] = option;
    return acc;
  }, {} as NavOptions);
};
