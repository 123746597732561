import { Version } from "rivals/shared/interfaces/List";
import { List } from "rivals/shared/interfaces/adminLists";

export interface OwnProps {
  list: List;
  schoolFocus?: string;
  sport: string;
  urlFilters?: PeopleFiltersType;
  versions: Version[];
  year: number;
}

export enum PEOPLE_FILTERS_TYPES {
  ATHLETIC_YEAR = "athleticYear",
  EXIT_COLLEGE = "exitCollegeName",
  ORIGIN_COLLEGE = "originCollegeName",
  TRANSFER_POSITION = "transferPosition"
}

export interface PeopleFiltersType {
  [PEOPLE_FILTERS_TYPES.ATHLETIC_YEAR]: string;
  [PEOPLE_FILTERS_TYPES.EXIT_COLLEGE]: string;
  [PEOPLE_FILTERS_TYPES.ORIGIN_COLLEGE]: string;
  [PEOPLE_FILTERS_TYPES.TRANSFER_POSITION]: string;
}

/**
 * The following parameters values are snake_case to be consistent with the
 * params form we have on other pages, eg: prospect search, <env>/search
 * note that for top transfers table filters params are also snake_case
 */
export enum LIST_FILTERS_PARAMS {
  ATHLETIC_YEAR = "athletic_year",
  EXIT_COLLEGE = "exit_college_name",
  LATEST_ACTIVITY_COLLEGE = "latest_activity_college_name",
  ORIGIN_COLLEGE = "origin_college_name",
  SCHOOL_FOCUS = "school_focus",
  TRANSFER_POSITION = "transfer_position"
}
