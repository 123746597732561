import { createSlice } from "@reduxjs/toolkit";
import { PendingAction } from "rivals/redux/Action";
import { CollegeLetterGroup } from "rivals/shared/interfaces/College";
import {
  Conference,
  ConferenceGroups
} from "rivals/shared/interfaces/Conference";
import { fetchConference, fetchConferences } from "./actions";

export type State = {
  collegeLetterGroups?: CollegeLetterGroup[];
  conference?: Conference;
  conferenceGroups?: ConferenceGroups;
  conferences?: Conference[];
  error?: string;
  searching: boolean;
};

export const initialState: State = {
  collegeLetterGroups: undefined,
  conferenceGroups: undefined,
  conferences: undefined,
  error: undefined,
  searching: false
};

const conferenceSlice = createSlice({
  extraReducers: builder => {
    builder
      .addCase(fetchConferences.fulfilled, (state, action) => {
        state.collegeLetterGroups = action.payload.letterGroups;
        state.conferences = action.payload.conferences;
        state.conferenceGroups = action.payload.conferenceGroups;
        state.searching = false;
      })
      .addCase(fetchConference.fulfilled, (state, action) => {
        state.conference = action.payload.conference;
        state.searching = false;
      })
      .addCase(fetchConference.rejected, (state, action) => {
        state.error = (action.payload as string) || action?.error?.message;
        state.searching = false;
        state.conference = undefined;
      })
      .addCase(fetchConferences.rejected, (state, action) => {
        state.error = (action.payload as string) || action?.error?.message;
        state.searching = false;
        state.conferences = undefined;
        state.collegeLetterGroups = undefined;
        state.conferenceGroups = undefined;
      })
      .addMatcher<PendingAction>(
        action =>
          [
            fetchConferences.pending.toString(),
            fetchConference.pending.toString()
          ].includes(action.type),
        state => {
          state.searching = true;
        }
      );
  },
  initialState,
  name: "conference",
  reducers: {}
});

export default conferenceSlice.reducer;
