import {
  all,
  call,
  getContext,
  put,
  PutEffect,
  takeLatest
} from "redux-saga/effects";
import { showAlert } from "rivals/redux/alert/actions";
import { AlertTypes } from "rivals/redux/alert/reducer";
import API from "rivals/services";
import { UpdateCollegeTransferResponse } from "rivals/services/container";
import { Response as createCollegeTransferResponse } from "rivals/services/createCollegeeTransfer/types";
import { Response as getAthleteFeaturedMediaResponse } from "rivals/services/getAthleteFeaturedMedia/types";
import { Response as getCollegePlayerGameDataResponse } from "rivals/services/getCollegePlayerGameData/types";
import { Response as getCollegePlayerRankingDataResponse } from "rivals/services/getCollegePlayerRankingData/types";
import { Response as getCollegeTransferResponse } from "rivals/services/getCollegeTransfers/types";
import {
  AthleteActions,
  createCollegeTransfer as createCollegeTransferAction,
  createCollegeTransferFailure,
  createCollegeTransferSuccess,
  deleteCollegeTransfer as deleteCollegeTransferAction,
  deleteCollegeTransferFailure,
  deleteCollegeTransferSuccess,
  getAllCollegeTransfersByCollegePlayerId as getAllCollegeTransfersByCollegePlayerIdAction,
  getAthleteFeaturedMedia as getAthleteFeaturedMediaAction,
  getAthleteFeaturedMediaFailure,
  getAthleteFeaturedMediaSuccess,
  getCollegePlayerGameData as getCollegePlayerGameDataAction,
  getCollegePlayerGameDataFailure,
  getCollegePlayerGameDataSuccess,
  getCollegePlayerRankingData as getCollegePlayerRankingDataAction,
  getCollegePlayerRankingDataFailure,
  getCollegePlayerRankingDataSuccess,
  getCollegeTransfers as getCollegeTransfersAction,
  getCollegeTransfersFailure,
  getCollegeTransfersSuccess,
  updateCollegeTransfer as updateCollegeTransferAction,
  updateCollegeTransferFailure,
  updateCollegeTransferSuccess
} from "./actions";

export function* createCollegeTransfer(
  action: ReturnType<typeof createCollegeTransferAction>
): unknown {
  try {
    const api: API = yield getContext("api");
    const response: createCollegeTransferResponse = yield call(
      api.createCollegeTransfer,
      action.payload
    );
    yield put(createCollegeTransferSuccess(response.collegeTransfers));
  } catch (error) {
    yield put(createCollegeTransferFailure((<Error>error)?.message));
    yield put(
      showAlert({
        hasCloseButton: true,
        message: (<Error>error)?.message,
        type: AlertTypes.SEVERE
      })
    );
  }
}

export function* getAllCollegeTransfersByCollegePlayerId(
  action: ReturnType<typeof getAllCollegeTransfersByCollegePlayerIdAction>
): unknown {
  try {
    const api: API = yield getContext("api");
    const response: getCollegeTransferResponse = yield call(
      api.getAllCollegeTransfersByCollegePlayerId,
      action.payload
    );
    yield put(getCollegeTransfersSuccess(response.collegeTransfers));
  } catch (error) {
    yield put(getCollegeTransfersFailure((<Error>error)?.message));
  }
}

export function* getAthleteFeaturedMedia(
  action: ReturnType<typeof getAthleteFeaturedMediaAction>
): unknown {
  try {
    const api: API = yield getContext("api");
    const response: getAthleteFeaturedMediaResponse = yield call(
      api.getAthleteFeaturedMedia,
      action.payload
    );
    yield put(getAthleteFeaturedMediaSuccess(response));
  } catch (error) {
    yield put(getAthleteFeaturedMediaFailure((<Error>error)?.message));
  }
}

export function* getCollegePlayerGameData(
  action: ReturnType<typeof getCollegePlayerGameDataAction>
): unknown {
  try {
    const api: API = yield getContext("api");
    const response: getCollegePlayerGameDataResponse = yield call(
      api.getCollegePlayerGameData,
      action.payload
    );
    yield put(getCollegePlayerGameDataSuccess(response.tables));
  } catch (error) {
    yield put(getCollegePlayerGameDataFailure((<Error>error)?.message));
  }
}

export function* getCollegePlayerRankingData(
  action: ReturnType<typeof getCollegePlayerRankingDataAction>
): unknown {
  try {
    const api: API = yield getContext("api");
    const response: getCollegePlayerRankingDataResponse = yield call(
      api.getCollegePlayerRankingData,
      action.payload
    );
    yield put(getCollegePlayerRankingDataSuccess(response.data));
  } catch (error) {
    yield put(getCollegePlayerRankingDataFailure((<Error>error)?.message));
  }
}

export function* getCollegeTransfers(
  action: ReturnType<typeof getCollegeTransfersAction>
): unknown {
  try {
    const api: API = yield getContext("api");
    const response: getCollegeTransferResponse = yield call(
      api.getCollegeTransfers,
      action.payload
    );
    yield put(getCollegeTransfersSuccess(response.collegeTransfers));
  } catch (error) {
    yield put(getCollegeTransfersFailure((<Error>error)?.message));
  }
}

export function* updateCollegeTransfer(
  action: ReturnType<typeof updateCollegeTransferAction>
): unknown {
  try {
    const api: API = yield getContext("api");
    const response: UpdateCollegeTransferResponse = yield call(
      api.updateCollegeTransfer,
      action.payload
    );
    const { collegePlayerId } = action.payload.collegeTransfer;

    const actions: Array<PutEffect> = [
      put(updateCollegeTransferSuccess(response.collegeTransfer))
    ];
    if (collegePlayerId) {
      actions.push(
        put(getAllCollegeTransfersByCollegePlayerIdAction({ collegePlayerId }))
      );
    }
    yield all(actions);
  } catch (error) {
    yield put(updateCollegeTransferFailure((<Error>error)?.message));
    yield put(
      showAlert({
        hasCloseButton: true,
        message: (<Error>error)?.message,
        type: AlertTypes.SEVERE
      })
    );
  }
}

export function* deleteCollegeTransfer(
  action: ReturnType<typeof deleteCollegeTransferAction>
): unknown {
  try {
    const api: API = yield getContext("api");
    yield call(api.deleteCollegeTransfer, action.payload);
    yield put(deleteCollegeTransferSuccess(action.payload.collegeTransferId));
  } catch (error) {
    yield put(deleteCollegeTransferFailure((<Error>error)?.message));
    yield put(
      showAlert({
        hasCloseButton: true,
        message: (<Error>error)?.message,
        type: AlertTypes.SEVERE
      })
    );
  }
}

export default function* saga(): Generator {
  yield all([
    takeLatest(AthleteActions.DELETE_COLLEGE_TRANSFER, deleteCollegeTransfer),
    takeLatest(
      AthleteActions.GET_ALL_COLLEGE_TRANSFERS_BY_COLLEGE_PLAYER_ID,
      getAllCollegeTransfersByCollegePlayerId
    ),
    takeLatest(
      AthleteActions.GET_COLLEGE_PLAYER_RANKING_DATA,
      getCollegePlayerRankingData
    ),
    takeLatest(AthleteActions.CREATE_COLLEGE_TRANSFER, createCollegeTransfer),
    takeLatest(AthleteActions.GET_COLLEGE_TRANSFER, getCollegeTransfers),
    takeLatest(AthleteActions.UPDATE_COLLEGE_TRANSFER, updateCollegeTransfer)
  ]);
  yield all([
    takeLatest(
      AthleteActions.GET_ATHLETE_FEATURED_MEDIA,
      getAthleteFeaturedMedia
    )
  ]);
  yield all([
    takeLatest(
      AthleteActions.GET_COLLEGE_PLAYER_GAME_DATA,
      getCollegePlayerGameData
    )
  ]);
}
