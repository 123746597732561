import classNames from "classnames";
import { setData } from "rivals/shared/utils/qa";
import styles from "./CustomButton.module.scss";
import { Props } from "./types";
/**
 * This button is used when the clickable element is an image/icon or when the
 * button text requires custom styles different from the RivalsButton component.
 */
const CustomButton = ({
  ariaLabel,
  buttonText = "",
  children,
  className,
  philter = "",
  disabled,
  icon,
  onClick,
  type = "button"
}: Props): JSX.Element => {
  const buttonClasses = classNames(styles.imageButton, className);

  return (
    <button
      aria-label={ariaLabel || buttonText}
      className={buttonClasses}
      disabled={disabled}
      onClick={onClick}
      type={type}
      {...setData(philter, "button")}
    >
      {!!buttonText && <span>{buttonText}</span>}
      {icon}
      {children}
    </button>
  );
};

export default CustomButton;
