import { all, call, getContext, put, takeLatest } from "redux-saga/effects";
import API from "rivals/services";
import {
  ImageTagActions,
  updateImageTag as updateImageTagAction,
  updateImageTagFailure,
  updateImageTagSuccess
} from "./actions";

export function* updateImageTag(
  action: ReturnType<typeof updateImageTagAction>
): unknown {
  try {
    const api: API = yield getContext("api");
    const response = yield call(api.updateImageTag, action.payload);
    yield put(updateImageTagSuccess(response));
  } catch (error) {
    yield put(updateImageTagFailure((<Error>error)?.message));
  }
}

export default function* saga(): Generator {
  yield all([takeLatest(ImageTagActions.UPDATE_IMAGE_TAG, updateImageTag)]);
}
