import { all, call, getContext, put, takeLatest } from "redux-saga/effects";
import { clearAlerts, showAlert } from "rivals/redux/alert/actions";
import { AlertTypes } from "rivals/redux/alert/reducer";
import {
  logoutUser,
  setAuthentication
} from "rivals/redux/authentication/actions";
import {
  forgotCredentialsSuccess,
  forgotPassword as forgotPasswordAction,
  forgotUsername as forgotUsernameAction,
  getAddressFailure,
  getAddressSuccess,
  getBetMGMUrlFailure,
  getBetMGMUrlSuccess,
  getBillingHistory as getBillingHistoryAction,
  getBillingHistoryFailure,
  getBillingHistorySuccess,
  getBillingInformationFailure,
  getBillingInformationSuccess,
  getCurrentUserFailure,
  getCurrentUserSuccess,
  getIsCurrentUserValidSuccess,
  getIsProspectFollowed as getIsProspectFollowedAction,
  getIsProspectFollowedFailure,
  getIsProspectFollowedSuccess,
  getPayPalBillingAgreement as getPayPalBillingAgreementAction,
  getPayPalBillingAgreementFailure,
  getPayPalBillingAgreementSuccess,
  getSubscriptionsFailure,
  getSubscriptionsSuccess,
  requestConfirmationEmail as requestConfirmationEmailAction,
  resetPayPal,
  strictSignIn as strictSignInAction,
  strictSignInFailure,
  strictSignInSuccess as strictSignInSuccessAction,
  updateBillingInformation as updateBillingInformationAction,
  updateBillingInformationFailure,
  updateBillingInformationSuccess,
  updateCurrentUser as updateCurrentUserAction,
  updateCurrentUserFailure,
  updateCurrentUserSuccess,
  updateIsProspectFollowed as updateIsProspectFollowedAction,
  updateIsProspectFollowedFailure,
  updateIsProspectFollowedSuccess,
  updatePassword as updatePasswordAction,
  updatePasswordFailure,
  updatePasswordSuccess,
  UserActions,
  validateBetMGMFailure,
  validateBetMGMSuccess,
  validateEmailOrUsername as validateEmailOrUsernameAction,
  validateEmailOrUsernameFailure,
  validateEmailOrUsernameSuccess
} from "rivals/redux/user/actions";
import API from "rivals/services";
import {
  RESPONSE_CODE_UNAUTHORIZED,
  RESPONSE_TOO_MANY_REQUESTS
} from "rivals/services/base";
import { GetIsCurrentUserValidResponse } from "rivals/services/container";
import { Response as ForgotPasswordResponse } from "rivals/services/forgotPassword/types";
import { Response as ForgotUsernameResponse } from "rivals/services/forgotUsername/types";
import { Response as AddressResponse } from "rivals/services/getAddress/types";
import { Response as getBetMGMUrlResponse } from "rivals/services/getBetMgmUrl/types";
import { Response as BillingHistoryResponse } from "rivals/services/getBillingHistory/types";
import { Response as BillingInformationResponse } from "rivals/services/getBillingInformation/types";
import { Response as GetCurrentUserResponse } from "rivals/services/getCurrentUser/types";
import { Response as GetIsProspectFollowedResponse } from "rivals/services/getIsProspectFollowed/types";
import { PayPalBillingAgreementResponse } from "rivals/services/getPayPalBillingAgreement/types";
import { Response as SubscriptionResponse } from "rivals/services/getSubscriptions/types";
import { Response as UpdateBillingInformationResponse } from "rivals/services/updateBillingInformation/types";
import { Response as UpdateCurrentUserResponse } from "rivals/services/updateCurrentUser/types";
import { Response as PasswordResponse } from "rivals/services/updatePassword/types";
import { Response as ValidateBetMGMResponse } from "rivals/services/validateBetMGM/types";
import {
  GENERIC_ERROR_MESSAGE,
  INVALID_CURRENT_PASSWORD,
  PATHS
} from "rivals/shared/constants";
import RivalsApiError from "rivals/shared/error";

const devLogger = function (msg = "", obj = {}): void {
  if (process.env.NEXT_PUBLIC_AUTH_LOGGER === "true") {
    console.log(msg, "\n", obj); // eslint-disable-line no-console
  }
};

// TODO: https://griosf.atlassian.net/browse/RVLS-2792
export function* forgotPassword(
  action: ReturnType<typeof forgotPasswordAction>
): unknown {
  try {
    yield put(clearAlerts());
    const api: API = yield getContext("api");
    const response: ForgotPasswordResponse = yield call(
      api.forgotPassword,
      action.payload
    );
    yield put(forgotCredentialsSuccess(response.message));
  } catch (error) {
    if (error instanceof RivalsApiError) {
      yield put(
        showAlert({
          message: error.message,
          persisted: false,
          type: AlertTypes.SEVERE
        })
      );
    }
  }
}

// TODO: https://griosf.atlassian.net/browse/RVLS-2792
export function* forgotUsername(
  action: ReturnType<typeof forgotUsernameAction>
): unknown {
  try {
    yield put(clearAlerts());
    const api: API = yield getContext("api");
    const response: ForgotUsernameResponse = yield call(
      api.forgotUsername,
      action.payload
    );
    yield put(forgotCredentialsSuccess(response.message));
  } catch (error) {
    if (error instanceof RivalsApiError) {
      yield put(
        showAlert({
          message: error.message,
          persisted: false,
          type: AlertTypes.SEVERE
        })
      );
    }
  }
}

// TODO: https://griosf.atlassian.net/browse/RVLS-2792
export function* getAddress(): unknown {
  try {
    const api: API = yield getContext("api");
    const response: AddressResponse = yield call(api.getAddress);
    yield put(getAddressSuccess(response.address));
  } catch (error) {
    yield put(getAddressFailure((<Error>error)?.message));
  }
}

// TODO: https://griosf.atlassian.net/browse/RVLS-2792
export function* getBillingInformation(): unknown {
  try {
    const api: API = yield getContext("api");
    const response: BillingInformationResponse = yield call(
      api.getBillingInformation
    );
    yield put(getBillingInformationSuccess(response.billingInformation));
  } catch (error) {
    yield put(getBillingInformationFailure((<Error>error)?.message));
  }
}

export function* getBillingHistory(): unknown {
  try {
    const api: API = yield getContext("api");
    const response: BillingHistoryResponse = yield call(api.getBillingHistory);
    yield put(getBillingHistorySuccess(response.transactions));
  } catch (error) {
    yield put(getBillingHistoryFailure((<Error>error)?.message));

    if (!(error instanceof RivalsApiError)) {
      yield put(
        showAlert({
          action: getBillingHistoryAction(),
          linkText: "Retry",
          message: "Failed to get billing history,",
          persisted: false,
          type: AlertTypes.SEVERE
        })
      );
    }
  }
}

// TODO: https://griosf.atlassian.net/browse/RVLS-2792
export function* getCurrentUser(): unknown {
  try {
    const api: API = yield getContext("api");
    // For the time being, we are continueing to bypass the
    // Browser cache for the getCurrentUser call in Redux
    const response: GetCurrentUserResponse = yield call(api.getCurrentUser);
    if (response.user) {
      devLogger("SAGA: getCurrentUser: SUCCESS", response);
      yield put(getCurrentUserSuccess(response.user));
    } else {
      devLogger("SAGA: getCurrentUser: FAILURE / HARDOUT", response);
      yield put(logoutUser());
    }
  } catch (error) {
    devLogger("SAGA CATCH: getCurrentUser", <Error>error);
    yield put(getCurrentUserFailure((<Error>error)?.message));
  }
}

export function* getIsCurrentUserValid(): unknown {
  try {
    const api: API = yield getContext("api");
    const response: GetIsCurrentUserValidResponse = yield call(
      api.getIsCurrentUserValid
    );
    if (response.isCurrentUserValid) {
      yield all([
        put(setAuthentication(true)),
        put(
          getIsCurrentUserValidSuccess({
            email: response.email,
            emailConfirmed: response.emailConfirmed,
            primarySite: null,
            rivalsEmails: response.rivalsEmails,
            thirdPartyEmails: response.thirdPartyEmails,
            userId: response.userId,
            username: response.username
          })
        )
      ]);
    } else {
      devLogger("SAGA: getIsCurrentUserValid NO", response);
      yield put(logoutUser());
    }
  } catch (error) {
    yield put(logoutUser());
  }
}

export function* getIsProspectFollowed(
  action: ReturnType<typeof getIsProspectFollowedAction>
): unknown {
  try {
    const api: API = yield getContext("api");
    const response: GetIsProspectFollowedResponse = yield call(
      api.getIsProspectFollowed,
      action.payload
    );
    yield put(getIsProspectFollowedSuccess(response.isFollowing));
  } catch (error) {
    yield put(getIsProspectFollowedFailure());
  }
}

// TODO: https://griosf.atlassian.net/browse/RVLS-2792
export function* getSubscriptions(): unknown {
  try {
    const api: API = yield getContext("api");
    const response: SubscriptionResponse = yield call(api.getSubscriptions);

    yield put(getSubscriptionsSuccess(response.subscriptions));
  } catch (error) {
    yield all([
      put(getSubscriptionsFailure((<Error>error)?.message)),
      put(
        showAlert({ message: GENERIC_ERROR_MESSAGE, type: AlertTypes.SEVERE })
      )
    ]);
  }
}

// TODO: https://griosf.atlassian.net/browse/RVLS-2792
export function* requestConfirmationEmail(
  action: ReturnType<typeof requestConfirmationEmailAction>
): unknown {
  try {
    const api: API = yield getContext("api");
    const response: { message: string } = yield call(
      api.requestConfirmationEmail,
      action.payload.email
    );
    yield put(
      showAlert({
        message: response.message
      })
    );
  } catch (error) {
    error;
  }
}

export function* updateIsProspectFollowed(
  action: ReturnType<typeof updateIsProspectFollowedAction>
): unknown {
  try {
    const api: API = yield getContext("api");
    yield call(api.updateIsProspectFollowed, action.payload);
    yield put(updateIsProspectFollowedSuccess(action.payload.follow));
  } catch (error) {
    yield all([
      put(updateIsProspectFollowedFailure()),
      put(
        showAlert({
          message: `Unable to update following status.`,
          type: AlertTypes.SEVERE
        })
      )
    ]);
  }
}

// TODO: https://griosf.atlassian.net/browse/RVLS-2792
export function* strictSignIn(
  action: ReturnType<typeof strictSignInAction>
): unknown {
  try {
    const api: API = yield getContext("api");

    yield call(api.strictSignIn, action.payload);
    window.history.back();
    yield put(strictSignInSuccessAction());
  } catch (error) {
    if (
      error instanceof RivalsApiError &&
      error.status === RESPONSE_TOO_MANY_REQUESTS
    ) {
      yield all([
        put(logoutUser()),
        put(
          showAlert({
            message: error.message,
            persisted: true,
            type: AlertTypes.SEVERE
          })
        )
      ]);
    } else {
      yield put(strictSignInFailure((<Error>error)?.message));
    }
  }
}

// TODO: https://griosf.atlassian.net/browse/RVLS-2792
export function* updateBillingInformation(
  action: ReturnType<typeof updateBillingInformationAction>
): unknown {
  try {
    const api: API = yield getContext("api");
    const response: UpdateBillingInformationResponse = yield call(
      api.updateBillingInformation,
      action.payload
    );
    yield put(
      updateBillingInformationSuccess(
        response.address,
        response.billingInformation
      )
    );
    yield put(resetPayPal());
  } catch (error) {
    yield put(updateBillingInformationFailure((<Error>error)?.message));
    if (error instanceof RivalsApiError) {
      yield put(showAlert({ message: error.message, type: AlertTypes.SEVERE }));
    }
  }
}

// TODO: https://griosf.atlassian.net/browse/RVLS-2792
export function* updateCurrentUser(
  action: ReturnType<typeof updateCurrentUserAction>
): unknown {
  try {
    const api: API = yield getContext("api");
    const response: UpdateCurrentUserResponse = yield call(
      api.updateCurrentUser,
      action.payload
    );
    yield put(updateCurrentUserSuccess(response.user));
  } catch (error) {
    if (
      error instanceof RivalsApiError &&
      error.status === RESPONSE_CODE_UNAUTHORIZED
    ) {
      const { message } = error;
      // Redirect to login page if the user is not authorized
      yield all([
        put(logoutUser()),
        put(showAlert({ message, persisted: true, type: AlertTypes.SEVERE }))
      ]);
    }
    yield put(updateCurrentUserFailure((<Error>error)?.message));
  }
}

// TODO: https://griosf.atlassian.net/browse/RVLS-2792
export function* updatePassword(
  action: ReturnType<typeof updatePasswordAction>
): unknown {
  try {
    const api: API = yield getContext("api");
    const response: PasswordResponse = yield call(
      api.updatePassword,
      action.payload
    );
    yield all([
      put(updatePasswordSuccess(response.passwordUpdated)),
      put(
        showAlert({
          message:
            "Password changed.  You will need to log in again to access your account, forums or other premium content.",
          persisted: true,
          type: AlertTypes.SUCCESS
        })
      ),
      put(logoutUser())
    ]);
  } catch (error) {
    if (
      error instanceof RivalsApiError &&
      error.status === RESPONSE_CODE_UNAUTHORIZED
    ) {
      const { message } = error;

      yield all([
        put(updatePasswordFailure(message)),
        put(
          showAlert({
            message:
              "Detected suspicious activity. You will need to log in again to access your account, forums or other premium content.",
            persisted: true,
            type: AlertTypes.SEVERE
          })
        ),
        put(logoutUser())
      ]);
    } else {
      const enteredInvalidCurrentPassword =
        (<Error>error)?.message === INVALID_CURRENT_PASSWORD;

      yield all([
        put(clearAlerts()),
        put(updatePasswordFailure((<Error>error)?.message)),
        put(
          showAlert({
            href: enteredInvalidCurrentPassword ? PATHS.FORGOT_PASSWORD : "",
            linkText: enteredInvalidCurrentPassword ? " Reset password" : "",
            message: (<Error>error)?.message,
            persisted: false,
            type: AlertTypes.SEVERE
          })
        )
      ]);
    }
  }
}

export function* validateBetMGM(): unknown {
  try {
    const api: API = yield getContext("api");
    const response: ValidateBetMGMResponse = yield call(api.validateBetMGM);
    yield put(validateBetMGMSuccess(response));
  } catch (error) {
    yield put(validateBetMGMFailure((<Error>error)?.message));
    put(showAlert({ message: GENERIC_ERROR_MESSAGE, type: AlertTypes.SEVERE }));
  }
}

export function* getBetMGMUrl(): unknown {
  try {
    const api: API = yield getContext("api");
    const response: getBetMGMUrlResponse = yield call(api.getBetMGMUrl);
    yield put(getBetMGMUrlSuccess(response.url));
  } catch (error) {
    yield put(getBetMGMUrlFailure((<Error>error)?.message));
    put(showAlert({ message: GENERIC_ERROR_MESSAGE, type: AlertTypes.SEVERE }));
  }
}

export function* validateUserData(
  action: ReturnType<typeof validateEmailOrUsernameAction>
): unknown {
  try {
    const api: API = yield getContext("api");
    yield call(api.validateEmailOrUsername, action.payload);
    yield put(validateEmailOrUsernameSuccess(action.payload.property));
  } catch (error) {
    yield put(
      validateEmailOrUsernameFailure({
        error: (<Error>error)?.message,
        property: action.payload.property
      })
    );
  }
}

export function* getPaypalBillingAgreement(
  action: ReturnType<typeof getPayPalBillingAgreementAction>
): unknown {
  try {
    const api: API = yield getContext("api");
    const response: PayPalBillingAgreementResponse = yield call(
      api.getPayPalBillingAgreement,
      action.payload
    );
    yield put(
      getPayPalBillingAgreementSuccess(response.paypalBillingAgreement)
    );
  } catch (error) {
    yield put(getPayPalBillingAgreementFailure((<Error>error)?.message));
  }
}

export default function* saga(): Generator {
  yield all([
    takeLatest(UserActions.FORGOT_PASSWORD, forgotPassword),
    takeLatest(UserActions.FORGOT_USERNAME, forgotUsername),
    takeLatest(UserActions.GET_ADDRESS, getAddress),
    takeLatest(UserActions.GET_BETMGM_URL, getBetMGMUrl),
    takeLatest(UserActions.GET_BILLING_HISTORY, getBillingHistory),
    takeLatest(UserActions.GET_BILLING_INFORMATION, getBillingInformation),
    takeLatest(UserActions.GET_CURRENT_USER, getCurrentUser),
    takeLatest(UserActions.GET_SUBSCRIPTIONS, getSubscriptions),
    takeLatest(
      UserActions.GET_PAYPAL_BILLING_AGREEMENT,
      getPaypalBillingAgreement
    ),
    takeLatest(UserActions.GET_IS_PROSPECT_FOLLOWED, getIsProspectFollowed),
    takeLatest(UserActions.GET_IS_CURRENT_USER_VALID, getIsCurrentUserValid),
    takeLatest(
      UserActions.REQUEST_CONFIRMATION_EMAIL,
      requestConfirmationEmail
    ),
    takeLatest(UserActions.STRICT_SIGN_IN, strictSignIn),
    takeLatest(
      UserActions.UPDATE_BILLING_INFORMATION,
      updateBillingInformation
    ),
    takeLatest(UserActions.UPDATE_CURRENT_USER, updateCurrentUser),
    takeLatest(
      UserActions.UPDATE_IS_PROSPECT_FOLLOWED,
      updateIsProspectFollowed
    ),
    takeLatest(UserActions.UPDATE_PASSWORD, updatePassword),
    takeLatest(UserActions.VALIDATE_BETMGM, validateBetMGM),
    takeLatest(UserActions.VALIDATE_EMAIL_OR_USERNAME, validateUserData)
  ]);
}
