import { all, call, getContext, put, takeLatest } from "redux-saga/effects";
import { clearAlerts, showAlert } from "rivals/redux/alert/actions";
import { AlertTypes } from "rivals/redux/alert/reducer";
import API from "rivals/services";
import { Response as SaveAdminListResponse } from "rivals/services/saveAdminList/types";
import { Response as SaveAdminListSheetsResponse } from "rivals/services/saveAdminListSheets/types";
import { Response as SearchPeopleResponse } from "rivals/services/searchPeople/types";
import {
  AdminListsActions,
  deleteList as deleteListAction,
  deleteListFailure,
  deleteListSuccess,
  getListData as getListAction,
  getListDataFailure,
  getListDataSuccess,
  getListPeople as getListPeopleAction,
  getListPeopleFailure,
  getListPeopleSuccess,
  saveAdminList as saveAdminListAction,
  saveAdminListFailure,
  saveAdminListSheets as saveAdminListSheetsAction,
  saveAdminListSheetsFailure,
  saveAdminListSheetsSuccess,
  saveAdminListSuccess,
  searchPeople as searchPeopleAction,
  searchPeopleFailure,
  searchPeopleSuccess
} from "./actions";

export function* deleteList(
  action: ReturnType<typeof deleteListAction>
): unknown {
  try {
    const api: API = yield getContext("api");
    yield call(api.deleteList, action.payload);
    yield put(deleteListSuccess());
  } catch (error) {
    yield put(deleteListFailure((<Error>error)?.message));
  }
}

export function* getListData(
  action: ReturnType<typeof getListAction>
): unknown {
  try {
    const api: API = yield getContext("api");
    const response = yield call(api.getListData, action.payload);

    yield put(getListDataSuccess(response));
  } catch (error) {
    yield put(getListDataFailure((<Error>error)?.message));
  }
}

export function* getListPeople(
  action: ReturnType<typeof getListPeopleAction>
): unknown {
  try {
    const api: API = yield getContext("api");
    const response = yield call(api.getListPeople, action.payload);

    yield put(getListPeopleSuccess(response));
  } catch (error) {
    yield put(getListPeopleFailure((<Error>error)?.message));
  }
}

export function* searchPeople(
  action: ReturnType<typeof searchPeopleAction>
): unknown {
  try {
    const api: API = yield getContext("api");
    const response: SearchPeopleResponse = yield call(
      api.searchPeople,
      action.payload
    );
    yield put(searchPeopleSuccess(response));
  } catch (error) {
    yield put(searchPeopleFailure((<Error>error)?.message));
  }
}

export function* saveAdminList(
  action: ReturnType<typeof saveAdminListAction>
): unknown {
  try {
    yield put(clearAlerts());
    const api: API = yield getContext("api");
    const response: SaveAdminListResponse = yield call(
      api.saveAdminList,
      action.payload
    );
    yield put(saveAdminListSuccess(response));
    yield put(
      showAlert({
        hasCloseButton: true,
        message: response.list.published
          ? `${response.list.name} was published`
          : `${response.list.name} was saved as draft`,
        type: AlertTypes.SUCCESS
      })
    );
  } catch (error) {
    yield put(saveAdminListFailure((<Error>error)?.message)),
      yield put(
        showAlert({
          hasCloseButton: true,
          message: (<Error>error)?.message,
          type: AlertTypes.SEVERE
        })
      );
  }
}

export function* saveAdminListSheets(
  action: ReturnType<typeof saveAdminListSheetsAction>
): unknown {
  try {
    yield put(clearAlerts());
    const api: API = yield getContext("api");
    const response: SaveAdminListSheetsResponse = yield call(
      api.saveAdminListSheets,
      action.payload
    );
    yield put(saveAdminListSheetsSuccess(response.workerTriggered));
    yield put(
      showAlert({
        durationInSeconds: 5,
        hasCloseButton: true,
        message: "List has been submitted. Check your email for updates.",
        type: AlertTypes.SUCCESS
      })
    );
  } catch (error) {
    yield put(saveAdminListSheetsFailure((<Error>error)?.message)),
      yield put(
        showAlert({
          hasCloseButton: true,
          message: (<Error>error)?.message,
          type: AlertTypes.SEVERE
        })
      );
  }
}

export default function* saga(): Generator {
  yield all([
    takeLatest(AdminListsActions.DELETE_LIST, deleteList),
    takeLatest(AdminListsActions.GET_LIST_DATA, getListData),
    takeLatest(AdminListsActions.GET_LIST_PEOPLE, getListPeople),
    takeLatest(AdminListsActions.SAVE_ADMIN_LIST, saveAdminList),
    takeLatest(AdminListsActions.SAVE_ADMIN_LIST_SHEETS, saveAdminListSheets),
    takeLatest(AdminListsActions.SEARCH_PEOPLE, searchPeople)
  ]);
}
