import classNames from "classnames";
import { usePathname } from "next/navigation";
import {
  buildMenu,
  buildMobileMenu
} from "rivals/components/app/Layout/Header/Nav/MenuBuilder";
import styles from "rivals/components/app/Layout/Header/Nav/Nav.module.scss";
import strings from "rivals/components/app/Layout/Header/Nav/strings";
import type { Props } from "rivals/components/app/Layout/Header/Nav/types";
import {
  getSelectedMenuItem,
  navElements
} from "rivals/components/app/Layout/Header/Nav/utils";
import MediaWrapper from "rivals/components/shared/Media";
import Menu from "rivals/components/shared/Menu";
import { MEDIA_BREAKPOINTS } from "rivals/media";
import { isAuthenticated } from "rivals/redux/authentication/selectors";
import { fetchTeamsNavItems } from "rivals/redux/teamsNav/actions";
import { selectTeamsNavItems } from "rivals/redux/teamsNav/selectors";
import { useAppDispatch, useAppSelector } from "rivals/shared/utils/hooks";
import {
  isAdminProtectedPage,
  isUserProtectedPage
} from "rivals/shared/utils/routes";

/**
 * Redux version of Nav for use in the Page Router only
 */
const Nav = ({
  collapsed,
  navItems,
  useLegacyTemplate = false,
  navBarRailsConstants
}: Props): React.JSX.Element => {
  const path = usePathname() ?? "";
  const dispatch = useAppDispatch();
  const teamsNavItems = useAppSelector(selectTeamsNavItems);
  const menuItems = navElements({
    navBarRailsConstants,
    navItems,
    path,
    teamOptions: teamsNavItems
  });
  const authenticated = useAppSelector(isAuthenticated);
  const desktopItems = buildMenu(useLegacyTemplate, menuItems);
  const mobileItems = buildMobileMenu(authenticated, desktopItems);

  const onOpenMenuChange = (openKeys: string[]): void => {
    if (!isUserProtectedPage(path) && !isAdminProtectedPage(path)) {
      const teamsMenuOpen = openKeys.some(key =>
        key.toLowerCase().includes(strings.navTitles.teams.toLowerCase())
      );
      if (teamsMenuOpen && !teamsNavItems) {
        dispatch(fetchTeamsNavItems());
      }
    }
  };

  return (
    <>
      <MediaWrapper greaterThanOrEqual={MEDIA_BREAKPOINTS.LG}>
        <div
          className={classNames({
            [styles.navContainerHorizontalMenu]: !useLegacyTemplate,
            [styles.navContainerHorizontalMenuLegacy]: useLegacyTemplate
          })}
        >
          <Menu
            defaultSelectedKeys={[getSelectedMenuItem(menuItems, path)]}
            items={desktopItems}
            mode="horizontal"
            onOpenChange={onOpenMenuChange}
          />
        </div>
      </MediaWrapper>

      <MediaWrapper lessThan={MEDIA_BREAKPOINTS.LG}>
        <Menu
          className={classNames(styles.navInlineMenu, {
            [styles.collapsed]: collapsed
          })}
          items={mobileItems}
          mode="inline"
          onOpenChange={onOpenMenuChange}
        />
      </MediaWrapper>
    </>
  );
};

export default Nav;
