import classNames from "classnames";
import { FunctionComponent, ReactElement } from "react";
import Link from "rivals/components/shared/Link";
import { setData } from "rivals/shared/utils/qa";
import styles from "./Button.module.scss";
import type { Props } from "./types";

const ButtonWrapper: FunctionComponent<Props> = ({
  backgroundColor,
  borderColor,
  buttonText,
  className,
  dark = false,
  data = "",
  disabled,
  fullWidth = false,
  icon,
  inverted = false,
  legacy = false,
  leadingIcon,
  onClick,
  shouldAddNewTab = false,
  size = "medium",
  textColor,
  type = "button",
  url
}): ReactElement => {
  const buttonStyles = {
    backgroundColor,
    borderColor,
    color: textColor
  };

  const buttonClasses = classNames(
    legacy ? styles.legacyButton : styles.button,
    styles[`${size}${legacy ? "Legacy" : ""}`],
    {
      [styles.fullWidth]: fullWidth,
      [styles.dark]: dark,
      [styles.inverted]: inverted && !dark
    },
    className
  );

  const textClasses = classNames(
    styles.text,
    { [styles.leadingIcon]: !!leadingIcon },
    { [styles.trailingIcon]: !!icon }
  );

  const button = (
    <button
      className={buttonClasses}
      {...setData(data, "button")}
      disabled={disabled}
      onClick={onClick}
      style={buttonStyles}
      type={type}
    >
      {leadingIcon}
      <span className={textClasses}>{buttonText}</span>
      {icon}
    </button>
  );

  return url ? (
    <Link
      className="buttonLink"
      data={data}
      href={url}
      target={shouldAddNewTab ? "_blank" : undefined}
    >
      {button}
    </Link>
  ) : (
    button
  );
};

export default ButtonWrapper;
