import { createSlice } from "@reduxjs/toolkit";
import { GetCloudinarySignatureResponse } from "rivals/services/container";
import { getCloudinarySignature } from "./actions";

export type State = {
  error?: string;
  signatureDetails?: GetCloudinarySignatureResponse;
  submitting: boolean;
};

const initialState: State = {
  error: undefined,
  signatureDetails: undefined,
  submitting: false
};

const cloudinarySlice = createSlice({
  extraReducers: builder => {
    builder
      .addCase(getCloudinarySignature.pending, state => {
        state.submitting = true;
      })
      .addCase(getCloudinarySignature.fulfilled, (state, action) => {
        state.submitting = false;
        state.signatureDetails = action.payload;
      })
      .addCase(getCloudinarySignature.rejected, (state, action) => {
        state.submitting = false;
        state.error = (action.payload as string) || action.error.message;
      });
  },
  initialState,
  name: "cloudinary",
  reducers: {}
});

export default cloudinarySlice.reducer;
