import { createSlice } from "@reduxjs/toolkit";

import { Transfer } from "rivals/shared/interfaces/Transfer";
import { getTransfers } from "./actions";

export type State = {
  error?: string;
  submitting: boolean;
  success?: boolean;
  transfers?: Transfer[];
};

const initialState: State = {
  submitting: false,
  success: false,
  transfers: undefined
};

const transferSlice = createSlice({
  extraReducers: builder => {
    builder
      .addCase(getTransfers.pending, state => {
        state.submitting = true;
        state.success = false;
      })
      .addCase(getTransfers.fulfilled, (state, action) => {
        state.submitting = false;
        state.success = true;
        state.transfers = action.payload.transferActivity;
      })
      .addCase(getTransfers.rejected, (state, action) => {
        state.error = (action.payload as string) || action.error.message;
        state.submitting = false;
        state.success = false;
      });
  },
  initialState,
  name: "transfer",
  reducers: {}
});

export default transferSlice.reducer;
