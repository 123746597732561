import Cookies from "js-cookie";
import { FunctionComponent, ReactElement, useEffect, useState } from "react";
import InstallPWAContainer from "rivals/components/shared/InstallPWA/container";
import PushNotificationsBanner from "rivals/components/shared/PushNotificationsBanner/container";
import { getSelectedSite } from "rivals/redux/rails/selectors";
import { RIVALS_MOBILE_CLIENT } from "rivals/shared/constants";
import { useAppSelector } from "rivals/shared/utils/hooks";
import { updateLocale } from "rivals/shared/utils/time";
import Alerts from "./Alerts/WithRedux";
import Footer from "./Footer/WithRedux/container";
import Header from "./Header/WithRedux";
import LoadingOverlay from "./LoadingOverlay";
import { OwnProps } from "./types";

const Layout: FunctionComponent<OwnProps> = ({
  children,
  flipperSettings
}): ReactElement => {
  const [isMobile, setIsMobile] = useState(false);
  const currentSite = useAppSelector(getSelectedSite);

  useEffect(() => {
    // Client side only operations that can't be represented in the next.js static page
    // need to be performed in a useEffect. That way it runs after initial load but before component mounting
    setIsMobile(Cookies.get(RIVALS_MOBILE_CLIENT) !== undefined ? true : false);
  }, []);

  useEffect(() => {
    updateLocale();
  }, []);

  return (
    <>
      {!isMobile && <Header useLegacyNavTemplate />}
      <LoadingOverlay />
      <Alerts site={currentSite} />
      <PushNotificationsBanner />
      {children}
      <InstallPWAContainer />
      {!isMobile && <Footer giftingEnabled={!!flipperSettings?.gifting} />}
    </>
  );
};

export default Layout;
