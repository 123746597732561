import { Response as CCPAFooterConstants } from "rivals/services/getCCPAFooter/types";
import {
  META_CONTENT_DIDOMI_CONSENT_FLOW,
  META_CONTENT_DIDOMI_LOCALE,
  META_NAME_DIDOMI_CONSENT_FLOW,
  META_NAME_DIDOMI_LOCALE,
  META_NAME_GUCE_CONSENT_HOST,
  SCRIPT_SRC_CMP_OAUTH
} from "rivals/shared/constants";
import { useMetaTag } from "./hooks/useMetaTag";
import { useScript } from "./hooks/useScript";

export const useCompliance = (constants?: CCPAFooterConstants): void => {
  useScript({
    shouldAppend: process.env.NEXT_PUBLIC_CMP_SCRIPT_ENABLED === "true",
    src: SCRIPT_SRC_CMP_OAUTH
  });

  useMetaTag({
    content: META_CONTENT_DIDOMI_CONSENT_FLOW,
    name: META_NAME_DIDOMI_CONSENT_FLOW,
    shouldAppend: constants?.enableDidomi
  });

  useMetaTag({
    content: META_CONTENT_DIDOMI_LOCALE,
    name: META_NAME_DIDOMI_LOCALE,
    shouldAppend: constants?.enableDidomi
  });

  useMetaTag({
    content: constants?.guceConsentHost,
    name: META_NAME_GUCE_CONSENT_HOST,
    shouldAppend: !!constants?.guceConsentHost
  });

  useScript({
    async: true,
    shouldAppend: !!constants?.yahooGucePath,
    src: constants?.yahooGucePath
  });

  useScript({
    async: true,
    shouldAppend: !!constants?.yahooGpcPath,
    src: constants?.yahooGpcPath
  });
};
