import { Menu as AntdMenu, ConfigProvider } from "antd";
import type { OverrideToken } from "antd/es/theme/interface";
import MediaWrapper from "rivals/components/shared/Media";
import { MEDIA_BREAKPOINTS } from "rivals/media";
import MenuTokens from "rivals/shared/design/component_tokens/menu";
import styles from "./Menu.module.scss";
import { MenuVariant, Props } from "./types";

const Menu = ({
  variant = MenuVariant.Light,
  ...antdMenuProps
}: Props): React.JSX.Element => {
  const menuWithComponentToken = (
    componentToken: Partial<OverrideToken["Menu"]>
  ): React.JSX.Element => {
    return (
      <ConfigProvider theme={{ components: { Menu: componentToken } }}>
        <AntdMenu {...antdMenuProps} />
      </ConfigProvider>
    );
  };

  return (
    <>
      <MediaWrapper
        className={styles.media}
        greaterThanOrEqual={MEDIA_BREAKPOINTS.LG}
      >
        {menuWithComponentToken(MenuTokens[variant].Desktop)}
      </MediaWrapper>

      <MediaWrapper className={styles.media} lessThan={MEDIA_BREAKPOINTS.LG}>
        {menuWithComponentToken(MenuTokens[variant].Mobile)}
      </MediaWrapper>
    </>
  );
};

export default Menu;
