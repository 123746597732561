import { NavOptions } from "rivals/components/app/Layout/Header/types";
import type {
  GetSiteRequest,
  GetSitesRequest,
  GetSitesResponse
} from "rivals/services/container";
import type { Response as CCPAFooterResponse } from "rivals/services/getCCPAFooter/types";
import type { Response as RailsConstantsResponse } from "rivals/services/getRailsConstants/types";
import { PLANS } from "rivals/shared/constants";
import type { Site } from "rivals/shared/interfaces/Site";
import type { TaxAddress } from "rivals/shared/interfaces/User";
import { RailsConstantsKeys } from "./reducer";

export enum RailsActions {
  GET_CCPA_FOOTER = "GET_CCPA_FOOTER",
  GET_CCPA_FOOTER_FAILURE = "GET_CCPA_FOOTER_FAILURE",
  GET_CCPA_FOOTER_SUCCESS = "GET_CCPA_FOOTER_SUCCESS",
  GET_NAV_ITEMS = "GET_NAV_ITEMS",
  GET_NAV_ITEMS_FAILURE = "GET_NAV_ITEMS_FAILURE",
  GET_NAV_ITEMS_SUCCESS = "GET_NAV_ITEMS_SUCCESS",
  GET_RAILS_CONSTANTS = "GET_RAILS_CONSTANTS",
  GET_RAILS_CONSTANTS_FAILURE = "GET_RAILS_CONSTANTS_FAILURE",
  GET_RAILS_CONSTANTS_SUCCESS = "GET_RAILS_CONSTANTS_SUCCESS",
  GET_SITE = "GET_SITE",
  GET_SITE_FAILURE = "GET_SITE_FAILURE",
  GET_SITE_SUCCESS = "GET_SITE_SUCCESS",
  GET_SITES = "GET_SITES",
  GET_SITES_FAILURE = "GET_SITES_FAILURE",
  GET_SITES_SUCCESS = "GET_SITES_SUCCESS",
  GET_TAX = "GET_TAX",
  GET_TAX_FAILURE = "GET_TAX_FAILURE",
  GET_TAX_SUCCESS = "GET_TAX_SUCCESS",
  RESET_ADDRESS_VALIDATION_FIELDS = "RESET_ADDRESS_VALIDATION_FIELDS",
  VALIDATE_ADDRESS_THROUGH_TAX_ESTIMATION = "VALIDATE_ADDRESS_THROUGH_TAX_ESTIMATION",
  VALIDATE_ADDRESS_THROUGH_TAX_ESTIMATION_FAILURE = "VALIDATE_ADDRESS_THROUGH_TAX_ESTIMATION_FAILURE",
  VALIDATE_ADDRESS_THROUGH_TAX_ESTIMATION_SUCCESS = "VALIDATE_ADDRESS_THROUGH_TAX_ESTIMATION_SUCCESS"
}

export const getCCPAFooter = (cache = true) =>
  ({
    payload: { cache },
    type: RailsActions.GET_CCPA_FOOTER
  } as const);

export const getCCPAFooterSuccess = (response: CCPAFooterResponse) =>
  ({
    payload: response,
    type: RailsActions.GET_CCPA_FOOTER_SUCCESS
  } as const);

export const getCCPAFooterFailure = (error?: string) =>
  ({
    error: true,
    payload: { error },
    type: RailsActions.GET_CCPA_FOOTER_FAILURE
  } as const);

export const getNavItems = (siteName?: string) =>
  ({
    payload: { siteName },
    type: RailsActions.GET_NAV_ITEMS
  } as const);

export const getNavItemsFailure = (error?: string) =>
  ({
    error: true,
    payload: { error },
    type: RailsActions.GET_NAV_ITEMS_FAILURE
  } as const);

export const getNavItemsSuccess = (menuItems: NavOptions) =>
  ({
    payload: { menuItems },
    type: RailsActions.GET_NAV_ITEMS_SUCCESS
  } as const);

export const getRailsConstants = (
  components: RailsConstantsKeys[] = [],
  filters?: RailsConstantsKeys[]
) =>
  ({
    payload: { components, filters },
    type: RailsActions.GET_RAILS_CONSTANTS
  } as const);

export const getRailsConstantsFailure = (error?: string) =>
  ({
    error: true,
    payload: { error },
    type: RailsActions.GET_RAILS_CONSTANTS_FAILURE
  } as const);

export const getRailsConstantsSuccess = (response: RailsConstantsResponse) =>
  ({
    payload: response,
    type: RailsActions.GET_RAILS_CONSTANTS_SUCCESS
  } as const);

export const getSites = (request?: GetSitesRequest) =>
  ({
    payload: request,
    type: RailsActions.GET_SITES
  } as const);

export const getSitesFailure = (error?: string) =>
  ({
    payload: { error },
    type: RailsActions.GET_SITES_FAILURE
  } as const);

export const getSitesSuccess = (response: GetSitesResponse) =>
  ({
    payload: response,
    type: RailsActions.GET_SITES_SUCCESS
  } as const);

export const getSite = (request: GetSiteRequest) =>
  ({
    payload: request,
    type: RailsActions.GET_SITE
  } as const);

export const getSiteFailure = (error?: string) =>
  ({
    payload: { error },
    type: RailsActions.GET_SITE_FAILURE
  } as const);

export const getSiteSuccess = (response: Site) =>
  ({
    payload: response,
    type: RailsActions.GET_SITE_SUCCESS
  } as const);

export const getTax = (
  plan: string,
  siteId: number,
  address: TaxAddress,
  promoCode: string,
  salePrice: number
) =>
  ({
    payload: {
      address,
      plan,
      promoCode,
      salePrice,
      siteId
    },
    type: RailsActions.GET_TAX
  } as const);

export const getTaxFailure = (error?: string) =>
  ({
    error: true,
    payload: { error },
    type: RailsActions.GET_TAX_FAILURE
  } as const);

export const getTaxSuccess = (tax: number) =>
  ({
    payload: { tax },
    type: RailsActions.GET_TAX_SUCCESS
  } as const);

// validate user address with OBI through using tax estimation request
export const validateAddressThroughTaxEstimation = (
  address: TaxAddress,
  siteId: number
) =>
  ({
    payload: {
      address,
      plan: PLANS.ANNUAL,
      promoCode: "",
      salePrice: 1,
      siteId
    },
    type: RailsActions.VALIDATE_ADDRESS_THROUGH_TAX_ESTIMATION
  } as const);

export const validateAddressThroughTaxEstimationFailure = (error?: string) =>
  ({
    error: true,
    payload: { error },
    type: RailsActions.VALIDATE_ADDRESS_THROUGH_TAX_ESTIMATION_FAILURE
  } as const);

export const validateAddressThroughTaxEstimationSuccess = () =>
  ({
    type: RailsActions.VALIDATE_ADDRESS_THROUGH_TAX_ESTIMATION_SUCCESS
  } as const);

export const resetAddressValidationFields = () =>
  ({
    type: RailsActions.RESET_ADDRESS_VALIDATION_FIELDS
  } as const);

export type RailsAction =
  | ReturnType<typeof getNavItems>
  | ReturnType<typeof getNavItemsFailure>
  | ReturnType<typeof getNavItemsSuccess>
  | ReturnType<typeof getCCPAFooter>
  | ReturnType<typeof getCCPAFooterFailure>
  | ReturnType<typeof getCCPAFooterSuccess>
  | ReturnType<typeof getRailsConstants>
  | ReturnType<typeof getRailsConstantsFailure>
  | ReturnType<typeof getRailsConstantsSuccess>
  | ReturnType<typeof getSites>
  | ReturnType<typeof getSitesFailure>
  | ReturnType<typeof getSitesSuccess>
  | ReturnType<typeof getSite>
  | ReturnType<typeof getSiteFailure>
  | ReturnType<typeof getSiteSuccess>
  | ReturnType<typeof getTax>
  | ReturnType<typeof getTaxFailure>
  | ReturnType<typeof getTaxSuccess>
  | ReturnType<typeof validateAddressThroughTaxEstimation>
  | ReturnType<typeof validateAddressThroughTaxEstimationFailure>
  | ReturnType<typeof validateAddressThroughTaxEstimationSuccess>
  | ReturnType<typeof resetAddressValidationFields>;
