export const capitalizeWords = (key: string): string => {
  const words = key.match(/[&A-Za-z][a-z]*/g) || [];

  return words
    .map(word => word.charAt(0).toUpperCase() + word.substring(1))
    .join(" ");
};

const ellipseTrailCount = 3;
const lastWordStartPos = -1;

export const ellipsifyString = (input: string, limit: number): string => {
  if (input.length > limit) {
    const numWords = input.split(" ").length;
    for (let i = 0; i < numWords; i++) {
      const lastWordStart = input.lastIndexOf(" ");
      if (lastWordStart == lastWordStartPos) {
        input = `${input.substr(0, limit - ellipseTrailCount)}...`;
        return input;
      }
      input = input.substring(0, lastWordStart);

      if (input.length <= limit - ellipseTrailCount) {
        input = `${input}...`;
        return input;
      }
    }
  }
  return input;
};

export const parseQuery = (param: string | string[]): string => {
  let parsedParam;
  if (typeof param === "string") {
    parsedParam = param;
  } else {
    parsedParam = param[0];
  }

  return parsedParam;
};

export const parseSlug = (slug: string | string[]): string => {
  const slugString = parseQuery(slug);
  return slugString.toString().split("-").pop() || "";
};

const escapeRegex = (string: string): string =>
  string.replace(/[/\-\\^$*+?.()|[\]{}]/g, "\\$&");

/**
 * replaces multiple words in a string with multiple other words
 * @param str the original string as first param
 * @param mapObj map of replacements as second param
 * @returns the transformed string
 * eg: replaceMultiple(something_cool.csv, {".csv", "", "_", " "}) returns -> something cool
 */
export const replaceMultiple = (
  str: string,
  mapObj: Record<string, string>
): string => {
  const regExp = new RegExp(
    Object.keys(mapObj)
      .map(key => escapeRegex(key))
      .join("|"),
    "gi"
  );
  return str.replace(regExp, matched => mapObj[matched.toLowerCase()]);
};
