import { configureStore, ThunkDispatch } from "@reduxjs/toolkit";
import { getCookie } from "cookies-next";
import { Context, createWrapper } from "next-redux-wrapper";
import { Store as ReduxStore } from "redux";
import createSagaMiddleware, { Task } from "redux-saga";
import { RivalsAppContext } from "rivals/Context";
import API from "rivals/services";
import { CSURF_TOKEN_COOKIE, USER_TOKEN_COOKIE } from "rivals/shared/constants";
import { isClient } from "rivals/shared/utils/isClient";
import { Action } from "./Action";
import rootReducer, { State } from "./reducer";
import rootSaga from "./rootSaga";

export type Store = ReduxStore<State, Action> & {
  dispatch: ThunkDispatch<State, void, Action>;
} & {
  sagaTask?: Task;
};

// to use from outside React components
let globalStore: Store;
let rivalsApiInstance: API;

/**
 * Function used to:
 *  - create an instance of API class located at rivals/services
 *  - create a redux middleware and connects the sagas to the redux store
 *  - configure redux middlewares (thunk, serializability, immutability)
 * @param context could be `NextPageContext` or `AppContext` or `getStaticProps`
 * or `getServerSideProps` context depending on which lifecycle function wraps
 * @returns a redux store
 */
export function makeStore(context?: Context): Store {
  const appContext = context as RivalsAppContext;
  const ctx = appContext?.ctx;

  const sagaContext: { api?: API } = {};
  const isServer = !isClient();

  if (ctx) {
    ctx.isServer = isServer;
  }

  let token;
  let csurf;

  if (ctx?.req || !isServer) {
    token = getCookie(USER_TOKEN_COOKIE, { req: ctx?.req });
    csurf = getCookie(CSURF_TOKEN_COOKIE, { req: ctx?.req });
  }

  // TODO: https://griosf.atlassian.net/browse/RVLS-10254
  sagaContext.api = new API(isServer, token, csurf);
  rivalsApiInstance = sagaContext.api;

  const sagaMiddleware = createSagaMiddleware({
    context: sagaContext
  });

  // getDefaultMiddleware returns an array of middlewares
  // refer to these docs: https://redux-toolkit.js.org/api/getDefaultMiddleware
  const store: Store = configureStore({
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        immutableCheck: { warnAfter: 128 }, // default: 32ms
        serializableCheck: { warnAfter: 128 } // default: 32ms
      }).concat(sagaMiddleware),
    reducer: rootReducer
  });

  store.sagaTask = sagaMiddleware.run(rootSaga);
  globalStore = store;

  return store;
}

export { globalStore, rivalsApiInstance as apiService };

// https://github.com/kirill-konshin/next-redux-wrapper#custom-serialization-and-deserialization
export const reduxWrapper = createWrapper<Store>(makeStore, {
  debug: false,
  deserializeState: state => {
    if (typeof state === "object") {
      return state;
    }

    return JSON.parse(state);
  },
  serializeState: state => {
    if (typeof state === "string") {
      return state;
    }

    return JSON.stringify(state);
  }
});

export type AppStore = ReturnType<typeof makeStore>;
