import { createSelector } from "reselect";
import { parseNavItems } from "rivals/components/app/Layout/Header/utils";
import { RailsConstantsKeys, State } from "rivals/redux/rails/reducer";
import { State as AppState } from "rivals/redux/reducer";
import { createDeepEqualSelector } from "rivals/redux/selector";
import {
  getIsUserLoadCompleted,
  getSpyAddOnSites,
  isSuperAdmin,
  selectHasAdLite
} from "rivals/redux/user/selectors";
import { ATS_TYPES } from "rivals/shared/constants";
import { isRootDomain } from "rivals/shared/utils/routes";

export const getRails = (state: AppState): State => state.rails;

export const selectCCPAFooterConstants = createDeepEqualSelector(
  getRails,
  state => state.CCPAFooter
);

export const selectAtsJurisdiction = createSelector(
  selectCCPAFooterConstants,
  footerConstants => footerConstants?.atsJurisdiction
);

export const selectIsGdprUser = createSelector(
  selectCCPAFooterConstants,
  footerConstants =>
    footerConstants?.atsJurisdiction?.toUpperCase() ===
    ATS_TYPES.GDPR.toUpperCase()
);

export const getThirdPartyEmbedConsent = createSelector(
  selectCCPAFooterConstants,
  footerConstants => !!footerConstants?.thirdPartyEmbedConsent
);

export const selectUserCountry = createSelector(
  selectCCPAFooterConstants,
  footerConstants => footerConstants?.usercountry
);

export const getNavItems = createSelector(getRails, state => state.navItems);

export const getSites = createSelector(getRails, state => state.sites);

export const getSelectedSite = createSelector(
  getRails,
  state => state.selectedSite
);

export const getSubscribableSites = createDeepEqualSelector(getSites, sites =>
  sites?.filter(site => site.subscribable === true)
);

export const getIsSubscribable = createSelector(
  getSelectedSite,
  site => site?.subscribable === true
);

export const selectHasSelectedSiteAddOns = createSelector(
  getSpyAddOnSites,
  getSelectedSite,
  (spyAddOnSites, selectedSite) =>
    spyAddOnSites &&
    spyAddOnSites.some(site => site.subdomain === selectedSite?.subdomain)
);

export const getRailsConstants = createSelector(
  getRails,
  state => state.railsConstants
);

export const getAdsSettingsConstants = createSelector(
  getRailsConstants,
  constants => constants && constants[RailsConstantsKeys.ADS_SETTINGS]
);

export const getAutoSaveConstants = createSelector(
  getRailsConstants,
  constants => constants && constants[RailsConstantsKeys.AUTO_SAVE]
);

export const getCancellationConstants = createSelector(
  getRailsConstants,
  constants => constants && constants[RailsConstantsKeys.CANCELLATION]
);

export const getCancelFormRailsConstants = createSelector(
  getRailsConstants,
  constants => constants && constants[RailsConstantsKeys.CANCELLATION_FORM]
);

export const selectNavBarConstants = createSelector(
  getRailsConstants,
  constants => constants?.[RailsConstantsKeys.NAV_BAR]
);

export const getForgotPasswordConstants = createSelector(
  getRailsConstants,
  constants => constants && constants[RailsConstantsKeys.FORGOT_PASSWORD]
);

export const getForgotUsernameConstants = createSelector(
  getRailsConstants,
  constants => constants && constants[RailsConstantsKeys.FORGOT_USERNAME]
);

export const getProspectProfileConstants = createSelector(
  getRailsConstants,
  constants => constants && constants[RailsConstantsKeys.PROSPECT_PROFILE]
);

export const getSaveOfferRailsConstants = createSelector(
  getRailsConstants,
  constants => constants && constants[RailsConstantsKeys.CANCELLATION_SAVE]
);

export const getStrictSignInConstants = createSelector(
  getRailsConstants,
  constants => constants && constants[RailsConstantsKeys.STRICT_SIGN_IN]
);

export const getUserProfileConstants = createSelector(
  getRailsConstants,
  constants => constants && constants[RailsConstantsKeys.USER_PROFILE]
);

export const getBillingInformationConstants = createSelector(
  getRailsConstants,
  constants => constants && constants[RailsConstantsKeys.BILLING_INFORMATION]
);

export const getStatesConstants = createSelector(
  getRailsConstants,
  constants => constants && constants[RailsConstantsKeys.STATE_LIST]
);

export const getSubscriptionsConstants = createSelector(
  getRailsConstants,
  constants => constants && constants[RailsConstantsKeys.USER_SUBSCRIPTIONS]
);

export const getSubscriptionPricingConstants = createSelector(
  getRailsConstants,
  constants => constants && constants[RailsConstantsKeys.SUBSCRIPTION_PRICING]
);

export const selectTaxEstimationError = createSelector(
  getRails,
  constants => constants.taxEstimationError
);

export const selectAddressValidationError = createSelector(
  getRails,
  constants => constants.addressValidationError
);

export const selectAddressValidationSuccess = createSelector(
  getRails,
  constants => constants.addressValidationSuccess
);

export const getRivalsRatingOptions = createSelector(
  getRailsConstants,
  constants => constants && constants[RailsConstantsKeys.RIVALS_RATING_OPTIONS]
);

const getSuperAdminLinks = createSelector(
  selectNavBarConstants,
  navBarConstants => navBarConstants?.superadminLinks
);

const getAdminLinks = createSelector(
  selectNavBarConstants,
  navBarConstants => navBarConstants?.adminLinks
);

export const getAdminPagesNavItems = createSelector(
  getSuperAdminLinks,
  getAdminLinks,
  isSuperAdmin,
  (superadminLinks, adminLinks, isSuperAdmin) => {
    const rawNavItems = isSuperAdmin ? superadminLinks : adminLinks;

    return rawNavItems ? parseNavItems(rawNavItems) : undefined;
  }
);

export const isRootSite = createSelector(
  getSelectedSite,
  site => site && isRootDomain(site.subdomain)
);

/**
 * DistroScale and Benji scripts are added only if:
 *
 * User opts in to third party embed consent
 * User data has finished fetched from the server, or user is logged out
 * User does not have ad lite or is anauthenticated
 */
export const selectRenderAdsForUser = createSelector(
  selectHasAdLite,
  getIsUserLoadCompleted,
  selectCCPAFooterConstants,
  (adLite, userLoadCompleted, footerConstants) =>
    !adLite && userLoadCompleted && !!footerConstants?.thirdPartyEmbedConsent
);
