import { Transaction } from "rivals/components/pages/User/BillingHistory/types";
import {
  ValidationFailureResponse,
  ValidationParams,
  ValidationProperty
} from "rivals/services/registration/types";
import { BillingParams } from "rivals/services/updateBillingInformation/types";
import { Request as UpdateIsProspectFollowedParams } from "rivals/services/updateIsProspectFollowed/types";
import { Response as ValidateBetMGMResponse } from "rivals/services/validateBetMGM/types";
import { AthletePageParams } from "rivals/shared/interfaces/Athlete";
import { Subscription } from "rivals/shared/interfaces/Subscription";
import {
  Address,
  BillingInformation,
  PayPalAuthorizationData,
  PayPalBillingAgreement,
  PayPalBillingAgreementParams,
  User
} from "rivals/shared/interfaces/User";

export enum UserActions {
  FORGOT_PASSWORD = "FORGOT_PASSWORD",
  FORGOT_CREDENTIALS_SUCCESS = "FORGOT_CREDENTIALS_SUCCESS",
  FORGOT_USERNAME = "FORGOT_USERNAME",
  GET_ADDRESS = "GET_ADDRESS",
  GET_ADDRESS_SUCCESS = "GET_ADDRESS_SUCCESS",
  GET_ADDRESS_FAILURE = "GET_ADDRESS_FAILURE",
  GET_BETMGM_URL = "GET_BETMGM_URL",
  GET_BETMGM_URL_FAILURE = "GET_BETMGM_URL_FAILURE",
  GET_BETMGM_URL_SUCCESS = "GET_BETMGM_URL_SUCCESS",
  GET_BILLING_INFORMATION = "GET_BILLING_INFORMATION",
  GET_BILLING_INFORMATION_SUCCESS = "GET_BILLING_INFORMATION_SUCCESS",
  GET_BILLING_INFORMATION_FAILURE = "GET_BILLING_INFORMATION_FAILURE",
  GET_BILLING_HISTORY = "GET_BILLING_HISTORY",
  GET_BILLING_HISTORY_SUCCESS = "GET_BILLING_HISTORY_SUCCESS",
  GET_BILLING_HISTORY_FAILURE = "GET_BILLING_HISTORY_FAILURE",
  GET_CURRENT_USER = "GET_CURRENT_USER",
  GET_CURRENT_USER_FAILURE = "GET_CURRENT_USER_FAILURE",
  GET_CURRENT_USER_SUCCESS = "GET_CURRENT_USER_SUCCESS",
  GET_IS_CURRENT_USER_VALID = "GET_IS_CURRENT_USER_VALID",
  GET_IS_CURRENT_USER_VALID_SUCCESS = "GET_IS_CURRENT_USER_VALID_SUCCESS",
  GET_IS_PROSPECT_FOLLOWED = "GET_IS_PROSPECT_FOLLOWED",
  GET_IS_PROSPECT_FOLLOWED_SUCCESS = "GET_IS_PROSPECT_FOLLOWED_SUCCESS",
  GET_IS_PROSPECT_FOLLOWED_FAILURE = "GET_IS_PROSPECT_FOLLOWED_FAILURE",
  GET_IS_USER_LOAD_COMPLETED = "GET_IS_USER_LOAD_COMPLETED",
  GET_PAYPAL_BILLING_AGREEMENT = "GET_PAYPAL_BILLING_AGREEMENT",
  GET_PAYPAL_BILLING_AGREEMENT_SUCCESS = "GET_PAYPAL_BILLING_AGREEMENT_SUCCESS",
  GET_PAYPAL_BILLING_AGREEMENT_FAILURE = "GET_PAYPAL_BILLING_AGREEMENT_FAILURE",
  GET_SUBSCRIPTIONS = "GET_SUBSCRIPTIONS",
  GET_SUBSCRIPTIONS_SUCCESS = "GET_SUBSCRIPTIONS_SUCCESS",
  GET_SUBSCRIPTIONS_FAILURE = "GET_SUBSCRIPTIONS_FAILURE",
  REQUEST_CONFIRMATION_EMAIL = "REQUEST_CONFIRMATION_EMAIL",
  RESET_PAYPAL = "RESET_PAYPAL",
  RESET_UPDATE_PASSWORD = "RESET_UPDATE_PASSWORD",
  STRICT_SIGN_IN = "STRICT_SIGN_IN",
  STRICT_SIGN_IN_FAILURE = "STRICT_SIGN_IN_FAILURE",
  STRICT_SIGN_IN_SUCCESS = "STRICT_SIGN_IN_SUCCESS",
  UPDATE_BILLING_INFORMATION = "UPDATE_BILLING_INFORMATION",
  UPDATE_BILLING_INFORMATION_FAILURE = "UPDATE_BILLING_INFORMATION_FAILURE",
  UPDATE_BILLING_INFORMATION_SUCCESS = "UPDATE_BILLING_INFORMATION_SUCCESS",
  UPDATE_CURRENT_USER = "UPDATE_CURRENT_USER",
  UPDATE_CURRENT_USER_FAILURE = "UPDATE_CURRENT_USER_FAILURE",
  UPDATE_CURRENT_USER_SUCCESS = "UPDATE_CURRENT_USER_SUCCESS",
  UPDATE_IS_PROSPECT_FOLLOWED = "UPDATE_IS_PROSPECT_FOLLOWED",
  UPDATE_IS_PROSPECT_FOLLOWED_SUCCESS = "UPDATE_IS_PROSPECT_FOLLOWED_SUCCESS",
  UPDATE_IS_PROSPECT_FOLLOWED_FAILURE = "UPDATE_IS_PROSPECT_FOLLOWED_FAILURE",
  UPDATE_IS_USER_LOAD_COMPLETED = "UPDATE_IS_USER_LOAD_COMPLETED",
  UPDATE_PASSWORD = "UPDATE_PASSWORD",
  UPDATE_PASSWORD_FAILURE = "UPDATE_PASSWORD_FAILURE",
  UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS",
  UPDATE_PAYPAL_ADDRESS = "UPDATE_PAYPAL_ADDRESS",
  UPDATE_PAYPAL_AUTHORIZATION_DATA = "UPDATE_PAYPAL_AUTHORIZATION_DATA",
  UPDATE_PAYPAL_BILLING_AGREEMENT = "UPDATE_PAYPAL_BILLING_AGREEMENT",
  UPDATE_PAYPAL_EMAIL = "UPDATE_PAYPAL_EMAIL",
  UPDATE_PAYPAL_ERROR = "UPDATE_PAYPAL_ERROR",
  VALIDATE_BETMGM = "VALIDATE_BETMGM",
  VALIDATE_BETMGM_FAILURE = "VALIDATE_BETMGM_FAILURE",
  VALIDATE_BETMGM_SUCCESS = "VALIDATE_BETMGM_SUCCESS",
  VALIDATE_EMAIL_OR_USERNAME = "VALIDATE_EMAIL_OR_USERNAME",
  VALIDATE_EMAIL_OR_USERNAME_FAILURE = "VALIDATE_EMAIL_OR_USERNAME_FAILURE",
  VALIDATE_EMAIL_OR_USERNAME_SUCCESS = "VALIDATE_EMAIL_OR_USERNAME_SUCCESS"
}

export const forgotPassword = (email: string) =>
  ({
    payload: { email },
    type: UserActions.FORGOT_PASSWORD
  } as const);

export const forgotCredentialsSuccess = (message: string) =>
  ({
    payload: { message },
    type: UserActions.FORGOT_CREDENTIALS_SUCCESS
  } as const);

export const forgotUsername = (email: string) =>
  ({
    payload: { email },
    type: UserActions.FORGOT_USERNAME
  } as const);

export const getAddress = () =>
  ({
    type: UserActions.GET_ADDRESS
  } as const);

export const getAddressSuccess = (address: Address) =>
  ({
    payload: { address },
    type: UserActions.GET_ADDRESS_SUCCESS
  } as const);

export const getAddressFailure = (error?: string) =>
  ({
    error: true,
    payload: { error },
    type: UserActions.GET_ADDRESS_FAILURE
  } as const);

export const getBillingInformation = () =>
  ({
    type: UserActions.GET_BILLING_INFORMATION
  } as const);

export const getBillingInformationSuccess = (
  billingInformation: BillingInformation
) =>
  ({
    payload: { billingInformation },
    type: UserActions.GET_BILLING_INFORMATION_SUCCESS
  } as const);

export const getBillingInformationFailure = (error?: string) =>
  ({
    error: true,
    payload: { error },
    type: UserActions.GET_BILLING_INFORMATION_FAILURE
  } as const);

export const getBillingHistory = () =>
  ({
    type: UserActions.GET_BILLING_HISTORY
  } as const);

export const getBillingHistorySuccess = (billingHistory: Transaction[]) =>
  ({
    payload: { billingHistory },
    type: UserActions.GET_BILLING_HISTORY_SUCCESS
  } as const);

export const getBillingHistoryFailure = (error?: string) =>
  ({
    error: true,
    payload: { error },
    type: UserActions.GET_BILLING_HISTORY_FAILURE
  } as const);

export const getCurrentUser = () =>
  ({
    type: UserActions.GET_CURRENT_USER
  } as const);

export const getCurrentUserFailure = (error?: string) =>
  ({
    error: true,
    payload: { error },
    type: UserActions.GET_CURRENT_USER_FAILURE
  } as const);

export const getCurrentUserSuccess = (user: User) =>
  ({ payload: { user }, type: UserActions.GET_CURRENT_USER_SUCCESS } as const);

export const getIsCurrentUserValid = () =>
  ({
    type: UserActions.GET_IS_CURRENT_USER_VALID
  } as const);

export const getIsCurrentUserValidSuccess = (user: User) =>
  ({
    payload: { user },
    type: UserActions.GET_IS_CURRENT_USER_VALID_SUCCESS
  } as const);

export const getIsUserLoadCompleted = () =>
  ({
    type: UserActions.GET_IS_USER_LOAD_COMPLETED
  } as const);

export const getIsProspectFollowed = (prospectParams: AthletePageParams) =>
  ({
    payload: prospectParams,
    type: UserActions.GET_IS_PROSPECT_FOLLOWED
  } as const);

export const getIsProspectFollowedSuccess = (isFollowing: boolean | null) =>
  ({
    payload: { isFollowing },
    type: UserActions.GET_IS_PROSPECT_FOLLOWED_SUCCESS
  } as const);

export const getIsProspectFollowedFailure = () =>
  ({
    type: UserActions.GET_IS_PROSPECT_FOLLOWED_FAILURE
  } as const);

export const getSubscriptions = () =>
  ({
    type: UserActions.GET_SUBSCRIPTIONS
  } as const);

export const getSubscriptionsSuccess = (subscriptions: Subscription[]) =>
  ({
    payload: subscriptions,
    type: UserActions.GET_SUBSCRIPTIONS_SUCCESS
  } as const);

export const getSubscriptionsFailure = (error?: string) =>
  ({
    payload: { error },
    type: UserActions.GET_SUBSCRIPTIONS_FAILURE
  } as const);

export const requestConfirmationEmail = (email: string) =>
  ({
    payload: { email },
    type: UserActions.REQUEST_CONFIRMATION_EMAIL
  } as const);

export const resetUpdatePassword = () =>
  ({
    type: UserActions.RESET_UPDATE_PASSWORD
  } as const);

export const updateIsProspectFollowed = ({
  id,
  follow
}: UpdateIsProspectFollowedParams) =>
  ({
    payload: { follow, id },
    type: UserActions.UPDATE_IS_PROSPECT_FOLLOWED
  } as const);

export const updateIsProspectFollowedSuccess = (follow: boolean) =>
  ({
    payload: { follow },
    type: UserActions.UPDATE_IS_PROSPECT_FOLLOWED_SUCCESS
  } as const);

export const updateIsProspectFollowedFailure = () =>
  ({
    type: UserActions.UPDATE_IS_PROSPECT_FOLLOWED_FAILURE
  } as const);

export const strictSignIn = (password: string) =>
  ({
    payload: { password },
    type: UserActions.STRICT_SIGN_IN
  } as const);

export const strictSignInFailure = (error?: string) =>
  ({
    payload: { error },
    type: UserActions.STRICT_SIGN_IN_FAILURE
  } as const);

export const strictSignInSuccess = () =>
  ({
    type: UserActions.STRICT_SIGN_IN_SUCCESS
  } as const);

export const updateBillingInformation = (billingParams: BillingParams) =>
  ({
    payload: billingParams,
    type: UserActions.UPDATE_BILLING_INFORMATION
  } as const);

export const updateBillingInformationFailure = (error?: string) =>
  ({
    error: true,
    payload: { error },
    type: UserActions.UPDATE_BILLING_INFORMATION_FAILURE
  } as const);

export const updateBillingInformationSuccess = (
  address: Address,
  billingInformation: BillingInformation
) =>
  ({
    payload: { address, billingInformation },
    type: UserActions.UPDATE_BILLING_INFORMATION_SUCCESS
  } as const);

export const updateCurrentUser = (
  email: string,
  rivalsEmails: boolean,
  thirdPartyEmails: boolean
) =>
  ({
    payload: {
      email,
      rivalsEmails,
      thirdPartyEmails
    },
    type: UserActions.UPDATE_CURRENT_USER
  } as const);

export const updateCurrentUserFailure = (error?: string) =>
  ({
    error: true,
    payload: { error },
    type: UserActions.UPDATE_CURRENT_USER_FAILURE
  } as const);

export const updateCurrentUserSuccess = (user: User) =>
  ({
    payload: { user },
    type: UserActions.UPDATE_CURRENT_USER_SUCCESS
  } as const);

export const updatePassword = (currentPassword: string, newPassword: string) =>
  ({
    payload: { currentPassword, newPassword },
    type: UserActions.UPDATE_PASSWORD
  } as const);

export const updatePasswordFailure = (error?: string) =>
  ({
    payload: { error },
    type: UserActions.UPDATE_PASSWORD_FAILURE
  } as const);

export const updatePasswordSuccess = (response: boolean) =>
  ({
    payload: response,
    type: UserActions.UPDATE_PASSWORD_SUCCESS
  } as const);

export const validateBetMGM = () =>
  ({
    type: UserActions.VALIDATE_BETMGM
  } as const);

export const validateBetMGMFailure = (error?: string) =>
  ({
    payload: { error },
    type: UserActions.VALIDATE_BETMGM_FAILURE
  } as const);

export const validateBetMGMSuccess = (response: ValidateBetMGMResponse) =>
  ({
    payload: response,
    type: UserActions.VALIDATE_BETMGM_SUCCESS
  } as const);

export const getBetMGMUrl = () =>
  ({
    type: UserActions.GET_BETMGM_URL
  } as const);

export const getBetMGMUrlFailure = (error?: string) =>
  ({
    payload: { error },
    type: UserActions.GET_BETMGM_URL_FAILURE
  } as const);

export const getBetMGMUrlSuccess = (response: string) =>
  ({
    payload: response,
    type: UserActions.GET_BETMGM_URL_SUCCESS
  } as const);

export const validateEmailOrUsername = (data: ValidationParams) =>
  ({
    payload: data,
    type: UserActions.VALIDATE_EMAIL_OR_USERNAME
  } as const);

export const validateEmailOrUsernameFailure = ({
  error,
  property
}: ValidationFailureResponse) =>
  ({
    payload: {
      error,
      property
    },
    type: UserActions.VALIDATE_EMAIL_OR_USERNAME_FAILURE
  } as const);

export const validateEmailOrUsernameSuccess = (property: ValidationProperty) =>
  ({
    payload: {
      property: property
    },
    type: UserActions.VALIDATE_EMAIL_OR_USERNAME_SUCCESS
  } as const);

export const getPayPalBillingAgreement = (
  request: PayPalBillingAgreementParams
) =>
  ({
    payload: request,
    type: UserActions.GET_PAYPAL_BILLING_AGREEMENT
  } as const);

export const getPayPalBillingAgreementSuccess = (
  payload: PayPalBillingAgreement
) =>
  ({
    payload,
    type: UserActions.GET_PAYPAL_BILLING_AGREEMENT_SUCCESS
  } as const);

export const getPayPalBillingAgreementFailure = (error?: string) =>
  ({
    payload: { error },
    type: UserActions.GET_PAYPAL_BILLING_AGREEMENT_FAILURE
  } as const);

export const updatePayPalAuthorizationData = (
  payload?: PayPalAuthorizationData
) => ({ payload, type: UserActions.UPDATE_PAYPAL_AUTHORIZATION_DATA } as const);

export const updatePayPalAddress = (payload?: Address) =>
  ({
    payload,
    type: UserActions.UPDATE_PAYPAL_ADDRESS
  } as const);

export const updatePayPalEmail = (payload?: string) =>
  ({
    payload,
    type: UserActions.UPDATE_PAYPAL_EMAIL
  } as const);

export const updatePayPalError = (payload?: string) =>
  ({
    payload,
    type: UserActions.UPDATE_PAYPAL_ERROR
  } as const);

export const updatePayPalBillingAgreement = (
  payload?: PayPalBillingAgreement
) =>
  ({
    payload,
    type: UserActions.UPDATE_PAYPAL_BILLING_AGREEMENT
  } as const);

export const resetPayPal = () =>
  ({
    type: UserActions.RESET_PAYPAL
  } as const);

export type UserAction =
  | ReturnType<typeof forgotCredentialsSuccess>
  | ReturnType<typeof forgotPassword>
  | ReturnType<typeof forgotUsername>
  | ReturnType<typeof getAddress>
  | ReturnType<typeof getAddressFailure>
  | ReturnType<typeof getAddressSuccess>
  | ReturnType<typeof getBetMGMUrl>
  | ReturnType<typeof getBetMGMUrlFailure>
  | ReturnType<typeof getBetMGMUrlSuccess>
  | ReturnType<typeof getBillingInformation>
  | ReturnType<typeof getBillingInformationSuccess>
  | ReturnType<typeof getBillingHistory>
  | ReturnType<typeof getBillingHistoryFailure>
  | ReturnType<typeof getBillingHistorySuccess>
  | ReturnType<typeof getCurrentUser>
  | ReturnType<typeof getCurrentUserFailure>
  | ReturnType<typeof getCurrentUserSuccess>
  | ReturnType<typeof getIsCurrentUserValid>
  | ReturnType<typeof getIsCurrentUserValidSuccess>
  | ReturnType<typeof getIsProspectFollowed>
  | ReturnType<typeof getIsProspectFollowedSuccess>
  | ReturnType<typeof getIsProspectFollowedFailure>
  | ReturnType<typeof getIsUserLoadCompleted>
  | ReturnType<typeof getPayPalBillingAgreement>
  | ReturnType<typeof getPayPalBillingAgreementSuccess>
  | ReturnType<typeof getPayPalBillingAgreementFailure>
  | ReturnType<typeof getSubscriptions>
  | ReturnType<typeof getSubscriptionsSuccess>
  | ReturnType<typeof getSubscriptionsFailure>
  | ReturnType<typeof requestConfirmationEmail>
  | ReturnType<typeof resetPayPal>
  | ReturnType<typeof resetUpdatePassword>
  | ReturnType<typeof strictSignIn>
  | ReturnType<typeof strictSignInFailure>
  | ReturnType<typeof strictSignInSuccess>
  | ReturnType<typeof updateBillingInformation>
  | ReturnType<typeof updateBillingInformationFailure>
  | ReturnType<typeof updateBillingInformationSuccess>
  | ReturnType<typeof updateCurrentUser>
  | ReturnType<typeof updateCurrentUserFailure>
  | ReturnType<typeof updateCurrentUserSuccess>
  | ReturnType<typeof updateIsProspectFollowed>
  | ReturnType<typeof updateIsProspectFollowedSuccess>
  | ReturnType<typeof updateIsProspectFollowedFailure>
  | ReturnType<typeof updatePassword>
  | ReturnType<typeof updatePasswordFailure>
  | ReturnType<typeof updatePasswordSuccess>
  | ReturnType<typeof updatePayPalAuthorizationData>
  | ReturnType<typeof updatePayPalBillingAgreement>
  | ReturnType<typeof updatePayPalAddress>
  | ReturnType<typeof updatePayPalEmail>
  | ReturnType<typeof updatePayPalError>
  | ReturnType<typeof validateBetMGM>
  | ReturnType<typeof validateBetMGMFailure>
  | ReturnType<typeof validateBetMGMSuccess>
  | ReturnType<typeof validateEmailOrUsername>
  | ReturnType<typeof validateEmailOrUsernameFailure>
  | ReturnType<typeof validateEmailOrUsernameSuccess>;
