import {
  PromotionAction,
  PromotionActions
} from "rivals/redux/promotion/actions";
import { Promotion } from "rivals/shared/interfaces/Promotion";

export type State = {
  promoCodeError?: string;
  promotion?: Promotion;
};

const initialState = {
  promoCodeError: undefined,
  promotion: undefined
};

export default function reducer(
  state: State = initialState,
  action: PromotionAction
): State {
  switch (action.type) {
    case PromotionActions.REMOVE_PROMOTION: {
      return {
        promoCodeError: undefined,
        promotion: undefined
      };
    }
    case PromotionActions.GET_PROMOTION_FAILURE: {
      return {
        promoCodeError: action.payload.error,
        promotion: undefined
      };
    }
    case PromotionActions.GET_PROMOTION_SUCCESS: {
      return {
        promoCodeError: undefined,
        promotion: action.payload.promotion
      };
    }
    default: {
      return state;
    }
  }
}
