import classNames from "classnames";
import styles from "rivals/components/app/Layout/Footer/Footer.module.scss";
import Link from "rivals/components/shared/Link";
import type { Props } from "./types";

export default function JurisdictionLinks({
  areArticleLinks = false,
  ccpaFooterConstants
}: Props): React.JSX.Element | null {
  if (!ccpaFooterConstants) {
    return null;
  }

  return (
    <>
      {ccpaFooterConstants.jurisdictionLinks.map((link, idx) => {
        const linkWrapper = classNames(styles.ccpaLinkWrapper, {
          [styles.articleLinks]: areArticleLinks,
          [link.cssClass || ""]: link.cssClass
        });
        if (link.shouldDisplay && link.footerIconUrl) {
          return (
            <Link
              className={`
                ${linkWrapper}
                ${link.cssClass ? ` ${link.cssClass}` : ""}
              `}
              href={link.footerUrl}
              key={idx}
              target="_blank"
            >
              {link.footerText}
              <img alt="" src={link.footerIconUrl}></img>
            </Link>
          );
        } else if (link.footerUrl && link.footerText && link.shouldDisplay) {
          return (
            <Link className={linkWrapper} href={link.footerUrl} key={idx}>
              {link.footerText}
            </Link>
          );
        }
      })}
    </>
  );
}
