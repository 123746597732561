import {
  AdminReportsAction,
  AdminReportsActions
} from "rivals/redux/admin/reports/actions";
import { FetchGoogleAnalyticsReportsResponse } from "rivals/services/googleAnalyticsReports/types";
import { CsAdminReportUser } from "rivals/shared/interfaces/Admin";

export type State = {
  addOnReports?: string[];
  error?: string;
  errorGeneratingAddOnReport?: string;
  errorListingAddOnReports?: string;
  fetchGoogleAnalyticsReportsError?: string;
  fetchGoogleAnalyticsReportsSubmitting?: boolean;
  generateGoogleAnalyticsReportError?: string;
  generateGoogleAnalyticsReportSuccess?: boolean;
  googleAnalyticsReports?: FetchGoogleAnalyticsReportsResponse;
  submitting: boolean;
  submittingAddOnReports?: boolean;
  updateAnalyticsMonitoredSitesError?: string;
  updateAnalyticsMonitoredSitesSubmitting?: boolean;
  updateAnalyticsMonitoredSitesSuccess?: boolean;
  users?: CsAdminReportUser[];
};

const initialState = {
  submitting: false
};

export default function reducer(
  state: State = initialState,
  action: AdminReportsAction
): State {
  switch (action.type) {
    case AdminReportsActions.GET_CS_ADMIN_REPORTS_FAILURE:
    case AdminReportsActions.UPDATE_CS_ADMIN_PERMISSIONS_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        submitting: false
      };
    case AdminReportsActions.UPDATE_CS_ADMIN_PERMISSIONS: {
      return {
        ...state,
        error: undefined,
        submitting: true
      };
    }
    case AdminReportsActions.GET_CS_ADMIN_REPORTS_SUCCESS:
      return {
        ...state,
        users: action.payload.users
      };
    case AdminReportsActions.UPDATE_CS_ADMIN_PERMISSIONS_SUCCESS: {
      return {
        ...state,
        error: undefined,
        submitting: false,
        users: action.payload.users
      };
    }

    case AdminReportsActions.GENERATE_ADD_ON_REPORT: {
      return {
        ...state,
        errorGeneratingAddOnReport: undefined,
        submitting: true
      };
    }
    case AdminReportsActions.GENERATE_ADD_ON_REPORT_FAILURE: {
      return {
        ...state,
        errorGeneratingAddOnReport: action.payload.error,
        submitting: false
      };
    }
    case AdminReportsActions.GENERATE_ADD_ON_REPORT_SUCCESS: {
      return {
        ...state,
        errorGeneratingAddOnReport: undefined,
        submitting: false
      };
    }

    case AdminReportsActions.GENERATE_GOOGLE_ANALYITCS_REPORT: {
      return {
        ...state,
        generateGoogleAnalyticsReportError: undefined,
        generateGoogleAnalyticsReportSuccess: false,
        submitting: true
      };
    }
    case AdminReportsActions.GENERATE_GOOGLE_ANALYITCS_REPORT_FAILURE: {
      return {
        ...state,
        generateGoogleAnalyticsReportError: action.payload.error,
        submitting: false
      };
    }
    case AdminReportsActions.GENERATE_GOOGLE_ANALYITCS_REPORT_SUCCESS: {
      return {
        ...state,
        generateGoogleAnalyticsReportSuccess: true,
        submitting: false
      };
    }

    case AdminReportsActions.GET_ADD_ON_REPORTS_LIST: {
      return {
        ...state,
        errorListingAddOnReports: undefined,
        submittingAddOnReports: true
      };
    }
    case AdminReportsActions.GET_ADD_ON_REPORTS_LIST_FAILURE: {
      return {
        ...state,
        errorListingAddOnReports: action.payload.error,
        submittingAddOnReports: false
      };
    }
    case AdminReportsActions.GET_ADD_ON_REPORTS_LIST_SUCCESS: {
      return {
        ...state,
        addOnReports: action.payload.reportsList,
        errorListingAddOnReports: undefined,
        submittingAddOnReports: false
      };
    }

    case AdminReportsActions.FETCH_GOOGLE_ANALYTICS_REPORTS: {
      return {
        ...state,
        fetchGoogleAnalyticsReportsError: undefined,
        fetchGoogleAnalyticsReportsSubmitting: true
      };
    }
    case AdminReportsActions.FETCH_GOOGLE_ANALYTICS_REPORTS_FAILURE: {
      return {
        ...state,
        fetchGoogleAnalyticsReportsError: action.payload.error,
        fetchGoogleAnalyticsReportsSubmitting: false
      };
    }
    case AdminReportsActions.FETCH_GOOGLE_ANALYTICS_REPORTS_SUCCESS: {
      return {
        ...state,
        fetchGoogleAnalyticsReportsSubmitting: false,
        googleAnalyticsReports: action.payload
      };
    }

    case AdminReportsActions.UPDATE_ANALYTICS_MONITORED_SITES: {
      return {
        ...state,
        updateAnalyticsMonitoredSitesError: undefined,
        updateAnalyticsMonitoredSitesSubmitting: true,
        updateAnalyticsMonitoredSitesSuccess: false
      };
    }
    case AdminReportsActions.UPDATE_ANALYTICS_MONITORED_SITES_FAILURE: {
      return {
        ...state,
        updateAnalyticsMonitoredSitesError: action.payload.error,
        updateAnalyticsMonitoredSitesSubmitting: false
      };
    }
    case AdminReportsActions.UPDATE_ANALYTICS_MONITORED_SITES_SUCCESS: {
      return {
        ...state,
        updateAnalyticsMonitoredSitesSubmitting: false,
        updateAnalyticsMonitoredSitesSuccess: true
      };
    }
    default:
      return state;
  }
}
