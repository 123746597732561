import { FunctionComponent, ReactElement } from "react";
import RivalsLogo from "rivals/components/shared/Icons/RivalsLogo";
import RivalsSquareIcon from "rivals/components/shared/Icons/RivalsSquareIcon";
import Link from "rivals/components/shared/Link";
import { BASE_APP_HOST_URL } from "rivals/services/base";
import { setData } from "rivals/shared/utils/qa";
import styles from "./LogoSection.module.scss";
import { Props } from "./types";

const MAXHEADERLENGTH = 27;
const LogoSection: FunctionComponent<Props> = ({
  isNational = false,
  selectedSite
}): ReactElement => {
  const siteDisplayName =
    !!selectedSite?.headerName &&
    selectedSite.headerName.length <= MAXHEADERLENGTH
      ? selectedSite?.headerName
      : selectedSite?.name;

  const Logo = (): ReactElement => {
    if (isNational || !selectedSite) {
      return (
        <RivalsLogo
          className={styles.responsiveRivalsLogo}
          fill={styles.bone}
        />
      );
    }
    return <span className={styles.headerText}>{siteDisplayName}</span>;
  };

  return (
    <>
      {!isNational && (
        <a
          aria-label="home"
          className={styles.rLogo}
          href={BASE_APP_HOST_URL}
          {...setData("rivals-logo-home-link")}
        >
          <RivalsSquareIcon
            backgroundFill={styles.transparent}
            height="40"
            iconFill={styles.bone}
            width="40"
          />
        </a>
      )}
      <Link
        aria-label={`${siteDisplayName} home`}
        className={styles.rivalsLogoWrapper}
        data="rivals-logo"
        href={selectedSite?.baseUrl || BASE_APP_HOST_URL}
      >
        <Logo />
      </Link>
    </>
  );
};

export default LogoSection;
