import { Providers } from "rivals/components/shared/VideoPlayer/types";
import commonStyles from "rivals/styles/exports.module.scss";
import { shouldAllowPaypalQa } from "./utils/paypal";

// Strings
export const ACCOUNT = "Account";
export const ADD_SUBSCRIPTION_BUTTON_TEXT = "Add Subscription";
export const ADD_SUBSCRIPTIONS = "Add a Subscription";
export const ADMIN = "Admin";
export const ADVANCED_SEARCH = "Advanced Search";
export const ARTICLES = "ARTICLES";
export const BASKETBALL_RECRUITING_SITE_IDENTIFIER = "basketballrecruiting";
export const BILLING = "Billing";
export const CANCEL = "Cancel";
export const CANCEL_SUBSCRIPTION = "Cancel Subscription";
export const CLICK_HERE = "Click here";
export const CLOSE = "Close";
export const CONFIRM = "Confirm";
export const CONTACT_US = "Contact Us";
export const CSURF_TOKEN_COOKIE = "XSRF-TOKEN";
export const CSURF_TOKEN_HEADER = "X-XSRF-TOKEN";
export const CUSTOMER_SUPPORT = "Customer Support";
export const DISMISSED_ENABLE_NOTIFICATION_KEY =
  "enable-notification-dismiss-date";
export const DISMISSED_INSTALL_PWA_KEY = "install-pwa-dismiss-date";
export const EDIT = "Edit";
export const FAN_FUTURECAST_LEADERBOARD = "Fan FutureCast Leaderboard";
export const FREE_ACCOUNT = "Free Account - No Subscriptions";
export const FREE_ACCOUNT_STATUS = "You have access to free message boards";
export const FUMBLE = "Fumble";
export const FUTURECAST = "FutureCast";
export const GIFT_SUBSCRIPTIONS = "Gift Subscriptions";
export const HOME = "Home";
export const INVALID_CURRENT_PASSWORD = "Current password invalid";
export const LOGIN_TEXT = "Log In";
export const LOGOUT_TEXT = "Log Out";
export const NATIONAL_SUBDOMAINS = ["n", "www"];
export const OK = "OK";
export const PAYPAL_SUBSCRIPTION = "PAYPAL_SUBSCRIPTION";
export const PROFILE = "Profile";
export const PROSPECT_SEARCH = "Prospect Search";
export const PROSPECTS = "PROSPECTS";
export const PAY_OUTSTANDING_BALANCE = "Pay Outstanding Balance";
export const QA_DATA_ATTRIBUTE_NAME = "data-philter";
export const RANK = "Rank";
export const REACTIVATE = "Reactivate Susbscription";
export const RESEND_EMAIL = "Resend Confirmation Email";
export const RIVALS_API_ERROR = "RivalsAPIError";
export const ROOT_SITE_IDENTIFIER = "rivals";
export const ROOT_SITE_NAME = "Rivals";
export const SAVE = "Save";
export const SCHOOL = "School";
export const SEE_MORE = "See More";
export const SEE_MORE_ON_RIVALS = " See More on Rivals";
export const SELECT_A_TEAM = "Select a Team";
export const SUBSCRIBE = "Subscribe";
export const SUBSCRIBE_NOW = "Subscribe Now";
export const SUBSCRIPTIONS = "Subscriptions";
export const TRANSFER_SEARCH = "Transfer Search";
export const TRANSFER_PORTAL_SITE_IDENTIFIER = "portal";
export const TOO_MANY_CURRENT_PASSWORD_FAILS =
  "Detected suspicious activity. Logging Out.";
export const UNCONFIRMED_ALERT =
  "Verify your email address to post on forums. Didn't get it?";

// rivals, basketballrecruiting, & portal (site short_name)
export const NATIONAL_SITE_IDENTIFIERS = [
  BASKETBALL_RECRUITING_SITE_IDENTIFIER,
  ROOT_SITE_IDENTIFIER,
  TRANSFER_PORTAL_SITE_IDENTIFIER
];

// Currency Strings
export const CURRENCY = "currency";
export const USD = "USD";

// Datetime format Strings
export const DATE_YYYY_MM_DD = "YYYY-MM-DD";
export const DATE_M_D_YY = "M/D/YY";
export const DATE_M_D_YYYY = "M/D/YYYY";
export const DATE_MM_DD_YYYY = "MM/DD/YYYY";
export const DATE_MMM_D_YYYY = "MMM D, YYYY";
export const DATE_MM_DD_YYYY_HH_MM_SS = "MM/DD/YYYY HH:mm:ss";
export const DATETIME_M_D_YY_H_MM_A = "M/D/YY h:mm a";
export const DATETIME_YYYY_MM_DD_HH_MM_SS = "YYYY-MM-DD HH:mm:ss";
export const DATETIME_MMM_D_YYYY_H_MM_A = "MMM D, YYYY h:mm a";
export const DATETIME_YYYY_MM_DD_HH_MM_SS_Z = "YYYY-MM-DD HH:mm:ss z";

// Navigation Paths
export enum PATHS {
  ADMIN_COACHES = "/admin/schools/coaches",
  ADMIN_COLLEGES = "/admin/schools/colleges",
  ADMIN_EDIT_TP_RANKING_LIST = "/admin/lists/transfer_portal_ranks/[id]/edit",
  ADMIN_EDIT_TP_RANKING_LIST_SHEETS = "/admin/lists/transfer_portal_ranks/sheets/[sport]/[year]",
  ADMIN_HIGHSCHOOLS = "/admin/schools/highschools",
  ADMIN_HOME = "/admin/home",
  ADMIN_LISTS = "/admin/lists",
  ADMIN_NEW_COACH = "/admin/schools/coaches/new",
  ADMIN_USERS = "/admin/users",
  ARTICLE = "/news/[slug]",
  ATHLETE_CARD = "/content/athletes/card/[slug]",
  ATHLETE_PROFILE_URL = "/content/athletes/[slug]",
  ATHLETE_FEATURED_MEDIA_URL = "/content/athletes/[slug]/featured/[mediaType]",
  BETMGM = "/betmgm",
  BILLING = "/users/billing_information",
  COMPREHENSIVE_TEAM_RANKINGS_FORMULA_LINK = "https://n.rivals.com/news/rivals-com-football-comprehensive-team-recruiting-rankings-formula",
  CONFIRM_EMAIL = "/users/confirmation?user[email]=",
  COMMITMENTS = "/commitments/[sport]/[year]",
  CONTACT = "/news/contact-us-5",
  CREATE_ACCOUNT = "/create_account",
  DOWNLOAD_ADD_ONS_REPORT = "admin/reports/add_on_reports/download_report",
  DOWNLOAD_GOOGLE_ANALYTICS_REPORT = "admin/reports/google_analytics/download_report",
  EDIT_ARTICLE = "publishing/contents/[content_id]/edit",
  EDIT_ATHLETE = "/admin/athletes/yahoo_profiles/[athlete_id]",
  EDIT_PROSPECT = "/admin/prospects/[prospect_id]/edit",
  EMBED = "/embed/",
  FOOTER_ABOUT_LINK = "/news/about-us",
  FOOTER_PRIVACY_LINK = "https://policies.yahoo.com/us/en/yahoo/privacy/products/rivals/",
  FOOTER_TOS_LINK = "/tos",
  FOOTER_YAHOO_LINK = "https://sports.yahoo.com/",
  FORGOT_PASSWORD = "/users/password/forgot",
  FORGOT_USERNAME = "/users/username/forgot",
  FUTURECAST = "/futurecast",
  GALLERIES = "/galleries/[id]",
  GIFTING = "/gifts/purchase",
  GIFT_REDEEM = "/redeem_gift",
  GIFTING_SUCCESS = "/gifts/purchase_success",
  GUCE = "https://guce.yahoo.com/manage-selling-my-info?locale=en-US",
  HELP = "/help",
  HOME = "/",
  HOME_WITH_SPORT = "/content/[sport]",
  FAN_FUTURECAST_LEADERBOARD = "/fan_futurecast_leaderboard",
  LANDING_PAGE = "/landing_page",
  MEMBER_SERVICES = "/news/member-services",
  MGM_REDEEM = "/mgm_redeem",
  NEW_SUBSCRIPTION = "/subscriptions/new",
  NOTIFICATIONS = "/users/notifications",
  OBI_IFRAME = "/sign_up_obi_iframe",
  PROSPECT_CARD = "/content/prospect_cards/[prospect_id]",
  PROSPECT_EMBED = "/content/prospects/[prospect_id]/embed",
  PROSPECT_PROFILE_URL = "/content/prospects/[year]/[slug]",
  PROSPECT_RANKINGS = "/prospect_rankings/[list]/[year]",
  RECAPTCHA_V3_URL = "https://www.google.com/recaptcha/api/siteverify",
  RECRUITING_TEAM_RANKINGS_FORMULA_LINK = "https://n.rivals.com/news/rivals-com-football-team-recruiting-rankings-formula",
  REGISTER = "/old_subscribe.user",
  RIVALS_TERMS = "https://n.rivals.com/tos",
  SEARCH = "/search",
  SIGN_IN = "/users/sign_in",
  SIGN_OUT = "/users/sign_out",
  SIGN_UP = "/sign_up",
  STRICT_SIGN_IN = "/users/strict_sign_in",
  SUBSCRIBE = "/subscribe",
  SUBSCRIPTIONS = "/users/subscriptions",
  SUBSCRIPTION_CANCELLATION = "/users/subscriptions/[id]/cancellation",
  SUBSCRIPTION_UPGRADE = "/users/subscriptions/[id]/upgrade",
  TEAMS = "/teams/[sport]/[type]/[group]",
  TEAM_RANKINGS = "/team_rankings/[year]/[conference]/[sport]/[type]",
  TRANSFER_TEAM_RANKINGS_FORMULA_LINK = "https://n.rivals.com/news/rivals-com-football-transfer-portal-team-recruiting-rankings-formula",
  TRANSFER_SEARCH = "/transfer_search/[sport]",
  TRANSFER_TRACKER = "/transfer_tracker/[sport]/[year]",
  TRANSFER_TRACKER_DEFAULT_YEAR = "/transfer_tracker/[sport]",
  USER_PROFILE = "/users/profile",
  X_URL = "http://x.com/",
  YAHOO_PRIVACY = "https://legal.yahoo.com/us/en/yahoo/privacy/products/mediaservices/index.html",
  YAHOO_TERMS = "https://legal.yahoo.com/us/en/yahoo/terms/otos/index.html"
}

export const ACCOUNT_PATHS = [
  PATHS.BILLING,
  PATHS.SUBSCRIPTIONS,
  PATHS.SUBSCRIPTION_CANCELLATION,
  PATHS.SUBSCRIPTION_UPGRADE,
  PATHS.USER_PROFILE
];

const SIGN_IN_PATHS = [
  PATHS.SIGN_IN,
  PATHS.FORGOT_USERNAME,
  PATHS.FORGOT_PASSWORD
];

const REGISTRATION_PATHS = [
  PATHS.CREATE_ACCOUNT,
  PATHS.NEW_SUBSCRIPTION,
  PATHS.SIGN_UP,
  PATHS.SUBSCRIBE
];

/**
 * The custom Link component uses this array to determine which paths to use Next.js
 * routing for instead of creating a standard <a>.  A path added here will bypass NGINX
 * configuration.  Only add paths here once the page is fully deployed to production in React
 * and has been released with no issues for serveral weeks.  This is the last step in the
 * conversion from Legacy code to React.
 */
export const REACT_PATHS = [
  ...SIGN_IN_PATHS,
  ...ACCOUNT_PATHS,
  ...REGISTRATION_PATHS,
  PATHS.BETMGM,
  PATHS.MGM_REDEEM,
  PATHS.HOME,
  PATHS.HOME_WITH_SPORT
];

// Asset Paths

export const NO_PROFILE_PICTURE =
  "/static/icons/icons_prospectprofile_avatar.svg";

export const NO_PROFILE_PICTURE_CIRCLE =
  "/static/icons/icons_personprofile_avatar.svg";

export const NO_PROFILE_PICTURE_CIRCLE_GRAYSCALE =
  "/static/icons/icons_generic_profile_avatar.svg";

// Script Paths
export const DISTRO_SCALE_SCRIPT_PATH = "//c.jsrdn.com/s/cs.js?p=22546";

export const CMP_SCRIPT_ID = "cmpJs";
export const GPC_SCRIPT_ID = "consentJs";
export const GUCE_SCRIPT_ID = "guceJs";

// Cookie Names
export const RIVALS_AUTH_COOKIE = "rivals_authenticated";
export const RIVALS_SESSION_V2_COOKIE = "_rivalry_session_v2";
export const RIVALS_REMEMBER_USER_COOKIE = "remember_user_token_v2;";
export const DEBUG_ADS_COOKIE = "debug-ads";
export const DEBUG_ANALYTICS_GA_COOKIE = "debug-analytics-ga";
export const DASHBOARD_WIDGETS_HIDDEN = "dashboard_widgets";
export const RIVALS_USER_COOKIES = [
  RIVALS_AUTH_COOKIE,
  RIVALS_SESSION_V2_COOKIE,
  RIVALS_REMEMBER_USER_COOKIE
];

export enum PROMOTION_TYPES {
  DISCOUNT = "discount",
  FREE_TRIAL = "free_trial",
  SALE_PROMO = "sale_promo"
}

// Promotion Units
export enum PROMOTION_UNITS {
  AMOUNT = "amount",
  PERCENT = "percent"
}

//  / ATS Jurisdiction Types (Upcase for consistency with API)
export enum ATS_TYPES {
  CCPA = "CCPA",
  GDPR = "GDPR",
  US = "US",
  WORLD = "WORLD"
}

// Subscription Plans
export enum PLANS {
  ALL = "all",
  ANNUAL = "annual",
  COMPED = "comped",
  FREE = "free",
  GIFT = "gift",
  MONTHLY = "monthly",
  PREPAID = "prepaid"
}

// Gifting Plan Length Units
export enum GIFTING_PLAN_LENGTH_UNIT {
  MONTH = "month",
  YEAR = "year"
}

export type PurchasablePlan = PLANS.ANNUAL | PLANS.MONTHLY;

const ARROW_RIGHT_KEY_CODE = 39;
const ARROW_LEFT_KEY_CODE = 37;
export const ENTER_KEY_CODE = 13;

export enum KEY_CODES {
  ARROW_RIGHT = ARROW_RIGHT_KEY_CODE,
  ARROW_LEFT = ARROW_LEFT_KEY_CODE,
  ENTER = ENTER_KEY_CODE
}

// KeyboardEvent.key Values
// https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key/Key_Values
export enum KEYS {
  ENTER = "Enter",
  ARROW_RIGHT = "ArrowRight",
  ARROW_LEFT = "ArrowLeft"
}

// Styles
export enum BREAKPOINT_STRINGS {
  DESKTOP = "desktop",
  MOBILE = "mobile"
}

export enum CARD_TYPE {
  AMEX = "AMERICAN_EXPRESS",
  DISCOVER = "DISCOVER",
  VISA = "VISA",
  MASTER_CARD = "MASTER_CARD"
}

export enum SOCIAL_MEDIA {
  FACEBOOK = "FACEBOOK",
  REDDIT = "REDDIT",
  TWITTER = "TWITTER"
}

export const USER_ROLES = {
  CUSTOMER_SUPPORT_ADMIN: "customer_support_admin",
  CUSTOMER_SUPPORT_ADMIN_MANAGER: "customer_support_admin_manager",
  MARKETING_ADMIN: "marketing_admin",
  REPORTING_ADMIN: "reporting_admin",
  SUPER_ADMIN: "super_admin",
  USER: "user"
} as const;

export enum RIVALS_LIST_SPORTS {
  BASEBALL = "baseball",
  BASKETBALL = "basketball",
  FOOTBALL = "football",
  HOCKEY = "hockey"
}

export enum PRIMARY_SPORTS {
  BASKETBALL = "basketball",
  FOOTBALL = "football"
}

export enum TEAM_RANKING_TYPES {
  COMPREHENSIVE = "comprehensive",
  RECRUITING = "recruiting",
  TRANSFER = "transfer"
}

export enum RANK_TITLES {
  STATE = "st",
  POSITION = "pos",
  NATIONAL = "natl"
}

export enum ELIGIBILITY_YEAR_LABELS {
  FRESHMAN = "FRESHMAN",
  SOPHOMORE = "SOPHOMORE",
  JUNIOR = "JUNIOR",
  SENIOR = "SENIOR"
}

export enum MEDIA_TYPES {
  GALLERIES = "galleries",
  NEWS = "news",
  PHOTOS = "photos",
  VIDEOS = "videos"
}

/* eslint-disable no-magic-numbers */
export enum ELIGIBILITY_YEAR {
  FRESHMAN = 1,
  SOPHOMORE = 2,
  JUNIOR = 3,
  SENIOR = 4
}
/* eslint-enable no-magic-numbers */

export enum SHAPES {
  CIRCLE = "circle",
  RECTANGLE = "rectangle"
}

export const TRANSFER_PORTAL_EDIT_CUTOFF_DATE = "[year]-08-14";

/* eslint-disable no-magic-numbers */
export const rankGraph = {
  black: commonStyles["card-gradient-dark"],
  blackRGB: [29, 41, 51],
  blue: commonStyles["rivals-blue"],
  blueRGB: [10, 78, 163],
  graphPositionMidpoint: 106,
  green: commonStyles["lima"],
  greenRGB: [104, 194, 12]
};

export enum BREAKPOINTS {
  "screen-sm-mobile-min" = 1,
  "screen-sm-mobile-max" = 359,
  "screen-mobile-min" = 360,
  "screen-mobile-max" = 489,
  "screen-lg-mobile-min" = 490,
  "screen-lg-mobile-max" = 767,
  "screen-tablet-min" = 768,
  "screen-tablet-max" = 1023,
  "screen-sm-desktop-min" = 1024,
  "screen-sm-desktop-max" = 1439,
  "screen-lg-desktop-min" = 1440,
  "screen-lg-desktop-max" = 9999
}

export enum Z_INDICES {
  "header-z-index" = 100000,
  "lightbox-z-index" = 100001,
  "embed-athlete-modal-z-index" = 100002
}
/* eslint-enable-line no-magic-numbers */

export const VIDEO_PROVIDERS: Providers = {
  aol: {
    domain: "http://vidible.tv/o2/search/v/",
    source: "aol"
  },
  brightcove: {
    domain:
      process.env.NEXT_PUBLIC_EV_PLAYER_SCRIPT_PATH ||
      "https://s.yimg.com/rx/ev/prod/evplayer.js",
    source: "brightcove"
  },
  hudl: {
    domain: "https://www.hudl.com/embed/",
    source: "hudl"
  },
  vimeo: {
    domain: "https://player.vimeo.com/video/",
    source: "vimeo"
  },
  /* eslint-disable @typescript-eslint/naming-convention */
  yahoo_sports: {
    domain: "https://sports.yahoo.com/video/",
    source: "yahoo_sports"
  },

  youtube: {
    domain: "https://www.youtube.com/embed/",
    source: "youtube"
  }
  /* eslint-enable @typescript-eslint/naming-convention */
};

export const DECLINED_PAYMENT_ERRORS = [
  "Your card was declined.",
  "An outstanding balance prevented this transaction from processing.",
  "We do not accept this card type.",
  "Address verification failed. Please update and try again.",
  "Your card data was invalid. Please update and try again."
];
export const OBI_ADD_ON_ERROR =
  "Something went wrong when attempting to remove the add-on. Please contact customer care.";

// Misc
export const ADD_ON_PRIORITY_ORDER = [
  "ALL_ACCESS",
  "AD_LITE",
  "SPY",
  "WAR_ROOM",
  "CONFERENCE"
];
export const CENTS_PER_DOLLAR = 100;
export const DAYS_PER_WEEK = 7;
export const DISMISSED_DURATION_DAYS = 45;
// milliseconds to sleep after user is done typing before firing email/username validation request
export const EMAIL_USERNAME_CHANGE_SLEEPTIME = 1000;
export const FIRST_TRANSFER_PORTAL_YEAR = 2023;
export const FORECAST_MODAL_AUTOCLOSE_SECONDS = 5;
export const GENERIC_ERROR_MESSAGE =
  "Something went wrong. Please try again later.";
export const GRAPH_TABLE_WIDTH = 700;
export const NUMBER_OF_HEADLINE_NEWS_ARTICLES = 13;
export const LAPSED_SUB_ALERT_DURATION_SECONDS = 300;
export const LAZY_LOADING_PAGE_SIZE = 250;
export const LAZY_LOADING_MORE_SIZE = 100;
export const LOGIN_TIME_COOKIE = "login_time";
export const INCHES_PER_FOOT = 12;
export const MAX_FORECAST_REASON_LENGTH = 200;
export const MAX_NUMBER_OF_WIDGETS = 4;
export const MAX_NUMBER_OF_MOBILE_WIDGETS = 2;
export const MAX_PROSPECT_INTERESTS = 8;
export const MAX_ATHLETE_STARS = 5;
export const MILLISECONDS_PER_SECOND = 1000;
export const MS_PER_DAY = 86400000;
export const ONE_HUNDRED_PERCENT = 100;
export const PASSWORD_LENGTH = 9;
export const NEWS_TITLE_CHAR_LIMIT = 64;
export const SECONDS_PER_MINUTE = 60;
export const SECONDS_PER_HOUR = 3600;
export const RECAPTCHA_SCORE_THRESHOLD = 0.5;
export const RIVALS_REFERRAL_SOURCE = "rivals_referral_source";
export const RIVALS_REFERRAL_TIME = "rivals_referral_time";
export const RIVALS_TIMEZONE = "America/Chicago";
export const ROTATE_90 = 90;
export const USER_TOKEN_COOKIE = "remember_user_token_v2";
export const RIVALS_MOBILE_CLIENT = "rivals_mobile_client";
export const NO_CACHE_HEADER = "nocache";
export const SEARCH_OVERLAY_LIMITS = {
  ARTICLES: 5,
  PROSPECTS: 5
};
export const PROSPECT_NEWS_PAGE_SIZE = 100;
export const TYPEAHEAD_DEBOUNCE_MILLISECONDS = 1000;
export const TRACKING_LOGS_PAGE_SIZE = 100;
export const TRANSFER_SEARCH_PAGE_SIZE = 100;
export const TRANSFER_SEARCH_MOBILE_ALERT_DURATION = 3000;
export const YAHOO_ID_PATTERN = /^[0-9]{4,7}$/;
export const YAHOO_LEAGUES = ["ncaaf", "ncaab"];
export const YAHOO_URL_ANATOMY = {
  domain: 2,
  league: 3,
  playerId: 5
};
export const playerProfileToggleTabs = {
  collegeTab: {
    key: "college",
    title: "COLLEGE"
  },
  highSchoolTab: {
    key: "highSchool",
    title: "HIGH SCHOOL"
  }
};

export const COACHES_PAGE_SIZE = 10;

export const RANKING_TITLES = {
  HIGHSCHOOL_RANKINGS: "high school rankings",
  RANKINGS: "rankings"
};

const paypalEnvsProxy = (value: string): boolean => {
  if (shouldAllowPaypalQa()) {
    return true;
  }

  return [...PAYPAL_ENVS].includes(value);
};

export const PAYPAL_ENVS = ["production", "sandbox"];
PAYPAL_ENVS.includes = paypalEnvsProxy;

export const PAYPAL_PREPARE_BILLING_AGREEMENT_ACTION = "PREPARE";
export const CARD_TYPES = ["visa", "mastercard", "amex", "discover"] as const;

// Ant Design values
export const ANT_GRID_HALF_WIDTH = 12;
export const ANT_GRID_FULL_WIDTH = 24;

export const MIN_TYPEAHEAD_INPUT_LENGTH = 3;

export const MIN_EVENTS_TO_SHOW_CAREER_HIGHTLIGHTS_TIMELINE = 2;

export const NUMERIC_FRACTIONS = {
  HALF: 1 / 2,
  THIRD: 1 / 3,
  THREE_QUARTERS: 3 / 4
};

export const TRANSFER_SEARCH_MOBILE_PAGINATION_SLIDER_SHOW_DURATION = 4000;
export const NOT_FOUND_INDEX = -1;

export const MOBILE_HEADER_HEIGHT = 65;
export const MOBILE_FOOTER_HEIGHT = 66;

export const SCRIPT_SRC_CMP_OAUTH = "https://consent.cmp.oath.com/cmp.js";

export const META_CONTENT_DIDOMI_CONSENT_FLOW = "dmi";
export const META_NAME_DIDOMI_CONSENT_FLOW = "conf:cjs:consent-flow";

export const META_CONTENT_DIDOMI_LOCALE = "en";
export const META_NAME_DIDOMI_LOCALE = "conf:cjs:locale";

export const META_NAME_GUCE_CONSENT_HOST = "conf:cjs:consent-host";

// TODO: https://griosf.atlassian.net/browse/RVLS-9445
export const GIFTING_ENABLED = false;
