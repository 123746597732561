export default {
  aboutLinkText: "About",
  contactLinkText: "Contact Us",
  footerCopyrightText: "© 2025 Yahoo. All rights reserved.",
  footerText: "Certain Data by Sportradar.",
  giftLinkText: "Gift Subscription",
  helpLinkText: "Help",
  privacyLinkText: "Privacy Policy",
  tosLinkText: "Terms of Service",
  yahooLinkText: "Yahoo Sports"
};
