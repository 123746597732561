import localFont from "next/font/local";

const gtAmerica = localFont({
  src: [
    {
      path: "./GT-America-Standard-Regular.woff2",
      style: "normal",
      weight: "400"
    },
    {
      path: "./GT-America-Standard-Medium.woff2",
      style: "normal",
      weight: "500"
    },
    {
      path: "./GT-America-Standard-Bold.woff2",
      style: "normal",
      weight: "700"
    }
  ],
  variable: "--gt-america"
});

export default gtAmerica;
