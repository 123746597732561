import { createNextState } from "@reduxjs/toolkit";
import deepmerge from "deepmerge";
import { Delta, diff } from "jsondiffpatch";
import isEqual from "lodash/isEqual";
import { HYDRATE } from "next-redux-wrapper";
// TODO: https://griosf.atlassian.net/browse/RVLS-8347
import { AnyAction, combineReducers } from "redux";
import { areAlertsEqual } from "rivals/components/app/Layout/Alerts/utils";
import adminAthletes, {
  State as AdminAthletes
} from "rivals/redux/admin/athlete/reducer";
import adminCoaches, {
  State as AdminCoachesState
} from "rivals/redux/admin/coaches/reducer";
import adminLists, {
  State as AdminListsState
} from "rivals/redux/admin/lists/reducer";
import adminReports, {
  State as AdminReportsState
} from "rivals/redux/admin/reports/reducer";
import adminUsers, {
  State as AdminUsersState
} from "rivals/redux/admin/users/reducer";
import alert, {
  State as AlertState,
  isAlert
} from "rivals/redux/alert/reducer";
import app, { State as AppState } from "rivals/redux/application/reducer";
import athlete, { State as AthleteState } from "rivals/redux/athlete/reducer";
import authentication, {
  State as AuthenticationState
} from "rivals/redux/authentication/reducer";
import author, { State as AuthorState } from "rivals/redux/author/reducer";
import cloudinary, {
  State as CloudinaryState
} from "rivals/redux/cloudinary/reducer";
import colleges, {
  State as CollegesState
} from "rivals/redux/colleges/reducer";
import conference, {
  State as ConferenceState
} from "rivals/redux/conference/reducer";
import content, { State as ContentState } from "rivals/redux/content/reducer";
import futurecast, {
  State as FuturecastState
} from "rivals/redux/futurecast/reducer";
import highschool, {
  State as HighschoolState
} from "rivals/redux/highschool/reducer";
import image, { State as ImageState } from "rivals/redux/image/reducer";
import imageTag, {
  State as ImageTagState
} from "rivals/redux/imageTag/reducer";
import list, { State as ListState } from "rivals/redux/list/reducer";
import position, {
  State as PositionState
} from "rivals/redux/position/reducer";
import promotion, {
  State as PromotionState
} from "rivals/redux/promotion/reducer";
import prospect, {
  State as ProspectState
} from "rivals/redux/prospect/reducer";
import rails, { State as RailsState } from "rivals/redux/rails/reducer";
import registration, {
  State as RegistrationState
} from "rivals/redux/registration/reducer";
import search, { State as SearchState } from "rivals/redux/search/reducer";
import subscriptionAddons, {
  State as SubscriptionAddonsState
} from "rivals/redux/subscriptionAddons/reducer";
import subscriptionCancellation, {
  State as SubscriptionCancellationState
} from "rivals/redux/subscriptionCancellation/reducer";
import teamRankings, {
  State as TeamRankingsState
} from "rivals/redux/teamRankings/reducer";
import teamsNav, {
  State as TeamsNavState
} from "rivals/redux/teamsNav/reducer";
import transfer, {
  State as TransferState
} from "rivals/redux/transfer/reducer";
import user, { State as UserState } from "rivals/redux/user/reducer";
import { arrayHasElement } from "rivals/shared/utils/comparisons";

export enum StateKeys {
  ADMIN_ATHLETES = "adminAthletes",
  ADMIN_COACHES = "adminCoaches",
  ADMIN_LISTS = "adminLists",
  ADMIN_REPORTS = "adminReports",
  ADMIN_USERS = "adminUsers",
  ALERT = "alert",
  APP = "app",
  ATHLETE = "athlete",
  AUTH = "auth",
  AUTHOR = "author",
  CLOUDINARY = "cloudinary",
  COLLEGES = "colleges",
  CONFERENCE = "conference",
  CONTENT = "content",
  FUTURECAST = "futurecast",
  HIGHSCHOOL = "highschool",
  IMAGE = "image",
  IMAGE_TAG = "imageTag",
  LIST = "list",
  POSITION = "position",
  PROMOTION = "promotion",
  PROSPECT = "prospect",
  PROSPECT_RANKINGS_LIST = "prospectRankingsList",
  RAILS = "rails",
  REGISTRATION = "registration",
  SEARCH = "search",
  SUBSCRIPTION_ADD_ONS = "subscriptionAddons",
  SUBSCRIPTION_CANCELLATION = "subscriptionCancellation",
  TEAM_RANKINGS = "teamRankings",
  TEAMS_NAV = "teamsNav",
  TRANSFER = "transfer",
  USER = "user"
}

/**
 * Defines all keys in redux state
 */
export interface State {
  [StateKeys.ADMIN_ATHLETES]: AdminAthletes;
  [StateKeys.ADMIN_LISTS]: AdminListsState;
  [StateKeys.ADMIN_REPORTS]: AdminReportsState;
  [StateKeys.ADMIN_USERS]: AdminUsersState;
  [StateKeys.ADMIN_COACHES]: AdminCoachesState;
  [StateKeys.ALERT]: AlertState;
  [StateKeys.APP]: AppState;
  [StateKeys.ATHLETE]: AthleteState;
  [StateKeys.AUTH]: AuthenticationState;
  [StateKeys.AUTHOR]: AuthorState;
  [StateKeys.CLOUDINARY]: CloudinaryState;
  [StateKeys.COLLEGES]: CollegesState;
  [StateKeys.CONFERENCE]: ConferenceState;
  [StateKeys.CONTENT]: ContentState;
  [StateKeys.FUTURECAST]: FuturecastState;
  [StateKeys.HIGHSCHOOL]: HighschoolState;
  [StateKeys.IMAGE]: ImageState;
  [StateKeys.IMAGE_TAG]: ImageTagState;
  [StateKeys.LIST]: ListState;
  [StateKeys.POSITION]: PositionState;
  [StateKeys.PROMOTION]: PromotionState;
  [StateKeys.PROSPECT]: ProspectState;
  [StateKeys.RAILS]: RailsState;
  [StateKeys.REGISTRATION]: RegistrationState;
  [StateKeys.SEARCH]: SearchState;
  [StateKeys.SUBSCRIPTION_ADD_ONS]: SubscriptionAddonsState;
  [StateKeys.SUBSCRIPTION_CANCELLATION]: SubscriptionCancellationState;
  [StateKeys.TEAM_RANKINGS]: TeamRankingsState;
  [StateKeys.TEAMS_NAV]: TeamsNavState;
  [StateKeys.TRANSFER]: TransferState;
  [StateKeys.USER]: UserState;
}

/**
 * @returns combined reducer for use in store configuration
 */
const createReducer = combineReducers({
  [StateKeys.ADMIN_ATHLETES]: adminAthletes,
  [StateKeys.ADMIN_COACHES]: adminCoaches,
  [StateKeys.ADMIN_LISTS]: adminLists,
  [StateKeys.ADMIN_REPORTS]: adminReports,
  [StateKeys.ADMIN_USERS]: adminUsers,
  [StateKeys.ALERT]: alert,
  [StateKeys.APP]: app,
  [StateKeys.ATHLETE]: athlete,
  [StateKeys.AUTH]: authentication,
  [StateKeys.AUTHOR]: author,
  [StateKeys.CLOUDINARY]: cloudinary,
  [StateKeys.COLLEGES]: colleges,
  [StateKeys.CONTENT]: content,
  [StateKeys.CONFERENCE]: conference,
  [StateKeys.FUTURECAST]: futurecast,
  [StateKeys.HIGHSCHOOL]: highschool,
  [StateKeys.IMAGE]: image,
  [StateKeys.IMAGE_TAG]: imageTag,
  [StateKeys.LIST]: list,
  [StateKeys.POSITION]: position,
  [StateKeys.PROMOTION]: promotion,
  [StateKeys.PROSPECT]: prospect,
  [StateKeys.REGISTRATION]: registration,
  [StateKeys.RAILS]: rails,
  [StateKeys.SEARCH]: search,
  [StateKeys.SUBSCRIPTION_ADD_ONS]: subscriptionAddons,
  [StateKeys.SUBSCRIPTION_CANCELLATION]: subscriptionCancellation,
  [StateKeys.TEAM_RANKINGS]: teamRankings,
  [StateKeys.TEAMS_NAV]: teamsNav,
  [StateKeys.TRANSFER]: transfer,
  [StateKeys.USER]: user
});

const getInitialState = (): State => {
  const prevState = undefined;
  const initAction = {
    payload: undefined,
    type: "__RIVALS_APP_INIT_ACTION__"
  };
  // returned value is each slice initialState,
  // all grouped in one object
  return createReducer(prevState, initAction);
};

/* eslint-disable  @typescript-eslint/no-explicit-any */
const mergeArrays = (destination: any[], source: any[]): any[] => {
  const mergeResult = [...destination, ...source];
  // removes duplicate elements
  return mergeResult.reduce((acc, item) => {
    if (isAlert(item)) {
      const hasSameAlert = arrayHasElement(acc, item, areAlertsEqual);
      return hasSameAlert ? acc : [...acc, item];
    }
    return !arrayHasElement(acc, item) ? [...acc, item] : acc;
  }, []);
};
/* eslint-enable  @typescript-eslint/no-explicit-any */

const mergePrevStateWithHydrateChanges = (
  action: AnyAction,
  deltaWithInitalState: Delta
) => (draftState: State): State => {
  // recipe to perform mutations on state, see `mergeArrays`
  // for arrays merging strategy

  // `result` is an object of only the changes carried by HYDRATE
  const result = Object.keys(deltaWithInitalState).reduce(
    (acc, deltaKey) =>
      action.payload[deltaKey]
        ? { ...acc, [deltaKey]: action.payload[deltaKey] }
        : acc,
    {}
  );

  // reference: https://github.com/TehShrike/deepmerge
  return deepmerge(draftState, result, { arrayMerge: mergeArrays });
};

const rootReducer = (state: State | undefined, action: AnyAction): State => {
  if (action.type === HYDRATE) {
    // reference: https://github.com/benjamine/jsondiffpatch
    // used to detect what changes getXXXProps made on
    // the state in a previous phase of the app
    const deltaWithInitalState = diff(getInitialState(), action.payload);
    const sameAsPreviousState = isEqual(state, action.payload);

    if (state && deltaWithInitalState && !sameAsPreviousState) {
      // RTK `createNextState` uses immer to perform all the mutations
      // without touching the base state, the returned value will reflect
      // all changes made to draft state, see `mergePrevStateWithHydrateChanges`
      return createNextState(
        state,
        mergePrevStateWithHydrateChanges(action, deltaWithInitalState)
      );
    }

    return {
      ...state,
      ...action.payload
    };
  }
  return createReducer(state, action);
};

export default rootReducer;
