"use client";

import { useSelector } from "react-redux";
import { MAX_NUMBER_ALERTS } from "rivals/components/app/Layout/Alerts";
import type { Props } from "rivals/components/app/Layout/Alerts/types";
import { getAlert } from "rivals/redux/alert/selectors";
import Alert from "./Alert";

const Alerts = ({ site }: Props): React.JSX.Element => {
  const alerts = useSelector(getAlert);

  return (
    <>
      {alerts
        .slice(0, MAX_NUMBER_ALERTS)
        .reverse()
        .map(alert => (
          <Alert alert={alert} key={alert.id} site={site} />
        ))}
    </>
  );
};

export default Alerts;
