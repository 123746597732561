import {
  all,
  call,
  getContext,
  put,
  takeEvery,
  takeLatest,
  throttle
} from "redux-saga/effects";
import { NavOptions } from "rivals/components/app/Layout/Header/types";
import API from "rivals/services";
import { Response as CCPAFooterResponse } from "rivals/services/getCCPAFooter/types";
import { Response as RailsConstantsResponse } from "rivals/services/getRailsConstants/types";
import { Response as GetTaxResponse } from "rivals/services/getTax/types";
import { Site } from "rivals/shared/interfaces/Site";
import {
  getCCPAFooterFailure,
  getCCPAFooterSuccess,
  getNavItems as getNavItemsAction,
  getNavItemsFailure,
  getNavItemsSuccess,
  getRailsConstants as getRailsConstantsAction,
  getRailsConstantsFailure,
  getRailsConstantsSuccess,
  getSite as getSiteAction,
  getSiteFailure,
  getSites as getSitesAction,
  getSitesFailure,
  getSitesSuccess,
  getSiteSuccess,
  getTax as getTaxAction,
  getTaxFailure,
  getTaxSuccess,
  RailsActions,
  validateAddressThroughTaxEstimation as validateAddressThroughTaxEstimationAction,
  validateAddressThroughTaxEstimationFailure,
  validateAddressThroughTaxEstimationSuccess
} from "./actions";
import { INVALID_ADDRESS } from "./constants";

const GET_TAX_THROTTLE_MILLISECONDS = 2000;

// TODO: https://griosf.atlassian.net/browse/RVLS-2792
export function* getCCPAFooter(): unknown {
  try {
    const api: API = yield getContext("api");
    const response: CCPAFooterResponse = yield call(api.getCCPAFooter);
    yield put(getCCPAFooterSuccess(response));
  } catch (error) {
    yield put(getCCPAFooterFailure((<Error>error)?.message));
  }
}

// TODO: https://griosf.atlassian.net/browse/RVLS-2792
export function* getNavItems(
  action: ReturnType<typeof getNavItemsAction>
): unknown {
  try {
    const api: API = yield getContext("api");
    const response: NavOptions = yield call(
      api.getNavItems,
      action.payload.siteName
    );

    yield put(getNavItemsSuccess(response));
  } catch (error) {
    yield put(getNavItemsFailure((<Error>error)?.message));
  }
}

// TODO: https://griosf.atlassian.net/browse/RVLS-2792
export function* getRailsConstants(
  action: ReturnType<typeof getRailsConstantsAction>
): unknown {
  try {
    const api: API = yield getContext("api");
    const response: RailsConstantsResponse = yield call(
      api.getRailsConstants,
      action.payload
    );
    yield put(getRailsConstantsSuccess(response));
  } catch (error) {
    yield put(getRailsConstantsFailure((<Error>error)?.message));
  }
}

// TODO: https://griosf.atlassian.net/browse/RVLS-2792
export function* getSites(action: ReturnType<typeof getSitesAction>): unknown {
  try {
    const api: API = yield getContext("api");
    const response: Site[] = yield call(api.getSites, action.payload);
    yield put(getSitesSuccess(response));
  } catch (error) {
    yield put(getSitesFailure((<Error>error)?.message));
  }
}

export function* getSite(action: ReturnType<typeof getSiteAction>): unknown {
  try {
    const api: API = yield getContext("api");
    const response = yield call(api.getSite, action.payload);
    yield put(getSiteSuccess(response));
  } catch (error) {
    yield put(getSiteFailure((<Error>error)?.message));
  }
}

// TODO: https://griosf.atlassian.net/browse/RVLS-2792
export function* getTax(action: ReturnType<typeof getTaxAction>): unknown {
  try {
    const api: API = yield getContext("api");
    const request = {
      address: action.payload.address,
      plan: action.payload.plan,
      promoCode: action.payload.promoCode,
      salePrice: action.payload.salePrice,
      siteId: action.payload.siteId
    };

    const response: GetTaxResponse = yield call(api.getTax, request);
    yield put(getTaxSuccess(response.estimatedTaxAmount));
  } catch (error) {
    yield put(getTaxFailure((<Error>error)?.message));
  }
}

export function* validateAddressThroughTaxEstimation(
  action: ReturnType<typeof validateAddressThroughTaxEstimationAction>
): unknown {
  try {
    const api: API = yield getContext("api");
    yield call(api.getTax, action.payload);
    // trigger success action if no errors returned
    yield put(validateAddressThroughTaxEstimationSuccess());
  } catch (error) {
    const errorMessage = (<Error>error)?.message;
    if (errorMessage?.toLowerCase() === INVALID_ADDRESS) {
      yield put(validateAddressThroughTaxEstimationFailure(errorMessage));
    }
  }
}

export default function* saga(): Generator {
  yield all([
    takeEvery(RailsActions.GET_CCPA_FOOTER, getCCPAFooter),
    takeEvery(RailsActions.GET_NAV_ITEMS, getNavItems),
    takeEvery(RailsActions.GET_RAILS_CONSTANTS, getRailsConstants),
    takeLatest(RailsActions.GET_SITES, getSites),
    takeLatest(RailsActions.GET_SITE, getSite),
    takeLatest(
      RailsActions.VALIDATE_ADDRESS_THROUGH_TAX_ESTIMATION,
      validateAddressThroughTaxEstimation
    ),
    throttle(GET_TAX_THROTTLE_MILLISECONDS, RailsActions.GET_TAX, getTax)
  ]);
}
