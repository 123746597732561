import { createSlice } from "@reduxjs/toolkit";
import { Response as CoachesData } from "rivals/services/getCoaches/types";
import { getCoaches } from "./actions";

export type State = {
  coachesData?: CoachesData;
  error?: string;
  submitting: boolean;
};

const initialState: State = {
  coachesData: undefined,
  error: undefined,
  submitting: false
};

const adminCoachesSlice = createSlice({
  extraReducers: builder => {
    builder.addCase(getCoaches.pending, state => {
      state.submitting = true;
    });

    builder.addCase(getCoaches.fulfilled, (state, action) => {
      state.coachesData = action.payload;
      state.submitting = false;
    });

    builder.addCase(getCoaches.rejected, (state, action) => {
      state.error = (action.payload as string) || action.error.message;
      state.submitting = false;
    });
  },
  initialState,
  name: "adminCoaches",
  reducers: {
    cleanCoaches: state => {
      state.coachesData = undefined;
    }
  }
});

export const { cleanCoaches } = adminCoachesSlice.actions;

export default adminCoachesSlice.reducer;
