import {
  Request as FetchListDataRequest,
  Response as FetchListDataResponse
} from "rivals/services/fetchListData/types";
import {
  Request as GetContentListPeopleRequest,
  Response as GetContentListPeopleResponse
} from "rivals/services/getContentListPeople/types";

export enum ListActions {
  FETCH_LIST_DATA = "FETCH_LIST_DATA",
  FETCH_LIST_DATA_FAILURE = "FETCH_LIST_DATA_FAILURE",
  FETCH_LIST_DATA_SUCCESS = "FETCH_LIST_DATA_SUCCESS",
  GET_CONTENT_LIST_PEOPLE = "GET_CONTENT_LIST_PEOPLE",
  GET_CONTENT_LIST_PEOPLE_FAILURE = "GET_CONTENT_LIST_PEOPLE_FAILURE",
  GET_CONTENT_LIST_PEOPLE_SUCCESS = "GET_CONTENT_LIST_PEOPLE_SUCCESS"
}

export const fetchListData = (params: FetchListDataRequest) =>
  ({
    payload: params,
    type: ListActions.FETCH_LIST_DATA
  } as const);

export const fetchListDataFailure = (error?: string) =>
  ({
    payload: { error },
    type: ListActions.FETCH_LIST_DATA_FAILURE
  } as const);

export const fetchListDataSuccess = (response: FetchListDataResponse) =>
  ({
    payload: response,
    type: ListActions.FETCH_LIST_DATA_SUCCESS
  } as const);

export const getContentListPeople = (
  params: GetContentListPeopleRequest,
  resetting: boolean
) =>
  ({
    payload: { params, resetting },
    type: ListActions.GET_CONTENT_LIST_PEOPLE
  } as const);

export const getContentListPeopleFailure = (error?: string) =>
  ({
    payload: { error },
    type: ListActions.GET_CONTENT_LIST_PEOPLE_FAILURE
  } as const);

export const getContentListPeopleSuccess = (
  response: GetContentListPeopleResponse,
  resetting: boolean
) =>
  ({
    payload: { resetting, response },
    type: ListActions.GET_CONTENT_LIST_PEOPLE_SUCCESS
  } as const);

export type ListAction =
  | ReturnType<typeof fetchListData>
  | ReturnType<typeof fetchListDataFailure>
  | ReturnType<typeof fetchListDataSuccess>
  | ReturnType<typeof getContentListPeople>
  | ReturnType<typeof getContentListPeopleFailure>
  | ReturnType<typeof getContentListPeopleSuccess>;
