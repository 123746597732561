"use client";

import { useEffect, useState } from "react";
import type { ReturnType } from "./types";

export default function usePWAManager(): ReturnType {
  const [isSupported, setIsSupported] = useState(false);
  const [isPWA, setIsPWA] = useState(false);

  useEffect(() => {
    // If the browser supports service workers and push notifications
    if ("serviceWorker" in navigator && "PushManager" in window) {
      setIsSupported(true);
    }

    // If the display mode is standalone, then it's a PWA
    if (window.matchMedia("(display-mode: standalone)").matches) {
      setIsPWA(true);
    }

    function domContentLoadedListener(): void {
      window
        .matchMedia("(display-mode: standalone)")
        .addEventListener("change", e => {
          setIsPWA(e.matches);
        });
    }

    // Listen for when the browser transitions to a PWA
    window.addEventListener("DOMContentLoaded", domContentLoadedListener);

    return (): void => {
      window.document.removeEventListener(
        "DOMContentLoaded",
        domContentLoadedListener
      );
    };
  }, []);

  return {
    isPWA,
    isSupported
  };
}
