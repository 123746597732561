/**
 *
 * Follow these steps before adding a new custom event:
 * 1) First, look for matching enhanced measurement event: https://support.google.com/analytics/answer/9216061?sjid=17896727711002789898-SA
 * If you find a matching event, you should not recreate a new event here. If not proceed to step 2
 *
 * 2) Look for a matching automatically-collected event: https://support.google.com/analytics/answer/9234069?sjid=17896727711002789898-SA
 * If you find a matching event, you should not recreate a new event here. If not proceed to step 3
 *
 * 3) Look for a matching recommended event: https://support.google.com/analytics/answer/9267735?sjid=17896727711002789898-SA
 * If you find a similar event, you should not recreate a new event here.
 * if you cannot find a suitable equivalent event, add your own custom event below.
 */

/**
 * If you're adding a new custom event, you should not forget to create custom definitions for each of its parameters
 * https://support.google.com/analytics/answer/14240153?hl=en
 * But before you do make sure to check the quota information on the custom dimensions/metrics on any Rivals analytics property
 * either on the primary or the secondary google accounts
 *
 * After you check that quota, you can create the custom dimensions by using the rake task on:
 * backend/lib/tasks/create_analytics_custom_definitions.rake
 * This task will create the new custom dimension on all network's sites for both primary and secondary google accounts
 */

import { PLANS } from "rivals/shared/constants";
import type { GAEventPayload } from ".";

// Use this when a link/button is clicked to navigate to somewhere else
const clickNavigation = (
  clickLocation: string,
  clickText: string
): GAEventPayload => ({
  eventName: "click_navigation",
  eventParams: {
    clickLocation,
    clickText
  }
});

// Use this when a form was abandoned without submitting its content
const abandonForm = (formIdentifier: string): GAEventPayload => ({
  eventName: "abandon_form",
  eventParams: {
    formIdentifier
  }
});

const extendMgmSubscription = (): GAEventPayload => ({
  eventName: "extend_bet_mgm_sub"
});

// subscriptionType here can be a gift subscription, a normal subscription or a bet_mgm subscription
const createSubscription = (
  subscriptionType: string,
  subscriptionPlan: PLANS,
  subscriptionSite: string
): GAEventPayload => ({
  eventName: "create_subscription",
  eventParams: {
    subscriptionPlan,
    subscriptionSite,
    subscriptionType
  }
});

// save subscription from cancellation
const saveSubscription = (saveType: string): GAEventPayload => ({
  eventName: "save_subscription",
  eventParams: {
    saveType
  }
});

const cancelSubscription = (
  subscriptionPlan: PLANS,
  cancelReason: string
): GAEventPayload => ({
  eventName: "cancel_subscription",
  eventParams: {
    cancelReason,
    subscriptionPlan
  }
});

const applyPromotion = (promotionCode: string): GAEventPayload => ({
  eventName: "apply_promotion",
  eventParams: {
    promotionCode
  }
});

export const CUSTOM_EVENTS = {
  abandonForm,
  applyPromotion,
  cancelSubscription,
  clickNavigation,
  createSubscription,
  extendMgmSubscription,
  saveSubscription
};
