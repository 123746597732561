"use client";

import Script from "next/script";
import useGetCCPAFooter from "rivals/services/getCCPAFooter/useGetCCPAFooter";
import type { Props } from "./types";

const GoogleAnalytics = ({
  googleAnalyticsMeasurementId
}: Props): JSX.Element | null => {
  const { data: footerConstants } = useGetCCPAFooter();

  if (
    !googleAnalyticsMeasurementId ||
    !footerConstants?.thirdPartyEmbedConsent
  ) {
    return null;
  }

  return (
    <>
      <Script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsMeasurementId}`}
        strategy="afterInteractive"
      />
      <Script strategy="afterInteractive">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${googleAnalyticsMeasurementId}', {
            page_location: window.location.href,
            page_path: window.location.pathname,
            page_title: window.document.title,
            page_running_tech: "react",
            debug_mode: ${process.env.NODE_ENV === "development"}
          });
        `}
      </Script>
    </>
  );
};

export default GoogleAnalytics;
