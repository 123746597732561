export enum NotificationPermissionStatus {
  GRANTED = "granted",
  DENIED = "denied",
  DEFAULT = "default"
}

export interface ReturnType {
  isFeatureEnabled: boolean;
  notificationPermissionStatus: NotificationPermission | null;
  requestNotificationPermission: () => Promise<boolean>;
  token: string | null;
}
