import {
  CheckGiftTokenResponse,
  CreateAccountResponse,
  GetPublisherLetterResponse,
  GiftPurchaseParams,
  RedeemGiftParams,
  RedeemGiftResponse,
  RegistrationParams
} from "rivals/services/registration/types";
import { BillingParams } from "rivals/services/updateBillingInformation/types";
import { AddOns } from "rivals/shared/interfaces/AddOn";
import { TaxAddress } from "rivals/shared/interfaces/User";

export enum RegistrationActions {
  CHECK_GIFT_TOKEN = "CHECK_GIFT_TOKEN",
  CHECK_GIFT_TOKEN_SUCCESS = "CHECK_GIFT_TOKEN_SUCCESS",
  CHECK_GIFT_TOKEN_FAILURE = "CHECK_GIFT_TOKEN_FAILURE",
  CREATE_ACCOUNT = "CREATE_ACCOUNT",
  CREATE_ACCOUNT_FAILURE = "CREATE_ACCOUNT_FAILURE",
  CREATE_ACCOUNT_SUCCESS = "CREATE_ACCOUNT_SUCCESS",
  CREATE_ACCOUNT_PAYMENT_FAILURE = "CREATE_ACCOUNT_PAYMENT_FAILURE",
  GET_GIFT_TAX = "GET_GIFT_TAX",
  GET_GIFT_TAX_FAILURE = "GET_GIFT_TAX_FAILURE",
  GET_GIFT_TAX_SUCCESS = "GET_GIFT_TAX_SUCCESS",
  GET_PUBLISHER_LETTER = "GET_PUBLISHER_LETTER",
  GET_PUBLISHER_LETTER_FAILURE = "GET_PUBLISHER_LETTER_FAILURE",
  GET_PUBLISHER_LETTER_SUCCESS = "GET_PUBLISHER_LETTER_SUCCESS",
  GIFT_SUBSCRIPTION = "GIFT_SUBSCRIPTION",
  GIFT_SUBSCRIPTION_FAILURE = "GIFT_SUBSCRIPTION_FAILURE",
  GIFT_SUBSCRIPTION_SUCCESS = "GIFT_SUBSCRIPTION_SUCCESS",
  REDEEM_GIFT = "REDEEM_GIFT",
  REDEEM_GIFT_SUCCESS = "REDEEM_GIFT_SUCCESS",
  REDEEM_GIFT_FAILURE = "REDEEM_GIFT_FAILURE",
  SET_SELECTED_ADD_ON = "SET_SELECTED_ADD_ON",
  TOGGLE_SUBSCRIPTION_AD_LITE = "TOGGLE_SUBSCRIPTION_AD_LITE"
}

export const checkGiftToken = (token: string, siteName: string) =>
  ({
    payload: { siteName, token },
    type: RegistrationActions.CHECK_GIFT_TOKEN
  } as const);

export const checkGiftTokenSuccess = (response: CheckGiftTokenResponse) =>
  ({
    payload: response,
    type: RegistrationActions.CHECK_GIFT_TOKEN_SUCCESS
  } as const);

export const checkGiftTokenFailure = (error?: string) =>
  ({
    payload: { error },
    type: RegistrationActions.CHECK_GIFT_TOKEN_FAILURE
  } as const);

export const createAccount = (
  registrationParams: RegistrationParams,
  billingParams?: BillingParams
) =>
  ({
    payload: {
      ...billingParams,
      recaptchaToken: registrationParams.recaptchaToken,
      registration: registrationParams
    },
    type: RegistrationActions.CREATE_ACCOUNT
  } as const);

export const createAccountSuccess = (response: CreateAccountResponse) =>
  ({
    payload: response,
    type: RegistrationActions.CREATE_ACCOUNT_SUCCESS
  } as const);

export const createAccountFailure = (
  response?: CreateAccountResponse | string
) =>
  ({
    payload: response,
    type: RegistrationActions.CREATE_ACCOUNT_FAILURE
  } as const);

export const createAccountPaymentFailure = (response: CreateAccountResponse) =>
  ({
    payload: response,
    type: RegistrationActions.CREATE_ACCOUNT_PAYMENT_FAILURE
  } as const);

export const getGiftTax = (
  plan: string,
  siteId: number,
  address: TaxAddress,
  salePrice: number
) =>
  ({
    payload: {
      address,
      plan,
      salePrice,
      siteId
    },
    type: RegistrationActions.GET_GIFT_TAX
  } as const);

export const getGiftTaxFailure = (error?: string) =>
  ({
    error: true,
    payload: { error },
    type: RegistrationActions.GET_GIFT_TAX_FAILURE
  } as const);

export const getGiftTaxSuccess = (
  tax: number,
  totalPrice: number,
  priceBeforeTax: number
) =>
  ({
    payload: { priceBeforeTax, tax, totalPrice },
    type: RegistrationActions.GET_GIFT_TAX_SUCCESS
  } as const);

export const getPublisherLetter = (siteId: number) =>
  ({
    payload: { siteId },
    type: RegistrationActions.GET_PUBLISHER_LETTER
  } as const);

export const getPublisherLetterFailure = () =>
  ({
    payload: null,
    type: RegistrationActions.GET_PUBLISHER_LETTER_FAILURE
  } as const);

export const getPublisherLetterSuccess = (
  response: GetPublisherLetterResponse
) =>
  ({
    payload: response,
    type: RegistrationActions.GET_PUBLISHER_LETTER_SUCCESS
  } as const);

export const giftSubscription = (
  giftPurchaseParams: GiftPurchaseParams,
  billingParams?: BillingParams
) =>
  ({
    payload: {
      ...billingParams,
      gift: giftPurchaseParams
    },
    type: RegistrationActions.GIFT_SUBSCRIPTION
  } as const);

export const giftSubscriptionSuccess = () =>
  ({
    type: RegistrationActions.GIFT_SUBSCRIPTION_SUCCESS
  } as const);

export const giftSubscriptionFailure = () =>
  ({
    type: RegistrationActions.GIFT_SUBSCRIPTION_FAILURE
  } as const);

export const redeemGift = (request: RedeemGiftParams) =>
  ({
    payload: request,
    type: RegistrationActions.REDEEM_GIFT
  } as const);

export const redeemGiftSuccess = (response: RedeemGiftResponse) =>
  ({
    payload: response,
    type: RegistrationActions.REDEEM_GIFT_SUCCESS
  } as const);

export const redeemGiftFailure = (error?: string) =>
  ({
    payload: { error },
    type: RegistrationActions.REDEEM_GIFT_FAILURE
  } as const);

export const setSelectedAddOn = (addOnName: AddOns) =>
  ({
    payload: addOnName,
    type: RegistrationActions.SET_SELECTED_ADD_ON
  } as const);

export const toggleSubscriptionAdLite = () =>
  ({
    type: RegistrationActions.TOGGLE_SUBSCRIPTION_AD_LITE
  } as const);

export type RegistrationAction =
  | ReturnType<typeof checkGiftToken>
  | ReturnType<typeof checkGiftTokenFailure>
  | ReturnType<typeof checkGiftTokenSuccess>
  | ReturnType<typeof createAccount>
  | ReturnType<typeof createAccountFailure>
  | ReturnType<typeof createAccountSuccess>
  | ReturnType<typeof createAccountPaymentFailure>
  | ReturnType<typeof getGiftTax>
  | ReturnType<typeof getGiftTaxFailure>
  | ReturnType<typeof getGiftTaxSuccess>
  | ReturnType<typeof getPublisherLetter>
  | ReturnType<typeof getPublisherLetterSuccess>
  | ReturnType<typeof getPublisherLetterFailure>
  | ReturnType<typeof giftSubscription>
  | ReturnType<typeof giftSubscriptionFailure>
  | ReturnType<typeof giftSubscriptionSuccess>
  | ReturnType<typeof redeemGift>
  | ReturnType<typeof redeemGiftFailure>
  | ReturnType<typeof redeemGiftSuccess>
  | ReturnType<typeof setSelectedAddOn>
  | ReturnType<typeof toggleSubscriptionAdLite>;
