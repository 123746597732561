import { ReactElement } from "react";
import styles from "rivals/components/shared/Icons/Icons.module.scss";

const RightArrow = ({ fill = styles.mud }): ReactElement => (
  <svg
    fill={fill}
    height="280"
    viewBox="0 0 577 280"
    width="577"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>RightArrow</title>
    <path
      clipRule="evenodd"
      d="M0 140L0.000640869 0H373.222L541.222 140L373.223 280.001L0.00062561 280.001L0 140ZM560.897 140L393.061 0.000137329H409.005L577.005 140L409.005 280.001H393.061L560.897 140Z"
      fill={fill}
      fillRule="evenodd"
    />
  </svg>
);

export default RightArrow;
