import { Request as DeleteCollegeTransferRequest } from "rivals/services/deleteCollegeTransfer/types";
import {
  AthleteFeaturedMedia,
  AthletePageParams,
  CollegePlayer,
  CollegePlayerGameData,
  CollegePlayerRankingData,
  CollegeTransfer,
  CreateCollegeTransferParams,
  GetCollegeTransferParams,
  PersonIdParams,
  UpdateCollegeTransferParams
} from "rivals/shared/interfaces/Athlete";
export enum AthleteActions {
  CREATE_COLLEGE_TRANSFER = "CREATE_COLLEGE_TRANSFER",
  CREATE_COLLEGE_TRANSFER_SUCCESS = "CREATE_COLLEGE_TRANSFER_SUCCESS",
  CREATE_COLLEGE_TRANSFER_FAILURE = "CREATE_COLLEGE_TRANSFER_FAILURE",
  DELETE_COLLEGE_TRANSFER = "DELETE_COLLEGE_TRANSFER",
  DELETE_COLLEGE_TRANSFER_SUCCESS = "DELETE_COLLEGE_TRANSFER_SUCCESS",
  DELETE_COLLEGE_TRANSFER_FAILURE = "DELETE_COLLEGE_TRANSFER_FAILURE",
  GET_ALL_COLLEGE_TRANSFERS_BY_COLLEGE_PLAYER_ID = "GET_ALL_COLLEGE_TRANSFERS_BY_COLLEGE_PLAYER_ID",
  GET_ATHLETE_FEATURED_MEDIA = "GET_ATHLETE_FEATURED_MEDIA",
  GET_ATHLETE_FEATURED_MEDIA_FAILURE = "GET_ATHLETE_FEATURED_MEDIA_FAILURE",
  GET_ATHLETE_FEATURED_MEDIA_SUCCESS = "GET_ATHLETE_FEATURED_MEDIA_SUCCESS",
  GET_COLLEGE_PLAYER = "GET_COLLEGE_PLAYER",
  GET_COLLEGE_PLAYER_FAILURE = "GET_COLLEGE_PLAYER/rejected",
  GET_COLLEGE_PLAYER_SUCCESS = "GET_COLLEGE_PLAYER/fulfilled",
  GET_COLLEGE_PLAYER_GAME_DATA = "GET_COLLEGE_PLAYER_GAME_DATA",
  GET_COLLEGE_PLAYER_GAME_DATA_FAILURE = "GET_COLLEGE_PLAYER_GAME_DATA_FAILURE",
  GET_COLLEGE_PLAYER_GAME_DATA_SUCCESS = "GET_COLLEGE_PLAYER_GAME_DATA_SUCCESS",
  GET_COLLEGE_PLAYER_RANKING_DATA = "GET_COLLEGE_PLAYER_RANKING_DATA",
  GET_COLLEGE_PLAYER_RANKING_DATA_FAILURE = "GET_COLLEGE_PLAYER_RANKING_DATA_FAILURE",
  GET_COLLEGE_PLAYER_RANKING_DATA_SUCCESS = "GET_COLLEGE_PLAYER_RANKING_DATA_SUCCESS",
  GET_COLLEGE_TRANSFER = "GET_COLLEGE_TRANSFER",
  GET_COLLEGE_TRANSFER_SUCCESS = "GET_COLLEGE_TRANSFER_SUCCESS",
  GET_COLLEGE_TRANSFER_FAILURE = "GET_COLLEGE_TRANSFER_FAILURE",
  UPDATE_COLLEGE_TRANSFER = "UPDATE_COLLEGE_TRANSFER",
  UPDATE_COLLEGE_TRANSFER_SUCCESS = "UPDATE_COLLEGE_TRANSFER_SUCCESS",
  UPDATE_COLLEGE_TRANSFER_FAILURE = "UPDATE_COLLEGE_TRANSFER_FAILURE"
}

export const createCollegeTransfer = (
  collegeTransferParams: CreateCollegeTransferParams
) =>
  ({
    payload: collegeTransferParams,
    type: AthleteActions.CREATE_COLLEGE_TRANSFER
  } as const);

export const createCollegeTransferSuccess = (
  collegeTransfers: CollegeTransfer[]
) =>
  ({
    payload: collegeTransfers,
    type: AthleteActions.CREATE_COLLEGE_TRANSFER_SUCCESS
  } as const);

export const createCollegeTransferFailure = (error?: string) =>
  ({
    payload: { error },
    type: AthleteActions.CREATE_COLLEGE_TRANSFER_FAILURE
  } as const);

export const getAthleteFeaturedMedia = (personParams: PersonIdParams) =>
  ({
    payload: personParams,
    type: AthleteActions.GET_ATHLETE_FEATURED_MEDIA
  } as const);

export const getAthleteFeaturedMediaFailure = (error?: string) =>
  ({
    error: true,
    payload: { error },
    type: AthleteActions.GET_ATHLETE_FEATURED_MEDIA_FAILURE
  } as const);

export const getAthleteFeaturedMediaSuccess = (
  featuredMedia: AthleteFeaturedMedia
) =>
  ({
    payload: { featuredMedia },
    type: AthleteActions.GET_ATHLETE_FEATURED_MEDIA_SUCCESS
  } as const);

export const getCollegePlayerFailure = (error?: string) =>
  ({
    error: true,
    payload: { error },
    type: AthleteActions.GET_COLLEGE_PLAYER_FAILURE
  } as const);

export const getCollegePlayerSuccess = (collegePlayer: CollegePlayer) =>
  ({
    payload: { collegePlayer },
    type: AthleteActions.GET_COLLEGE_PLAYER_SUCCESS
  } as const);

export const getCollegePlayerGameData = (collegePlayer: AthletePageParams) =>
  ({
    payload: collegePlayer,
    type: AthleteActions.GET_COLLEGE_PLAYER_GAME_DATA
  } as const);

export const getCollegePlayerGameDataFailure = (error?: string) =>
  ({
    error: true,
    payload: { error },
    type: AthleteActions.GET_COLLEGE_PLAYER_GAME_DATA_FAILURE
  } as const);

export const getCollegePlayerGameDataSuccess = (
  collegePlayerGameData: CollegePlayerGameData
) =>
  ({
    payload: { collegePlayerGameData },
    type: AthleteActions.GET_COLLEGE_PLAYER_GAME_DATA_SUCCESS
  } as const);

export const getCollegePlayerRankingData = (collegePlayer: AthletePageParams) =>
  ({
    payload: collegePlayer,
    type: AthleteActions.GET_COLLEGE_PLAYER_RANKING_DATA
  } as const);

export const getCollegePlayerRankingDataFailure = (error?: string) =>
  ({
    error: true,
    payload: { error },
    type: AthleteActions.GET_COLLEGE_PLAYER_RANKING_DATA_FAILURE
  } as const);

export const getCollegePlayerRankingDataSuccess = (
  collegePlayerRankingData: CollegePlayerRankingData
) =>
  ({
    payload: { collegePlayerRankingData },
    type: AthleteActions.GET_COLLEGE_PLAYER_RANKING_DATA_SUCCESS
  } as const);

export const getCollegeTransfers = (
  getCollegeTransferParams: GetCollegeTransferParams
) =>
  ({
    payload: getCollegeTransferParams,
    type: AthleteActions.GET_COLLEGE_TRANSFER
  } as const);

export const getAllCollegeTransfersByCollegePlayerId = (
  getCollegeTransferParams: Omit<GetCollegeTransferParams, "collegeTransferId">
) =>
  ({
    payload: getCollegeTransferParams,
    type: AthleteActions.GET_ALL_COLLEGE_TRANSFERS_BY_COLLEGE_PLAYER_ID
  } as const);

export const getCollegeTransfersSuccess = (
  collegeTransfers: CollegeTransfer[]
) =>
  ({
    payload: collegeTransfers,
    type: AthleteActions.GET_COLLEGE_TRANSFER_SUCCESS
  } as const);

export const getCollegeTransfersFailure = (error?: string) =>
  ({
    error: true,
    payload: { error },
    type: AthleteActions.GET_COLLEGE_TRANSFER_FAILURE
  } as const);

export const updateCollegeTransfer = (
  collegeTransferParam: UpdateCollegeTransferParams
) =>
  ({
    payload: { collegeTransfer: collegeTransferParam },
    type: AthleteActions.UPDATE_COLLEGE_TRANSFER
  } as const);

export const updateCollegeTransferSuccess = (
  collegeTransfer: CollegeTransfer
) =>
  ({
    payload: { collegeTransfer },
    type: AthleteActions.UPDATE_COLLEGE_TRANSFER_SUCCESS
  } as const);

export const updateCollegeTransferFailure = (error?: string) =>
  ({
    payload: { error },
    type: AthleteActions.UPDATE_COLLEGE_TRANSFER_FAILURE
  } as const);

export const deleteCollegeTransfer = (params: DeleteCollegeTransferRequest) =>
  ({
    payload: params,
    type: AthleteActions.DELETE_COLLEGE_TRANSFER
  } as const);

export const deleteCollegeTransferSuccess = (id: number) =>
  ({
    payload: { id },
    type: AthleteActions.DELETE_COLLEGE_TRANSFER_SUCCESS
  } as const);

export const deleteCollegeTransferFailure = (error?: string) =>
  ({
    payload: { error },
    type: AthleteActions.DELETE_COLLEGE_TRANSFER_FAILURE
  } as const);

export type AthleteAction =
  | ReturnType<typeof createCollegeTransfer>
  | ReturnType<typeof createCollegeTransferSuccess>
  | ReturnType<typeof createCollegeTransferFailure>
  | ReturnType<typeof deleteCollegeTransfer>
  | ReturnType<typeof deleteCollegeTransferFailure>
  | ReturnType<typeof deleteCollegeTransferSuccess>
  | ReturnType<typeof getAllCollegeTransfersByCollegePlayerId>
  | ReturnType<typeof getAthleteFeaturedMedia>
  | ReturnType<typeof getAthleteFeaturedMediaFailure>
  | ReturnType<typeof getAthleteFeaturedMediaSuccess>
  | ReturnType<typeof getCollegePlayerFailure>
  | ReturnType<typeof getCollegePlayerSuccess>
  | ReturnType<typeof getCollegePlayerGameData>
  | ReturnType<typeof getCollegePlayerGameDataFailure>
  | ReturnType<typeof getCollegePlayerGameDataSuccess>
  | ReturnType<typeof getCollegePlayerRankingData>
  | ReturnType<typeof getCollegePlayerRankingDataFailure>
  | ReturnType<typeof getCollegePlayerRankingDataSuccess>
  | ReturnType<typeof getCollegeTransfers>
  | ReturnType<typeof getCollegeTransfersSuccess>
  | ReturnType<typeof getCollegeTransfersFailure>
  | ReturnType<typeof updateCollegeTransfer>
  | ReturnType<typeof updateCollegeTransferSuccess>
  | ReturnType<typeof updateCollegeTransferFailure>;
