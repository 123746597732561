import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiService } from "rivals/redux/store";
import {
  GetPositionsRequest,
  GetPositionsResponse
} from "rivals/services/container";

export enum PositionActions {
  GET_POSITIONS = "positions/getPositions"
}

export const getPositions = createAsyncThunk(
  PositionActions.GET_POSITIONS,
  async (request: GetPositionsRequest, { rejectWithValue }) => {
    try {
      const response: GetPositionsResponse = await apiService.getPositions(
        request
      );
      return response;
    } catch (error) {
      return rejectWithValue((<Error>error)?.message);
    }
  }
);
