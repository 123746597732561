import { Response as GetProspectNewsResponse } from "rivals/services/getProspectNews/types";
import {
  CareerEvent as CareerHighlight,
  GraphForecastList,
  InterestSpread,
  Prospect,
  ProspectCollegeInterest,
  ProspectForecastGraphData,
  ProspectForecastTable,
  ProspectTopForecasts,
  RankingGraphData
} from "rivals/shared/interfaces/Prospect";

import { ProspectAction, ProspectActions } from "./actions";

export type State = {
  careerHighlightsData?: CareerHighlight[];
  error?: string;
  forecastGraphData?: ProspectForecastGraphData;
  forecastGraphList?: GraphForecastList;
  forecastTableData?: ProspectForecastTable[];
  interestSpread?: InterestSpread;
  interests?: ProspectCollegeInterest[];
  news?: GetProspectNewsResponse;
  prospect?: Prospect;
  rankingGraphData?: RankingGraphData;
  submitting: boolean;
  topForecasts?: ProspectTopForecasts;
};

const initialState = {
  careerHighlightsData: undefined,
  error: undefined,
  forecastGraphData: undefined,
  forecastGraphList: undefined,
  forecastTableData: undefined,
  interestSpread: undefined,
  interests: undefined,
  prospect: undefined,
  rankingGraphData: undefined,
  submitting: false,
  topForecasts: undefined
};

export default function reducer(
  state: State = initialState,
  action: ProspectAction
): State {
  switch (action.type) {
    case ProspectActions.GET_CAREER_HIGHLIGHTS_FAILURE:
    case ProspectActions.GET_PROSPECT_FAILURE:
    case ProspectActions.GET_PROSPECT_COLLEGE_INTERESTS_FAILURE:
    case ProspectActions.GET_PROSPECT_FORECAST_TABLE_FAILURE:
    case ProspectActions.GET_PROSPECT_RANKING_GRAPH_FAILURE:
    case ProspectActions.GET_PROSPECT_FORECAST_GRAPH_FAILURE:
    case ProspectActions.GET_PROSPECT_FORECAST_GRAPH_LIST_FAILURE:
    case ProspectActions.GET_PROSPECT_NEWS_FAILURE:
    case ProspectActions.GET_PROSPECT_TOP_FORECASTS_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        submitting: false
      };
    case ProspectActions.GET_CAREER_HIGHLIGHTS_SUCCESS:
      return {
        ...state,
        careerHighlightsData: action.payload.events
      };
    case ProspectActions.GET_PROSPECT_SUCCESS:
      return {
        ...state,
        prospect: action.payload.prospect
      };
    case ProspectActions.GET_PROSPECT_COLLEGE_INTERESTS_SUCCESS:
      return {
        ...state,
        interestSpread: action.payload.prospectCollegeInterests.interestSpread,
        interests: action.payload.prospectCollegeInterests.interests
      };
    case ProspectActions.GET_PROSPECT_FORECAST_TABLE_SUCCESS:
      return {
        ...state,
        forecastTableData: action.payload.data
      };
    case ProspectActions.GET_PROSPECT_NEWS:
      return {
        ...state,
        submitting: true
      };
    case ProspectActions.GET_PROSPECT_NEWS_SUCCESS:
      return {
        ...state,
        news: {
          ...action.payload.prospectNews,
          items:
            state.news && state.news.items.length
              ? [...state.news.items, ...action.payload.prospectNews.items]
              : action.payload.prospectNews.items
        },
        submitting: false
      };
    case ProspectActions.GET_PROSPECT_TOP_FORECASTS_SUCCESS:
      return {
        ...state,
        topForecasts: action.payload.topForecasts
      };
    case ProspectActions.GET_PROSPECT_RANKING_GRAPH_SUCCESS:
      return {
        ...state,
        rankingGraphData: action.payload.rankingGraphData
      };
    case ProspectActions.GET_PROSPECT_FORECAST_GRAPH_SUCCESS:
      return {
        ...state,
        forecastGraphData: action.payload.forecastGraphData
      };
    case ProspectActions.GET_PROSPECT_FORECAST_GRAPH_LIST_SUCCESS:
      return {
        ...state,
        forecastGraphList: action.payload.forecastGraphList
      };
    default:
      return state;
  }
}
