import { Transaction } from "rivals/components/pages/User/BillingHistory/types";
import { Response as AdminUpgradingUserResponse } from "rivals/services/getAdminUpgradingUser/types";
import { Request as Params } from "rivals/services/getBillingHistory/types";
import {
  Request as ProspectTrackingHistoryParams,
  Response as ProspectTrackingHistoryResponse
} from "rivals/services/prospectTrackingHistory/types";
import {
  Request as UserForumInfoParams,
  Response as UserForumInfoResponse
} from "rivals/services/userForumInfo/types";
import {
  Request as UserHistoryTrackingHistoryParams,
  Response as UserHistoryTrackingHistoryResponse
} from "rivals/services/userHistoryTrackingHistory/types";
import {
  Request as UserTrackingHistoryParams,
  Response as UserTrackingHistoryResponse
} from "rivals/services/userTrackingHistory/types";

export enum AdminUsersActions {
  GET_ADMIN_UPGRADING_USER = "GET_ADMIN_UPGRADING_USER",
  GET_ADMIN_UPGRADING_USER_FAILURE = "GET_ADMIN_UPGRADING_USER_FAILURE",
  GET_ADMIN_UPGRADING_USER_SUCCESS = "GET_ADMIN_UPGRADING_USER_SUCCESS",
  GET_USER_BILLING_HISTORY = "GET_USER_BILLING_HISTORY",
  GET_USER_BILLING_HISTORY_SUCCESS = "GET_USER_BILLING_HISTORY_SUCCESS",
  GET_USER_BILLING_HISTORY_FAILURE = "GET_USER_BILLING_HISTORY_FAILURE",
  UPGRADE_USER_SUBSCRIPTION = "UPGRADE_USER_SUBSCRIPTION",
  UPGRADE_USER_SUBSCRIPTION_FAILURE = "UPGRADE_USER_SUBSCRIPTION_FAILURE",
  UPGRADE_USER_SUBSCRIPTION_SUCCESS = "UPGRADE_USER_SUBSCRIPTION_SUCCESS",
  GET_USER_TRACKING_HISTORY = "GET_USER_TRACKING_HISTORY",
  GET_USER_TRACKING_HISTORY_SUCCESS = "GET_USER_TRACKING_HISTORY_SUCCESS",
  GET_USER_TRACKING_HISTORY_FAILURE = "GET_USER_TRACKING_HISTORY_FAILURE",
  GET_USER_FORUM_INFO = "GET_USER_FORUM_INFO",
  GET_USER_FORUM_INFO_SUCCESS = "GET_USER_FORUM_INFO_SUCCESS",
  GET_USER_FORUM_INFO_FAILURE = "GET_USER_FORUM_INFO_FAILURE",
  GET_USER_HISTORY_TRACKING_HISTORY = "GET_USER_HISTORY_TRACKING_HISTORY",
  GET_USER_HISTORY_TRACKING_HISTORY_SUCCESS = "GET_USER_HISTORY_TRACKING_HISTORY_SUCCESS",
  GET_USER_HISTORY_TRACKING_HISTORY_FAILURE = "GET_USER_HISTORY_TRACKING_HISTORY_FAILURE",
  GET_PROSPECT_TRACKING_HISTORY = "GET_PROSPECT_TRACKING_HISTORY",
  GET_PROSPECT_TRACKING_HISTORY_SUCCESS = "GET_PROSPECT_TRACKING_HISTORY_SUCCESS",
  GET_PROSPECT_TRACKING_HISTORY_FAILURE = "GET_PROSPECT_TRACKING_HISTORY_FAILURE"
}

export const getAdminUpgradingUser = (subscriptionId: number) =>
  ({
    payload: { subscriptionId },
    type: AdminUsersActions.GET_ADMIN_UPGRADING_USER
  } as const);

export const getAdminUpgradingUserSuccess = (
  response: AdminUpgradingUserResponse
) =>
  ({
    payload: { response },
    type: AdminUsersActions.GET_ADMIN_UPGRADING_USER_SUCCESS
  } as const);

export const getAdminUpgradingUserFailure = (error?: string) =>
  ({
    payload: { error },
    type: AdminUsersActions.GET_ADMIN_UPGRADING_USER_FAILURE
  } as const);

export const upgradeUserSubscription = (
  subscriptionId: string | string[],
  promoCode: string,
  isAutoRenewAgreed: boolean,
  userId: number | string
) =>
  ({
    payload: {
      isAutoRenewAgreed,
      promoCode,
      subscriptionId,
      userId
    },
    type: AdminUsersActions.UPGRADE_USER_SUBSCRIPTION
  } as const);

export const upgradeUserSubscriptionSuccess = () =>
  ({
    type: AdminUsersActions.UPGRADE_USER_SUBSCRIPTION_SUCCESS
  } as const);

export const upgradeUserSubscriptionFailure = (error?: string) =>
  ({
    payload: { error },
    type: AdminUsersActions.UPGRADE_USER_SUBSCRIPTION_FAILURE
  } as const);

export const getUserBillingHistory = (userParams: Params) =>
  ({
    payload: userParams,
    type: AdminUsersActions.GET_USER_BILLING_HISTORY
  } as const);

export const getUserBillingHistoryFailure = (error?: string) =>
  ({
    error: true,
    payload: { error },
    type: AdminUsersActions.GET_USER_BILLING_HISTORY_FAILURE
  } as const);

export const getUserBillingHistorySuccess = (
  userBillingHistory: Transaction[]
) =>
  ({
    payload: { userBillingHistory },
    type: AdminUsersActions.GET_USER_BILLING_HISTORY_SUCCESS
  } as const);

export const getUserTrackingHistory = (params: UserTrackingHistoryParams) =>
  ({
    payload: params,
    type: AdminUsersActions.GET_USER_TRACKING_HISTORY
  } as const);

export const getUserTrackingHistorySuccess = (
  response: UserTrackingHistoryResponse
) =>
  ({
    payload: response,
    type: AdminUsersActions.GET_USER_TRACKING_HISTORY_SUCCESS
  } as const);

export const getUserTrackingHistoryFailure = (error?: string) =>
  ({
    payload: { error },
    type: AdminUsersActions.GET_USER_TRACKING_HISTORY_FAILURE
  } as const);

export const getUserForumInfo = (params: UserForumInfoParams) =>
  ({
    payload: params,
    type: AdminUsersActions.GET_USER_FORUM_INFO
  } as const);

export const getUserForumInfoSuccess = (response: UserForumInfoResponse) =>
  ({
    payload: response,
    type: AdminUsersActions.GET_USER_FORUM_INFO_SUCCESS
  } as const);

export const getUserForumInfoFailure = (error?: string) =>
  ({
    payload: { error },
    type: AdminUsersActions.GET_USER_FORUM_INFO_FAILURE
  } as const);

export const getProspectTrackingHistory = (
  params: ProspectTrackingHistoryParams
) =>
  ({
    payload: params,
    type: AdminUsersActions.GET_PROSPECT_TRACKING_HISTORY
  } as const);

export const getProspectTrackingHistorySuccess = (
  response: ProspectTrackingHistoryResponse
) =>
  ({
    payload: response,
    type: AdminUsersActions.GET_PROSPECT_TRACKING_HISTORY_SUCCESS
  } as const);

export const getProspectTrackingHistoryFailure = (error?: string) =>
  ({
    payload: { error },
    type: AdminUsersActions.GET_PROSPECT_TRACKING_HISTORY_FAILURE
  } as const);

export const getUserHistoryTrackingHistory = (
  params: UserHistoryTrackingHistoryParams
) =>
  ({
    payload: params,
    type: AdminUsersActions.GET_USER_HISTORY_TRACKING_HISTORY
  } as const);

export const getUserHistoryTrackingHistorySuccess = (
  response: UserHistoryTrackingHistoryResponse
) =>
  ({
    payload: response,
    type: AdminUsersActions.GET_USER_HISTORY_TRACKING_HISTORY_SUCCESS
  } as const);

export const getUserHistoryTrackingHistoryFailure = (error?: string) =>
  ({
    payload: { error },
    type: AdminUsersActions.GET_USER_HISTORY_TRACKING_HISTORY_FAILURE
  } as const);

export type AdminUsersAction =
  | ReturnType<typeof getAdminUpgradingUser>
  | ReturnType<typeof getAdminUpgradingUserFailure>
  | ReturnType<typeof getAdminUpgradingUserSuccess>
  | ReturnType<typeof getUserBillingHistory>
  | ReturnType<typeof getUserBillingHistoryFailure>
  | ReturnType<typeof getUserBillingHistorySuccess>
  | ReturnType<typeof getUserForumInfo>
  | ReturnType<typeof getUserForumInfoFailure>
  | ReturnType<typeof getUserForumInfoSuccess>
  | ReturnType<typeof upgradeUserSubscription>
  | ReturnType<typeof upgradeUserSubscriptionFailure>
  | ReturnType<typeof upgradeUserSubscriptionSuccess>
  | ReturnType<typeof getUserTrackingHistory>
  | ReturnType<typeof getUserTrackingHistorySuccess>
  | ReturnType<typeof getUserTrackingHistoryFailure>
  | ReturnType<typeof getUserHistoryTrackingHistory>
  | ReturnType<typeof getUserHistoryTrackingHistorySuccess>
  | ReturnType<typeof getUserHistoryTrackingHistoryFailure>
  | ReturnType<typeof getProspectTrackingHistory>
  | ReturnType<typeof getProspectTrackingHistorySuccess>
  | ReturnType<typeof getProspectTrackingHistoryFailure>;
