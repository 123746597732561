import { createSelector } from "reselect";
import { State as AppState, StateKeys } from "rivals/redux/reducer";
import { State } from "./reducer";

export const getAlertState = (state: AppState): State => state[StateKeys.ALERT];

export const getAlert = createSelector(
  getAlertState,
  alertState => alertState.alerts
);
