import { createSlice } from "@reduxjs/toolkit";
import { Position } from "rivals/shared/interfaces/Position";
import { getPositions } from "./actions";

export type State = {
  error?: string;
  positions: Position[];
  submitting: boolean;
};

export const initialState: State = {
  error: undefined,
  positions: [],
  submitting: false
};

const positionsSlice = createSlice({
  extraReducers: builder => {
    builder.addCase(getPositions.pending, state => {
      state.submitting = true;
    });

    builder.addCase(getPositions.fulfilled, (state, action) => {
      state.positions = action.payload.positions;
      state.submitting = false;
    });

    builder.addCase(getPositions.rejected, (state, action) => {
      state.error = (action.payload as string) || action.error.message;
      state.submitting = false;
    });
  },
  initialState,
  name: "positions",
  reducers: {}
});

export default positionsSlice.reducer;
