import Modal from "antd/lib/modal/Modal";
import classNames from "classnames";
import { FunctionComponent, ReactElement } from "react";
import Button from "rivals/components/shared/Button";
import CustomButton from "rivals/components/shared/CustomButton";
import { CANCEL, OK } from "rivals/shared/constants";
import styles from "./index.module.scss";
import { Props } from "./types";

// NOTE: when buttonText is blank the button will disappear
const ModalWrapper: FunctionComponent<Props> = ({
  bodyStyles = {},
  buttonSize = "medium",
  cancelButtonText = CANCEL,
  children,
  confirmButtonText = OK,
  disabled = false,
  handleCancel,
  handleConfirm,
  hasCloseIcon = false,
  maskClosable = true,
  philter = "",
  rightAlignFooter = false,
  showFooter = true,
  title: titleText,
  titleClassName,
  open,
  width,
  wrapClassName = "",
  zIndex
}): ReactElement => {
  const title = (
    <div
      className={classNames(styles.title, titleClassName, {
        [styles.hasCloseIcon]: hasCloseIcon
      })}
    >
      <div>{titleText}</div>
      {hasCloseIcon && (
        <CustomButton
          ariaLabel="hide"
          className={styles.closeIconWrapper}
          icon={<span className={styles.closeIcon} />}
          onClick={handleCancel}
          philter={`${philter}-close`}
        />
      )}
    </div>
  );

  const footer = showFooter ? (
    <div
      className={classNames(styles.footer, {
        [styles.rightAlign]: rightAlignFooter
      })}
    >
      {!!cancelButtonText && (
        <Button
          backgroundColor="transparent"
          borderColor="transparent"
          buttonText={cancelButtonText}
          className={styles.leftButton}
          data={`${philter}-cancel`}
          inverted={true}
          key="cancel"
          onClick={handleCancel}
          size={buttonSize}
        />
      )}
      {!!confirmButtonText && (
        <Button
          buttonText={confirmButtonText}
          data={`${philter}-confirm`}
          disabled={disabled}
          inverted
          key="confirm"
          onClick={() => {
            if (handleConfirm) {
              handleConfirm();
            }
          }}
          size={buttonSize}
        />
      )}
    </div>
  ) : null;

  return (
    <Modal
      closable={false}
      destroyOnClose
      footer={footer}
      maskClosable={maskClosable}
      onCancel={handleCancel}
      open={open}
      styles={{ body: bodyStyles }}
      title={title}
      width={width ? width : styles.antdModalWidth}
      wrapClassName={wrapClassName}
      zIndex={zIndex}
    >
      <div className={styles.body}>{children}</div>
    </Modal>
  );
};

export default ModalWrapper;
