import CheckCircle from "rivals/components/shared/Icons/CheckCircle";
import Warning from "rivals/components/shared/Icons/Warning";
import { AlertTypes } from "rivals/redux/alert/reducer";
import styles from "./AlertPrefix.module.scss";
import type { Props } from "./types";

const AlertPrefix = ({ type }: Props): React.JSX.Element | null => {
  switch (type) {
    case AlertTypes.INFO:
      return (
        <Warning
          className={styles.warning}
          fill={styles.abyss90}
          fillBg={styles.star}
          stroke={styles.star70}
          title="Warning"
        />
      );
    case AlertTypes.SEVERE:
      return (
        <Warning
          className={styles.warning}
          fill={styles.bone}
          fillBg={styles.fail}
          stroke={styles.crimson}
          title="Error"
        />
      );
    case AlertTypes.SUCCESS:
      return (
        <CheckCircle
          className={styles.check}
          fill={styles.white}
          fillBg={styles.pass}
          title="Success"
        />
      );
    default:
      return null;
  }
};

export default AlertPrefix;
