import { useEffect } from "react";

export type UseMetaTagOptions = {
  content?: string;
  name?: string;
  shouldAppend?: boolean;
};

/*
 * useMetaTag React hook:
 * Tries to append meta tag to end of head. Will not append if a meta tag is found with matching name.
 * Uses reference counting to conditionally remove script when unmounting. If after unmounting, no
 * other useMetaTag hooks are mounted with matching name, the meta tag will be removed from the DOM.
 */
export const useMetaTag = ({
  content = "",
  name = "",
  shouldAppend = true
}: UseMetaTagOptions): void => {
  const findMetaTag = (): HTMLMetaElement | null => {
    return window.document.querySelector(
      `meta[name='${name}']`
    ) as HTMLMetaElement | null;
  };

  // Will not append meta tag if already present.
  // Will increment refCount if already present.
  const appendMetaTag = (): void => {
    let metaTag = findMetaTag();

    if (!metaTag) {
      metaTag = window.document.createElement("meta");
      metaTag.content = content;
      metaTag.name = name;
      metaTag = window.document.head.appendChild(metaTag);
    }
    updateMetaTagRefCount(metaTag, 1);
  };

  const removeMetaTag = (): void => {
    const metaTag = findMetaTag();
    if (metaTag) {
      // eslint-disable-next-line no-magic-numbers
      const refCount = updateMetaTagRefCount(metaTag, -1);
      if (refCount === 0) {
        metaTag.remove();
      }
    }
  };

  const updateMetaTagRefCount = (
    script: HTMLMetaElement,
    increment: number
  ): number => {
    const currentRefCount = script.getAttribute("refCount");
    const newRefCount = parseInt(currentRefCount || "0") + increment;
    script.setAttribute("refCount", newRefCount.toString());
    return newRefCount;
  };

  useEffect(() => {
    if (shouldAppend) {
      appendMetaTag();
    } else {
      removeMetaTag();
    }

    return removeMetaTag;
  }, [shouldAppend]);
};
