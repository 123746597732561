import { QA_DATA_ATTRIBUTE_NAME } from "rivals/shared/constants";

export function setData(
  value: string,
  suffix = ""
): { [QA_DATA_ATTRIBUTE_NAME]: string } | undefined {
  if (!value) {
    return;
  }
  return { [QA_DATA_ATTRIBUTE_NAME]: `${value}${suffix ? `-${suffix}` : ""}` };
}
