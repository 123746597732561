import { ReactElement } from "react";
import styles from "rivals/components/shared/Icons/Icons.module.scss";
import { setData } from "rivals/shared/utils/qa";

const RivalsLogomarkBlock = ({
  fill = styles.hoops,
  height = "98",
  philter = "",
  width = "126"
}): ReactElement => {
  return (
    <svg
      fill="none"
      height={height}
      viewBox="0 0 126 98"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
      {...setData(philter, "rivals-logomark-block-svg")}
    >
      <rect fill={fill} height="98" width="126" />
      <title>Rivals Logomark Block</title>
      <g clipPath="url(#clip0_9997_33236)">
        <path
          d="M38.546 78.6926H19.25V19.3075H92.8715L104.523 28.956V46.0294L92.8715 55.6779H78.8457L106.75 78.6891H82.6291L38.546 42.3187V78.6926ZM38.546 34.1538V42.3187H85.2274V34.1538H38.546Z"
          fill="#FCFCFC"
        />
      </g>
      <defs>
        <clipPath id="clip0_9997_33236">
          <rect
            fill="white"
            height="59.3851"
            transform="translate(19.25 19.3075)"
            width="87.5"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RivalsLogomarkBlock;
