import {
  all,
  call,
  debounce,
  getContext,
  put,
  takeLatest
} from "redux-saga/effects";

import API from "rivals/services";
import {
  ArticleResponse as ArticleSearchResponse,
  PredictTextResponse as PredictTextSearchResponse,
  ProspectResponse as ProspectSearchResponse
} from "rivals/services/search/types";
import { TYPEAHEAD_DEBOUNCE_MILLISECONDS } from "rivals/shared/constants";
import {
  SearchActions,
  searchArticle as searchArticleAction,
  searchArticleFailure,
  searchArticleSuccess,
  searchCollegePlayers as searchCollegePlayersAction,
  searchCollegePlayersFailure,
  searchCollegePlayersSuccess,
  searchCollegeTransfers as searchCollegeTransfersAction,
  searchCollegeTransfersFailure,
  searchCollegeTransfersSuccess,
  searchPredictText as searchPredictTextAction,
  searchPredictTextFailure,
  searchPredictTextSuccess,
  searchProspect as searchProspectAction,
  searchProspectFailure,
  searchProspectSuccess
} from "./actions";

// TODO: https://griosf.atlassian.net/browse/RVLS-2792
export function* searchArticle(
  action: ReturnType<typeof searchArticleAction>
): unknown {
  const { payload } = action;
  try {
    const api: API = yield getContext("api");
    const { contents }: ArticleSearchResponse = yield call(
      api.articleSearch,
      payload
    );
    yield put(searchArticleSuccess(contents));
  } catch (error) {
    yield put(searchArticleFailure((<Error>error)?.message));
  }
}

export function* searchCollegePlayers(
  action: ReturnType<typeof searchCollegePlayersAction>
): unknown {
  const { payload } = action;
  try {
    const api: API = yield getContext("api");
    const response = yield call(api.collegePlayerSearch, payload);
    yield put(searchCollegePlayersSuccess(response));
  } catch (error) {
    yield put(searchCollegePlayersFailure((<Error>error)?.message));
  }
}

export function* searchCollegeTransfers(
  action: ReturnType<typeof searchCollegeTransfersAction>
): unknown {
  const { payload } = action;
  try {
    const api: API = yield getContext("api");
    const response = yield call(api.collegeTransfersSearch, payload);
    yield put(searchCollegeTransfersSuccess(response));
  } catch (error) {
    yield put(searchCollegeTransfersFailure((<Error>error)?.message));
  }
}

export function* searchPredictText(
  action: ReturnType<typeof searchPredictTextAction>
): unknown {
  try {
    const api: API = yield getContext("api");
    const { predictions }: PredictTextSearchResponse = yield call(
      api.predictTextSearch,
      {
        term: action.payload
      }
    );
    yield put(searchPredictTextSuccess(predictions));
  } catch (error) {
    yield put(searchPredictTextFailure((<Error>error)?.message));
  }
}

// TODO: https://griosf.atlassian.net/browse/RVLS-2792
export function* searchProspect(
  action: ReturnType<typeof searchProspectAction>
): unknown {
  const { payload } = action;
  try {
    const api: API = yield getContext("api");
    const { people }: ProspectSearchResponse = yield call(
      api.prospectSearch,
      payload
    );
    yield put(searchProspectSuccess(people));
  } catch (error) {
    yield put(searchProspectFailure((<Error>error)?.message));
  }
}

export default function* saga(): Generator {
  yield all([
    debounce(
      TYPEAHEAD_DEBOUNCE_MILLISECONDS,
      SearchActions.HEADER_SEARCH_ARTICLE,
      searchArticle
    ),
    takeLatest(SearchActions.HEADER_SEARCH_PREDICT_TEXT, searchPredictText),
    debounce(
      TYPEAHEAD_DEBOUNCE_MILLISECONDS,
      SearchActions.HEADER_SEARCH_PROSPECT,
      searchProspect
    ),
    takeLatest(SearchActions.SEARCH_COLLEGE_PLAYERS, searchCollegePlayers),
    takeLatest(SearchActions.SEARCH_COLLEGE_TRANSFERS, searchCollegeTransfers)
  ]);
}
