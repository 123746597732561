"use client";

import Cookies from "js-cookie";
import { isEmpty } from "lodash";
import { FunctionComponent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showAlert } from "rivals/redux/alert/actions";
import { AlertTypes } from "rivals/redux/alert/reducer";
import { isAuthenticated } from "rivals/redux/authentication/selectors";
import { selectCCPAFooterConstants } from "rivals/redux/rails/selectors";
import {
  getCurrentUser as getCurrentUserAction,
  getIsCurrentUserValid,
  requestConfirmationEmail
} from "rivals/redux/user/actions";
import { getCurrentUser } from "rivals/redux/user/selectors";
import {
  LAPSED_SUB_ALERT_DURATION_SECONDS,
  PATHS,
  RESEND_EMAIL,
  RIVALS_AUTH_COOKIE,
  UNCONFIRMED_ALERT
} from "rivals/shared/constants";
import { isCurrentUserType } from "rivals/shared/interfaces/User";
import { Props } from "../types";

/**
 * Wrapper component that performs a current user check on page load
 * and displays alerts based on the user's status.
 *
 * Redux version for use in the Page Router only
 */
const AuthenticationWrapper: FunctionComponent<Props> = ({
  children
}: Props) => {
  const dispatch = useDispatch();
  const user = useSelector(getCurrentUser);
  const authenticated = useSelector(isAuthenticated);
  const footerConstants = useSelector(selectCCPAFooterConstants);

  const authLogger = function (msg = ""): void {
    if (process.env.NEXT_PUBLIC_AUTH_LOGGER === "true") {
      const logNameMsg = `AUTH WRAPPER: ${msg}`;
      const baseCookie =
        Cookies.get(RIVALS_AUTH_COOKIE) !== undefined ? true : false;
      const authState = {
        isAuthenticated: authenticated,
        rivalsAuthenticated: baseCookie
      };
      console.log(logNameMsg, "\n", authState); // eslint-disable-line no-console
    }
  };

  useEffect(() => {
    // Alert the user to buy subscription by showing cta banner
    if (footerConstants?.showCtaBanner) {
      dispatch(
        showAlert({
          hasCloseButton: false,
          href: footerConstants.ctaBannerUrl || PATHS.SIGN_UP,
          linkFillsAlert: true,
          linkText: footerConstants.ctaBannerTxt || "",
          message: "",
          persisted: true,
          type: AlertTypes.SITE_ADAPTIVE
        })
      );
    }
    // This check is happening in _app for server side rendered pages
    // Still needs to happen here when naviating from one React page to another
    if (!authenticated) {
      authLogger("getIsCurrentUserValid");
      dispatch(getIsCurrentUserValid());
      return;
    }
    // Fetch the full current user with subscriptions after authenticating
    if (authenticated && !user?.subscriptions) {
      authLogger("getCurrentUser");
      dispatch(getCurrentUserAction());
      return;
    }
    // Alert the user if they need to confirm their email
    if (user && user.email && !user.emailConfirmed) {
      authLogger("showAlert (confirmEmail)");
      dispatch(
        showAlert({
          action: requestConfirmationEmail(user.email),
          linkText: RESEND_EMAIL,
          message: UNCONFIRMED_ALERT
        })
      );
    }
    // Alert the user if they have lapsed subscriptions
    if (user && isCurrentUserType(user) && !isEmpty(user.lapsedSubs)) {
      authLogger("showAlert (lapsedSubs)");
      const { lapsedSubs } = user;
      if (!lapsedSubs) {
        return;
      }
      lapsedSubs.forEach(sub => {
        const { href, linkText, message } = sub;
        dispatch(
          showAlert({
            durationInSeconds: LAPSED_SUB_ALERT_DURATION_SECONDS,
            href,
            linkText,
            message
          })
        );
      });
    }
  }, [authenticated, user, footerConstants]);

  return <>{children}</>;
};

export default AuthenticationWrapper;
