"use client";

import classNames from "classnames";
import dynamic from "next/dynamic";
import { usePathname } from "next/navigation";
import React, {
  FunctionComponent,
  ReactElement,
  useEffect,
  useState
} from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "rivals/components/app/Layout/Header/Header.module.scss";
import LogoSection from "rivals/components/app/Layout/Header/LogoSection";
import RivalsNav from "rivals/components/app/Layout/Header/Nav/WithRedux";
import { fixNavLinksforEnv } from "rivals/components/app/Layout/Header/Nav/utils";
import CustomButton from "rivals/components/shared/CustomButton";
import {
  getNavItems as getNavItemsAction,
  getRailsConstants as getRailsConstantsAction
} from "rivals/redux/rails/actions";
import { RailsConstantsKeys } from "rivals/redux/rails/reducer";
import {
  getAdminPagesNavItems,
  getNavItems,
  getSelectedSite,
  selectNavBarConstants
} from "rivals/redux/rails/selectors";
import { getCurrentUser } from "rivals/redux/user/selectors";
import { ROOT_SITE_IDENTIFIER, USER_ROLES } from "rivals/shared/constants";
import { isUserProtectedPage } from "rivals/shared/utils/routes";
import type { Props } from "../types";

// Preventing Server Side Rendering (SSR) to avoid potential caching of username
const AccountLinks = dynamic(
  () => import("rivals/components/app/Layout/Header/AccountLinks/WithRedux"),
  { ssr: false }
);

const COMPONENTS = [RailsConstantsKeys.NAV_BAR];

const Header: FunctionComponent<Props> = ({
  useLegacyNavTemplate
}): ReactElement | null => {
  const pathname = usePathname() ?? "";
  const dispatch = useDispatch();
  const navConstants = useSelector(selectNavBarConstants);
  const selectedSite = useSelector(getSelectedSite);
  const user = useSelector(getCurrentUser);
  const isAdminPage = pathname?.startsWith("/admin");
  const isAdminReportsPage = pathname?.startsWith("/admin/reports");
  const isNational =
    ROOT_SITE_IDENTIFIER == selectedSite?.shortName?.toLowerCase();
  let navItems = useSelector(isAdminPage ? getAdminPagesNavItems : getNavItems);
  // Prod links are not displayed on admin or user protected pages
  if (!isAdminPage && !isUserProtectedPage(pathname) && navItems) {
    navItems = fixNavLinksforEnv(navItems);
  }
  const [collapsed, toggleCollapsed] = useState(true);

  const showReportingAdmin =
    user && user.role === USER_ROLES.REPORTING_ADMIN && isAdminReportsPage;

  useEffect(() => {
    toggleCollapsed(true);
  }, [pathname]);

  useEffect(() => {
    if (!isAdminPage) {
      dispatch(getNavItemsAction(selectedSite && selectedSite.subdomain));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSite, isAdminPage]);

  useEffect(() => {
    if (!navConstants) {
      dispatch(getRailsConstantsAction(COMPONENTS));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navConstants]);

  const collapsedClass = classNames(
    {
      [styles.collapsed]: collapsed,
      [styles.visible]: !collapsed,
      [styles.hideSecondaryNav]: showReportingAdmin
    },
    styles.header
  );

  const onBurgerClick = (): void => {
    toggleCollapsed(!collapsed);
  };

  return (
    <div className={collapsedClass}>
      <div
        className={styles.headerMenuContainer}
        style={
          selectedSite
            ? {
                backgroundColor: `#${selectedSite.primaryColor}`,
                borderColor: `#${selectedSite.secondaryColor}`
              }
            : {}
        }
      >
        <div className={styles.headerMenu}>
          <div className={styles.headerLogoWrapper}>
            {!showReportingAdmin && (
              <CustomButton
                ariaLabel="mobile navigation fold"
                className={styles.headerNavMobile}
                icon={<div className={styles.navMobileDropdown} />}
                onClick={onBurgerClick}
              />
            )}
            <LogoSection isNational={isNational} selectedSite={selectedSite} />
          </div>
          <div>
            <AccountLinks />
          </div>
        </div>
      </div>
      {!showReportingAdmin && (
        <div className={styles.headerNavLargeBreakpoint}>
          <RivalsNav
            collapsed={collapsed}
            navBarRailsConstants={navConstants}
            navItems={navItems}
            useLegacyTemplate={useLegacyNavTemplate}
          />
        </div>
      )}
    </div>
  );
};

export default Header;
