import { ErrorInfo as ReactExtraInfo } from "react";

export type ExtraInfo = ReactExtraInfo & {
  columnNumber?: number;
  event: Event | string;
  fileno?: number;
  source?: string;
};

export type State<T> = Partial<
  Readonly<{
    byId: T | Error;
  }>
>;

// Currently only works for client errors in the Page Router
const capture = (error?: Error, extraInfo?: ExtraInfo): void => {
  if (!error) {
    return;
  }

  const errorAttributes = {
    componentStack: extraInfo?.componentStack || "",
    errorInfo: JSON.stringify(extraInfo || {})
  };

  if (typeof window !== "undefined") {
    window.newrelic?.noticeError(error, errorAttributes);
  }
};

export default {
  capture
};
