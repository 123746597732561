import {
  BILLING,
  PATHS,
  PROFILE,
  RIVALS_LIST_SPORTS,
  SUBSCRIPTIONS
} from "rivals/shared/constants";
import strings from "./strings";
import type { MenuItem } from "./types";

export const USER_NAV_ELEMENTS: MenuItem[] = [
  {
    path: PATHS.USER_PROFILE,
    title: PROFILE
  },
  {
    path: PATHS.SUBSCRIPTIONS,
    title: SUBSCRIPTIONS
  },
  {
    path: PATHS.BILLING,
    title: BILLING
  }
];

export const ADVANCED_SEARCH_ELEMENTS: MenuItem = {
  path: PATHS.SEARCH,
  subMenuItems: [
    {
      path: PATHS.SEARCH,
      title: strings.navSubTitles.prospectSearch
    },
    {
      path: PATHS.TRANSFER_SEARCH.replace(
        "[sport]",
        RIVALS_LIST_SPORTS.FOOTBALL
      ),
      subMenuItems: [
        {
          path: PATHS.TRANSFER_SEARCH.replace(
            "[sport]",
            RIVALS_LIST_SPORTS.FOOTBALL
          ),
          title: "Football"
        },
        {
          path: PATHS.TRANSFER_SEARCH.replace(
            "[sport]",
            RIVALS_LIST_SPORTS.BASKETBALL
          ),
          title: "Basketball"
        }
      ],
      title: strings.navSubTitles.transferSearch
    }
  ],
  title: strings.navTitles.advancedSearch
};

export const HOME_ELEMENT: MenuItem = {
  path: PATHS.HOME,
  title: strings.navTitles.home
};

export const FUTURECAST_ELEMENTS: MenuItem = {
  path: PATHS.FUTURECAST,
  subMenuItems: [
    {
      path: PATHS.FUTURECAST,
      title: strings.navTitles.futureCast
    },
    {
      path: PATHS.FAN_FUTURECAST_LEADERBOARD,
      title: strings.navSubTitles.fanFuturecastLeaderboard
    }
  ],
  title: strings.navTitles.futureCast
};
