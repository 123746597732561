import { useEffect, useState } from "react";
import { SWRResponse } from "swr";
import useQuery, {
  UseQueryOptions
} from "rivals/shared/hooks/useQuery/useQuery";
import type { Response } from "./types";
import { DidomiConsentEventData } from "./types";

export default function useGetCCPAFooter(
  options?: UseQueryOptions
): SWRResponse<Response> {
  const response = useQuery<Response>("/api/v2/check_jurisdiction", options);
  const [finalState, setFinalState] = useState<Response | undefined>(
    response.data
  );

  useEffect(() => {
    if (!finalState && response?.data) {
      setFinalState(response.data);
    }

    // To trigger a consent event use the following:
    // this is the true case, but adjust thirdPartyContentEmbed if you need to test the false case
    //
    // const event = new CustomEvent("cjsUserConsented", { detail: { consentStatus: false, consentData: { coreEuConsent: false, sellPersonalInformation: false, searchHistory: false, preciseGeolocation: false, thirdPartyContentEmbed: true, oathAsThirdParty: false, contentPersonalization: false, firstPartyAds: false, accountMatching: false, crossDeviceMapping: false }, } });
    // document.dispatchEvent(event);

    if (response?.data?.enableDidomi) {
      window.document.addEventListener("cjsUserConsented", ((
        event: CustomEvent<DidomiConsentEventData>
      ) => {
        const tpcEmbed = event.detail?.consentData?.thirdPartyContentEmbed;

        if (response.data && tpcEmbed) {
          const mergedData: Response = {
            ...response.data,
            thirdPartyEmbedConsent: tpcEmbed
          };
          setFinalState(mergedData);
        }
      }) as EventListener);
    }
  }, [finalState, response.data]);

  return { ...response, data: finalState };
}
