import { NavOptions } from "rivals/components/app/Layout/Header/types";
import { PricingConstants as SubscriptionPricingConstants } from "rivals/components/pages/User/Subscriptions/types";
import { Response as CCPAFooterConstants } from "rivals/services/getCCPAFooter/types";
import { Response as RailsConstantsResponse } from "rivals/services/getRailsConstants/types";
import { Site } from "rivals/shared/interfaces/Site";
import { RailsAction, RailsActions } from "./actions";

// List of all known keys for RailsConstants
export enum RailsConstantsKeys {
  ADS_SETTINGS = "adsSettings",
  AUTO_SAVE = "autoSave",
  BILLING_INFORMATION = "billingInformation",
  CANCELLATION = "cancellation",
  CANCELLATION_FORM = "cancellationForm",
  CANCELLATION_SAVE = "cancellationSave",
  FOOTER = "footer",
  FORGOT_PASSWORD = "forgotPassword",
  FORGOT_USERNAME = "forgotUsername",
  HEADER = "header",
  NAV_BAR = "navBar",
  PROSPECT_PROFILE = "prospectProfile",
  RIVALS_RATING_OPTIONS = "rivalsRatingOptions",
  STATE_LIST = "stateList",
  STRICT_SIGN_IN = "strictSignIn",
  SUBSCRIPTION_PRICING = "subscriptionPricing",
  USER_PROFILE = "userProfile",
  USER_SUBSCRIPTIONS = "userSubscriptions"
}

export interface State {
  CCPAFooter?: CCPAFooterConstants;
  addressValidationError?: string;
  addressValidationSuccess?: boolean;
  error?: string;
  navItems?: NavOptions;
  railsConstants?: RailsConstantsResponse;
  selectedSite?: Site;
  sites?: Site[];
  taxEstimationError?: string;
}

const initialState = {
  error: undefined,
  railsConstants: undefined,
  sites: undefined
};

export default function reducer(
  state: State = initialState,
  action: RailsAction
): State {
  switch (action.type) {
    case RailsActions.VALIDATE_ADDRESS_THROUGH_TAX_ESTIMATION_SUCCESS: {
      return {
        ...state,
        addressValidationError: undefined,
        addressValidationSuccess: true
      };
    }
    case RailsActions.VALIDATE_ADDRESS_THROUGH_TAX_ESTIMATION_FAILURE: {
      return {
        ...state,
        addressValidationError: action.payload.error,
        addressValidationSuccess: false
      };
    }
    case RailsActions.RESET_ADDRESS_VALIDATION_FIELDS: {
      return {
        ...state,
        addressValidationError: undefined,
        addressValidationSuccess: undefined
      };
    }
    case RailsActions.GET_CCPA_FOOTER_SUCCESS: {
      return {
        ...state,
        CCPAFooter: { ...action.payload }
      };
    }
    case RailsActions.GET_TAX_FAILURE: {
      const subscriptionPricingConstants =
        state.railsConstants &&
        state.railsConstants[RailsConstantsKeys.SUBSCRIPTION_PRICING];
      return {
        ...state,
        railsConstants: {
          ...state.railsConstants,
          [RailsConstantsKeys.SUBSCRIPTION_PRICING]: {
            ...subscriptionPricingConstants,
            tax: undefined
          } as SubscriptionPricingConstants
        },
        taxEstimationError: action.payload.error
      };
    }
    case RailsActions.GET_CCPA_FOOTER_FAILURE:
    case RailsActions.GET_RAILS_CONSTANTS_FAILURE: {
      return {
        ...state,
        error: action.payload.error
      };
    }
    case RailsActions.GET_NAV_ITEMS_SUCCESS: {
      return {
        ...state,
        navItems: action.payload.menuItems
      };
    }
    case RailsActions.GET_RAILS_CONSTANTS_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        error: undefined,
        railsConstants: { ...state.railsConstants, ...payload }
      };
    }
    case RailsActions.GET_SITES_FAILURE:
    case RailsActions.GET_SITE_FAILURE: {
      return {
        ...state,
        error: action.payload.error
      };
    }
    case RailsActions.GET_SITES_SUCCESS: {
      return {
        ...state,
        error: undefined,
        sites: action.payload
      };
    }
    case RailsActions.GET_SITE_SUCCESS: {
      return {
        ...state,
        error: undefined,
        selectedSite: action.payload
      };
    }
    case RailsActions.GET_TAX_SUCCESS: {
      const subscriptionPricingConstants =
        state.railsConstants &&
        state.railsConstants[RailsConstantsKeys.SUBSCRIPTION_PRICING];
      const railsConstants = {
        ...state.railsConstants,
        [RailsConstantsKeys.SUBSCRIPTION_PRICING]: {
          ...subscriptionPricingConstants,
          tax: action.payload.tax
        } as SubscriptionPricingConstants
      };
      return {
        ...state,
        railsConstants,
        taxEstimationError: undefined
      };
    }
    default: {
      return state;
    }
  }
}
