import { AsyncThunkPayloadCreator, createAsyncThunk } from "@reduxjs/toolkit";
import { apiService } from "rivals/redux/store";
import { Response as SearchCollegesResponse } from "rivals/services/searchColleges/types";
import RivalsApiError from "rivals/shared/error";

enum CollegeActions {
  SEARCH_COLLEGES = "colleges/searchColleges",
  SEARCH_EXIT_COLLEGES = "colleges/searchExitColleges",
  SEARCH_ORIGIN_COLLEGES = "colleges/searchOriginColleges"
}

const searchCollegesPayloadCreator: AsyncThunkPayloadCreator<
  SearchCollegesResponse,
  string
> = async (request, { rejectWithValue }) => {
  try {
    const response: SearchCollegesResponse = await apiService.searchColleges(
      request
    );
    return response;
  } catch (error) {
    return rejectWithValue((<RivalsApiError>error)?.message);
  }
};

export const searchColleges = createAsyncThunk(
  CollegeActions.SEARCH_COLLEGES,
  searchCollegesPayloadCreator
);
export const searchExitColleges = createAsyncThunk(
  CollegeActions.SEARCH_EXIT_COLLEGES,
  searchCollegesPayloadCreator
);
export const searchOriginColleges = createAsyncThunk(
  CollegeActions.SEARCH_ORIGIN_COLLEGES,
  searchCollegesPayloadCreator
);
