import { isClient } from "./isClient";

export const shouldAllowPaypalQa = (): boolean => {
  if (isClient()) {
    const PAYPAL_QA_ROUTE_SEGMENT = "qapp";
    const isPaypalQaRoute = window.location.pathname
      .split("/")
      .includes(PAYPAL_QA_ROUTE_SEGMENT);

    if (isPaypalQaRoute) {
      return true;
    }
  }

  return false;
};

export const getPaypalEnvSafe = (): string | undefined => {
  const PRODUCTION_ENV = "production";

  if (shouldAllowPaypalQa()) {
    return PRODUCTION_ENV;
  }

  return process.env.NEXT_PUBLIC_PAYPAL_ENV;
};
