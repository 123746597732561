import { theme } from "antd";
import classNames from "classnames";
import { FunctionComponent, PropsWithChildren, ReactElement } from "react";
import gtAmericaExtended from "rivals/shared/fonts/gtAmerica/extended";
import gtAmerica from "rivals/shared/fonts/gtAmerica/normal";
import rivalsNumbersLogo from "rivals/shared/fonts/rivalsNumbers/logo";
import rivalsNumbersRanking from "rivals/shared/fonts/rivalsNumbers/ranking";
import yahooSans from "rivals/shared/fonts/yahooSans";

const RootStyles: FunctionComponent<PropsWithChildren> = ({
  children
}): ReactElement => {
  const { token } = theme.useToken();

  return (
    <div
      className={classNames(
        gtAmerica.variable,
        gtAmericaExtended.variable,
        rivalsNumbersLogo.variable,
        rivalsNumbersRanking.variable,
        yahooSans.variable
      )}
      style={{
        color: token.colorTextBase,
        fontFamily: token.fontFamily,
        fontFeatureSettings: "tnum",
        fontSize: token.fontSize,
        fontVariant: "tabular-nums",
        lineHeight: token.lineHeight
      }}
    >
      {children}
    </div>
  );
};

export default RootStyles;
