import { SearchPeopleResponse } from "rivals/services/container";
import {
  CollegeTransferSearchRequest,
  CollegeTransferSearchResponse,
  PersonSearchRequest
} from "rivals/services/search/types";
import { Prospect } from "rivals/shared/interfaces/Prospect";
import { SearchArticle } from "rivals/shared/interfaces/search";

export enum SearchActions {
  CLEAR_COLLEGE_TRANSFERS = "CLEAR_COLLEGE_TRANSFERS",
  SEARCH_COLLEGE_PLAYERS = "SEARCH_COLLEGE_PLAYERS",
  SEARCH_COLLEGE_PLAYERS_FAILURE = "SEARCH_COLLEGE_PLAYERS_FAILURE",
  SEARCH_COLLEGE_PLAYERS_SUCCESS = "SEARCH_COLLEGE_PLAYERS_SUCCESS",
  SEARCH_COLLEGE_TRANSFERS = "SEARCH_COLLEGE_TRANSFERS",
  SEARCH_COLLEGE_TRANSFERS_FAILURE = "SEARCH_COLLEGE_TRANSFERS_FAILURE",
  SEARCH_COLLEGE_TRANSFERS_SUCCESS = "SEARCH_COLLEGE_TRANSFERS_SUCCESS",
  HEADER_SEARCH_ARTICLE = "HEADER_SEARCH_ARTICLE",
  HEADER_SEARCH_ARTICLE_FAILURE = "HEADER_SEARCH_ARTICLE_FAILURE",
  HEADER_SEARCH_ARTICLE_SUCCESS = "HEADER_SEARCH_ARTICLE_SUCCESS",
  HEADER_SEARCH_PREDICT_TEXT = "HEADER_SEARCH_PREDICT_TEXT",
  HEADER_SEARCH_PREDICT_TEXT_FAILURE = "HEADER_SEARCH_PREDICT_TEXT_FAILURE",
  HEADER_SEARCH_PREDICT_TEXT_SUCCESS = "HEADER_SEARCH_PREDICT_TEXT_SUCCESS",
  HEADER_SEARCH_PROSPECT = "HEADER_SEARCH_PROSPECT",
  HEADER_SEARCH_PROSPECT_SUCCESS = "HEADER_SEARCH_PROSPECT_SUCCESS",
  HEADER_SEARCH_PROSPECT_FAILURE = "HEADER_SEARCH_PROSPECT_FAILURE"
}

export const searchArticle = (term?: string, limit?: number) =>
  ({
    payload: { limit, term },
    type: SearchActions.HEADER_SEARCH_ARTICLE
  } as const);

export const searchArticleFailure = (error?: string) =>
  ({
    error: true,
    payload: { error },
    type: SearchActions.HEADER_SEARCH_ARTICLE_FAILURE
  } as const);

export const searchArticleSuccess = (contents: SearchArticle[]) =>
  ({
    payload: contents,
    type: SearchActions.HEADER_SEARCH_ARTICLE_SUCCESS
  } as const);

export const searchCollegePlayers = (searchParams: PersonSearchRequest) =>
  ({
    payload: searchParams,
    type: SearchActions.SEARCH_COLLEGE_PLAYERS
  } as const);

export const searchCollegePlayersFailure = (error?: string) =>
  ({
    error: true,
    payload: { error },
    type: SearchActions.SEARCH_COLLEGE_PLAYERS_FAILURE
  } as const);

export const searchCollegePlayersSuccess = (response: SearchPeopleResponse) =>
  ({
    payload: response,
    type: SearchActions.SEARCH_COLLEGE_PLAYERS_SUCCESS
  } as const);

export const searchCollegeTransfers = (
  searchParams: CollegeTransferSearchRequest
) =>
  ({
    payload: searchParams,
    type: SearchActions.SEARCH_COLLEGE_TRANSFERS
  } as const);

export const searchCollegeTransfersFailure = (error?: string) =>
  ({
    error: true,
    payload: { error },
    type: SearchActions.SEARCH_COLLEGE_TRANSFERS_FAILURE
  } as const);

export const searchCollegeTransfersSuccess = (
  response: CollegeTransferSearchResponse
) =>
  ({
    payload: response,
    type: SearchActions.SEARCH_COLLEGE_TRANSFERS_SUCCESS
  } as const);

export const clearCollegeTransfers = () =>
  ({
    type: SearchActions.CLEAR_COLLEGE_TRANSFERS
  } as const);

export const searchPredictText = (term?: string) =>
  ({
    payload: term,
    type: SearchActions.HEADER_SEARCH_PREDICT_TEXT
  } as const);

export const searchPredictTextFailure = (error?: string) =>
  ({
    error: true,
    payload: { error },
    type: SearchActions.HEADER_SEARCH_PREDICT_TEXT_FAILURE
  } as const);

export const searchPredictTextSuccess = (predictions: string[]) =>
  ({
    payload: predictions,
    type: SearchActions.HEADER_SEARCH_PREDICT_TEXT_SUCCESS
  } as const);

export const searchProspect = (term?: string, limit?: number) =>
  ({
    payload: { limit, term },
    type: SearchActions.HEADER_SEARCH_PROSPECT
  } as const);

export const searchProspectFailure = (error?: string) =>
  ({
    error: true,
    payload: { error },
    type: SearchActions.HEADER_SEARCH_PROSPECT_FAILURE
  } as const);

export const searchProspectSuccess = (people: Prospect[]) =>
  ({
    payload: people,
    type: SearchActions.HEADER_SEARCH_PROSPECT_SUCCESS
  } as const);

export type SearchAction =
  | ReturnType<typeof searchArticle>
  | ReturnType<typeof searchArticleFailure>
  | ReturnType<typeof searchArticleSuccess>
  | ReturnType<typeof searchCollegePlayers>
  | ReturnType<typeof searchCollegePlayersFailure>
  | ReturnType<typeof searchCollegePlayersSuccess>
  | ReturnType<typeof clearCollegeTransfers>
  | ReturnType<typeof searchCollegeTransfers>
  | ReturnType<typeof searchCollegeTransfersFailure>
  | ReturnType<typeof searchCollegeTransfersSuccess>
  | ReturnType<typeof searchPredictText>
  | ReturnType<typeof searchPredictTextFailure>
  | ReturnType<typeof searchPredictTextSuccess>
  | ReturnType<typeof searchProspect>
  | ReturnType<typeof searchProspectFailure>
  | ReturnType<typeof searchProspectSuccess>;
