import { UpgradeAnnualParameters } from "rivals/components/pages/User/Subscriptions/Upgrade/types";
import { Request as CreateSubscriptionRequest } from "rivals/services/createSubscription/types";
import {
  SaveEligibility,
  Subscription
} from "rivals/shared/interfaces/Subscription";

export enum SubscriptionActions {
  AUTO_SAVE = "AUTO_SAVE",
  AUTO_SAVE_FAILURE = "AUTO_SAVE_FAILURE",
  AUTO_SAVE_SUCCESS = "AUTO_SAVE_SUCCESS",
  CANCEL_SUBSCRIPTION = "CANCEL_SUBSCRIPTION",
  CANCEL_SUBSCRIPTION_FAILURE = "CANCEL_SUBSCRIPTION_FAILURE",
  CANCEL_SUBSCRIPTION_SUCCESS = "CANCEL_SUBSCRIPTION_SUCCESS",
  CREATE_SUBSCRIPTION = "CREATE_SUBSCRIPTION",
  CREATE_SUBSCRIPTION_FAILURE = "CREATE_SUBSCRIPTION_FAILURE",
  CREATE_SUBSCRIPTION_SUCCESS = "CREATE_SUBSCRIPTION_SUCCESS",
  EMAIL_OFFER = "EMAIL_OFFER",
  EMAIL_OFFER_FAILURE = "EMAIL_OFFER_FAILURE",
  EMAIL_OFFER_SUCCESS = "EMAIL_OFFER_SUCCESS",
  EXTEND_MGM_SUBSCRIPTION = "EXTEND_MGM_SUBSCRIPTION",
  EXTEND_MGM_SUBSCRIPTION_FAILURE = "EXTEND_MGM_SUBSCRIPTION_FAILURE",
  EXTEND_MGM_SUBSCRIPTION_SUCCESS = "EXTEND_MGM_SUBSCRIPTION_SUCCESS",
  GET_SAVE_ELIGIBILITY = "GET_SAVE_ELIGIBILITY",
  GET_SAVE_ELIGIBILITY_SUCCESS = "GET_SAVE_ELIGIBILITY_SUCCESS",
  PAY_OUTSTANDING_BALANCE = "PAY_OUTSTANDING_BALANCE",
  PAY_OUTSTANDING_BALANCE_SUCCESS = "PAY_OUTSTANDING_BALANCE_SUCCESS",
  PAY_OUTSTANDING_BALANCE_FAILURE = "PAY_OUTSTANDING_BALANCE_FAILURE",
  REACTIVATE_SUBSCRIPTION = "REACTIVATE_SUBSCRIPTION",
  REACTIVATE_SUBSCRIPTION_FAILURE = "REACTIVATE_SUBSCRIPTION_FAILURE",
  REACTIVATE_SUBSCRIPTION_SUCCESS = "REACTIVATE_SUBSCRIPTION_SUCCESS",
  UPGRADE_TO_ANNUAL = "UPGRADE_TO_ANNUAL",
  UPGRADE_TO_ANNUAL_FAILURE = "UPGRADE_TO_ANNUAL_FAILURE"
}

export const autoSave = (subscriptionId: number) =>
  ({
    payload: {
      subscriptionId
    },
    type: SubscriptionActions.AUTO_SAVE
  } as const);

export const autoSaveSuccess = () =>
  ({ type: SubscriptionActions.AUTO_SAVE_SUCCESS } as const);

export const autoSaveFailure = (error?: string) =>
  ({
    payload: { error },
    type: SubscriptionActions.AUTO_SAVE_FAILURE
  } as const);

export const cancelSubscription = (
  subscriptionId: number,
  cancelReason: string,
  cancelText: string
) =>
  ({
    payload: {
      cancelReason,
      cancelText,
      subscriptionId
    },
    type: SubscriptionActions.CANCEL_SUBSCRIPTION
  } as const);

export const cancelSubscriptionSuccess = () =>
  ({ type: SubscriptionActions.CANCEL_SUBSCRIPTION_SUCCESS } as const);

export const cancelSubscriptionFailure = (error?: string) =>
  ({
    payload: { error },
    type: SubscriptionActions.CANCEL_SUBSCRIPTION_FAILURE
  } as const);

export const createSubscription = (request: CreateSubscriptionRequest) =>
  ({
    payload: request,
    type: SubscriptionActions.CREATE_SUBSCRIPTION
  } as const);

export const createSubscriptionSuccess = () =>
  ({ type: SubscriptionActions.CREATE_SUBSCRIPTION_SUCCESS } as const);

export const createSubscriptionFailure = (error?: string) =>
  ({
    payload: { error },
    type: SubscriptionActions.CREATE_SUBSCRIPTION_FAILURE
  } as const);

export const emailOffer = (subscriptionId: number) =>
  ({
    payload: {
      subscriptionId
    },
    type: SubscriptionActions.EMAIL_OFFER
  } as const);

export const emailOfferFailure = (error?: string) =>
  ({
    payload: { error },
    type: SubscriptionActions.EMAIL_OFFER_FAILURE
  } as const);

export const emailOfferSuccess = () =>
  ({ type: SubscriptionActions.EMAIL_OFFER_SUCCESS } as const);

export const extendMgmSubscription = (subscriptionId: number) =>
  ({
    payload: {
      subscriptionId
    },
    type: SubscriptionActions.EXTEND_MGM_SUBSCRIPTION
  } as const);

export const extendMgmSubscriptionFailure = (error?: string) =>
  ({
    payload: { error },
    type: SubscriptionActions.EXTEND_MGM_SUBSCRIPTION_FAILURE
  } as const);

export const extendMgmSubscriptionSuccess = () =>
  ({ type: SubscriptionActions.EXTEND_MGM_SUBSCRIPTION_SUCCESS } as const);

export const getSaveEligibility = (subscriptionId: string | string[]) =>
  ({
    payload: {
      subscriptionId
    },
    type: SubscriptionActions.GET_SAVE_ELIGIBILITY
  } as const);

export const getSaveEligibilitySuccess = (saveEligibility: SaveEligibility) =>
  ({
    payload: {
      saveEligibility
    },
    type: SubscriptionActions.GET_SAVE_ELIGIBILITY_SUCCESS
  } as const);

export const payOutstandingBalance = () =>
  ({
    type: SubscriptionActions.PAY_OUTSTANDING_BALANCE
  } as const);

export const payOutstandingBalanceFailure = (error?: string) =>
  ({
    error: true,
    payload: { error },
    type: SubscriptionActions.PAY_OUTSTANDING_BALANCE_FAILURE
  } as const);

export const payOutstandingBalanceSuccess = (message: string) =>
  ({
    payload: { message },
    type: SubscriptionActions.PAY_OUTSTANDING_BALANCE_SUCCESS
  } as const);

export const reactivateSubscription = (subscriptionId: number) =>
  ({
    payload: {
      subscriptionId
    },
    type: SubscriptionActions.REACTIVATE_SUBSCRIPTION
  } as const);

export const reactivateSubscriptionFailure = (error?: string) =>
  ({
    error: true,
    payload: { error },
    type: SubscriptionActions.REACTIVATE_SUBSCRIPTION_FAILURE
  } as const);

export const reactivateSubscriptionSuccess = (subscription: Subscription) =>
  ({
    payload: {
      subscription
    },
    type: SubscriptionActions.REACTIVATE_SUBSCRIPTION_SUCCESS
  } as const);

export const upgradeToAnnual = ({
  autoRenewalOptIn,
  promoCode,
  subscriptionId,
  subscriptionPlan,
  userId
}: UpgradeAnnualParameters) =>
  ({
    payload: {
      autoRenewalOptIn,
      promoCode,
      subscriptionId,
      subscriptionPlan,
      userId
    },
    type: SubscriptionActions.UPGRADE_TO_ANNUAL
  } as const);

export const upgradeToAnnualFailure = (error?: string) =>
  ({
    error: true,
    payload: { error },
    type: SubscriptionActions.UPGRADE_TO_ANNUAL_FAILURE
  } as const);

export type SubscriptionAction =
  | ReturnType<typeof autoSave>
  | ReturnType<typeof autoSaveFailure>
  | ReturnType<typeof autoSaveSuccess>
  | ReturnType<typeof cancelSubscription>
  | ReturnType<typeof cancelSubscriptionFailure>
  | ReturnType<typeof cancelSubscriptionSuccess>
  | ReturnType<typeof createSubscription>
  | ReturnType<typeof createSubscriptionFailure>
  | ReturnType<typeof createSubscriptionSuccess>
  | ReturnType<typeof emailOffer>
  | ReturnType<typeof emailOfferFailure>
  | ReturnType<typeof emailOfferSuccess>
  | ReturnType<typeof extendMgmSubscription>
  | ReturnType<typeof extendMgmSubscriptionFailure>
  | ReturnType<typeof extendMgmSubscriptionSuccess>
  | ReturnType<typeof getSaveEligibility>
  | ReturnType<typeof getSaveEligibilitySuccess>
  | ReturnType<typeof payOutstandingBalance>
  | ReturnType<typeof payOutstandingBalanceFailure>
  | ReturnType<typeof payOutstandingBalanceSuccess>
  | ReturnType<typeof reactivateSubscription>
  | ReturnType<typeof reactivateSubscriptionFailure>
  | ReturnType<typeof reactivateSubscriptionSuccess>
  | ReturnType<typeof upgradeToAnnual>
  | ReturnType<typeof upgradeToAnnualFailure>;
